import * as typeCrudForm from "src/components/Common/typeCrudForm";
import FormCrudContainer from "src/components/CRUD/CrudForm";
import FormManufacturer from "./forms/form";

export default function FormCrud(props: {
  handleNewRegister: (mode: string) => void;
  queryGetByCode: any;
  queryGet: any;
  querySave: any;
  queryUpdate: any;
  queryDelete: any;
  selectedRow: string;
  setSelectedRow: any;
  queryNextCrCode: any;
  columnsTable: Array<typeCrudForm.typeCrudForm>;
}) {
  const { handleNewRegister } = props;

  return (
    <FormCrudContainer
      Form={FormManufacturer}
      handleNewRegister={handleNewRegister}
      {...props}
    />
  );
}
