import React from 'react';
import { typeCrudForm } from 'src/components/Common/typeCrudForm';
import FormCrudContainer from 'src/components/CRUD/CrudForm';
import FormManufacturer from './forms/formManufacturer';
import {post} from "../../../helpers/api_helper";
import {getDays} from "../../Day/graphqlQuery";

export default function FormCrud(props: {
  handleNewRegister: (mode: string) => void;
  queryGetByCode: any;
  queryGet: any;
  querySave: any;
  queryUpdate: any;
  queryDelete: any;
  selectedRow: string;
  setSelectedRow: any;
  queryNextCrCode: any;
  columnsTable: Array<typeCrudForm>;
}) {
  const { handleNewRegister } = props;
  const [listDays, setListDays] = React.useState([]);

  React.useEffect(() => {
    async function getListDays() {
      let response = await post(
          "/graphql",
          {
            query: getDays(),
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
      ).then(response => response);
      if (response.data[Object.keys(response.data)[0]]) {
        let temp = [];
        const list = response.data[Object.keys(response.data)[0]];
        list.map(item => {
          temp.push({
            cr_code: item.cr_code,
            ws_value: item.ws_title,
          });
        });
        temp = [
          {
            nameList: "ws_days",
            content: temp,
          },
        ];
        setListDays(temp);
      } else {
        alert("Falha ao trazer lista de Days!");
        setListDays([]);
      }
    }
    getListDays().then();
  }, []);

  return (
    <FormCrudContainer
      Form={FormManufacturer}
      handleNewRegister={handleNewRegister}
      listItens={listDays}
      {...props}
    />
  );
}
