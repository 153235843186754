import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Editor } from "react-draft-wysiwyg"
import { ContentState, convertToRaw, EditorState } from "draft-js"
import draftToHtml from "draftjs-to-html"
import htmlToDraft from "html-to-draftjs"
import { AvField, AvForm } from "availity-reactstrap-validation";
import moment from "moment"
//import Breadcrumbs
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap"
import { API_URL_UPLOAD, post } from "src/helpers/api_helper"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { getConfigurationRMA, updateConfigurationRMA } from "./graphqlQuery"

const UploadRma = () => {
  const [fileWarrantyTable, setFileWarrantyTable] = useState(null);
  const [fileProcedureRMA, setFileProcedureRMA] = useState(null);
  const [dataFormDb, setDataFormDb] = useState("");
  const [valueField, setValueField] = useState("");
  const [valueFieldDateTimeBegin, setValueFieldDateTimeBegin] = useState("");
  const [valueFieldDateTimeEnd, setValueFieldDateTimeEnd] = useState("");
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const locationWarrantyTable = "/user/rma/tabela_garantia/";
  const locationProcedureRMA = "/user/rma/procedimento_rma/";
  // const locationWarrantyTable = "/teste/";
  // const locationProcedureRMA = "/teste/";

  const nameWarrantyTable = "Tabela_garantia.pdf";
  const nameProcedureRMA =  "Procedimento_de_RMA.pdf";

  const contentBlock = dataFormDb ? htmlToDraft(dataFormDb) : "";

  const onFileUpload = async (file, location, name) => {
    const formData = new FormData();    
    formData.append("location", location);
    formData.append("namefile", name);
    formData.append("file", file);
    
    const options = {
      method: 'POST',
      body: formData,
      maxContentLength: "infinity",
      maxBodyLength: "infinity",
    };
    
    fetch(`${API_URL_UPLOAD}/user/upload`, options)    
      .then(response => alert(`Arquivo ${name} enviado com sucesso!`))
      .catch(error => alert(`Arquivo ${name} enviado com sucesso!`)/* alert(`Erro ao enviar arquivo ${name}!`) */);
  };

  const updateTextDescription = async state => {
    await setEditorState(state);

    const data = convertToRaw(editorState.getCurrentContent());
    setValueField(draftToHtml(data))
  };

  /* Lógica de requisições BACK END */
  const getDataPolicy = React.useCallback(async () => {
    try {
      let response = await post(
        "/graphql",
        {
          query: getConfigurationRMA(),
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then(response => response)
        .catch(error => alert("Falha ao buscar Configuração de RMA!"));
      if (response.data) {
        setDataFormDb(response.data.getAlertRmaAdmin.wt_commercial_text)
        setValueFieldDateTimeBegin(response.data.getAlertRmaAdmin.dt_publication_begin)
        setValueFieldDateTimeEnd(response.data.getAlertRmaAdmin.dt_publication_end)
      }
    } catch (e) {
      setValueField("");
    }
  }, []);

  const updateDataPolicy = React.useCallback(async () => {
    if (!valueField) {
      alert("Nenhuma alteração realizada!")
      return
    }
    const data = {
      wt_commercial_text: valueField,
      dt_publication_end: valueFieldDateTimeEnd,
      dt_publication_begin: valueFieldDateTimeBegin
    }
    try {
      await post(
        "/graphql",
        {
          query: updateConfigurationRMA(data),
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then(response => {
          if(response.data.updateConfigurationRMA.status=== 200){
            alert(response.data.updateConfigurationRMA.result)
            getDataPolicy();
          }
        })
        .catch(error => {
          console.log(error)
          alert("Falha ao editar Política de Privacidade!")
        });
    } catch (e) {
      console.log(e);
    }
  }, [valueField]);

  useEffect(() => {
    getDataPolicy();
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const editorState = EditorState.createWithContent(contentState);
      setEditorState(editorState);
    }
  }, [dataFormDb]);

  useEffect(() => {}, [valueFieldDateTimeBegin, valueFieldDateTimeEnd]);

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Uploads RMA | Braile Distribuidora</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Outros" breadcrumbItem="Uploads RMA" />
          <Container fluid>
            <Card style={{ backgroundColor: "#f1f1f1" }}>
              <CardBody>
                <Row>
                  <Col className="mt-3" lg={12} sm={12} md={12} xs={12}>
                    <span>
                      Utilize este formulário para atualizar os arquivos de
                      download do RMA. IMPORTANTE: os arquivos devem estar no
                      formato PDF.
                    </span>
                    <div
                      style={{
                        border: "1px solid silver",
                        borderRadius: "5px",
                        padding: "15px",
                        backgroundColor: "white",
                      }}
                    >
                      <Row>
                        <Col className="mt-3" md={3}>
                          <b>Tabela de Garantia</b>{" "}
                        </Col>
                        <Col className="mt-3" md={9}>
                          <span>
                            {fileWarrantyTable ? fileWarrantyTable.name : nameWarrantyTable}
                          </span>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="mt-3" md={3}>
                          <b>Arquivo upload</b>
                        </Col>
                        <Col className="mt-3" md={9}>
                          <input
                            type="file"
                            id="selectedFileWarrantyTable"
                            style={{ display: "none" }}
                            onChange={e => setFileWarrantyTable(e.target.files[0])}
                          />
                          <input
                            type="button"
                            value="Escolher arquivo"
                            onClick={() => { document.getElementById('selectedFileWarrantyTable').click() }}
                          />
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col className="mt-3" md={3}>
                          <b>Procedimento de RMA</b>{" "}
                        </Col>
                        <Col className="mt-3" md={9}>
                          <span>
                            {fileProcedureRMA ? fileProcedureRMA.name : nameProcedureRMA}
                          </span>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="mt-3" md={3}>
                          <b>Arquivo upload</b>
                        </Col>
                        <Col className="mt-3" md={9}>
                          <input
                            type="file"
                            id="selectedFileProcedureRMA"
                            style={{ display: "none" }}
                            onChange={e => setFileProcedureRMA(e.target.files[0])}
                          />
                          <input
                            type="button"
                            value="Escolher arquivo"
                            onClick={() => { document.getElementById('selectedFileProcedureRMA').click() }}
                          />
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
                <div className="d-flex flex-wrap gap-2 mt-3">
                  <Button type="submit" color="success" onClick={() => {
                    if(fileWarrantyTable){
                      onFileUpload(fileWarrantyTable, locationWarrantyTable, 'Tabela_garantia');
                    }
                    if(fileProcedureRMA){
                      onFileUpload(fileProcedureRMA, locationProcedureRMA, 'Procedimento_de_RMA')
                    }
                  }}>
                    Confirmar
                  </Button>
                </div>
              </CardBody>
            </Card>
          </Container>
        </Container>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Outros" breadcrumbItem="Configuração da tela RMA" />
          <Container fluid>            
            <Card style={{ backgroundColor: "#f1f1f1" }}>
              <CardBody>
                <Row>
                  <Col className="mt-3" lg={4} sm={4} md={4} xs={4}>
                    <AvForm>
                      {/* <AvField name="dateRange" label="Data inicial da configuração" type="date" validate={{dateRange: {start: {value: -5, units: 'years',} ,end: {value: 5, units: 'years',}}}} /> */}                    
                      <AvField
                        value={valueFieldDateTimeBegin ? moment(moment(valueFieldDateTimeBegin, "YYYY-MM-DD")).format("YYYY-MM-DD") : null}
                        name={"begin"}
                        label={"Data inicial"}
                        placeholder=""
                        type="date"   
                        onChange={e => setValueFieldDateTimeBegin(e.target.value)}                   
                      />
                    </AvForm>
                  </Col>
                  <Col className="mt-3" lg={4} sm={4} md={4} xs={4}>
                    <AvForm>
                      {/* <AvField name="dateRange" label="Data final da configuração" type="date" validate={{dateRange: {start: {value: -5, units: 'years',} ,end: {value: 5, units: 'years',}}}} /> */}                    
                      <AvField
                        value={valueFieldDateTimeEnd ? moment(moment(valueFieldDateTimeEnd, "YYYY-MM-DD")).format("YYYY-MM-DD") : null}
                        name={"begin"}
                        label={"Data inicial"}
                        placeholder=""
                        type="date"             
                        onChange={e => setValueFieldDateTimeEnd(e.target.value)}                            
                      />
                    </AvForm>
                  </Col>
                </Row>
                <Row>
                  <Col className="mt-3" lg={12} sm={12} md={12} xs={12}>
                    <Editor
                      editorState={editorState}
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapperClassName"
                      editorClassName="editorClassName"
                      editorStyle={{ height: "25vh", backgroundColor: "white" }}
                      onEditorStateChange={updateTextDescription}
                    />
                  </Col>
                </Row>
                <div className="d-flex flex-wrap gap-2 mt-3">
                  <Button type="submit" color="success" onClick={() => updateDataPolicy()}>
                    Salvar configuração
                  </Button>
                </div>
              </CardBody>            
            </Card>
          </Container>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default UploadRma;
