import { typeCrudListColumns } from "src/components/Common/typeCridList";
import {
  typeCrudCollapse,
  typeCrudForm,
} from "src/components/Common/typeCrudForm";
import GenerateForm from "src/components/CRUD/generateComponents";

/* Lógica de requisições BACK END retorna um novo CR_CODE */
const FormSubCategory = (props: {
  dataFormDb: {
    cr_code: String;
    ws_name: String;
    /* qi_categories: String; */
    bf_sale_cartucho: Boolean;
  };
  columnsTable: Array<typeCrudListColumns>;
  nextCrCode: string;
  listItens: any;
}) => {
  let { dataFormDb, columnsTable, nextCrCode, listItens } = props;
  if (nextCrCode) {
    dataFormDb.cr_code = nextCrCode;
  }

  function adapterListCategories(): Array<{
    cr_code: string;
    ws_value: string;
  }> {
    let temp = [];
    const list = listItens.filter(item => item.nameList === "ws_categories");
    list.length &&
      list.length > 0 &&
      list[0].content.map(item =>
        temp.push({
          cr_code: item.cr_code,
          ws_value: item.ws_value,
        })
      );
    return temp;
  }

  const subtitleContent: Array<typeCrudForm> = [
    {
      Header: "Código",
      accessor: "cr_code",
      cols: "6",
      required: true,
    },
    {
      Header: "Liberação",
      accessor: "bf_liberation",
      cols: "1",
      required: true,
      uniqueRow: true,
    },
    {
      Header: "Categoria",
      accessor: "cr_categorie_cr_code",
      cols: "8",
      required: true,
      listItens: adapterListCategories(),
    },
    {
      Header: "Nome",
      accessor: "ws_name",
      cols: "6",
      required: true,
    },
    {
      Header: "Preços cartucho",
      accessor: "bf_sale_cartucho",
      cols: "4",
      required: true,
    },
    {
      Header: "Nome URL",
      accessor: "hl_name_url",
      cols: "4",
      required: false,
      disabled: true,
    },
    {
      Header: "Data cadastro",
      accessor: "dx_register",
      cols: "4",
      required: false,
      disabled: true,
    },
  ];
  const subtitleForm: Array<typeCrudCollapse> = [
    {
      CollapseContent: subtitleContent,
    },
  ];

  return GenerateForm(dataFormDb, subtitleForm);
};

export default FormSubCategory;
