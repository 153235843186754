import React from "react"
import { AiOutlineGlobal, AiTwotoneUsb } from 'react-icons/ai'
import { BiBarcodeReader } from 'react-icons/bi'
import { BsFillMouse2Fill } from 'react-icons/bs'
import { CgSmartphoneChip, CgTag } from 'react-icons/cg'
import { GiCardboardBox } from 'react-icons/gi'
import { ImHome3, ImPrinter } from 'react-icons/im'
import {
  IoIosBook,
  IoIosConstruct,
  IoIosGlobe,
  IoMdRibbon
} from 'react-icons/io'
import {
  IoDesktopOutline,
  IoFlash,
  IoGameController,
  IoHourglassOutline,
  IoWifi
} from 'react-icons/io5'
import { MdOutlineCable, MdOutlineImportantDevices } from 'react-icons/md'
import CrudContainer from "./CrudContainer"
import {
  deleteCategory,
  getCategoryByCode,
  getCategorys,
  getNextCrCodeCategory,
  insertCategory,
  updateCategory
} from "./graphqlQuery"

function BfLiberation({ value, column, row }) {
  return (
    <div style={{ width: "100%", textAlign: "center" }}>
      {value ? (
        <span style={{ color: "green" }}>Sim</span>
      ) : (
        <span style={{ color: "red" }}>Não</span>
      )}
    </div>
  );
}

function IconLoad({ value, column, row }): any {
  let iconResult = null;
  let list = [
    {
      cr_code: 'AiOutlineGlobal',
      ws_value: 'AiOutlineGlobal',
      icon: <AiOutlineGlobal style={{ width: '50px', height: '50px' }} />
    },
    {
      cr_code: 'IoHourglassOutline',
      ws_value: 'IoHourglassOutline',
      icon: <IoHourglassOutline style={{ width: '50px', height: '50px' }} />
    },
    {
      cr_code: 'IoMdRibbon',
      ws_value: 'IoMdRibbon',
      icon: <IoMdRibbon style={{ width: '50px', height: '50px' }} />
    },
    {
      cr_code: 'IoIosGlobe',
      ws_value: 'IoIosGlobe',
      icon: <IoIosGlobe style={{ width: '50px', height: '50px' }} />
    },
    {
      cr_code: 'IoIosBook',
      ws_value: 'IoIosBook',
      icon: <IoIosBook style={{ width: '50px', height: '50px' }} />
    },
    {
      cr_code: 'BsFillMouse2Fill',
      ws_value: 'BsFillMouse2Fill',
      icon: <BsFillMouse2Fill style={{ width: '50px', height: '50px' }} />
    },
    {
      cr_code: 'AiTwotoneUsb',
      ws_value: 'AiTwotoneUsb',
      icon: <AiTwotoneUsb style={{ width: '50px', height: '50px' }} />
    },
    {
      cr_code: 'BiBarcodeReader',
      ws_value: 'BiBarcodeReader',
      icon: <BiBarcodeReader style={{ width: '50px', height: '50px' }} />
    },
    {
      cr_code: 'MdOutlineCable',
      ws_value: 'MdOutlineCable',
      icon: <MdOutlineCable style={{ width: '50px', height: '50px' }} />
    },
    {
      cr_code: 'CgSmartphoneChip',
      ws_value: 'CgSmartphoneChip',
      icon: <CgSmartphoneChip style={{ width: '50px', height: '50px' }} />
    },
    {
      cr_code: 'MdOutlineImportantDevices',
      ws_value: 'MdOutlineImportantDevices',
      icon: <MdOutlineImportantDevices style={{ width: '50px', height: '50px' }} />
    },
    {
      cr_code: 'IoWifi',
      ws_value: 'IoWifi',
      icon: <IoWifi style={{ width: '50px', height: '50px' }} />
    },
    {
      cr_code: 'IoFlash',
      ws_value: 'IoFlash',
      icon: <IoFlash style={{ width: '50px', height: '50px' }} />
    },
    {
      cr_code: 'IoIosConstruct',
      ws_value: 'IoIosConstruct',
      icon: <IoIosConstruct style={{ width: '50px', height: '50px' }} />
    },
    {
      cr_code: 'IoGameController',
      ws_value: 'IoGameController',
      icon: <IoGameController style={{ width: '50px', height: '50px' }} />
    },
    {
      cr_code: 'ImPrinter',
      ws_value: 'ImPrinter',
      icon: <ImPrinter style={{ width: '50px', height: '50px' }} />
    },
    {
      cr_code: 'IoDesktopOutline',
      ws_value: 'IoDesktopOutline',
      icon: <IoDesktopOutline style={{ width: '50px', height: '50px' }} />
    },
    {
      cr_code: 'ImHome3',
      ws_value: 'ImHome3',
      icon: <ImHome3 style={{ width: '50px', height: '50px' }} />
    },
    {
      cr_code: 'CgTag',
      ws_value: 'CgTag',
      icon: <CgTag style={{ width: '50px', height: '50px' }} />
    },
    {
      cr_code: 'GiCardboardBox',
      ws_value: 'GiCardboardBox',
      icon: <GiCardboardBox style={{ width: '50px', height: '50px' }} />
    }
  ]  
  if (value) {
    const tmp = list.filter(item => item.ws_value === value)    
    iconResult = tmp[0].icon
  }
  return <div style={{ width: "100%", textAlign: "center" }}>
    {iconResult ? iconResult : ''}
  </div>
}

const Category = () => {
  const columns = React.useMemo(
    () => [
      {
        Header: "Código",
        accessor: "cr_code",
        visible: false,
      },
      {
        Header: "Nome",
        accessor: "ws_name",
      },
      {
        Header: "Ícone",
        accessor: "sl_react_icones",
        Cell: IconLoad,
      },
      {
        Header: "SubCategorias / Quantidade",
        accessor: "qi_subcategories",
        Cell: ({ value, column, row }) => {
          let temp = "";
          value.map(category => {
            temp === ""
              ? (temp = category.ws_name)
              : (temp = temp + ", " + category.ws_name);
          });
          return (
            <a style={{ fontSize: "12px" }}>
              {temp} <b>({value.length})</b>
            </a>
          );
        },
      },
      {
        Header: "Destaque",
        Cell: BfLiberation,
        accessor: "bf_highlight",
      },
      {
        Header: "Ativo",
        Cell: BfLiberation,
        accessor: "bf_active",
      },
      {
        Header: "Ações",
        accessor: "actions",
        Filter: () => <div style={{ height: "10px" }}></div>,
        Cell: cellContent => (
          <div
            style={{
              width: "100%",
              textAlign: "center",
              backgroundColor: "red",
            }}
          >
            {cellContent}
          </div>
        ),
      },
    ],
    []
  );
  return (
    <CrudContainer
      routePath=""
      queryGet={getCategorys}
      queryGetByCode={getCategoryByCode}
      querySave={insertCategory}
      queryUpdate={updateCategory}
      queryDelete={deleteCategory}
      queryNextCrCode={getNextCrCodeCategory}
      title="Categoria | Braile Distribuidora"
      breadcrumbTitle="Categoria"
      breadcrumbItem="Categorias"
      columnsTable={columns}
    />
  );
};

export default Category;
