/* IMPORTANTE: Deve incluir as fun principais do CRUD
 * getData, updateData, deleteData, postData
 * estas chamadas são padrão no CRUD/CrudGroupForm */

export const getPrivacyPolicy = (cr_code: string): string => {
  return `query {
    getLgpdPreview(cr_code: "${cr_code}") {
      wt_content
    }
  }`;
};

export const updatePrivacyPolicy = (data: {
  cr_code: String;
  ht_content: String
}): string => {
  return `mutation {
    updateLgpd(
      cr_code: "${data.cr_code}", 
      ht_content: ${JSON.stringify(data.ht_content)},
    ){
      result
      status
    }
  }`;
};
