import moment from 'moment';
import React from 'react';
import { formatDateTime } from 'src/helpers/functions';
import CrudContainer from './CrudContainer';
import {
  deleteSeller,
  getNextCrCodeSeller,
  getSellerByCode,
  getSellers,
  insertSeller,
  updateSeller
} from './graphqlQuery';

function BfLiberation({ value, column, row }) {
  return (
    <a>
      {value ? (
        <span style={{ color: 'green' }}>Sim</span>
      ) : (
        <span style={{ color: 'red' }}>Não</span>
      )}
    </a>
  );
}

const Seller = () => {
  const columns = React.useMemo(
    () => [
      {
        Header: "Código",
        accessor: "cr_code",
      },
      {
        Header: "Nome",
        accessor: "ws_name",
      },
      {
        Header: "E-mail",
        accessor: "ws_email",
      },
      {
        Header: "Liberação",
        Cell: BfLiberation,
        accessor: "bf_liberation",
      },
      {
        Header: "Data Cadastro",
        accessor: "dx_register",
        Cell: ({ value, column, row }) => {
          return moment(value).format(formatDateTime);
        },
      },
      {
        Header: "Ações",
        accessor: "actions",
        Filter: () => <div style={{ height: "10px" }}></div>,
      },
    ],
    []
  );
  return (
    <CrudContainer
      routePath=""
      queryGet={getSellers}
      queryGetByCode={getSellerByCode}
      querySave={insertSeller}
      queryUpdate={updateSeller}
      queryDelete={deleteSeller}
      queryNextCrCode={getNextCrCodeSeller}
      queryPreview={getSellers}
      title="Vendedores | Braile Distribuidora"
      breadcrumbTitle="Outros"
      breadcrumbItem="Vendedores"
      columnsTable={columns}
    />
  );
};

export default Seller;
