/* IMPORTANTE: Deve incluir as fun principais do CRUD
 * getData, updateData, deleteData, postData
 * estas chamadas são padrão no CRUD/CrudGroupForm */

export const getPaymentTermsByCode = (cr_code: string): string => {
  return `{
    getPaymentTermByCode(cr_code:"${cr_code}") {
      ws_minimum_value
      ws_description
      ws_name
      dx_modification
      dx_register
      ck_registeruser
      cr_code
      bf_liberation
      bf_active
      cv_version
      qi_payment_term_range {
        cv_version
        bf_active
        bf_liberation
        cr_code
        ck_registeruser
        dx_register
        dx_modification
        cr_code_payment_term
        ws_start
        ws_installment
        qi_installment
      }
    }
  }`;
};

export const getPaymentTerms = (): string => {
  return `query {
    getPaymentTerms {
      ws_minimum_value
      ws_description
      ws_name
      dx_modification
      dx_register
      ck_registeruser
      cr_code
      bf_liberation
      bf_active
      cv_version
    }
  }`;
};

export const deletePaymentTerms = (cr_code: string): string => {
  return `query {
    deletePaymentTermByCode(cr_code:"${cr_code}") {
      message
    }
  }`;
};

export const updatePaymentTerms = (data: {
  bf_liberation: Boolean;
  cr_code: String;
  ws_name: String;
  ws_description: String;
  ws_minimum_value: String;
  qi_payment_term_range: Array<{
    cr_code: String;
    ws_start: Number;
    ws_installment: String;
    qi_installment: Number[];
  }>;
}): string => {
  let formatted = "";
  data.qi_payment_term_range.map(range => {
    formatted += `{       
      cr_code: "${range.cr_code}",
      ws_start: ${Number(range.ws_start)},
      ws_installment: "${range.ws_installment}",
      qi_installment: [${range.qi_installment.map(code => {return `"${Number(code)}"`})}],
    }`;
  });

  return `mutation {
    updatePaymentTerm(
      bf_liberation: ${data.bf_liberation},
      cr_code: "${data.cr_code ? data.cr_code : ""}",
      ws_name: "${data.ws_name ? data.ws_name : ""}",
      ws_description: "${data.ws_description}",
      ws_minimum_value: ${data.ws_minimum_value},
      qi_payment_term_range: [${formatted}],
   ){
  	message
   }
  }`;
};

export const insertPaymentTerms = (data: {
  bf_liberation: Boolean;
  cr_code: String;
  ws_name: String;
  ws_description: String;
  ws_minimum_value: String;
  qi_payment_term_range: Array<{
    ws_start: Number;
    ws_installment: String;
    qi_installment: Number[];
  }>;
}): string => {
  let formatted = "";

  data.qi_payment_term_range.map(range => {
    formatted += `{       
      ws_start: ${Number(range.ws_start)},
      ws_installment: "${range.ws_installment}",
      qi_installment: [${range.qi_installment ? range.qi_installment.map(code => {return `"${code}"`}) : []}],
    }`;
  });
  return `mutation {
    insertPaymentTerm(
      bf_liberation: ${data.bf_liberation},
      cr_code: "${data.cr_code ? data.cr_code : ""}",
      ws_name: "${data.ws_name ? data.ws_name : ""}",
      ws_description: "${data.ws_description}",
      ws_minimum_value: ${data.ws_minimum_value},
      qi_payment_term_range: [${formatted}],
   ){
  	message
   }
  }`;
};

export const getNextCrCodePaymentTerms = (): string => {
  return `query {
    getNextCrCodePaymentTerm {
      result
    }
  }`;
};

export const getPaymentTermRangesByPayment = (paymentcode: string): string => {
  return `query
  {
    getPaymentTermRangesByPayment(paymentcode:"${paymentcode}") {
        cv_version
        bf_active
        bf_liberation
        cr_code
        ck_registeruser
        dx_register
        dx_modification
        ws_descricao
    }
  } 
  `;
}

export const getPaymentTables = (): string => {
  return `query
  {
    getPaymentTables {
        cv_version
        bf_active
        bf_liberation
        cr_code
        ck_registeruser
        dx_register
        dx_modification
        ws_descricao
    }
  } 
  `;
}