
export interface RouterProps {
  name: String,
  icon?: any,
  link: any,
  children?: Array<RouterProps>,
}

const routers: Array<RouterProps> = [
  {
    name: "Banner",
    icon: "home",
    link: "",
    children: [
      { name: "Banners", link: "banner" },
      { name: "Popups", link: "popup" },
    ],
  },
  {
    name: "Categoria",
    icon: "home",
    link: "",
    children: [
      { name: "Categorias", link: "category" },
      { name: "Subcategorias", link: "subcategory" },
    ],
  },
  {
    name: "Cadastro",
    icon: "home",
    link: "",
    children: [
      { name: "Clientes", link: "customer" },
      { name: "Aprovação de Cadastro", link: "registrationapproval" },
      /* { name: "Integração de Cadastro", link: "registrationintegration" } */
    ],
  },
  {
    name: "Consulta",
    icon: "home",
    link: "",
    children: [
      /* { name: "Avisos de Produtos", link: "productnotice" }, */
      { name: "Bundles", link: "bundle" },
      { name: "LGPD", link: "lgpd" },
    ],
  },
  { name: "Day", icon: "home", link: "day" },
  { name: "Pedido", icon: "home", link: "order" },
  {
    name: "Produto",
    icon: "home",
    link: "",
    children: [
      { name: "Atualização de Novos Produtos", link: "updatenewproduct" },
      { name: "Cadastro de Produtos", link: "productregistration" },
      /* { name: "Regras de Produtos", link: "productrule" }, */
      { name: "Itens Diferidos", link: "deferreditem" },
    ],
  },
  {
    name: "Outros",
    icon: "home",
    link: "",
    children: [
      { name: "Condições de Pagamento", link: "paymentterm" },
      { name: "Destinatários", link: "recipient" },
      { name: "Fabricantes", link: "manufacturer" },
      { name: "Flyers", link: "flyer" },
      { name: "Política de Privacidade", link: "privacypolicy" },
      { name: "Uploads RMA", link: "uploadrma" },
      { name: "Vendedores", link: "seller" },
      { name: "Curva ABC", link: "abccurve" },
    ],
  },
  {
    name: "Logs",
    icon: "home",
    link: "",
    children: [
      { name: "Logs de E-mails", link: "logsemails" },
      { name: "Logs Fiscais", link: "logsfiscal" },
      { name: "Logs Pedidos", link: "logsorder" },
    ],
  },
  {
    name: "Configurações",
    icon: "home",
    link: "",
    children: [
      { name: "Integrador", link: "configurationintegration" },
    ],
  },
];

export { routers };

