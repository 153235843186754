import React from "react";

//import Breadcrumbs
import CrudContainer from "./CrudContainer";
import {
  getBundles,
  getCustomerByCode,
  getNextCrCodeCustomer,
  insertCustomer,
} from "./graphqlQuery";

function WSrazao_cnpj({ value, column, row }) {
  const image = "https://braileonline.com.br/user/p/m/70340_152972.jpg";
  return (
    <>
      <div style={{ float: "left" }}>
        <img className="rounded img-t1" src={image} alt="" />
      </div>
      <div>
        {row.original.cr_code} - {value}
      </div>
    </>
  );
}

const Bundle = () => {
  const columns = React.useMemo(
    () => [
      {
        Header: "Produto Pai",
        accessor: "ws_commercial_description",
        Cell: WSrazao_cnpj,
      },
      {
        Header: "Produtos Filhos",
        accessor: "bf_liberation",
      },
    ],
    []
  );
  return (
    <CrudContainer
      routePath=""
      queryGet={getBundles}
      queryGetByCode={getCustomerByCode}
      querySave={insertCustomer}
      queryUpdate={null}
      queryDelete={null}
      queryNextCrCode={getNextCrCodeCustomer}
      title="Bundle | Braile Distribuidora"
      breadcrumbTitle="Consulta"
      breadcrumbItem="Bundle"
      columnsTable={columns}
    />
  );
};

export default Bundle;
