import {
  typeCrudCollapse,
  typeCrudForm
} from "src/components/Common/typeCrudForm"
import GenerateForm from "src/components/CRUD/generateComponents"

const CURVE_TYPE = [
  // {value: 'A', label: 'Curva A'},
  {value: 'B', label: 'Curva B'},
  {value: 'C', label: 'Curva C'},
  {value: 'D', label: 'Curva D'},
  {value: 'E', label: 'Curva E'},
];

function listTypeAlign(): Array<{
  cr_code: string;
  ws_value: string;
}> {
  let temp = [];
  CURVE_TYPE.map(item => temp.push({
    cr_code: item.value,
    ws_value: item.label,
  }))

  return temp;
}

const FormAbcCurve = (props: {
  dataFormDb: {
    cr_code: string,
    sl_curve_type: string,
    dc_value: number
  };
  nextCrCode: string;
}) => {
  let { dataFormDb, nextCrCode } = props;
  if (nextCrCode && dataFormDb) {
    dataFormDb.cr_code = nextCrCode;
  }

  if (dataFormDb && !dataFormDb.dc_value) {
    dataFormDb.dc_value = 0
  }

  const subtitleContent: Array<typeCrudForm> = [
    {
      Header: "Código",
      accessor: "cr_code",
      cols: "6",
      required: true,
    },
    {
      Header: "Curva",
      accessor: "sl_curve_type",
      cols: "4",
      listItens: listTypeAlign(),
      required: true,
    },
    {
      Header: "Valor",
      accessor: "dc_value",
      cols: "6",
      required: true,
    },
  ];

  const subtitleForm: Array<typeCrudCollapse> = [
    {
      CollapseContent: subtitleContent,
    },
  ];

  return GenerateForm(dataFormDb, subtitleForm);
};

export default FormAbcCurve;
