import React from 'react';
import { typeCrudForm } from 'src/components/Common/typeCrudForm';
import FormCrudContainer from 'src/components/CRUD/CrudForm';
import FormBanner from './forms/formBanner';

export default function FormCrud(props: {
  handleNewRegister: (mode: string) => void;
  queryGetByCode: any;
  queryGet: any;
  querySave: any;
  queryUpdate: any;
  queryDelete: any;
  selectedRow: string;
  setSelectedRow: any;
  queryNextCrCode: any;
  columnsTable: Array<typeCrudForm>;
}) {
  const { handleNewRegister } = props;

  return (
    <FormCrudContainer
      Form={FormBanner}
      handleNewRegister={handleNewRegister}
      {...props}
    />
  );
}
