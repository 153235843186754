/* IMPORTANTE: Deve incluir as fun principais do CRUD
 * getData, updateData, deleteData, postData
 * estas chamadas são padrão no CRUD/CrudGroupForm */

export const getSubCategoryByCode = (cr_code: string): string => {
  return `{
    getSubCategoryByCode(cr_code:"${cr_code}") {
      cr_code
      bf_liberation
      ws_name
      bf_sale_cartucho
      dx_register
      ws_categories
      ws_categorie
      cr_categorie_cr_code
      hl_name_url
    }
  }`;
};

export const getSubCategorys = (
  filter: String,
  limit: Number = 10,
  random: Boolean = false,
  skip: Number = 0,
  sort: String = "{}"
): string => {
  let filter2 = "{}";
  let filter3 = JSON.stringify(filter2);
  return `query {
    getSubCategorysPaginationAdmin(filter: {
      filter: ${filter3},
      sort: "${sort}",
      skip: ${skip},  
      limit: ${limit},                      
      random: ${random},
    }) {
      result {
        cr_code
        ws_name
        bf_sale_cartucho
        dx_register
        ws_categories
        ws_categorie
        cr_categorie_cr_code
      } 
      rows
      rowsall
    }
  }`;
};

export const deleteSubCategory = (cr_code: string): string => {
  return `query {
    deleteSubCategoryByCode(cr_code:"${cr_code}") {
      message
    }
  }`;
};

export const updateSubCategory = (data: {
  bf_liberation: Boolean;
  cr_code: String;
  cr_categorie_cr_code: String;
  ws_name: String;
  bf_sale_cartucho: Boolean;
  hl_name_url: String;
}): string => {
  return `mutation {
    updateSubCategory(
      bf_liberation: ${data.bf_liberation},
      cr_code: "${data.cr_code}",
      cr_categorie_cr_code: "${data.cr_categorie_cr_code}",
      ws_name: "${data.ws_name}",
      bf_sale_cartucho: ${data.bf_sale_cartucho}, 
      hl_name_url: "${data.hl_name_url}"
   ){
  	message
   }
  }`;
};

export const insertSubCategory = (data: {
  bf_liberation: Boolean;
  cr_code: String;
  cr_categorie_cr_code: String;
  ws_name: String;
  bf_sale_cartucho: Boolean;
  hl_name_url: String;
}): string => {
  return `mutation {
    insertSubCategory(
      bf_liberation: ${data.bf_liberation},
      cr_code: "${data.cr_code}",
      cr_categorie_cr_code: "${data.cr_categorie_cr_code}",
      ws_name: "${data.ws_name}",
      bf_sale_cartucho: ${data.bf_sale_cartucho},
   ){
  	message
   }
  }`;
};

export const getNextCrCodeSubCategory = (): string => {
  return `query {
    getNextCrCodeSubCategory {
      result
    }
  }`;
};

export const getListCategorys = (): string => {
  return `query {
    getListCategorys {
      cr_code
      ws_name
    }
  }`;
};
