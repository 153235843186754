import { useEffect, useState } from "react"
import { Col, Input } from "reactstrap"
import { LabelStyled } from "./styled"

export const getInputBox = (item, setFilterDb?: any, filterDb?: any, sendFilterAdapter?: any) => {
  const [value, setValue] = useState('');
  const [valueDefault, setValueDefault] = useState('');
  const [defaultCols, setDefaultCols] = useState(3);
  const [cols, setCols] = useState(defaultCols);
  
  function handleChange(key: string, value: any) {
    setValue(value)
    if (!value) {
      setFilterDb(filterDb => { 
        delete filterDb[key];
        return filterDb 
      })
    } else {
      setFilterDb(filterDb => { return { ...filterDb, [key]: {
        $regex: `${value}.*`,
        $options: 'si'
      } }})
    }
  }

  useEffect(() => {
    if (item?.cols && item?.cols > 0) {
      setCols(item.cols * defaultCols)
    }
  }, [item])

  // Limpa os campos
  useEffect(() => {
    setValue(valueDefault)    
  }, [item.clearFields])

  return <><Col 
  lg={cols}
  sm={cols}
  md={cols}
  xs={cols}
  style={{ textAlign: "left" }}
>
  <LabelStyled>{item.name}</LabelStyled>
  <Input 
    value={value}
    maxLength={item.maxLength ? item.maxLength : 30}
    bsSize="sm"
    type={item.type}
    onChange={e => handleChange(item.field, e.target.value)}
    onKeyDown={e => {
      if (e.key === "Enter") {
        sendFilterAdapter(filterDb);
      }
    }}
  />
</Col>
{item.uniqueRow && <Col 
  className="m-1"
  lg={cols - 12}
  sm={cols - 12}
  md={cols - 12}
  xs={cols - 12}
  style={{ textAlign: "left" }}
  ></Col>}
  </>
}