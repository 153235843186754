import moment from "moment"
import React, { useState } from "react"
import { FilterInputs } from "src/components/Filters/types"
import { post } from "src/helpers/api_helper"
import { formatDateTime } from "src/helpers/functions"
import CrudContainer from "./CrudContainer"
import {
  getCustomerByCode,
  getCustomerFilter,
  getCustomerPagination,
  getNextCrCodeCustomer,
  getPaymentTerms,
  insertCustomer,
  updateCustomer
} from "./graphqlQuery"
import { LabelCnpj, LabelSocial } from "./styled"

function BFLiberation({ value, column, row }) {
  return (
    <a>
      {value ? (
        <span style={{ color: "green" }}>Sim</span>
      ) : (
        <span style={{ color: "red" }}>Não</span>
      )}
    </a>
  );
}

function WSrazao_cnpj({ value, column, row }) {
  return (
    <>
      <LabelCnpj>{row.original.ox_registration_fields.ws_corporate_reason}</LabelCnpj>
      <LabelSocial>{row.original.wj_cnpj}</LabelSocial>
    </>
  );
}

function WSadress({ value, column, row }) {
  const state =
    row.original.ox_registration_fields &&
    row.original.ox_registration_fields.qi_address.length &&
    row.original.ox_registration_fields.qi_address[0].ws_state;
  return (
    <>
      <div>{state}</div>
    </>
  );
}

const Customer = () => {
  const [listPaymentsTerms, setListPaymentsTerms] = useState([]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Código ERP",
        accessor: "cr_code_erp",
      },
      {
        Header: "Código",
        accessor: "cr_code",
        visible: false,
      },
      {
        Header: "Razão Social e CNPJ",
        accessor: "ws_corporate_reason",
        Cell: WSrazao_cnpj,
        accessorParent: "ox_registration_fields",
      },
      {
        Header: "Estado",
        accessor: "ws_state",
        Cell: WSadress,
        accessorParent: 'ox_registration_fields',
        accessorParent2: 'qi_address',
      },
      {
        Header: "Tipo",
        accessor: "sl_type_client",
      },
      {
        Header: "Curva",
        accessor: "sl_curve_type",
      },
      {
        Header: "Condição Tributária",
        accessor: "nb_tax_condition",
      },
      {
        Header: "Restrito",
        accessor: "bf_restricted_registry",
        Cell: ({ value, column, row }) => {
          return (
            <a>
              {value ? (
                <span style={{ color: "green" }}>Sim</span>
              ) : (
                <span style={{ color: "red" }}>Não</span>
              )}
            </a>
          );
        },
      },
      {
        Header: "Crédito",
        accessor: "bf_own_credit",
        Cell: ({ value, column, row }) => {
          return (
            <a>
              {value ? (
                <span style={{ color: "green" }}>Sim</span>
              ) : (
                <span style={{ color: "red" }}>Não</span>
              )}
            </a>
          );
        },
      },
      {
        Header: "Último Login",
        accessor: "dx_data_login",
        Cell: ({ value, column, row }) => {
          if (value) {
            return moment(value).format(formatDateTime);
          } else {
            return (
              <div style={{ color: "silver" }}>
                <i>Nunca</i>
              </div>
            );
          }
        },
      },
      {
        Header: "Liberação",
        Cell: BFLiberation,
        accessor: "bf_liberation",
      },
      {
        Header: "Data Cadastro",
        accessor: "dx_register",
        Cell: ({ value, column, row }) => {
          return moment(value).format(formatDateTime);
        },
      },
      {
        Header: "Ações",
        accessor: "actions",
        Filter: () => <div style={{ height: "10px" }}></div>,
        Cell: cellContent => (
          <div
            style={{
              width: "100%",
              textAlign: "center",
              backgroundColor: "red",
            }}
          >
            {cellContent}
          </div>
        ),
      },
    ],
    []
  );
  const columnsFilterCustom: Array<FilterInputs> = React.useMemo(
    () => [
      {
        name: "Codigo",
        type: "text",
        field: "ox_identification.cr_code_erp"
      },
      {
        name: "cnpj",
        type: "text",
        field: "ox_register_data.wj_cnpj"
      },
      {
        name: "Condição Tributária",
        type: "select",
        field: "ox_register_data.nb_tax_condition",
        options: [
          {
            name: "0",
            value: "0",
          },
          {
            name: "40",
            value: "40",
          },
          {
            name: "41",
            value: "41",
          },
          {
            name: "50",
            value: "50",
          },
          {
            name: "51",
            value: "51",
          },
        ],
      },
      {
        name: "Clientes que nunca fizeram login",
        type: "switch",
        field: "ox_data_login"
      },
      {
        name: "Razão Social",
        field: "ox_company_data.ws_corporate_reason",
        type: "text",
        cols: 2,
      },
      {
        name: "Restrito",
        type: "select",
        field: "ox_register_data.bf_restricted_registry",
        options: [
          {
            name: "Sim",
            value: "true",
          },
          {
            name: "Não",
            value: "false",
          },
        ],
      },
      {
        name: "Crédito",
        type: "select",
        field: "ox_register_data.bf_own_credit",
        options: [
          {
            name: "Sim",
            value: "true",
          },
          {
            name: "Não",
            value: "false",
          },
        ],
      },
      {
        name: "Cond. de Pgto.",
        type: "select",
        field: "ox_register_data.cr_code_payment_terms",
        options: listPaymentsTerms,
      },
      {
        name: "Cond. de Pgto. Especial",
        type: "select",
        field: "ox_register_data.cr_code_special_payment",
        options: listPaymentsTerms,
      },
      {
        name: "Estado",
        type: "select",
        field: "ox_company_data.qi_address[0].ws_state",
        // field: "ox_registration_fields.qi_address[0].ws_state",
        options: [
          {
            name: "RS",
            value: "RS",
          },
          {
            name: "SC",
            value: "SC",
          },
          {
            name: "PR",
            value: "PR",
          },
          {
            name: "PA",
            value: "PA",
          }
        ],
      },
    ], [listPaymentsTerms]);

  React.useEffect(() => {
    //Retorna a lista de condições de pagamento
    async function PaymentTerms() {
      let response = await post(
        "/graphql",
        {
          query: getPaymentTerms(),
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      ).then(response => response);
      if (response.data.getPaymentTerms) {
        let temp = [];
        const list = response.data.getPaymentTerms
        list.map(item => {
          temp.push({
            name: item.ws_name,
            value: item.cr_code,
          });
        });
        setListPaymentsTerms(temp);
      } else {
        alert("Falha ao trazer lista de clientes!");
        setListPaymentsTerms([])
      }
    }

    PaymentTerms();
  }, []);

  return (
    <CrudContainer
      routePath=""
      queryGet={getCustomerPagination}
      queryGetFilter={getCustomerFilter}
      queryGetByCode={getCustomerByCode}
      querySave={insertCustomer}
      queryUpdate={updateCustomer}
      queryDelete={null}
      queryNextCrCode={getNextCrCodeCustomer}
      title="Cliente | Braile Distribuidora"
      breadcrumbTitle="Cadastro"
      breadcrumbItem="Clientes"
      columnsTable={columns}
      fieldsCustomFilters={columnsFilterCustom}
    />
  );
};

export default Customer;
