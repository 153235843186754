import React from "react"

//import Breadcrumbs
import moment from "moment"
import { formatDateTime } from "src/helpers/functions"
import CrudContainer from "./CrudContainer"
import {
  deleteRecipient,
  getNextCrCodeRecipient,
  getRecipientByCode,
  getRecipients,
  insertRecipient,
  updateRecipient
} from "./graphqlQuery"
import { LabelCnpj, LabelSocial } from "./styled"

function WSrazao_cnpj({ value, column, row }) {
  return (
    <>
      <LabelCnpj>{value}</LabelCnpj>
      <LabelSocial>{row.original.wj_cnpj}</LabelSocial>
    </>
  );
}

function BfLiberation({ value, column, row }) {
  return (
    <a>
      {value ? (
        <span style={{ color: "green" }}>Sim</span>
      ) : (
        <span style={{ color: "red" }}>Não</span>
      )}
    </a>
  );
}

const RegistrationApproval = () => {
  const columns = React.useMemo(
    () => [
      {
        Header: "Código",
        accessor: "cr_code",
        visible: false,
      },
      {
        Header: "E-mail",
        accessor: "ws_email",
      },
      {
        Header: "Contato",
        accessor: "bf_pagina_contato",
        Cell: BfLiberation,
      },
      {
        Header: "Trabalhe Conosco",
        accessor: "bf_trabalhe_conosco",
        Cell: BfLiberation,
      },
      {
        Header: "Cadastro Cliente",
        accessor: "bf_aviso_cad_cliente",
        Cell: BfLiberation,
      },
      {
        Header: "Inclusão de Pedido",
        accessor: "bf_aviso_pedido",
        Cell: BfLiberation,
      },
      // {
      //   Header: "Resumo Integração Novo Cadastro",
      //   accessor: "qi_list_roles",
      //   Cell: BfLiberation,
      // },
      {
        Header: "RMA",
        accessor: "bf_aviso_rma",
        Cell: BfLiberation,
      },
      {
        Header: "Item Carrinho",
        accessor: "bf_aviso_item_carrinho",
        Cell: BfLiberation,
      },
      {
        Header: "Avaliação de Crédito",
        accessor: "bf_aviso_aval_credito",
        Cell: BfLiberation,
      },
      {
        Header: "Recuperação de Senha",
        accessor: "bf_recuperacao_senha",
        Cell: BfLiberation,
      },      
      {
        Header: "Ativo",
        accessor: "bf_active",
        Cell: BfLiberation,
      },      
      {
        Header: "Data Cadastro",
        accessor: "dx_register",
        Cell: ({ value, column, row }) => {
          return moment(value).format(formatDateTime);
        },
      },
      {
        Header: "Ações",
        accessor: "actions",
        Filter: () => <div style={{ height: "10px" }}></div>,
        Cell: cellContent => (
          <div
            style={{
              width: "100%",
              textAlign: "center",
              backgroundColor: "red",
            }}
          >
            {cellContent}
          </div>
        ),
      },
    ],
    []
  );
  return (
    <CrudContainer
      routePath=""
      queryGet={getRecipients}
      queryGetByCode={getRecipientByCode}
      querySave={insertRecipient}
      queryUpdate={updateRecipient}
      queryDelete={deleteRecipient}
      queryNextCrCode={getNextCrCodeRecipient}
      title="Destinatários | Braile Distribuidora"
      breadcrumbTitle="Cadastro"
      breadcrumbItem="Destinatários"
      columnsTable={columns}
    />
  );
};

export default RegistrationApproval;
