import classnames from "classnames"
import React, { useState } from 'react'
import {
  Button, Card, CardBody, CardFooter, Collapse, Row
} from "reactstrap"
import { getCheckBox } from './components/getCheckBox'
import { getDateBox } from './components/getDateBox'
import { getDateBoxRange } from "./components/getDateBoxRange"
import { getInputBox } from './components/getInputBox'
import { getRadioBox } from './components/getRadioBox'
import { getSelectBox } from './components/getSelectBox'
import { getSwitchBox } from './components/getSwitchBox'
import { FilterProps } from './types'

const Filters: React.FC<FilterProps> = (props) => {
  let cols = [];
  const [filterDb, setFilterDb] = useState({});
  const [clearFields, setClearFields] = useState(false);
  const { filter, setGlobalFilterCustom } = props;
  const [col1, setcol1] = useState(cols);
  const [search, setSearch] = useState([]);

  function handleClear() {
    setFilterDb({})
    setClearFields(!clearFields)
    setGlobalFilterCustom("{}")
  }

  function getTypeComponent(type, item) {
    item.clearFields = clearFields
    switch (type) {
      case "checkbox":
        return getCheckBox(item, setFilterDb)
      case "select":
        return getSelectBox(item, setFilterDb)
      case "switch":
        return getSwitchBox(item, setFilterDb)
      case "date":
        return getDateBox(item, setFilterDb)
      case "radio":
        return getRadioBox(item, setFilterDb)
      case "daterange":
        return getDateBoxRange(item, setFilterDb)
      default:
        return getInputBox(item, setFilterDb, filterDb, sendFilterAdapter)
    }
  }

  function adapterFindFilter(fields) {

  }

  function sendFilterAdapter(filter: any) {
    setGlobalFilterCustom(filter)
  }

  // Controla o abrir/fechar dos collapses dinamicamente...
  const t_col1 = item => {
    let tempCols = col1;
    tempCols.map(col => (col = false));
    tempCols[item] = !col1[item];
    setcol1(() => {
      return [...col1, (col1[item] = !col1[item])];
    });
  };

  return (<>
    {filter && filter.length > 0 && (
      <>
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingOne">
            <button
              className={classnames("accordion-button", "fw-medium", {
                collapsed: !col1[0],
              })}
              type="button"
              onClick={() => t_col1(0)}
              style={{ cursor: "pointer", backgroundColor: "White" }}
            >
              FILTRO PERSONALIZADO - Informe os campos desejados para realizar a pesquisa
            </button>
          </h2>
          <Collapse
            isOpen={true}
            className="accordion-collapse"
            style={{ backgroundColor: "White" }}
          >
            <Card color='light'>
              <>
                <CardBody>
                  {/* <CardTitleStyled>Informe os campos desejados para realizar a pesquisa</CardTitleStyled> */}
                  <Row>
                    {filter.map((item, index) => getTypeComponent(item.type, item))}
                  </Row>
                </CardBody>
                <CardFooter style={{ textAlign: "right" }}>
                  <Button className="ml-2" color="secondary" onClick={() => handleClear()}>
                    Limpar
                  </Button>
                  {" "}
                  <Button color="primary" onClick={() => sendFilterAdapter(filterDb)} >
                    Pesquisar
                  </Button>
                </CardFooter>
              </>
            </Card>
          </Collapse>
        </div>
        <br />
      </>
    )}
  </>)
}

export default Filters;
