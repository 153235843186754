/* IMPORTANTE: Deve incluir as fun principais do CRUD
 * getData, updateData, deleteData, postData
 * estas chamadas são padrão no CRUD/CrudGroupForm */

export const getManufacturerByCode = (cr_code: string): string => {
  return `{
    getManufacturerByCode(cr_code:"${cr_code}") {
      cv_version
      bf_active
      bf_liberation
      cr_code
      ck_registeruser
      dx_register
      dx_modification
      ws_name
      ws_abbreviation
      hl_abbreviation_url
      wh_image
    }
  }`;
};

export const getManufacturers = (): string => {
  return `query {
    getManufacturerAdmin {
      cr_code
      bf_liberation
      dx_register
      ws_name
      ws_abbreviation
      hl_abbreviation_url
      wh_image
    }
  }`;
};

export const deleteManufacturer = (cr_code: string): string => {
  return `query {
    deleteManufacturerByCode(cr_code:"${cr_code}") {
      message
    }
  }`;
};

export const updateManufacturer = (data: {
  bf_liberation: Boolean;
  cr_code: String;
  ws_name: String;
  wh_image: String;
  ws_abbreviation: String;
  hl_abbreviation_url: String;
}): string => {
  return `mutation {
    updateManufacturer(
      bf_liberation: ${data.bf_liberation},
      cr_code: "${data.cr_code ? data.cr_code : ""}",
      ws_name: "${data.ws_name ? data.ws_name : ""}",
      wh_image: "${data.wh_image ? data.wh_image : ""}",
      ws_abbreviation: "${data.ws_abbreviation ? data.ws_abbreviation : ""}",
      hl_abbreviation_url: "${
        data.hl_abbreviation_url ? data.hl_abbreviation_url : ""
      }",
   ){
  	message
   }
  }`;
};

export const insertManufacturer = (data: {
  bf_liberation: Boolean;
  cr_code: String;
  ws_name: String;
  wh_image: String;
  ws_abbreviation: String;
  hl_abbreviation_url: String;
}): string => {
  return `mutation {
    insertManufacturer(
      bf_liberation: ${data.bf_liberation},
      cr_code: "${data.cr_code ? data.cr_code : ""}",
      ws_name: "${data.ws_name ? data.ws_name : ""}",
      wh_image: "${data.wh_image ? data.wh_image : ""}",
      ws_abbreviation: "${data.ws_abbreviation ? data.ws_abbreviation : ""}",
      hl_abbreviation_url: "${
        data.hl_abbreviation_url ? data.hl_abbreviation_url : ""
      }",
   ){
  	message
   }
  }`;
};

export const getNextCrCodeManufacturer = (): string => {
  return `query {
    getNextCrCodeManufacturer {
      result
    }
  }`;
};
