import { useEffect, useState } from "react"
import { Col, FormGroup } from "reactstrap"
import ToggleSwitch from "src/components/general/ToggleSwitch/ToggleSwitch";
import { LabelStyled } from "./styled"

export const getSwitchBox = (item, setFilterDb?: any) => {
  const [valueDefault, setValueDefault] = useState(false);
  const [value, setValue] = useState(valueDefault);  
  const [search, setSearch] = useState([]);
  const [defaultCols, setDefaultCols] = useState(3);
  const [cols, setCols] = useState(defaultCols);
  
  function handleChange(key: string, value: any) {
    setValue(value)
    if(item.field.includes("bf_")) {
      setFilterDb(filterDb => { return { ...filterDb, [key]: value }})
    } else {
      setFilterDb(filterDb => { return { ...filterDb, [key]: null }})
    }
  }

  useEffect(() => {
    if (item?.cols && item?.cols > 0) {
      setCols(item.cols * defaultCols)
    }
  }, [item])

  // Limpa os campos
  useEffect(() => {
    setValue(valueDefault)    
  }, [item.clearFields])

  return <><Col 
  className="mt-1 "
  lg={cols}
  sm={cols}
  md={cols}
  xs={cols}
  style={{ textAlign: "left", display: "flex", alignItems: "center" }}
>
  <FormGroup className="">
    <LabelStyled for="exampleSelect">{item.name}</LabelStyled><br />
    <ToggleSwitch
      id={item.field}
      checked={value}
      onChange={checked => handleChange(item.field, checked)}
      disabled={false}
    />
  </FormGroup>
</Col>
{item.uniqueRow && <Col 
  className="m-1"
  lg={cols - 12}
  sm={cols - 12}
  md={cols - 12}
  xs={cols - 12}
  style={{ textAlign: "left" }}
  ></Col>}
  </>
}