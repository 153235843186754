import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
//import Breadcrumbs
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap"
import { post } from "src/helpers/api_helper"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { getIntegrationConfiguration, setIntegrationConfigurationStatus } from "./graphqlQuery"

const ConfigurationIntegration = () => {
  const [services, setServices] = useState<Array<any>>([]);
  const getStatusIntegrationConfiguration = async () => {        
    post("/graphql", 
    {
      query: getIntegrationConfiguration(),
    },
    {
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
      },
    })    
      .then(response => setServices(response?.data?.getIntegrationConfigurationStatus?.services ?? []))
      .catch(error => setServices([]));
  };

  const setStatusIntegrationConfiguration = async (service: string, status: boolean) => {        
    post("/graphql", 
    {
      query: setIntegrationConfigurationStatus(String(service), Boolean(status)),
    },
    {
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
      },
    })    
      .then(response => getStatusIntegrationConfiguration())
      .catch(error => alert("Falha ao atualizar status!"));
  };

  const getStatus = (service: any) => {
    if (service.ws_status === "success") {
      if (service.bf_active === true) {
        return "Ativo"
      } else {
        return "Inativo"
      }
    } else if(service.ws_status === "error") {
      return "Erro: " + service.ws_error_system
    } else if(service.ws_status === "message") {
      return "Aviso: " + service.ws_error_system
    }
  }

  const getRows = (service: any) => {
    switch (service.sl_service_select_name) {
      case "bo-ms-manufacturer-node":
        return (
          <Row>
            <Col className="mt-3" md={3}>
              <b>Fornecedores</b>{" "}
            </Col>
            <Col className="mt-3" md={3}>
              <span>
                {getStatus(service)}
              </span>
            </Col>
            <Col className="mt-3" md={6}>
              <input type={"button"} value={"Ativar"} onClick={() => setStatusIntegrationConfiguration(service.sl_service_select_name, true)} />{' '}
              <input type={"button"} value={"Desativar"} onClick={() => setStatusIntegrationConfiguration(service.sl_service_select_name, false)} />
            </Col>
          </Row>
        )
      case "bo-ms-seller-node":
        return (
          <Row>
            <Col className="mt-3" md={3}>
              <b>Vendedores</b>
            </Col>
            <Col className="mt-3" md={3}>
              <span>
                {getStatus(service)}
              </span>
            </Col>
            <Col className="mt-3" md={6}>
              <input type={"button"} value={"Ativar"} onClick={() => setStatusIntegrationConfiguration(service.sl_service_select_name, true)} />{' '}
              <input type={"button"} value={"Desativar"} onClick={() => setStatusIntegrationConfiguration(service.sl_service_select_name, false)} />
            </Col>
          </Row>
        )
      case "bo-ms-fiscal-node":
        return (
          <Row>
            <Col className="mt-3" md={3}>
              <b>Classificação Fiscal</b>{" "}
            </Col>
            <Col className="mt-3" md={3}>
              <span>
                {getStatus(service)}
              </span>
            </Col>
            <Col className="mt-3" md={6}>
              <input type={"button"} value={"Ativar"} onClick={() => setStatusIntegrationConfiguration(service.sl_service_select_name, true)} />{' '}
              <input type={"button"} value={"Desativar"} onClick={() => setStatusIntegrationConfiguration(service.sl_service_select_name, false)} />
            </Col>
          </Row>
        )
      case "bo-ms-fiscal-operation-node":
        return (
          <Row>
            <Col className="mt-3" md={3}>
              <b>Operação Fiscal</b>{" "}
            </Col>
            <Col className="mt-3" md={3}>
              <span>
                {getStatus(service)}
              </span>
            </Col>
            <Col className="mt-3" md={6}>
              <input type={"button"} value={"Ativar"} onClick={() => setStatusIntegrationConfiguration(service.sl_service_select_name, true)} />{' '}
              <input type={"button"} value={"Desativar"} onClick={() => setStatusIntegrationConfiguration(service.sl_service_select_name, false)} />
            </Col>
          </Row>
        )
      case "bo-ms-tax-rule-node":
        return (
          <Row>
            <Col className="mt-3" md={3}>
              <b>Regra Fiscal</b>{" "}
            </Col>
            <Col className="mt-3" md={3}>
              <span>
                {getStatus(service)}
              </span>
            </Col>
            <Col className="mt-3" md={6}>
              <input type={"button"} value={"Ativar"} onClick={() => setStatusIntegrationConfiguration(service.sl_service_select_name, true)} />{' '}
              <input type={"button"} value={"Desativar"} onClick={() => setStatusIntegrationConfiguration(service.sl_service_select_name, false)} />
            </Col>
          </Row>
        )
      case "bo-ms-tax-rule-item-node":
        return (
          <Row>
            <Col className="mt-3" md={3}>
              <b>Regra Fiscal Item</b>{" "}
            </Col>
            <Col className="mt-3" md={3}>
              <span>
                {getStatus(service)}
              </span>
            </Col>
            <Col className="mt-3" md={6}>
              <input type={"button"} value={"Ativar"} onClick={() => setStatusIntegrationConfiguration(service.sl_service_select_name, true)} />{' '}
              <input type={"button"} value={"Desativar"} onClick={() => setStatusIntegrationConfiguration(service.sl_service_select_name, false)} />
            </Col>
          </Row>
        )
      case "bo-ms-customer-node":
        return (
          <Row>
            <Col className="mt-3" md={3}>
              <b>Cliente</b>{" "}
            </Col>
            <Col className="mt-3" md={3}>
              <span>
                {getStatus(service)}
              </span>
            </Col>
            <Col className="mt-3" md={6}>
              <input type={"button"} value={"Ativar"} onClick={() => setStatusIntegrationConfiguration(service.sl_service_select_name, true)} />{' '}
              <input type={"button"} value={"Desativar"} onClick={() => setStatusIntegrationConfiguration(service.sl_service_select_name, false)} />
            </Col>
          </Row>
        )
      case "bo-ms-product-node":
        return (
          <Row>
            <Col className="mt-3" md={3}>
              <b>Produto</b>{" "}
            </Col>
            <Col className="mt-3" md={3}>
              <span>
                {getStatus(service)}
              </span>
            </Col>
            <Col className="mt-3" md={6}>
              <input type={"button"} value={"Ativar"} onClick={() => setStatusIntegrationConfiguration(service.sl_service_select_name, true)} />{' '}
              <input type={"button"} value={"Desativar"} onClick={() => setStatusIntegrationConfiguration(service.sl_service_select_name, false)} />
            </Col>
          </Row>
        )
      case "bo-ms-order-node":
        return (
          <Row>
            <Col className="mt-3" md={3}>
              <b>Pedido</b>{" "}
            </Col>
            <Col className="mt-3" md={3}>
              <span>
                {getStatus(service)}
              </span>
            </Col>
            <Col className="mt-3" md={6}>
              <input type={"button"} value={"Ativar"} onClick={() => setStatusIntegrationConfiguration(service.sl_service_select_name, true)} />{' '}
              <input type={"button"} value={"Desativar"} onClick={() => setStatusIntegrationConfiguration(service.sl_service_select_name, false)} />
            </Col>
          </Row>
        )
    }
  }

  useEffect(() => {
    getStatusIntegrationConfiguration()
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Configuração do Integrador | Braile Distribuidora</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Configuração" breadcrumbItem="Integrador" />
          <Container fluid>
            <Card style={{ backgroundColor: "#f1f1f1" }}>
              <CardBody>
                <Row>
                  <Col className="mt-3" lg={12} sm={12} md={12} xs={12}>
                    <span>
                      Configurações de ativação e desativação do Integrador
                    </span>
                    <div
                      style={{
                        border: "1px solid silver",
                        borderRadius: "5px",
                        padding: "15px",
                        backgroundColor: "white",
                      }}
                    >         
                    {console.log(8, services)}             
                      {services.map(service => getRows(service))}
                    </div>
                  </Col>
                </Row>
                <div className="d-flex flex-wrap gap-2 mt-3">
                  <Button type="button" color="success" onClick={() => setStatusIntegrationConfiguration("", true)}>
                    Ativar todos
                  </Button>{' '}
                  <Button type="button" color="success" onClick={() => setStatusIntegrationConfiguration("", false)}>
                    Desativar todos
                  </Button>
                </div>
              </CardBody>
            </Card>
          </Container>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ConfigurationIntegration;
