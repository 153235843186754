import React from "react"
import ContentLoader from "react-content-loader"

const getTB = (props) => {
  let tr = [];
  let td = [];

  for (var x = 0; x < props.rows; x++) {
    tr.push(
      <tr style={{ backgroundColor: "white" }} key={x}>
        {td}
      </tr>
    )
  }

  for (var i = 0; i < props.cols; i++) {
    td.push(
      <td style={{ margin: "0px", padding: "0px" }} key={`getTB_${i}`}>
        <ContentLoader
          speed={2}
          width="auto"
          height={45}
          viewBox="0 0 800 150"
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
          style={{ paddingTop: "-0px" }}
          {...props}
        >
          <rect x="25" y="15" rx="5" ry="5" width="100%" height="120" />
        </ContentLoader>
      </td>
    )
  }

  return (tr)
}

const LoadTable = (props) => {
  return (
    <tbody>
      {getTB(props)}
    </tbody>
  )
}

export default LoadTable

/* <circle cx="10" cy="50" r="8" />
    <rect x="25" y="45" rx="5" ry="5" width="220" height="10" />
    <circle cx="10" cy="80" r="8" />
    <rect x="25" y="75" rx="5" ry="5" width="220" height="10" />
    <circle cx="10" cy="110" r="8" />
    <rect x="25" y="105" rx="5" ry="5" width="220" height="10" /> */