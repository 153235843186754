import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";

import { logoutUser } from "../../store/actions";

//redux
import { useDispatch } from "react-redux";

interface LogoutProps {
  history: any;
}

const Logout = ({ history }: LogoutProps) => {
  const dispatch = useDispatch();
  //localStorage.setItem("authUser", ""); //Temporario inserido manualmente
  useEffect(() => {
    dispatch(logoutUser(history));
    window.location.reload();
  }, [dispatch, history]);

  return <></>;
};

export default withRouter(Logout);
