import styled from "styled-components"

interface FlyerProps {
  width: number;
  marginLeft: number;
  marginRight: number;
  marginTop: number;
  marginBottom: number;
}

export const BoxDayItens = styled.div`
  padding-top: 25px;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const Message = styled.div`
  margin-top: 20px;
  width: 100%;
  background-color: #fcf8e3;
  padding: 10px;
  font-size: 1em;
`;

export const BoxSpinner = styled.div`
  width: 100%;
  height: 460px;
  text-align: center;
  padding-top: 20%;
`;

export const RowStyled = styled.div<{ gap: number }>`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: ${({ gap }) => gap ? gap : '20'}px;
`;

export const Flyer = styled.div<FlyerProps>`
  width: ${({ width }) => width ? width : '950'}px;
  padding: ${({ marginTop, marginRight, marginBottom, marginLeft }) => marginTop && marginLeft && marginBottom && marginRight ? `${marginTop}px ${marginRight}px ${marginBottom}px ${marginLeft}px` : '20px'};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  position: fixed;
  left: -2500px;
  z-index: -1;
`;

export const ButtonDownload = styled.button`
  border: none;
  border-radius: 10px;
  width: 50%;
  margin: 10px;
  color: white;
  background-color: #d73831;
`;

export const Visualization = styled.div`
  display: flex;
  flex-direction: column;
  background-image: linear-gradient(to bottom, #e8e8e8 0, #f5f5f5 100%);
  padding: 9px;
  width: 100%;
  margin: 20px 0;

  & > p {
    font-size: 12px;
  }
`;

export const VisualizationFlyer = styled.div<{ background: string}>`
  display: flex;
  padding: 20px 50px 20px 20px;
  height: 400px;
  overflow: auto;
  background-image: ${({ background}) => `url(${background})`};
`;

export const WrapperImage = styled.div`
  height: 100%;

  & > img {
    margin-bottom: 20px;
  }
`;