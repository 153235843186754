import { typeCrudListColumns } from "src/components/Common/typeCridList"
import {
  typeCrudCollapse,
  typeCrudForm
} from "src/components/Common/typeCrudForm"
import GenerateForm from "src/components/CRUD/generateComponents"
import { FormOrderItens } from "./formOrderItens"

const FormManufacturer = (props: {
  dataFormDb: {
    bf_liberation: Boolean;
    cr_code: String;
    ws_name: String;
    wh_image: String;
    ws_abbreviation: String;
    hl_abbreviation_url: String;
  };
  columnsTable: Array<typeCrudListColumns>;
  nextCrCode: string;
}) => {
  let { dataFormDb, columnsTable, nextCrCode } = props;
  if (nextCrCode) {
    dataFormDb.cr_code = nextCrCode;
  }

  const subtitleContent: Array<typeCrudForm> = [
    {
      Header: "",
      accessor: "wh_image",
      cols: "12",
      required: false,
      uniqueRow: true,
      custom: <FormOrderItens dataFormDb={dataFormDb} />,
    },
  ];
  const budgetContent: Array<typeCrudForm> = [
    {
      Header: "",
      accessor: "wh_image",
      cols: "12",
      required: false,
      uniqueRow: true,
      custom: <FormOrderItens dataFormDb={dataFormDb} />,
    },
  ];
  const subtitleForm: Array<typeCrudCollapse> = [
    {
      CollapseContent: subtitleContent,
      CollapseTitle: "Pedido",
    },
    {
      CollapseContent: budgetContent,
      CollapseTitle: "Informações do Orçamento",      
    }
  ];
  return GenerateForm(dataFormDb, subtitleForm);
};

export default FormManufacturer;
