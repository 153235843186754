import { ButtonStyled, SpinnerStyled } from "./styled"

export default function BOButton({ loading, ...props }) {
  const handleSpinner = () => {
    return <SpinnerStyled speed="0.65s" />;
  };
  return loading ? (
    <ButtonStyled {...props}>
      <>
        <SpinnerStyled primary={props.primary} /> &nbsp;
        {props.children}
      </>
    </ButtonStyled>
  ) : (
    <div>
      <ButtonStyled {...props} />
    </div>
  );
}
