import moment from "moment"
import React from "react"
import { contentUrlServer, typeUrlServer } from "src/common/urlServer"
import { formatDateTime } from "src/helpers/functions"
import CrudContainer from "./CrudContainer"
import {
  deleteDay,
  getDayByCode,
  getDayPaginationAdmin,
  getNextCrCodeDay,
  insertDay,
  updateDay
} from "./graphqlQuery"

// Traz a configuração pré-definida de urls do servidor
const urlServer: typeUrlServer = contentUrlServer.filter(
  item => item.name == "MANUFACTURER"
)[0];

function BfLiberation({ value, column, row }) {
  return (
    <a>
      {value ? (
        <span style={{ color: "green" }}>Sim</span>
      ) : (
        <span style={{ color: "red" }}>Não</span>
      )}
    </a>
  );
}

const Day = () => {
  const columns = React.useMemo(
    () => [
      {
        Header: "Código",
        accessor: "cr_code",
        visible: false,
      },
      {
        Header: "Fabricante",
        accessor: "wh_image_manufactures",
        Cell: ({ value, column, row }) => {
          const image = urlServer.urlServer + urlServer.pathServer + value;
          return (
            <div style={{ width: "100%", margin: "auto", textAlign: "center" }}>
              <img
                className="rounded m-auto"
                src={image}
                alt=""
                style={{
                  maxHeight: "18px",
                  margin: "auto",
                  textAlign: "center",
                }}
              />
            </div>
          );
        },
        /* Cell: AvatarCell, */
        /* imgAccessor: "cr_code_manufacturer", */
      },
      {
        Header: "Título",
        accessor: "ws_title",
      },
      {
        Header: "UF",
        accessor: "sl_uf",
      },
      {
        Header: "Itens",
        accessor: "nb_day_itens_count",
      },
      {
        Header: "Desconto",
        accessor: "ws_percentage_discount_format",
      },
      {
        Header: "Período Publicação",
        accessor: "dt_publication",
      },
      {
        Header: "Liberação",
        Cell: BfLiberation,
        accessor: "bf_liberation",
      },
      {
        Header: "Data Cadastro",
        accessor: "dx_register",
        Cell: ({ value, column, row }) => {
          return moment(value).format(formatDateTime);
        },
      },
      {
        Header: "Ações",
        accessor: "actions",
        Filter: () => <div style={{ height: "10px" }}></div>,
        Cell: cellContent => (
          <div
            style={{
              width: "100%",
              textAlign: "center",
              backgroundColor: "red",
            }}
          >
            {cellContent}
          </div>
        ),
      },
    ],
    []
  );
  return (
    <CrudContainer
      routePath=""
      queryGet={getDayPaginationAdmin}
      queryGetByCode={getDayByCode}
      querySave={insertDay}
      queryUpdate={updateDay}
      queryDelete={deleteDay}
      queryNextCrCode={getNextCrCodeDay}
      title="Day | Braile Distribuidora"
      breadcrumbTitle=""
      breadcrumbItem="Day"
      columnsTable={columns}
    />
  );
};

export default Day;
