import _ from "lodash"
import { Col } from "reactstrap"
import { typeCrudForm } from "src/components/Common/typeCrudForm"
import AvFieldBraile from "src/components/general/Input/AvFieldBraile";
import { Observation } from "../styled"

// Input # Numero
export default function getNC(
  valueField: any,
  subtitleFields: typeCrudForm,
  dataFormDb: any
): any {
  if (subtitleFields.cols === "0") return "";

  function handleChangeValue(e) {
    const value = e.target.value; //.replace(/\+|-/gi, "");
    _.set(dataFormDb, subtitleFields.accessor, value);
  }
  return (
    <Col
      className="mt-3"
      lg={subtitleFields.cols}
      sm={subtitleFields.cols}
      md={subtitleFields.cols}
      xs={12}
    >
      <AvFieldBraile
        disabled={subtitleFields.disabled}
        value={valueField}
        name={subtitleFields.accessor}
        id={
          subtitleFields.idfield
            ? subtitleFields.idfield
            : subtitleFields.accessor
        }
        label={subtitleFields.Header + " (% Percentual)"}
        placeholder=""
        type="text"
        /* errorMessage={
          subtitleFields.errorMessage
            ? subtitleFields.errorMessage
            : "Campo obrigatório"
        } */
        validate={{
          required: { value: false },
          // pattern: { value: "^[A-Za-z0-9]+$" },
        }}
        onChange={e => handleChangeValue(e)}
      />
      {subtitleFields.observation && (
        <Observation>{subtitleFields.observation}</Observation>
      )}
    </Col>
  );
}