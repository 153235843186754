import { AiOutlineGlobal, AiTwotoneUsb } from 'react-icons/ai'
import { BiBarcodeReader } from 'react-icons/bi'
import { BsFillMouse2Fill } from 'react-icons/bs'
import { CgSmartphoneChip, CgTag } from 'react-icons/cg'
import { GiCardboardBox, GiKidSlide, GiPartyPopper } from 'react-icons/gi'
import { ImHome3, ImPrinter } from 'react-icons/im'
import {
  IoIosBook,
  IoIosConstruct,
  IoIosGlobe,
  IoMdRibbon
} from 'react-icons/io'
import {
  IoDesktopOutline,
  IoFlash,
  IoGameController,
  IoHourglassOutline,
  IoWifi
} from 'react-icons/io5'
import { MdOutlineCable, MdOutlineImportantDevices } from 'react-icons/md'
import { contentUrlServer, typeUrlServer } from "src/common/urlServer"
import {
  typeCrudCollapse,
  typeCrudForm
} from "src/components/Common/typeCrudForm"
import GenerateForm from "src/components/CRUD/generateComponents"

// Traz a configuração pré-definida de urls do servidor
const urlServer: typeUrlServer = contentUrlServer.filter(
  item => item.name == "CATEGORY"
)[0];
// Imagem do banner
const urlServerBanner: typeUrlServer = contentUrlServer.filter(
  item => item.name == "CATEGORYMINIBANNER"
)[0];

const FormCategory = (props: {
  dataFormDb: {
    bf_liberation: Boolean;
    cr_code: String;
    ws_name: String;
    wh_image: String;
    ws_abbreviation: String;
    hl_abbreviation_url: String;
  };
  columnsTable: Array<typeCrudForm>;
  nextCrCode: string;
}) => {
  let { dataFormDb, columnsTable, nextCrCode } = props;
  if (nextCrCode) {
    dataFormDb.cr_code = nextCrCode;
  }

    function amountListIcons(): Array<{
      cr_code: string;
      ws_value: any;
      icon?: any;
    }> {
      let list = [
        {
          cr_code: 'AiOutlineGlobal',
          ws_value: 'AiOutlineGlobal',
          icon: <AiOutlineGlobal style={{ width: '50px', height: '50px' }} />
        },
        {
          cr_code: 'IoHourglassOutline',
          ws_value: 'IoHourglassOutline',
          icon: <IoHourglassOutline style={{ width: '50px', height: '50px' }} />
        },
        {
          cr_code: 'IoMdRibbon',
          ws_value: 'IoMdRibbon',
          icon: <IoMdRibbon style={{ width: '50px', height: '50px' }} />
        },
        {
          cr_code: 'IoIosGlobe',
          ws_value: 'IoIosGlobe',
          icon: <IoIosGlobe style={{ width: '50px', height: '50px' }} />
        },
        {
          cr_code: 'IoIosBook',
          ws_value: 'IoIosBook',
          icon: <IoIosBook style={{ width: '50px', height: '50px' }} />
        },
        {
          cr_code: 'BsFillMouse2Fill',
          ws_value: 'BsFillMouse2Fill',
          icon: <BsFillMouse2Fill style={{ width: '50px', height: '50px' }} />
        },
        {
          cr_code: 'AiTwotoneUsb',
          ws_value: 'AiTwotoneUsb',
          icon: <AiTwotoneUsb style={{ width: '50px', height: '50px' }} />
        },
        {
          cr_code: 'BiBarcodeReader',
          ws_value: 'BiBarcodeReader',
          icon: <BiBarcodeReader style={{ width: '50px', height: '50px' }} />
        },
        {
          cr_code: 'MdOutlineCable',
          ws_value: 'MdOutlineCable',
          icon: <MdOutlineCable style={{ width: '50px', height: '50px' }} />
        },
        {
          cr_code: 'CgSmartphoneChip',
          ws_value: 'CgSmartphoneChip',
          icon: <CgSmartphoneChip style={{ width: '50px', height: '50px' }} />
        },
        {
          cr_code: 'MdOutlineImportantDevices',
          ws_value: 'MdOutlineImportantDevices',
          icon: <MdOutlineImportantDevices style={{ width: '50px', height: '50px' }} />
        },
        {
          cr_code: 'IoWifi',
          ws_value: 'IoWifi',
          icon: <IoWifi style={{ width: '50px', height: '50px' }} />
        },
        {
          cr_code: 'IoFlash',
          ws_value: 'IoFlash',
          icon: <IoFlash style={{ width: '50px', height: '50px' }} />
        },
        {
          cr_code: 'IoIosConstruct',
          ws_value: 'IoIosConstruct',
          icon: <IoIosConstruct style={{ width: '50px', height: '50px' }} />
        },
        {
          cr_code: 'IoGameController',
          ws_value: 'IoGameController',
          icon: <IoGameController style={{ width: '50px', height: '50px' }} />
        },
        {
          cr_code: 'ImPrinter',
          ws_value: 'ImPrinter',
          icon: <ImPrinter style={{ width: '50px', height: '50px' }} />
        },
        {
          cr_code: 'IoDesktopOutline',
          ws_value: 'IoDesktopOutline',
          icon: <IoDesktopOutline style={{ width: '50px', height: '50px' }} />
        },
        {
          cr_code: 'ImHome3',
          ws_value: 'ImHome3',
          icon: <ImHome3 style={{ width: '50px', height: '50px' }} />
        },
        {
          cr_code: 'CgTag',
          ws_value: 'CgTag',
          icon: <CgTag style={{ width: '50px', height: '50px' }} />
        },
        {
          cr_code: 'GiCardboardBox',
          ws_value: 'GiCardboardBox',
          icon: <GiCardboardBox style={{ width: '50px', height: '50px' }} />
        }, 
        {
          cr_code: 'GiKidSlide',
          ws_value: 'GiKidSlide',
          icon: <GiKidSlide style={{ width: '50px', height: '50px' }} />
        },
        {
          cr_code: 'GiPartyPopper',
          ws_value: 'GiPartyPopper',
          icon: <GiPartyPopper style={{ width: '50px', height: '50px' }} />
        }
      ]
    return list
  }

  const subtitleContent: Array<typeCrudForm> = [
    {
      Header: "Código",
      accessor: "cr_code",
      cols: "4",
      required: true,
    },
    {
      Header: "Destaque",
      accessor: "bf_highlight",
      cols: "1",
      required: true,
    },
    {
      Header: "Liberação",
      accessor: "bf_active",
      cols: "1",
      required: true,
      uniqueRow: true,
    },
    {
      Header: "Nome",
      accessor: "ws_name",
      cols: "4",
      required: true,
    },
    {
      Header: "Nome URL",
      accessor: "ws_name_url",
      cols: "4",
      required: false,
      disabled: true,
    },
    {
      Header: "Ordem menu",
      accessor: "nb_order_menu",
      cols: "4",
      required: true,
      maxlength: 1,
    },
    {
      Header: "Menu colunas",
      accessor: "nb_column_menu",
      cols: "4",
      required: true,
    },
    {
      Header: "Link banner",
      accessor: "hl_banner_link",
      cols: "8",
      required: false,
    },
    {
      Header: "Imagem ícone",
      accessor: "si_react_icones",
      cols: "4",
      required: true,
      uniqueRow: true,
      listItensIcons: amountListIcons()
      // url: urlServer.urlServer + urlServer.pathServer + "/",
      // observation:
      //   "IMPORTANTE: as imagens devem estar padronizadas em tamanho X altura e ser no formato PNG com fundo transparente.",
    },
    {
      Header: "Imagem banner",
      accessor: "wh_banner_imagem",
      cols: "4",
      required: false,
      uniqueRow: true,
      url: urlServerBanner.urlServer + urlServerBanner.pathServer + "/",
      observation:
        "Esta imagem é apresentada no menu de categorias do site, à direita das subcategorias.",
      urlPathServerFile: urlServer.pathServer
    },
    {
      Header: "Posição banner",
      accessor: "sl_banner_position",
      cols: "4",
      required: false,
      observation:
        "Posição de alinhamento horizontal do banner da categoria dentro do menu de subcategorias do site.",
    },
    {
      Header: "Data cadastro",
      accessor: "dx_register",
      cols: "4",
      required: false,
      disabled: true,
    },
  ];
  const subtitleForm: Array<typeCrudCollapse> = [
    {
      CollapseContent: subtitleContent,
    },
  ];
  return GenerateForm(dataFormDb, subtitleForm);
};

export default FormCategory;
