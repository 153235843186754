
import { useEffect, useState } from 'react'
import { X } from 'react-feather'
import { Button, Col } from 'reactstrap'
import { contentUrlServer, typeUrlServer } from 'src/common/urlServer'
import { API_URL_DNS, API_URL_UPLOAD } from 'src/helpers/api_helper'
import FileUploaderMultiple from './FileUploaderMultiple'

// Traz a configuração pré-definida de urls do servidor
const urlServer: typeUrlServer = contentUrlServer.filter(
  item => item.name == "PRODUCTIMAGE"
)[0];

const urlServerLarge: typeUrlServer = contentUrlServer.filter(
  item => item.name == "PRODUCTIMAGELARGE"
)[0];

export const ComponentMultUpload = (props: { dataFormDb: any, label: any }) => {
  const [listFileUpload, setListFileUpload] = useState([])
  const [files, setFiles] = useState([])
  const [imagesAlreadyAdded, setImagesAlreadyAdded] = useState([])

  function handleSendFiles() {
    for (const file of files) {
      // Altera o nome do arquivo que será salvo
      const namefile = file.lastModified
      const extension = file.path.split(".").pop() || ""
      // Add essa imagem a lista do produto
      props.dataFormDb.wl_image_list.push(String(namefile + "." + extension))
      // Envia o arquivo com form data
      const formDataNew = new FormData();
      formDataNew.append("location", urlServer.pathServer); //"/teste/");
      formDataNew.append("namefile", namefile);
      formDataNew.append("file", file);
      const apiUrl = API_URL_UPLOAD;
      const options = {
        method: 'POST',
        body: formDataNew,
        maxContentLength: "infinity",
        maxBodyLength: "infinity",
      };

      fetch(`${apiUrl}/user/upload`, options)

      // Envia também para o formato grande
      // Envia o arquivo com form data
      const formDataNewLarge = new FormData();
      formDataNewLarge.append("location", urlServerLarge.pathServer); //"/teste/");
      formDataNewLarge.append("namefile", namefile);
      formDataNewLarge.append("file", file);
      const optionsLarge = {
        method: 'POST',
        body: formDataNewLarge,
        maxContentLength: "infinity",
        maxBodyLength: "infinity",
      };

      fetch(`${apiUrl}/user/upload`, optionsLarge)
    }
    alert("Arquivos enviados com sucesso! Salve o cadastro do produto!")
  }

  const handleRemoveFile = file => {
    const filesFromData = [...imagesAlreadyAdded];
    const filtered = filesFromData.filter(i => i !== file);
    setImagesAlreadyAdded([...filtered]);

    // Remove o arquivo da lista de arquivos que serão enviados
    props.dataFormDb.wl_image_list.map(item => {
      if (item === file) {
        const index = props.dataFormDb.wl_image_list.indexOf(item)
        props.dataFormDb.wl_image_list.splice(index, 1)
      }
    })
  };

  const handleRemoveFileInit = () => {
    // Remove o arquivo da lista de arquivos que serão enviados
    props.dataFormDb.wl_image_list.map(item => {
      if (item === props.dataFormDb.wh_image) {
        const index = props.dataFormDb.wl_image_list.indexOf(item)
        props.dataFormDb.wl_image_list.splice(index, 1)
      }
    })
  };

  useEffect(() => {
    handleRemoveFileInit()
    if (props.dataFormDb.wl_image_list) {
      setImagesAlreadyAdded(props.dataFormDb.wl_image_list)
    }
  }, [props.dataFormDb.wl_image_list])

  return (<>
    <h5 className='mt-5'>{props.label}</h5>
    <b className='mt-5' style={{ marginLeft: '20px' }}>Imagens atuais</b>
    {imagesAlreadyAdded.map(img => (<>
      <Col style={{ maxWidth: '200px' }}>
        <div className="flex items-center">
          <div className="flex-shrink-0 h-10 w-10" style={{ width: '150px', height: '150px' }}>
            <img
              className="rounded img-t1"
              src={API_URL_DNS + "/user/p/g/" + img}
              alt="img"
              style={{
                width: '150px',
                height: '150px',
                objectFit: 'contain',
              }}
            />
            <Button
              outline
              size="sm"
              className="btn-icon"
              style={{
                position: 'absolute',
                top: '0',
                right: '0',
                backgroundColor: '#d73831',
                color: 'white',
                borderColor: '#d73831'
              }}
              onClick={() => handleRemoveFile(img)}
            >
              <X size={14} />
            </Button>
          </div>
        </div>
      </Col>
    </>))}
    <FileUploaderMultiple setListFileUpload={setListFileUpload} setData={setFiles} handleSendFiles={handleSendFiles} />
  </>)
}
