import { Modal } from "reactstrap"

export default function modalConfirm(
  modal_backdrop,
  setmodal_backdrop,
  tog_backdrop,
  sendSuccess,
  setmodal_success
) {
  return (
    <Modal
      isOpen={modal_backdrop}
      toggle={() => {
        tog_backdrop();
      }}
      backdrop={"static"}
      id="staticBackdrop"
    >
      <div className="modal-header">
        <h5 className="modal-title" id="staticBackdropLabel">
          Envio de senha
        </h5>
        <button
          type="button"
          className="btn-close"
          onClick={() => {
            // sendSuccess()
            setmodal_backdrop()
          }}
          aria-label="Close"
        ></button>
      </div>
      <div className="modal-body">
        <p>Confirma envio do email com nova senha de acesso?</p>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-light"
          onClick={() => setmodal_backdrop()}
        >
          Cancelar
        </button>
        <button type="button" className="btn btn-primary" onClick={() => {
            sendSuccess()
            setmodal_backdrop()
          }}>
          Enviar
        </button>
      </div>
    </Modal>
  );
}
