/* IMPORTANTE: Deve incluir as fun principais do CRUD
 * getData, updateData, deleteData, postData
 * estas chamadas são padrão no CRUD/CrudGroupForm */

export const getDayByCode = (cr_code: string): string => {
  return `{
    getDayByCodeAdmin(cr_code:"${cr_code}") {
      bf_active
      bf_liberation
      cr_code
      ck_registeruser
      dx_register
      dx_modification
      cr_code_manufacturer
      ws_title
      dt_publication_begin
      dt_publication_end
      nc_percentage_discount
      sl_uf
      cl_color_background
      wh_top_image
      wh_footer_image
      wh_highlight_image
      wh_highlight_image_transition
      qi_day_itens {
        cr_code
        cr_code_product
        cr_code_day
        dc_day_price_sale_site
        dc_day_price_cartridge_10d
        dc_day_price_sale_site_forward
        bf_price_site_automatic
        bf_price_site_forward_automatic
        ox_product {
          cr_code
        }
      }
    }
  }`;
};

export const getDays = (): string => {
  return `query {
    getDay {
      cr_code
      bf_liberation
      dx_register
      cr_code_manufacturer
      ws_title
      dt_publication_begin
      dt_publication_end
      dt_publication
      nc_percentage_discount
      ws_percentage_discount_format
      sl_uf
      cl_color_background
      wh_top_image
      wh_footer_image
      wh_highlight_image
      wh_highlight_image_transition
      nb_day_itens_count      
      wh_image_manufactures
    }
  }`;
};

export const getDayItem = (cr_code: string): string => {
  return `query {
    getDayItem(cr_code:"${cr_code}") {
      cr_code_erp
      cv_version
      bf_active
      bf_liberation
      cr_code
      ck_registeruser
      dx_register
      dx_modification
      cr_code_product
      cr_code_day
      dc_day_price_sale_site
      dc_day_price_cartridge_10d
      dc_day_price_sale_site_forward
      bf_price_site_automatic
      bf_price_site_forward_automatic
      ox_product {
        cr_code
        ws_commercial_description
        wh_image   
        bf_discount_percentage
        nc_discount_percentage     
      }
    }
  }`;
};

export const getDayItensPaginationController = (
  filter: String,
  limit: Number = 10,
  random: Boolean = false,
  skip: Number = 0,
  sort: String = "{}"
): string => {
  let filter2 = "{}";
  let filter3 = JSON.stringify(filter);
  return `query {
    getDayItemPagination(filter: {
      filter: ${filter3},
      sort: "${sort}",
      skip: ${skip},  
      limit: ${limit},                      
      random: ${random},
    }) {
      result {
        cr_code_erp
        cv_version
        bf_active
        bf_liberation
        cr_code
        ck_registeruser
        dx_register
        dx_modification
        cr_code_product
        cr_code_day
        dc_day_price_sale_site
        dc_day_price_cartridge_10d
        dc_day_price_sale_site_forward
        bf_price_site_automatic
        bf_price_site_forward_automatic
        ox_product {
          cr_code
          ws_commercial_description
          wh_image   
          bf_discount_percentage
          nc_discount_percentage     
        }
      }
      rows
      rowsall
    }
  }`;
};

export const deleteDay = (cr_code: string): string => {
  return `query {
    deleteDayByCode(cr_code:"${cr_code}") {
      message
    }
  }`;
};

export const updateDay = (data: {
  cr_code: String;
  bf_active: Boolean;
  cr_code_manufacturer: String;
  ws_title: String;
  dt_publication_begin: String;
  dt_publication_end: String;  
  nc_percentage_discount: String;
  sl_uf: String;
  cl_color_background: String;
  wh_top_image: String;
  wh_footer_image: String;
  wh_highlight_image: String;
  wh_highlight_image_transition: String;  
}): string => {
  return `mutation {
    updateDay(
      cr_code: "${data.cr_code}",
      bf_active: ${data.bf_active},
      cr_code_manufacturer: "${data.cr_code_manufacturer}",
      ws_title: "${data.ws_title}",
      dt_publication_begin: "${data.dt_publication_begin}",
      dt_publication_end: "${data.dt_publication_end}",
      nc_percentage_discount: "${data.nc_percentage_discount}",
      sl_uf: "${data.sl_uf}",
      cl_color_background: "${data.cl_color_background}",
      wh_top_image: "${data.wh_top_image}",
      wh_footer_image: "${data.wh_footer_image}",
      wh_highlight_image: "${data.wh_highlight_image}",
      wh_highlight_image_transition: "${data.wh_highlight_image_transition}", 
   ){
  	message
   }
  }`;
};

export const insertDay = (data: {
  cr_code: String;
  bf_active: Boolean;
  cr_code_manufacturer: String;
  ws_title: String;
  dt_publication_begin: String;
  dt_publication_end: String;  
  nc_percentage_discount: String;
  sl_uf: String;
  cl_color_background: String;
  wh_top_image: String;
  wh_footer_image: String;
  wh_highlight_image: String;
  wh_highlight_image_transition: String;  
}): string => {
  
  return `mutation {
    insertDay(
      cr_code: "${data.cr_code}",
      bf_active: ${data.bf_active},
      cr_code_manufacturer: "${data.cr_code_manufacturer}",
      ws_title: "${data.ws_title}",
      dt_publication_begin: "${data.dt_publication_begin}",
      dt_publication_end: "${data.dt_publication_end}",
      nc_percentage_discount: "${data.nc_percentage_discount}",
      sl_uf: "${data.sl_uf}",
      cl_color_background: "${data.cl_color_background}",
      wh_top_image: "${data.wh_top_image}",
      wh_footer_image: "${data.wh_footer_image}",
      wh_highlight_image: "${data.wh_highlight_image}",
      wh_highlight_image_transition: "${data.wh_highlight_image_transition}", 
   ){
  	message
   }
  }`;
};

export const getNextCrCodeDay = (): string => {
  return `query {
    getNextCrCodeDay {
      result
    }
  }`;
};

export const getDayPaginationAdmin = (
  filter: String,
  limit: Number = 10,
  random: Boolean = false,
  skip: Number = 0,
  sort: String = "{}"
): string => {
  let filter2 = "{}";
  let filter3 = JSON.stringify(filter);
  return `query {
    getDayPaginationAdmin(filter: {
      filter: ${filter3},
      sort: "${sort}",
      skip: ${skip},  
      limit: ${limit},                      
      random: ${random},
    }) {
      result {
        cr_code
        bf_liberation
        dx_register
        cr_code_manufacturer
        ws_title
        dt_publication_begin
        dt_publication_end
        dt_publication
        nc_percentage_discount
        ws_percentage_discount_format
        sl_uf
        cl_color_background
        wh_top_image
        wh_footer_image
        wh_highlight_image
        wh_highlight_image_transition
        nb_day_itens_count      
        wh_image_manufactures
      } 
      rows
      rowsall
    }
  }`;
};

export const getProducts = (
  filter: String = "{}",
  limit: Number = 30,
  random: Boolean = false,
  skip: Number = 0,
  sort: String = "{}",
  state: String = ""
): string => {
  let filter2 = "{}";
  let filter3 = JSON.stringify(filter);
  return `{
    getProductPaginationAdmin(filter: {
      filter: ${filter3},
        sort: "${sort}",
        skip: ${skip},  
        limit: ${limit},                      
        random: ${random},
        state: "${state}"
    }) { 
      result {
        cr_code_erp
        cv_version
        bf_active
        bf_liberation
        cr_code
        ck_registeruser
        dx_register
        dx_modification
        bf_highlighted
        bf_hot_deal
        bf_new_product
        qs_buy_too
        qs_bundle
        cr_code_manufactures
        ws_name_manufactures
        cr_fiscal_classification
        cr_code_category
        cr_code_subcategory
        dt_validate_new
        ws_commercial_description
        wu_unit_measure
        ws_part_number
        dc_gross_weight
        dc_inventory_balance
        dc_percent_ipi
        nb_tax_code_source
        wt_commercial_text        
        wh_image
        ws_name_category
        ws_name_subcategory
        bf_discount_percentage
        nc_discount_percentage  
        qi_price {
          dc_price
          wr_price_currency
          sl_price_description
          dc_price_format
          dc_price_discount
          dc_price_discount_format
          bf_emphasis_price_special
          dt_start_period
          dt_end_period
        }
        qf_price_special{
          dc_price
          wr_price_currency
          sl_price_currency
          sl_price_description
          dc_price_format
          dt_start_period
          dt_end_period
          bf_emphasis
        }
      }
      rows
      rowsall
    }
  }`;
};

export const insertDayItens = (data: {
  ck_registeruser: String;
  cr_code_product: String;
  cr_code_day: String;
  dc_day_price_sale_site: String;
  dc_day_price_cartridge_10d: String;
  dc_day_price_sale_site_forward: String;
  bf_price_site_automatic: Boolean;
  bf_price_site_forward_automatic: Boolean;
}): string => {
  return `mutation {
    insertDayItens(
      ck_registeruser: "${data.ck_registeruser}",
      cr_code_product: "${data.cr_code_product ? data.cr_code_product : ""}",
      cr_code_day: "${data.cr_code_day ? data.cr_code_day : ""}",
      dc_day_price_sale_site: "${
        data.dc_day_price_sale_site ? data.dc_day_price_sale_site : ""
      }",
      dc_day_price_cartridge_10d: "${
        data.dc_day_price_cartridge_10d ? data.dc_day_price_cartridge_10d : ""
      }",
      dc_day_price_sale_site_forward: "${data.dc_day_price_sale_site_forward ? data.dc_day_price_sale_site_forward : ""}",
      bf_price_site_automatic: ${data.bf_price_site_automatic},
      bf_price_site_forward_automatic: ${data.bf_price_site_forward_automatic}
   ){
  	result
   }
  }`;
};

export const updateDayItem = (data: {
  cr_code_product: String;
  cr_code_day: String;
  dc_day_price_sale_site: String;
  dc_day_price_sale_site_forward: String;
  bf_price_site_automatic: Boolean;
  bf_price_site_forward_automatic: Boolean;
}): string => {  
  return `mutation {
    updateDayItem(
      cr_code_product: "${data.cr_code_product ? data.cr_code_product : ""}",
      cr_code_day: "${data.cr_code_day ? data.cr_code_day : ""}",
      dc_day_price_sale_site: "${
        data.dc_day_price_sale_site ? data.dc_day_price_sale_site : ""
      }",
      dc_day_price_sale_site_forward: "${data.dc_day_price_sale_site_forward ? data.dc_day_price_sale_site_forward : ""}",
      bf_price_site_automatic: ${data.bf_price_site_automatic},
      bf_price_site_forward_automatic: ${data.bf_price_site_forward_automatic}
   ){
  	result
   }
  }`;
};

export const deleteDayItem = (data: {
  cr_code_product: String;
  cr_code_day: String;
}): string => {
  return `mutation {
    deleteDayItem(
      cr_code_product: "${data.cr_code_product ? data.cr_code_product : ""}",
      cr_code_day: "${data.cr_code_day ? data.cr_code_day : ""}",      
   ){
  	result
   }
  }`;
};

export const getListManufacturers = (): string => {
  return `query {
    getManufacturers {
      cr_code
      ws_abbreviation
    }
  }`;
};
