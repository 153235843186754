import React from "react"
import { Col, Label } from "reactstrap"
import { typeCrudForm } from "src/components/Common/typeCrudForm"
import CrudList from "../CrudList"
import { Observation } from "../styled"
import { hasJsonStructure } from "../utils/functions"

// Input # Nome de arquivo
export default function getQI(
  valueField: any,
  subtitleFields: typeCrudForm,
  dataFormDb: any,
  functionCollapse: any
): any {
  const columns = [
    {
      Header: "Faixa Inicial R$",
      accessor: "ws_start",
    },
    {
      Header: "Parcelas Disponíveis",
      accessor: "ws_installment",
    },
    {
      Header: "Ações",
      accessor: "actions",
      Filter: () => <div style={{ height: "10px" }}></div>,
      Cell: cellContent => (
        <div
          style={{
            width: "100%",
            textAlign: "center",
            backgroundColor: "red",
          }}
        >
          {cellContent}
        </div>
      ),
    },
  ];
  const [itens, setItens] = React.useState([]);
  if (subtitleFields.cols === "0") return "";
  let maps = [];
  /* dataFormDb.map(item =>
    maps.push({
      cr_code: item.cr_code,
    })
  ); */
  return (
    <Col
      className="mt-3"
      lg={subtitleFields.cols}
      sm={subtitleFields.cols}
      md={subtitleFields.cols}
      xs={12}
    >

      {dataFormDb.qi_payment_term_range.length > 0 &&
        hasJsonStructure(dataFormDb.qi_payment_term_range[0]) && (
          <>
            <Label className="form-Label">{subtitleFields.Header}</Label>
            {/* <DataTable
              columns={columns}
              dataTable={[
                {
                  cr_code: "XXX1",
                },
                {
                  cr_code: "XXX2",
                },
              ]}
              headFilters={false}
              handleNewRegister={null}
              optionsNewRegister={[]}
              loadingEntitie={false}
              mergeCollumnsRows={null}
              visibleNewRegister={null}
            /> */}
            <CrudList
              queryGet={() => functionCollapse.queryGetByCode("CODE_PT_001")}
              queryDelete={() => {}}
              queryUpdate={() => {}}
              handleNewRegister={() => {}}
              setSelectedRow={() => {}}
              optionsActions={[]}
              columns={columns}
            />
          </>
        )}
      {/* <select
        className="form-select"
        name={subtitleFields.accessor}
        id={
            subtitleFields.idfield
              ? subtitleFields.idfield
              : subtitleFields.accessor
          }
        onChange={e => {
          _.set(dataFormDb, subtitleFields.accessor, e.target.value);
        }}
        defaultValue={valueField}
      >
        <option key={1}>Selecione uma opção</option>
        <option key={2}>Acessórios e Periféricos</option>
      </select> */}
      {subtitleFields.observation && (
        <Observation>{subtitleFields.observation}</Observation>
      )}
    </Col>
  );
}
