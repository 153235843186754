import moment from 'moment';
import React from 'react';
import { formatDateTime } from 'src/helpers/functions';
import CrudContainer from './CrudContainer';
import {
  deletePaymentTerms,
  getNextCrCodePaymentTerms,
  getPaymentTerms,
  getPaymentTermsByCode,
  insertPaymentTerms,
  updatePaymentTerms
} from './graphqlQuery';

function BfLiberation({ value, column, row }) {
  return (
    <a>
      {value ? (
        <span style={{ color: 'green' }}>Sim</span>
      ) : (
        <span style={{ color: 'red' }}>Não</span>
      )}
    </a>
  );
}

const PaymentTerms = () => {
  const columns = React.useMemo(
    () => [
      {
        Header: 'Código',
        accessor: 'cr_code'
      },
      {
        Header: 'Nome',
        accessor: 'ws_name'
      },
      {
        Header: 'Descrição',
        accessor: 'ws_description'
      },
      {
        Header: 'Valor Mínimo R$',
        accessor: 'ws_minimum_value'
      },
      {
        Header: 'Liberação',
        Cell: BfLiberation,
        accessor: 'bf_liberation'
      },
      {
        Header: 'Data Cadastro',
        accessor: 'dx_register',
        Cell: ({ value, column, row }) => {
          return moment(value).format(formatDateTime);
        }
      },
      {
        Header: 'Ações',
        accessor: 'actions',
        Filter: () => <div style={{ height: '10px' }}></div>,
        Cell: (cellContent) => (
          <div
            style={{
              width: '100%',
              textAlign: 'center',
              backgroundColor: 'red'
            }}
          >
            {cellContent}
          </div>
        )
      }
    ],
    []
  );
  return (
    <CrudContainer
      routePath=""
      queryGet={getPaymentTerms}
      queryGetByCode={getPaymentTermsByCode}
      querySave={insertPaymentTerms}
      queryUpdate={updatePaymentTerms}
      queryDelete={deletePaymentTerms}
      queryNextCrCode={getNextCrCodePaymentTerms}
      title="Condição de Pagamento | Braile Distribuidora"
      breadcrumbTitle="Outros"
      breadcrumbItem="Condição de Pagamento"
      columnsTable={columns}
    />
  );
};

export default PaymentTerms;
