import React from "react";

//import Breadcrumbs
import moment from "moment";
import { formatDateTime } from "src/helpers/functions";
import CrudContainer from "./CrudContainer";
import {
  getProductByCode,
  getProducts,
  updateProduct
} from "./graphqlQuery";
import { LabelCnpj, LabelSocial } from "./styled";
import { Button } from "reactstrap";
import modalConfirm from "./CrudFormGroup/forms/modalConfirm";
import { post } from "src/helpers/api_helper";

function BfLiberation({ value, column, row }) {
  return (
    <a>
      {value ? (
        <span style={{ color: "green" }}>Sim</span>
      ) : (
        <span style={{ color: "red" }}>Não</span>
      )}
    </a>
  );
}

const RegistrationApproval = () => {
  const [checkeds, setCheckeds] = React.useState([]);
  const [subcategorySelected, setSubcategorySelected] = React.useState<string[]>([]);
  const [date, setDate] = React.useState<string>('');
  const [openModal, setOpenModal] = React.useState(false);

  function toggleModal() {
    setOpenModal(!openModal);
  };

  async function handleUpdate() {
    //aqui atualiza os dados
    console.log('aqui', subcategorySelected, date, checkeds);
    if(!subcategorySelected) {
      alert('Selecione uma categoria');
      return;
    }

    if(!date) {
      alert('Selecione uma data');
      return;
    } 
    //se a data for menor que a data atual
    if(moment(date).isBefore(moment())) {
      alert('A data deve ser maior que a data atual');
      return;
    }
    
    checkeds.map((item) => (
      update(item)
    ));
  }

  async function update(item) {
    const data = {
      cr_code: item,
      dt_validate_new: date,
      // // ws_name_subcategory: subcategorySelected,
      qs_code_tags: subcategorySelected,
    }
    await post(
      "/graphql",
      {
        query: updateProduct(data),
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then(response => {
        setOpenModal(false)
        setCheckeds([]);
      })
      .catch(error => alert("Falha ao atualizar registro!"));
    setOpenModal(false)
  }

  const columns = React.useMemo(
    () => [
      {
        Header: "Selecionar",
        accessor: "cr_code_erp",
        Cell: ({ value, column, row }) => {
          console.log('aqui', row)
          return <input
            type="checkbox"
            checked={!!checkeds.find(item => item === row.cells[0].value)}
            onClick={(e: any) => {
              if (e.target.checked) {
                setCheckeds(prev => [...prev, row.cells[0].value])
              } else {
                setCheckeds(prev => prev.filter(item => item !== row.cells[0].value))
              }
            }} />
        }
      },
      {
        Header: "Código",
        accessor: "cr_code",
      },
      {
        Header: "Subcategoria",
        accessor: "cr_code_category",
      },
      {
        Header: "Descrição",
        accessor: "ws_commercial_description",
      },
      {
        Header: "Validade",
        accessor: "dt_validate_new",
        Cell: ({ value, column, row }) => {
          return moment(value).format(formatDateTime);
        },
      },
      {
        Header: "Novo",
        visible: false,
        Cell: BfLiberation,
        accessor: "bf_new_product",
      },
    ],
    [checkeds]
  );


  return (
    <div>
      {modalConfirm(
        openModal,
        toggleModal,
        subcategorySelected,
        setSubcategorySelected,
        date,
        setDate,
        handleUpdate
      )}
      <Button
        style={{ zIndex: 1003, position: 'relative', top: '80px', left: '20px' }}
        onClick={() => {
          if (checkeds.length > 0) {
            setOpenModal(true)
          } else {
            alert('Selecione ao menos um produto')
          }
        }}
      >
        Atualizar selecionados
      </Button>
      <CrudContainer
        routePath=""
        queryGet={getProducts}
        queryGetByCode={getProductByCode}
        querySave={() => { }}
        queryUpdate={updateProduct}
        queryDelete={null}
        queryNextCrCode={() => { }}
        title="Atualização Produtos Novos | Braile Distribuidora"
        breadcrumbTitle="Produto"
        breadcrumbItem="Atualização Produtos Novos"
        columnsTable={columns}
        setCheckeds={setCheckeds}
      />
    </div>
  );
};

export default RegistrationApproval;
