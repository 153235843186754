import { Redirect } from "react-router-dom";
// Paginas gerais
import AbcCurve from "src/pages/AbcCurve/index";
//Authentication pages
import Login from "src/pages/Authentication/Login";
import Logout from "src/pages/Authentication/Logout";
import Register from "src/pages/Authentication/Register";
import userProfile from "src/pages/Authentication/user-profile";
import Banner from "src/pages/Banner/index";
import Bundle from "src/pages/Bundle/index";
import Category from "src/pages/Category/index";
import Customer from "src/pages/Customer/index";
import Day from "src/pages/Day/index";
import DeferredItem from "src/pages/DeferredItem/index";
import Flyer from "src/pages/Flyer/index";
import Lgpd from "src/pages/Lgpd/index";
import Manufacturer from "src/pages/Manufacturer/index";
import Order from "src/pages/Order/index";
import PaymentTerms from "src/pages/PaymentTerms/index";
import Popup from "src/pages/Popup/index";
import PrivacyPolicy from "src/pages/PrivacyPolicy/index";
import ProductNotice from "src/pages/ProductNotice/index";
import ProductRegistration from "src/pages/ProductRegistration/index";
import ProductRule from "src/pages/ProductRule/index";
import Recipient from "src/pages/Recipient/index";
import RegistrationApproval from "src/pages/RegistrationApproval/index";
import RegistrationIntegration from "src/pages/RegistrationIntegration/index";
import Seller from "src/pages/Seller/index";
import SubCategory from "src/pages/SubCategory/index";
import UpdateNewProduct from "src/pages/UpdateNewProduct";
import UploadRma from "src/pages/UploadRma/index";
import LogsEmails from "src/pages/Logs/Emails";
import LogsFiscal from "src/pages/Logs/Fiscal";
import LogsOrder from "src/pages/Logs/Order";
import ConfigurationIntegration from "src/pages/Configuration/Integration";
//Dashboard
import Dashboard from "../pages/Dashboard";


interface RouteProps {
  path: string;
  component: any;
  exact?: boolean;
}

const userRoutes: Array<RouteProps> = [
  //User Profile
  { path: "/profile", component: userProfile },

  //dashboard
  { path: "/dashboard", component: Dashboard },

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },

  //Pages
  { path: "/abccurve", component: AbcCurve },
  { path: "/banner", component: Banner },
  { path: "/bundle", component: Bundle },
  { path: "/category", component: Category },
  { path: "/customer", component: Customer },
  { path: "/day", component: Day },
  { path: "/deferreditem", component: DeferredItem },
  { path: "/flyer", component: Flyer },
  { path: "/lgpd", component: Lgpd },
  { path: "/manufacturer", component: Manufacturer },
  { path: "/order", component: Order },
  { path: "/paymentterm", component: PaymentTerms },
  { path: "/popup", component: Popup },
  { path: "/privacypolicy", component: PrivacyPolicy },
  { path: "/productnotice", component: ProductNotice },
  { path: "/productregistration", component: ProductRegistration },
  { path: "/productrule", component: ProductRule },
  { path: "/recipient", component: Recipient },
  { path: "/registrationapproval", component: RegistrationApproval },
  { path: "/registrationapproval/:id", component: RegistrationApproval },
  { path: "/registrationintegration", component: RegistrationIntegration },
  { path: "/seller", component: Seller },
  { path: "/subcategory", component: SubCategory },
  { path: "/uploadrma", component: UploadRma },
  { path: "/updatenewproduct", component: UpdateNewProduct },
  { path: "/logsemails", component: LogsEmails },
  { path: "/logsfiscal", component: LogsFiscal },
  { path: "/logsorder", component: LogsOrder },
  { path: "/configurationintegration", component: ConfigurationIntegration },
];

const authRoutes: Array<RouteProps> = [
  //Authentication pages
  { path: "/login", component: Login },
  { path: "/logout", component: Logout },
  { path: "/register", component: Register },
];

export { userRoutes, authRoutes };

