import React from "react";
import { typeCrudForm } from "src/components/Common/typeCrudForm";
import FormCrudContainer from "src/components/CRUD/CrudForm";
import { post } from "src/helpers/api_helper";
import { getListCategorys } from "../graphqlQuery";
import FormSubCategory from "./forms/formSubCategory";

export default function FormCrud(props: {
  handleNewRegister: (mode: string) => void;
  queryGetByCode: any;
  queryGet: any;
  querySave: any;
  queryUpdate: any;
  queryDelete: any;
  selectedRow: string;
  setSelectedRow: any;
  queryNextCrCode: any;
  columnsTable: Array<typeCrudForm>;
}) {
  const [listItensSelected, setListItensSelected] = React.useState([]);
  const { handleNewRegister } = props;
  
  const getListCategory = React.useCallback(async () => {   
    
    let response = await post(
      "/graphql",
      {
        query: getListCategorys(),
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    ).then(response => response);
      if (response.data[Object.keys(response.data)[0]]) {
      let temp = [];
      const list = response.data[Object.keys(response.data)[0]];
      list.map(item => {
        temp.push({
          cr_code: item.cr_code,
          ws_value: item.ws_name,
        });
      });
      temp = [
        {
          nameList: "ws_categories",
          content: temp,
        },
      ];
      setListItensSelected(temp);
    } else {
      alert("Falha ao trazer lista de categorias1!");
      setListItensSelected([]);
    }    
  }, [])

  React.useEffect(() => {
    getListCategory()
  }, []);

  return (
    <FormCrudContainer
      Form={FormSubCategory}
      handleNewRegister={handleNewRegister}
      listItens={listItensSelected}
      {...props}
    />
  );
}
