import React, { useCallback, useEffect, useRef, useState } from "react"
import styled from "styled-components"
import html2canvas from 'html2canvas';
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import CardViewProduct from "src/components/CardViewProduct/Index";
import { post } from "src/helpers/api_helper";
import { getDayByCode, getDayItem } from "../../../Day/graphqlQuery";
import { contentUrlServer, typeUrlServer } from "src/common/urlServer";
import {
  BoxDayItens,
  RowStyled,
  Flyer,
  ButtonDownload,
  Visualization,
  VisualizationFlyer,
  WrapperImage,
  ImageTopo,
  ImageFooter
} from "./styled"
import _ from "lodash";

const urlServer: typeUrlServer = contentUrlServer.filter(
  item => item.name == "STATIC"
)[0];

// Traz a configuração pré-definida de urls do servidor
const urlServerDay: typeUrlServer = contentUrlServer.filter(
  item => item.name == "DAY"
)[0];

export const FormPreview = (props: { dataFormDb?: any, refresh?: boolean }) => {
  // Loading do day
  const [loadingDaySelecteds, setLoadingDaySelecteds] = useState(false);
  // Lista com todos os itens do day

  //lista produtos do day
  const [dataProductsDay, setDataProductsDay] = useState([]);

  // Dados do day (estrutura principal do crud)
  const { dataFormDb } = props;

  const [imageHeader, setImageHeader] = useState(null);
  const [imageFooter, setImageFooter] = useState(null);

  const [marginBottomImage, setMarginBottom]  = useState(0);

  const getImages = React.useCallback(async () => {
    if (!getDayByCode) return;
    try {
      let response = await post(
        "/graphql",
        {
          query: getDayByCode(dataFormDb.cr_code_day),
        },
        {
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
        .then(response => response)
        .catch(error => {
          // setLoadingDaySelecteds(false);
        });
      if (
        response &&
        response.data
      ) {
        setImageHeader(response.data.getDayByCode?.wh_top_image);
        setImageFooter(response.data.getDayByCode?.wh_footer_image);
      }
    } catch (e) {
      alert(`Falha ao buscar imagens de topo e rodapé!`);
    }
  }, [getDayByCode]);

  const getDataCrudGraphql = React.useCallback(async () => {
    setLoadingDaySelecteds(true);
    let tempPageCount = 0;
    if (!getDayItem) return;
    try {
      let response = await post(
        "/graphql",
        {
          query: getDayItem(dataFormDb.cr_code_day),
        },
        {
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
        .then(response => response)
        .catch(error => {
          setLoadingDaySelecteds(false);
        });
      if (
        response &&
        response.data &&
        response.data[Object.keys(response.data)[0]]
      ) {
        const count = response.data[Object.keys(response.data)[0]].result
          ? response.data[Object.keys(response.data)[0]].rowsall
          : 0;
        // mergeCollumnsRows(response.data[Object.keys(response.data)[0]]);
        setDataProductsDay(
          response.data[Object.keys(response.data)[0]].result
            ? response.data[Object.keys(response.data)[0]].result
            : response.data[Object.keys(response.data)[0]]
        );
      }
    } catch (e) {
      setDataProductsDay([]);
      alert(`Falha ao buscar lista!`);
    } finally {
      setLoadingDaySelecteds(false);
    }
  }, [getDayItem]);

  useEffect(() => {
    getDataCrudGraphql().then();
    getImages().then();
  }, [dataFormDb.cr_code_day]);

  useEffect(() => {
    setMarginBottom(dataFormDb.nb_top_margin_bottom_image);
  }, [dataFormDb.nb_top_margin_bottom_image]);

  //canvas
  const ref = useRef(null)

  const [flyer, setFlyer] = useState('');

  const options = {
    width: dataFormDb.nb_width_image,
    marginLeft: dataFormDb.nb_margin_left_image,
    marginRight: 20,
    marginTop: dataFormDb.nb_footer_margin_higher_image,
    marginBottom: dataFormDb.nb_top_margin_bottom_image,
    footerAlign: dataFormDb.sl_top_footer_align_image === 'D' ? 'right' : dataFormDb.sl_top_footer_align_image === 'C' ? 'center' : 'left',
    footerBottom: dataFormDb.nb_footer_margin_higher_image,
    gap: 5,
    textFontSize: dataFormDb.nb_font_size_image,
    boxHeight: dataFormDb.nb_box_prod_height_image,
    boxWidth: dataFormDb.nb_box_prod_width_image,
    boxBorderColor: dataFormDb.cl_box_prod_border_color_image,
    boxMarginRight: dataFormDb.nb_box_prod_margin_right_image,
    boxMarginBottom: dataFormDb.nb_box_prod_margin_bottom_image,
    priceAlign: dataFormDb.sl_box_price_align === 'D' ? 'right' : dataFormDb.sl_box_price_align === 'C' ? 'center' : 'left',
    priceCurrencyFontSize: dataFormDb.nb_coin_font_size,
    priceValueFontSize: dataFormDb.nb_price_font_size,
    priceMarginTop: dataFormDb.nb_coin_margin_higher,
    priceMarginRight: dataFormDb.nb_coin_margin_right,
    imgHeader: `${urlServerDay.urlServer}${urlServerDay.pathServer}topo/${imageHeader}`,
    imgFooter: `${urlServerDay.urlServer}${urlServerDay.pathServer}rodape/${imageFooter}`,
    typePrice: dataFormDb.sl_origin_price,
  }

  const handleFlyer = useCallback(() => {
    if (ref.current === null) {
      return
    }

    html2canvas(ref.current, {
      allowTaint: true,
      useCORS: true
    }).then(canvas => {
      let imageData = canvas.toDataURL("image/png");
      imageData = imageData.replace(/^data:image\/png/, "data:application/octet-stream");
      setFlyer(imageData);
    })
  }, [ref]);

  const handleDownloadFlyer = useCallback(() => {
    if (ref.current === null) {
      return
    }

    html2canvas(ref.current, {
      allowTaint: true,
      useCORS: true
    }).then(canvas => {
      let imageData = canvas.toDataURL("image/png");
      imageData = imageData.replace(/^data:image\/png/, "data:application/octet-stream");

      const link = document.createElement('a');
      link.download = 'flyer.png';
      link.href = imageData;
      link.target = '_blank';
      link.click();
    })
  }, [ref]);

  useEffect(() => {
    getImages();
  }, [imageFooter, imageHeader]);

  useEffect(() => {
    handleFlyer()
  }, [dataProductsDay, imageFooter, imageHeader])

  useEffect(() => {
    handleFlyer()
  },
    [
      dataFormDb.nb_width_image,
      dataFormDb.nb_margin_left_image,
      dataFormDb.nb_footer_margin_higher_image,
      dataFormDb.nb_top_margin_bottom_image,
      dataFormDb.sl_top_footer_align_image,
      dataFormDb.nb_footer_margin_higher_image,
      dataFormDb.nb_font_size_image,
      dataFormDb.nb_box_prod_height_image,
      dataFormDb.nb_box_prod_width_image,
      dataFormDb.cl_box_prod_border_color_image,
      dataFormDb.nb_box_prod_margin_right_image,
      dataFormDb.nb_box_prod_margin_bottom_image,
      dataFormDb.sl_box_price_align,
      dataFormDb.nb_coin_font_size,
      dataFormDb.nb_price_font_size,
      dataFormDb.nb_coin_margin_higher,
      dataFormDb.nb_coin_margin_right,
      dataFormDb.sl_origin_price,
    ])

  // CardViewProduct
  return (
    <BoxDayItens>
      <Row>
        <Col className="col-12">
          <Card style={{ backgroundColor: "#e9ecef" }}>
            <CardBody>
              <CardTitle
                className="h6 mb-4 mt-4"
                style={{ textAlign: "center" }}
              >
                VISUALIZAÇÃO DOS PRODUTOS CONFORME O ESTADO
                <br />
                <br />
                <br />
                <RowStyled gap={20}>
                  {dataProductsDay.length && dataProductsDay.map(product => {
                    let totalPrice = '0,00';
                    let partPrice = '0,00';

                    // Remove as formatações se existir
                    let dc_day_price_sale_site = product.dc_day_price_sale_site.trim().split("$").pop().trim()
                    let dc_day_price_sale_site_forward = product.dc_day_price_sale_site_forward.trim().split("$").pop().trim()
                    dc_day_price_sale_site = dc_day_price_sale_site.replace(',', '.')
                    dc_day_price_sale_site_forward = dc_day_price_sale_site_forward.replace(',', '.')

                    totalPrice = Number(dc_day_price_sale_site).toLocaleString("pt-br", {
                      style: "currency",
                      currency: "BRL",
                    });
                    partPrice = Number(dc_day_price_sale_site_forward).toLocaleString("pt-br", {
                      style: "currency",
                      currency: "BRL",
                    });

                    return (
                      <Col>
                        <CardViewProduct
                          cardItem={{
                            image: product.ox_product.wh_image,
                            title: product.ox_product.ws_commercial_description,
                            subTitle: "",
                            codProduct: product.ox_product.cr_code,
                            totalPrice: totalPrice,
                            partPrice: partPrice,    // A prazo
                            partPriceFormat: partPrice,
                            discountPrice: totalPrice,    // A vista
                            discountPriceFormat: totalPrice,
                            bfHotDeal: false,
                            bfNewProduct: false,
                          }}
                          isLogged={false}
                          horizontal={false}
                          simple={false}
                          offerRed={false}
                          messages={
                            <div style={{ marginTop: '10px' }}>
                              {product.bf_price_site_automatic ? (<DivContentNot>Preço à vista automático</DivContentNot>) : <DivContent>Preço à vista manual</DivContent>}
                              {product.bf_price_site_forward_automatic ? (<DivContentNot>Preço à prazo automático</DivContentNot>) : <DivContent>Preço à prazo manual</DivContent>}
                              {product.ox_product.bf_discount_percentage ? (<DivContentNot>Diferimento: {product.ox_product.nc_discount_percentage} %</DivContentNot>) : <DivContent>sem Diferimento</DivContent>}
                              {Number(dataFormDb.nc_percentage_discount) > 0 ? (<DivContentNot>Desconto Day: {dataFormDb.nc_percentage_discount} %</DivContentNot>) : <DivContent>sem Desconto Day</DivContent>}
                            </div>
                          }
                        />
                      </Col>
                    )
                  })}
                </RowStyled>
              </CardTitle>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row className="d-flex justify-content-center" style={{ width: '950px' }}>
        <Flyer
          ref={ref}
          className="flyer"
          width={options.width}
          marginLeft={options.marginLeft}
          marginRight={options.marginRight}
          marginTop={options.marginTop}
          marginBottom={options.marginBottom}
          border={options.boxBorderColor}
          alignItems={options.priceAlign}
          fontSize={options.priceValueFontSize}
        >
          <div style={{ justifyContent: options.footerAlign, marginBottom: options.marginBottom }} >
            <ImageTopo src={options.imgHeader} width={'100%'} marginBottom={options.marginBottom} />
          </div>
          <RowStyled gap={options.gap}>
            {dataProductsDay.map(product => {
              let totalPrice = '0,00';
              let partPrice = '0,00';

              // Remove as formatações se existir
              let dc_day_price_sale_site = product.dc_day_price_sale_site.trim().split("$").pop().trim()
              let dc_day_price_sale_site_forward = product.dc_day_price_sale_site_forward.trim().split("$").pop().trim()
              dc_day_price_sale_site = dc_day_price_sale_site.replace(',', '.')
              dc_day_price_sale_site_forward = dc_day_price_sale_site_forward.replace(',', '.')

              totalPrice = Number(dc_day_price_sale_site).toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
              });
              partPrice = Number(dc_day_price_sale_site_forward).toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
              });

              return (
                <CardViewProduct
                  cardItem={{
                    image: product.ox_product.wh_image,
                    title: product.ox_product.ws_commercial_description,
                    subTitle: "",
                    codProduct: product.ox_product.cr_code,
                    totalPrice: totalPrice,
                    partPrice: partPrice,    // A prazo
                    partPriceFormat: partPrice,
                    discountPrice: totalPrice,    // A vista
                    discountPriceFormat: totalPrice,
                    bfHotDeal: false,
                    bfNewProduct: false,
                  }}
                  isLogged={false}
                  horizontal={false}
                  simple={false}
                  offerRed={false}
                  messages={
                    <div style={{ marginTop: '10px' }}>
                      {product.bf_price_site_automatic ? (<DivContentNot>Preço à vista automático</DivContentNot>) : <DivContent>Preço à vista manual</DivContent>}
                      {product.bf_price_site_forward_automatic ? (<DivContentNot>Preço à prazo automático</DivContentNot>) : <DivContent>Preço à prazo manual</DivContent>}
                      {product.ox_product.bf_discount_percentage ? (<DivContentNot>Diferimento: {product.ox_product.nc_discount_percentage} %</DivContentNot>) : <DivContent>sem Diferimento</DivContent>}
                      {Number(dataFormDb.nc_percentage_discount) > 0 ? (<DivContentNot>Desconto Day: {dataFormDb.nc_percentage_discount} %</DivContentNot>) : <DivContent>sem Desconto Day</DivContent>}
                    </div>
                  }
                  options={options}
                />
              )
            })}
          </RowStyled>
          <div style={{ justifyContent: options.footerAlign, marginBottom: options.footerBottom }} >
            <ImageFooter src={options.imgFooter} width={'100%'} marginTop={options.marginTop} />
          </div>
        </Flyer>
      </Row>
      <Row style={{ width: '100%', justifyContent: 'center' }}>
        <Visualization>
          <p>
            Esta é a pré-visualização dinâmica do Flyer a partir das configurações atuais de layout (mesmo sem gravar o registro). Tipo preço: <strong>Produto | A prazo</strong>
            <br />
            <strong>ATENÇÃO:</strong> enquanto o Flyer não for confirmado, esta pré-visualização é temporária.
          </p>
          <VisualizationFlyer background={urlServer.urlServer + urlServer.pathServer + 'fundo_quad.png'}>
            <WrapperImage>
              {flyer && flyer !== 'data:,' && <img src={flyer} width={'100%'} />}
            </WrapperImage>
          </VisualizationFlyer>
        </Visualization>

        <ButtonDownload onClick={handleDownloadFlyer}>Baixar flyer</ButtonDownload>
      </Row>
    </BoxDayItens>
  );
};

const DivContent = styled.div`
  width: 100%;
  background-color: #c2dfc4;
  margin-bottom: 5px;
  text-align: left;
  padding: 2px;
  font-size: 11px;
  border-radius: 5px;
  padding-left: 5px;
`

const DivContentNot = styled.div`
  width: 100%;
  background-color: #e9cccd;
  margin-bottom: 5px;
  text-align: left;
  padding: 2px;
  font-size: 11px;
  border-radius: 5px;
  padding-left: 5px;
`