import { typeCrudListColumns } from "src/components/Common/typeCridList";
import {
  typeCrudCollapse,
  typeCrudForm,
} from "src/components/Common/typeCrudForm";
import GenerateForm from "src/components/CRUD/generateComponents";

const FormManufacturer = (props: {
  dataFormDb: any;
  columnsTable: Array<typeCrudListColumns>;
  nextCrCode: string;
  listItens: any;
}) => {
  let { dataFormDb, columnsTable, nextCrCode, listItens } = props;
  if (nextCrCode) {
    dataFormDb.cr_code = nextCrCode;
  }

  function adapterListPayments(): Array<{
    cr_code: string;
    ws_value: string;
  }> {
    let temp = [];
    if (listItens) {
      const list = listItens.filter(item => item.nameList === "ws_payments");
      list.length &&
        list.length > 0 &&
        list[0].content.map(item =>
          temp.push({
            cr_code: item.cr_code,
            ws_value: item.ws_value,
          })
        );
    }
    return temp;
  }

  const complementsContent: Array<typeCrudForm> = [
    {
      Header: "Código",
      accessor: "cr_code",
      cols: "4",
      required: false,
    },
    {
      Header: "Código no ERP",
      accessor: "cr_code_erp",
      cols: "4",
      required: false,
    },
    {
      Header: "Condição de Pagamento",
      accessor: "cr_code_payment_terms",
      cols: "8",
      required: false,
      listItens: adapterListPayments(),
      observation:
        "Se informada, esta condição tem preferencial (sobrescreve) a condição de pagamento normal.",
    },
    {
      Header: "Condição de Pgto. Especial",
      accessor: "cr_code_special_payment",
      cols: "8",
      required: false,
      listItens: adapterListPayments(),
      uniqueRow: true,
    },
    {
      Header: "Vendedor",
      accessor: "ws_seller",
      cols: "4",
      required: false,
      disabled: true,
    },
    {
      Header: "Operação Fiscal",
      accessor: "cr_code_operation",
      cols: "4",
      required: false,
    },
    {
      Header: "Já possui vendedor",
      accessor: "ws_seller_indication",
      cols: "4",
      required: false,
      disabled: true,
    },
    {
      Header: "Razão Social",
      accessor: "ws_corporate_reason",
      subaccessor: "ox_registration_fields",
      cols: "4",
      required: false,
      disabled: true,
    },
    {
      Header: "CNPJ",
      accessor: "wj_cnpj",
      cols: "4",
      required: false,
      disabled: true,
    },
    {
      Header: "Inscrição Estadual",
      accessor: "wi_state_registration",
      subaccessor: "ox_registration_fields",
      cols: "4",
      required: false,
      disabled: true,
    },
    {
      Header: "Contato",
      accessor: "ws_indication",
      subaccessor: "ox_registration_fields",
      cols: "4",
      required: false,
      disabled: true,
    },
    {
      Header: "Email",
      accessor: "wm_email",
      subaccessor: "ox_registration_fields",
      observation:
        "O site envia email de confirmação de pedidos para o cliente utilizando este email.",
      cols: "4",
      required: false,
      disabled: true,
    },
  ];

  const addressContent: Array<typeCrudForm> = [
    {
      Header: "Endereço",
      accessor: "ws_street",
      subaccessor: "ox_registration_fields",
      subaccessorin: "qi_address",
      cols: "8",
      required: false,
      disabled: true,
    },
    {
      Header: "Número",
      accessor: "nb_street_number",
      subaccessor: "ox_registration_fields",
      subaccessorin: "qi_address",
      cols: "4",
      required: false,
      uniqueRow: true,
      disabled: true,
    },
    {
      Header: "Bairro",
      accessor: "ws_district",
      subaccessor: "ox_registration_fields",
      subaccessorin: "qi_address",
      cols: "8",
      required: false,
      disabled: true,
    },
    {
      Header: "CEP",
      accessor: "zp_zipcode",
      subaccessor: "ox_registration_fields",
      subaccessorin: "qi_address",
      cols: "4",
      required: false,
      uniqueRow: true,
      disabled: true,
    },
    {
      Header: "Cidade",
      accessor: "ws_city",
      subaccessor: "ox_registration_fields",
      subaccessorin: "qi_address",
      cols: "8",
      required: false,
      disabled: true,
    },
    {
      Header: "Estado",
      accessor: "ws_state",
      subaccessor: "ox_registration_fields",
      subaccessorin: "qi_address",
      cols: "4",
      required: false,
      disabled: true,
    },
  ];

  const bankContent: Array<typeCrudForm> = [
    {
      Header: "Fornecedor 1",
      accessor: "st_0",
      subaccessor: "ox_registration_fields",
      subaccessorin: "ws_supplier_reference",
      cols: "4",
      required: false,
      disabled: true,
    },
    {
      Header: "Fornecedor 2",
      accessor: "st_1",
      subaccessor: "ox_registration_fields",
      subaccessorin: "ws_supplier_reference",
      cols: "4",
      required: false,
      disabled: true,
    },
    {
      Header: "Fornecedor 3",
      accessor: "st_2",
      subaccessor: "ox_registration_fields",
      subaccessorin: "ws_supplier_reference",
      cols: "4",
      required: false,
      disabled: true,
    },
    {
      Header: "Banco 1",
      accessor: "st_0",
      subaccessor: "ox_registration_fields",
      subaccessorin: "ws_bank_reference",
      cols: "4",
      required: false,
      disabled: true,
    },
    {
      Header: "Agência 1",
      accessor: "st_0",
      subaccessor: "ox_registration_fields",
      subaccessorin: "ws_agency_reference",
      cols: "4",
      required: false,
      disabled: true,
    },
    {
      Header: "Telefone 1",
      accessor: "st_0",
      subaccessor: "ox_registration_fields",
      subaccessorin: "ct_contact_reference",
      cols: "4",
      required: false,
      disabled: true,
    },
    {
      Header: "Banco 2",
      accessor: "st_1",
      subaccessor: "ox_registration_fields",
      subaccessorin: "ws_bank_reference",
      cols: "4",
      required: false,
      disabled: true,
    },
    {
      Header: "Agência 2",
      accessor: "st_1",
      subaccessor: "ox_registration_fields",
      subaccessorin: "ws_agency_reference",
      cols: "4",
      required: false,
      disabled: true,
    },
    {
      Header: "Telefone 2",
      accessor: "st_1",
      subaccessor: "ox_registration_fields",
      subaccessorin: "ct_contact_reference",
      cols: "4",
      required: false,
      disabled: true,
    },
  ];

  const resumeContent: Array<typeCrudForm> = [
    {
      Header: "Tipo Cliente",
      accessor: "sl_type_client",
      observation: "Clientes inativos não podem acessar o site.",
      cols: "4",
      required: false,
      disabled: true,
    },
    {
      Header: "Tipo Frete",
      accessor: "sl_type_transport",
      cols: "4",
      required: false,
      disabled: true,
    },
    {
      Header: "Tipo Curva",
      accessor: "sl_curve_type",
      cols: "4",
      required: false,
      disabled: true,
    },
    {
      Header: "Condição Tributária",
      accessor: "nb_tax_condition",
      cols: "4",
      required: false,
      disabled: true,
    },
    {
      Header: "Cadastro Restrito",
      accessor: "bf_restricted_registry",
      observation: "Clientes com cadastro restrito Não podem visualizar Days.",
      cols: "2",
      required: false,
    },
    {
      Header: "Possui Crédito",
      accessor: "bf_own_credit",
      observation:
        "Clientes que Não possuem crédito Não podem comprar com prazo, somente à vista.",
      cols: "2",
      required: false,
    },
    {
      Header: "Gera Orçamento",
      accessor: "bf_generate_budget",
      observation:
        'Se o cliente pode gerar orçamentos na tela "Meus Pedidos" do site. Os pedidos só marcados com o campo "Site Orçamento" igual a SIM.',
      cols: "4",
      required: false,
    },
    {
      Header: "Data Cadastro",
      accessor: "dx_register",
      cols: "4",
      required: false,
      disabled: true,
    },
    {
      Header: "Data última alteração no site",
      accessor: "dx_modification",
      cols: "4",
      required: false,
      disabled: true,
    },
    {
      Header: "Data de aprovação",
      accessor: "dx_register_approval",
      subaccessor: "ox_registration_fields",
      cols: "4",
      required: false,
      disabled: true,
    },
    {
      Header: "Data de envio para o ERP",
      accessor: "dx_integration_sitetoerp",
      cols: "4",
      required: false,
      disabled: true,
    },
    {
      Header: "Data de retorno do ERP",
      accessor: "dx_integration_erptosite",
      cols: "4",
      required: false,
      disabled: true,
    },
    {
      Header: "Data último Login",
      accessor: "dx_data_login",
      cols: "4",
      required: false,
      disabled: true,
    },
  ];

  const subtitleForm: Array<typeCrudCollapse> = [
    {
      CollapseContent: complementsContent,
      CollapseTitle: "Informações de identificação",
    },
    {
      CollapseContent: addressContent,
      CollapseTitle: "Informações de endereço",
    },
    {
      CollapseContent: bankContent,
      CollapseTitle: "Informações bancárias",
    },
    {
      CollapseContent: resumeContent,
      CollapseTitle: "Informações complementares",
    },
  ];
  return GenerateForm(dataFormDb, subtitleForm);
};

export default FormManufacturer;
