/* IMPORTANTE: Deve incluir as fun principais do CRUD
 * getData, updateData, deleteData, postData
 * estas chamadas são padrão no CRUD/CrudGroupForm */

export const getProductByCode = (cr_code: string): string => {
  return `{
    getProductByCodeAdmin(cr_code:"${cr_code}") {
      cr_code_erp
      cv_version
      bf_active
      bf_liberation
      cr_code
      ck_registeruser
      dx_register
      dx_modification      
      bf_highlighted
      bf_hot_deal
      bf_new_product
      qs_buy_too
      qs_bundle
      cr_fiscal_classification
      cr_code_manufactures
      cr_code_subcategory
      dt_validate_new
      ws_commercial_description
      wu_unit_measure
      ws_part_number
      dc_gross_weight
      dc_inventory_balance
      dc_percent_ipi
      nb_tax_code_source
      ws_name_category
      ws_name_subcategory
      dc_price_sale_cash
      dc_price_sale_rs
      dc_price_sale_sc
      dc_price_sale_cartucho
      dc_price_sale_pr
      wt_commercial_text
      bf_bundle
      ws_name_manufactures
      bf_discount_percentage
      nc_discount_percentage
      wh_image
      wl_image_list
      ws_gross_weight
      ws_percent_ipi
      bf_highlighted_card_top
      bf_highlighted_card_horizontal_showcase
      bf_highlighted_card_horizontal_showcase_best_offer
      qf_price_special{
        dc_price
        wr_price_currency
        sl_price_currency
        sl_price_description
        dc_price_format
        dt_start_period
        dt_end_period
        bf_emphasis
      }
    }
  }`;
};

export const getProducts = (
  filter: string = "{}",
  limit: Number = 10,
  random: Boolean = false,
  skip: Number = 0,
  sort: String = "{}"
): string => {
  let filter2;

  if (filter === undefined || filter === "{}") {
    filter2 = filter //no inicio sem filtro nenhum
  } else {
    let filterText = '';
    if (isJson(filter)) {
      let filterJson = JSON.parse(filter);
      if (filterJson.$text && filterJson.$text.$search) {
        filterText = JSON.stringify(filterJson.$text.$search);
      }
    } 

    if (filterText) {
      filter2 = `{"$text":{"$search":${filterText}}}`; //com filtro busca
    } else {
      filter2 = JSON.stringify(filter);  //filtro custom
    }
  }

  let filter3 = JSON.stringify(filter2);

  return `{
    getProductPaginationAdmin(filter: {
        filter: ${filter3},
        sort: "${sort}",
        skip: ${skip},  
        limit: ${limit},                      
        random: ${random},
    }) { 
      result {
        cr_code_erp
        cv_version
        bf_active
        bf_liberation
        cr_code
        ck_registeruser
        dx_register
        dx_modification
        bf_highlighted
        bf_hot_deal
        bf_new_product
        qs_buy_too
        qs_bundle
        cr_code_manufactures
        ws_name_manufactures
        cr_fiscal_classification
        cr_code_category
        cr_code_subcategory
        dt_validate_new
        ws_commercial_description
        wu_unit_measure
        ws_part_number
        dc_gross_weight
        dc_inventory_balance
        dc_percent_ipi
        nb_tax_code_source
        wt_commercial_text        
        wh_image
        ws_name_category
        ws_name_subcategory
        ws_gross_weight
        ws_percent_ipi
        qs_code_tags
        qf_price_special{
          dc_price
          wr_price_currency
          sl_price_currency
          sl_price_description
          dc_price_format
          dt_start_period
          dt_end_period
          bf_emphasis
        }
      }
      rows
      rowsall
    }
  }`;
};

export const deleteProduct = (cr_code: string): string => {
  return `query {
    deleteProductByCode(cr_code:"${cr_code}") {
      message
    }
  }`;
};

export const updateProduct = (data: {
  cr_code_erp: String;
  cv_version: String;
  bf_active: Boolean;
  bf_liberation: Boolean;
  cr_code: String;
  ck_registeruser: String;
  dx_register: String;
  dx_modification: String;
  // cr_code_tag: String;
  bf_highlighted: Boolean;
  bf_hot_deal: Boolean;
  bf_new_product: Boolean;
  qs_buy_too: [String];
  qs_bundle: [String];
  ws_name_manufactures: String;
  cr_fiscal_classification: String;
  cr_code_subcategory: String;
  dt_validate_new: String;
  ws_commercial_description: String;
  wu_unit_measure: String;
  ws_part_number: String;
  dc_gross_weight: String;
  dc_inventory_balance: String;
  qf_price_special: Array<{
    dc_price: String;
    wr_price_currency: String;
    sl_price_currency: String;
    sl_price_description: String;
    dc_price_format: String;
    dt_start_period: String;
    dt_end_period: String;
    bf_emphasis: Boolean;
  }>;
  dc_percent_ipi: String;
  nb_tax_code_source: String;
  wh_image: String;
  wl_image_list: [String];
  ws_name_category: String;
  ws_name_subcategory: String;
  dc_price_sale_cash: String;
  dc_price_sale_rs: String;
  dc_price_sale_sc: String;
  dc_price_sale_cartucho: String;
  dc_price_sale_pr: String;
  wt_commercial_text: String;
  bf_bundle: Boolean;
  bf_discount_percentage: Boolean;
  nc_discount_percentage: String;
  bf_highlighted_card_top: Boolean;
  bf_highlighted_card_horizontal_showcase: Boolean;
  bf_highlighted_card_horizontal_showcase_best_offer: Boolean;
  bf_apply_icms?: Boolean;
}): string => {
  let formattedAttachments = "";
  data.qf_price_special.map(attachment => {
    formattedAttachments += `{       
        dc_price: "${attachment.dc_price}"
      , wr_price_currency: "${attachment.wr_price_currency}"
      , sl_price_currency: "${attachment.sl_price_currency}"
      , sl_price_description: "${attachment.sl_price_description}"
      , dc_price_format: "${attachment.dc_price_format}"
      , dt_start_period: "${attachment.dt_start_period}"
      , dt_end_period: "${attachment.dt_end_period}" 
      , bf_emphasis: ${attachment.bf_emphasis}
    }`;
  });
  return `mutation {
    updateProduct(
      cr_code_erp: "${data.cr_code_erp}", 
      cv_version: "${data.cv_version}", 
      bf_active: ${data.bf_active}, 
      bf_liberation: ${data.bf_liberation}, 
      cr_code: "${data.cr_code}", 
      ck_registeruser: "${data.ck_registeruser}", 
      dx_register: "${data.dx_register}", 
      dx_modification: "${data.dx_modification}", 
      bf_highlighted: ${data.bf_highlighted}, 
      bf_hot_deal: ${data.bf_hot_deal}, 
      bf_new_product: ${data.bf_new_product}, 
      ws_name_manufactures: "${data.ws_name_manufactures}", 
      cr_fiscal_classification: "${data.cr_fiscal_classification}", 
      cr_code_subcategory: "${data.cr_code_subcategory}", 
      dt_validate_new: "${data.dt_validate_new}", 
      ws_commercial_description: ${JSON.stringify(data.ws_commercial_description.replace('"', "''"))}, 
      wu_unit_measure: "${data.wu_unit_measure}", 
      ws_part_number: "${data.ws_part_number}", 
      dc_gross_weight: "${data.dc_gross_weight}", 
      dc_inventory_balance: "${data.dc_inventory_balance}", 
      dc_percent_ipi: "${data.dc_percent_ipi}", 
      nb_tax_code_source: "${data.nb_tax_code_source}", 
      nc_discount_percentage: "${data.nc_discount_percentage}",             
      wh_image: "${data.wh_image}", 
      wl_image_list: [${data.wl_image_list.map(img => {return `"${img}"`})}],
      ws_name_category: "${
        data.ws_name_category ? data.ws_name_category : ""
      }", 
      ws_name_subcategory: ${
        data.ws_name_subcategory ? JSON.stringify(data.ws_name_subcategory.replace('"', "''")) : JSON.stringify('')
      },
      qf_price_special: [${formattedAttachments}],
      dc_price_sale_cash: "${data.dc_price_sale_cash}", 
      dc_price_sale_rs: "${data.dc_price_sale_rs}", 
      dc_price_sale_sc: "${data.dc_price_sale_sc}", 
      dc_price_sale_cartucho: "${data.dc_price_sale_cartucho}", 
      dc_price_sale_pr: "${data.dc_price_sale_pr}",
      bf_discount_percentage: ${data.bf_discount_percentage},
      bf_bundle: ${data.bf_bundle},
      wt_commercial_text: ${JSON.stringify(data.wt_commercial_text)},
      bf_highlighted_card_top: ${data.bf_highlighted_card_top},
      bf_highlighted_card_horizontal_showcase: ${data.bf_highlighted_card_horizontal_showcase},
      bf_highlighted_card_horizontal_showcase_best_offer: ${data.bf_highlighted_card_horizontal_showcase_best_offer},      
    ){
  	message
   }
  }`;
};

export const getNextCrCodeProduct = (): string => {
  return `query {
    getNextCrCodeProduct {
      result
    }
  }`;
};

export const getListSubCategorys = (): string => {
  return `query {
    getLayoutFilterCategory {
      cr_code
      ws_name
      qi_subcategories {
        cr_code
        ws_name
        nb_products
      }
      nb_products
    }
  }`;
};

export const updateCache = (cr_code: string): string => {
  return `query {
    updateCacheProducts(cr_code_list: ["${cr_code}"]) {
      message
    }
  }`;
};

function isJson(str) {
  try {
      JSON.parse(str);
  } catch (e) {
      return false;
  }
  return true;
}

export const getListManufacturers = (): string => {
  return `query {
    getManufacturers {
      cr_code
      ws_abbreviation
    }
  }`;
};


export const getProductsFilter = (
  filter: string = "{}",
  limit: Number = 10,
  random: Boolean = false,
  skip: Number = 0,
  sort: String = "{}"
): string => {
  let filter2;
  
  if (filter === undefined || filter === "{}" || filter === "") {
    filter2 = filter //no inicio sem filtro nenhum
  } else {
    let filterText = '';
    if (isJson(filter)) {
      let filterJson = JSON.parse(filter);
      if (filterJson.$text && filterJson.$text.$search) {
        filterText = JSON.stringify(filterJson.$text.$search);
      }
    } 

    if (filterText) {
      filter2 = `{"$text":{"$search":${filterText}}}`; //com filtro busca
    } else {
      filter2 = JSON.stringify(filter);  //filtro custom
    }
  }

  let filter3 = filter2 === "" ? JSON.stringify("{}") : JSON.stringify(filter2);

  return `{
    getProductPaginationAdmin(filter: {
        filter: ${filter3},
        sort: "${sort}",
        skip: ${skip},  
        limit: ${limit},                      
        random: ${random},
    }) { 
      result {
        cr_code
        ws_name_subcategory
        ws_name_category
        ws_commercial_description
        bf_active
        bf_highlighted
        bf_hot_deal
        bf_new_product
        bf_liberation
        dx_register
        qf_price_special{ 
          bf_emphasis
        }
      }
      rows
      rowsall
    }
  }`;
};
