import { deleteAbcCurve, getAbcCurveByCode, getAbcCurves, getNextCrCodeAbcCurve, insertAbcCurve, updateAbcCurve } from "./graphqlQuery"
import { useMemo } from "react"
import CrudContainer from "./CrudContainer"

const AbcCurve = () => {

  const columnsTable = useMemo(() => {
    return [
      {
        Header: 'Código',
        accessor: 'cr_code',
      },
      {
        Header: 'Curva',
        accessor: 'sl_curve_type',
      },
      {
        Header: 'Valor',
        accessor: 'dc_value',
      },
      {
        Header: "Ações",
        accessor: "actions",
      }
    ]
  }, [])

  return (
    <CrudContainer
      breadcrumbTitle="Outros"
      breadcrumbItem="Curva ABC"
      columnsTable={columnsTable}
      queryGet={getAbcCurves}
      queryGetByCode={getAbcCurveByCode}
      queryNextCrCode={getNextCrCodeAbcCurve}
      querySave={insertAbcCurve}
      queryUpdate={updateAbcCurve}
      queryDelete={deleteAbcCurve}
      routePath=""
      title="Curva ABC | Braile Distribuidora"
    />
  )
}

export default AbcCurve
