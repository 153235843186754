/* IMPORTANTE: Deve incluir as fun principais do CRUD
 * getData, updateData, deleteData, postData
 * estas chamadas são padrão no CRUD/CrudGroupForm */

export const getBannerByCode = (cr_code: string): string => {
  return `{
    getBannerByCode(cr_code:"${cr_code}") {
      cv_version
      bf_active
      bf_liberation
      cr_code
      ck_registeruser
      dx_register
      dx_modification
      wh_image
      ws_image_name
      hl_link
      ws_text_botton
      nb_order_apresentation
    }
  }`;
};

export const getBanners = (): string => {
  return `query {
    getBannersAdmin {
      cr_code
      wh_image
      nb_order_apresentation
      hl_link
      bf_liberation
      dx_register
    }
  }`;
};

export const deleteBanner = (cr_code: string): string => {
  return `query {
    deleteBannerByCode(cr_code:"${cr_code}") {
      message
    }
  }`;
};

export const updateBanner = (data: {
  bf_liberation: Boolean;
  cr_code: String;
  wh_image: String;
  ws_image_name: String;
  hl_link: String;
  ws_text_botton: String;
  nb_order_apresentation: Number;
}): string => {
  return `mutation {
    updateBanner(
      bf_liberation: ${data.bf_liberation},
      cr_code: "${data.cr_code ? data.cr_code : ""}",
      wh_image: "${data.wh_image ? data.wh_image : ""}",
      ws_image_name: "${data.ws_image_name ? data.ws_image_name : ""}",
      hl_link: "${data.hl_link ? data.hl_link : ""}",
      ws_text_botton: "${data.ws_text_botton ? data.ws_text_botton : ""}",
      nb_order_apresentation: ${
        data.nb_order_apresentation ? data.nb_order_apresentation : 0
      },
   ){
  	message
   }
  }`;
};

export const insertBanner = (data: {
  bf_liberation: Boolean;
  cr_code: String;
  wh_image: String;
  ws_image_name: String;
  hl_link: String;
  ws_text_botton: String;
  nb_order_apresentation: Number;
}): string => {
  return `mutation {
    insertBanner(
      bf_liberation: ${data.bf_liberation},
      cr_code: "${data.cr_code ? data.cr_code : ""}",
      wh_image: "${data.wh_image ? data.wh_image : ""}",
      ws_image_name: "${data.ws_image_name ? data.ws_image_name : ""}",
      hl_link: "${data.hl_link ? data.hl_link : ""}",
      ws_text_botton: "${data.ws_text_botton ? data.ws_text_botton : ""}",
      nb_order_apresentation: ${
        data.nb_order_apresentation ? data.nb_order_apresentation : 0
      },
   ){
  	message
   }
  }`;
};

export const getNextCrCodeBanner = (): string => {
  return `query {
    getNextCrCodeBanner {
      result
    }
  }`;
};
