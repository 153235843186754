import { useEffect, useState } from "react"
import { Col, FormGroup, Input } from "reactstrap"
import { FilterInputs } from "../types"
import { LabelStyled } from "./styled"

export const getCheckBox = (item: FilterInputs, setFilterDb?: any) => {  
  const [value, setValue] = useState('');
  const [valueDefault, setValueDefault] = useState('');
  const [search, setSearch] = useState([]);
  const [defaultCols, setDefaultCols] = useState(3);
  const [cols, setCols] = useState(defaultCols);
  const { uniqueRow = false } = item
  
  function handleChange(key: string, value: any) {
    console.log('val', value, key);
    setSearch(prevRegister => ({ ...prevRegister, [key]: value })); 
  }

  useEffect(() => {
    if (item?.cols && item?.cols > 0) {
      setCols(item.cols * defaultCols)
    }
  }, [item])

  useEffect(() => {
    setValue(valueDefault)    
  }, [item.clearFields])

  return <><Col 
  className="m-1"
  lg={cols}
  sm={cols}
  md={cols}
  xs={cols}
  style={{ textAlign: "left" }}
  >
    <FormGroup check>
      <LabelStyled check>
        <Input 
          value={value}
          bsSize="sm"
          type={item.type} 
          onChange={e => handleChange(`${item.field}`, e.target.value)}
          />
        {item.name}
      </LabelStyled>
    </FormGroup>
  </Col>
  {item.uniqueRow && <Col 
  className="m-1"
  lg={cols - 12}
  sm={cols - 12}
  md={cols - 12}
  xs={cols - 12}
  style={{ textAlign: "left" }}
  ></Col>}
  </>
}