import { useEffect, useMemo, useState } from "react"
import BootstrapTable from "react-bootstrap-table-next"
import cellEditFactory from "react-bootstrap-table2-editor"
import AvFieldBraile from "src/components/general/Input/AvFieldBraile";
import { post } from "src/helpers/api_helper"
import { getPaymentTables } from "../../graphqlQuery"

export const CustomPayment = (props: { dataFormDb: any, label: any }) => {
  const [items, setItems] = useState([]);
  const [paymentRange, setPaymentRange] = useState([]);
  const [data, setData] = useState([]);

  async function paymenTermByTable() {
    await post(
      "/graphql",
      {
        query: getPaymentTables(),
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then(response => {
        setData(response.data.getPaymentTables.map(item => {
          return {
            value: item.cr_code,
            label: item.ws_descricao,
          }
        }));
      })
      .catch(error => alert("Falha!"))
  }

  const columns = useMemo(
    () => [
      {
        dataField: "ws_start",
        text: "Faixa Inicial R$",
        sort: true,
        headerAlign: 'left',
        editor: {
          type: 'number',
        },
        formatter: (cell, row) => {
          return (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
              {cell}
            </div>
          )
        }
      },
      {
        dataField: "ws_installment",
        text: "Parcelas Disponíveis",
        sort: true,
        headerAlign: 'left',
        editable: false,
        formatter: (cell, row) => {
          return (
            <div style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'flex-start' }}>
              <p style={{ marginRight: '10px' }}>
                {cell}
              </p>
              <AvFieldBraile
                value={paymentRange}
                name="add_installment"
                id={"add_installment"}
                type="select"
                onChange={e => {
                  setPaymentRange(e.target.value)

                  const find = data.find(i => i.value === e.target.value);
                  const description = find.label

                  const itemsUpdated = [...items];
                  const findItem = itemsUpdated.find(item => item.cr_code === row.cr_code);
                  findItem.ws_installment = findItem.ws_installment
                    ? findItem.ws_installment === 'Selecione aqui as parcelas disponíveis'
                      ? description
                      : findItem.ws_installment + ' ' + description
                    : description;

                  if (e.target.value.length === 1) {
                    const value = `0${e.target.value}`;
                    findItem.qi_installment = [...findItem.qi_installment, value];
                  } else {
                    findItem.qi_installment = [...findItem.qi_installment, e.target.value];
                  }

                  if (props.dataFormDb.qi_payment_term_range.length > 0) {
                    setItems(itemsUpdated)
                  }
                }}
              >
                <option value={""} label={"Selecione aqui uma nova parcela"} />
                {data && data.map((item: any, index: any) => (
                  <option key={index} value={item.value} label={item.label} />
                ))}
              </AvFieldBraile>
            </div>
          );
        }
      },
      {
        dataField: "cr_code",
        text: "Ações",
        sort: true,
        headerAlign: 'center',
        formatter: (cell, row) => {
          return (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <button
                type="button"
                className={`btn btn-danger waves-effect waves-light p-0 px-2 m-auto}`}
                onClick={() => handleDeleteRow(cell)}
              >
                <i className="mdi mdi-trash-can font-size-8"></i>
              </button>
            </div>
          )
        }
      },
    ], [data, items]
  );

  function handleDeleteRow(code: string) {
    setItems(prevItems => prevItems.filter(item => item.cr_code !== code));
  }

  function handleAddRow(e) {
    e.preventDefault();
    // gera um código aleatório
    const code = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    setItems(prevItems => [...prevItems, {
      ws_installment: 'Selecione aqui as parcelas disponíveis',
      ws_start: 0,
      qi_installment: [],
      cr_code: code,
    }]);
  }

  useEffect(() => {
    if (props.dataFormDb.qi_payment_term_range) {
      if (props.dataFormDb.qi_payment_term_range.length > 0) {
        let itemsFormatted = props.dataFormDb.qi_payment_term_range.map((item: any) => {
          /* if (!Array.isArray(item.ws_installment)){
            item.ws_installment = item.ws_installment.split(' ')  
          } */
          return item;
        }
        );
        setItems(itemsFormatted);
      } else {
        setItems([]);
      }
    }

    paymenTermByTable();
  }, []);

  useEffect(() => {
    props.dataFormDb.qi_payment_term_range = items;
  }, [items])

  return (<>
    <h6 className='mt-5'>{props.label}</h6>

    {data && data.length && (
      <BootstrapTable
        columns={columns}
        data={items}
        keyField="cr_code"
        noDataIndication="Nenhum registro encontrado"
        striped
        hover
        cellEdit={cellEditFactory({
          mode: "click",
          blurToSave: true,
          afterSaveCell: (
            oldValue,
            newValue,
            row,
            column
          ) => {
          },
        })}
      >
      </BootstrapTable>
    )}

    <button
      onClick={e => handleAddRow(e)}
      className={`btn btn-danger waves-effect waves-light p-0 px-2 m-auto}`}
    >
      <i className="mdi mdi-plus font-size-8"></i>
      Adicionar nova faixa
    </button>
  </>
  );
}
