import classnames from "classnames"
import { useState } from "react"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import {
  Col,
  Collapse, Row
} from "reactstrap"
import {
  typeCrudCollapse,
  typeCrudForm
} from "src/components/Common/typeCrudForm"
import getBF from "./GeneratorComponents/getBF"
import getCL from "./GeneratorComponents/getCL"
import getCR from "./GeneratorComponents/getCR"
import getCT from "./GeneratorComponents/getCT"
import getCV from "./GeneratorComponents/getCV"
import getDC from "./GeneratorComponents/getDC"
import getDT from "./GeneratorComponents/getDT"
import getDX from "./GeneratorComponents/getDX"
import getHL from "./GeneratorComponents/getHL"
import getNB from "./GeneratorComponents/getNB"
import getNC from "./GeneratorComponents/getNC"
import getQF from "./GeneratorComponents/getQF"
import getQI from "./GeneratorComponents/getQI"
import getSI from "./GeneratorComponents/getSI"
import getSJ from "./GeneratorComponents/getSJ"
import getSL from "./GeneratorComponents/getSL"
import getWH from "./GeneratorComponents/getWH"
import getWI from "./GeneratorComponents/getWI"
import getWJ from "./GeneratorComponents/getWJ"
import getWL from "./GeneratorComponents/getWL"
import getWM from "./GeneratorComponents/getWM"
import getWS from "./GeneratorComponents/getWS"
import getWT from "./GeneratorComponents/getWT"

// Função que faz a abstração das siglas para direcionar as funções de retorno de componente
const getMapConvention = (
  convention: string,
  value: any,
  subtitleRow: typeCrudForm,
  dataFormDb: any,
  functionCollapse: any
) => {
  // Contepudo customizado, anulando e gerador inteligente
  if (subtitleRow.custom) {
    return subtitleRow.custom;
  }

  if (
    (convention === "dt" || convention === "dc") &&
    (value === undefined || value === "")
  ) {
    if (convention === "dt" && !value && subtitleRow.default) {
      value = subtitleRow.default;
    } else {
      return "";
    }    
  }

  // Caso exist lista de itens com icones
  if (subtitleRow.listItensIcons && subtitleRow.listItensIcons.length > 0) { 
    return getSI(value, subtitleRow, dataFormDb);
  }

  // Caso exist lista de itens, manda direto para o Select de carregamento dinamico
  if (subtitleRow.listItens && subtitleRow.listItens.length > 0) { 
    return getSJ(value, subtitleRow, dataFormDb);
  }

  switch (convention) {
    case "cv": // Input de controle de versão
      return getCV(value, subtitleRow, dataFormDb);
    case "bf": // Switch Sim/Não
      return getBF(value, subtitleRow, dataFormDb);
    case "cr": // Input # Código do registro
      return getCR(value, subtitleRow, dataFormDb);
    case "ws": // Input # Nome de arquivo
      return getWS(value, subtitleRow, dataFormDb);
    case "wj": // Input # CNPJ
      return getWJ(value, subtitleRow, dataFormDb);
    case "wi": // Input # Inscrição estadual
      return getWI(value, subtitleRow, dataFormDb);
    case "wh": // Input # Imagem do arquivo
      return getWH(value, subtitleRow, dataFormDb);
    case "wl": // Input # Lista de Imagens do arquivo
      return getWL(value, subtitleRow, dataFormDb);
    case "hl": // Input # URL
      return getHL(value, subtitleRow, dataFormDb);
    case "nb": // Input # Numero
      return getNB(value, subtitleRow, dataFormDb);
    case "nc": // Input # Numero
      return getNC(value, subtitleRow, dataFormDb);
    /* case "sl":
      return getWS(value, subtitleRow, dataFormDb); */
    case "qi": // Input # Nome de arquivo
      return getQI(value, subtitleRow, dataFormDb, functionCollapse);
    case "ct": // Input # Descrição do contato
      return getCT(value, subtitleRow, dataFormDb);
    case "wm": // Input # Email
      return getWM(value, subtitleRow, dataFormDb);
    case "zp": // Input # Nome de arquivo
      return getWS(value, subtitleRow, dataFormDb);
    case "sl": // Input # Campo de seleção unica com lista de valor unico, ex.: [String]
      return getSL(value, subtitleRow, dataFormDb);
    case "sj": // Input # Carrega a lista pre carregada
      return getSJ(value, subtitleRow, dataFormDb);
    case "si": // Input # Carrega a lista pre carregada com icones
      return getSI(value, subtitleRow, dataFormDb);
    case "dx": // Input # Data/Hora
      return getDX(value, subtitleRow, dataFormDb);
    case "dc": // Input # Valor decimal
      return getDC(value, subtitleRow, dataFormDb);
    case "dt": // Input # Data/Hora com DatePicker
      return getDT(value, subtitleRow, dataFormDb);
    case "wt": // Input # Render HTML
      return getWT(value, subtitleRow, dataFormDb);
    case "qf": // Input # Nome de arquivo
      return getQF(value, subtitleRow, dataFormDb, getMapConvention);
    case "cl": // Input # ColorPick padrão
      return getCL(value, subtitleRow, dataFormDb);
    default: // Input # Nome de arquivo
      return getWS(value, subtitleRow, dataFormDb);
  }
};

// Função PRINCIPAL que gera/valida todos os valores para retornar
const GenerateForm = (
  dataFormDb: any,
  subtitleForm: Array<typeCrudCollapse>
) => {
  // Titulo padrão caso não venha definido
  const titleCollapse: String = "Informações Básicas";
  if (!dataFormDb || Object.keys(dataFormDb).length === 0) return <></>;

  /* Função faz o mapeamento dos componentes criando as Cols */
  const getMapComponents = (subtitleCollapse, functionCollapse) => {
    return subtitleCollapse.map(subtitleRow => {
      if (subtitleRow.accessor.length < 2) return;
      if (!subtitleRow.accessor) return;

      // Verifica se tem um subaccessor (uma estrutura ox_ vindo do backend em alguns casos)
      let valueDataFormDb = dataFormDb[subtitleRow.accessor];
      if (subtitleRow.subaccessor) {
        let valueSubAcessor = dataFormDb[subtitleRow.subaccessor];
        if (valueSubAcessor && subtitleRow.subaccessorin) {
          valueSubAcessor = valueSubAcessor[subtitleRow.subaccessorin];
          if (valueSubAcessor.length && valueSubAcessor.length > 0) {
            if (subtitleRow.accessor.substring(0, 2).toString() !== "st") {
              valueSubAcessor = valueSubAcessor[0];
              valueDataFormDb = subtitleRow.accessor
                ? valueSubAcessor[subtitleRow.accessor]
                : valueSubAcessor;
            } else {
              valueSubAcessor =
                valueSubAcessor[Number(subtitleRow.accessor.substring(3, 4))];
              valueDataFormDb = valueSubAcessor;
            }
          }
        } else {
          valueDataFormDb =
            valueSubAcessor && subtitleRow.accessor
              ? valueSubAcessor[subtitleRow.accessor]
              : valueSubAcessor;
        }
      }
      // Faz o mapeamento dos campos pelas siglas
      const components = getMapConvention(
        subtitleRow.accessor.substring(0, 2),
        valueDataFormDb,
        subtitleRow,
        dataFormDb,
        functionCollapse
      );
      return getAdaptCols(components, subtitleRow);
    });
  };

  /* Função monta as Cols com responsibilidade definidas */
  const getAdaptCols = (components, subtitleFields) => {
    return subtitleFields.uniqueRow ? (
      <>
        {components}
        <Col
          className="mt-3"
          lg={12 - parseInt(subtitleFields.cols)}
          sm={12 - parseInt(subtitleFields.cols)}
          md={12 - parseInt(subtitleFields.cols)}
          xs={12}
        ></Col>
      </>
    ) : (
      components
    );
  };

  /* Função seta o Collapse */
  const setCollapseContent = () => {
    let cols = [];
    subtitleForm.map(item => cols.push(false)); //Criar os controle dos collapse de acordo com a quantidade de estruturas;
    cols[0] = true; //Define sempre o primeiro como aberto
    const [col1, setcol1] = useState(cols);

    // Controla o abrir/fechar dos collapses dinamicamente...
    const t_col1 = item => {
      let tempCols = col1;
      tempCols.map(col => (col = false));
      tempCols[item] = !col1[item];
      setcol1(() => {
        return [...col1, (col1[item] = !col1[item])];
      });
    };

    return subtitleForm.map((subtitleCollapse, index) => (
      <div className="p-1">
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingOne">
            <button
              className={classnames("accordion-button", "fw-medium", {
                collapsed: !col1[index],
              })}
              type="button"
              onClick={() => t_col1(index)}
              style={{ cursor: "pointer", backgroundColor: "White", border: '1px solid #e9e9ef', padding: '15px' }}
            >
              {subtitleCollapse.CollapseTitle
                ? subtitleCollapse.CollapseTitle
                : titleCollapse}
            </button>
          </h2>
          <Collapse
            isOpen={col1[index]}
            className="accordion-collapse"
            style={{ backgroundColor: "White", border: '1px solid #e9e9ef', padding: '15px' }}
          >
            <div className="accordion-body">
              <Row>
                {getMapComponents(
                  subtitleCollapse.CollapseContent,
                  subtitleCollapse.CollapseFunction
                )}
              </Row>
            </div>
          </Collapse>
        </div>
      </div>
    ));
  };

  return setCollapseContent();
};

function isJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export default GenerateForm;
