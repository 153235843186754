import _ from "lodash"
import { useState } from "react"
import { Col, FormGroup } from "reactstrap"
import { typeCrudForm } from "src/components/Common/typeCrudForm"
import LabelBraile from "src/components/general/Label/Label";
import ToggleSwitch from "src/components/general/ToggleSwitch/ToggleSwitch";
import { Observation } from "../styled"

// ToogleSwitch # Sim/Não
export default function getBF(
  valueField: any,
  subtitleFields: typeCrudForm,
  dataFormDb: any
): any {
  if (valueField === null || valueField === undefined) valueField = false;
  _.set(dataFormDb, subtitleFields.accessor, valueField);
  let [newsletter, setNewsletter] = useState(valueField);
  const onNewsletterChange = checked => {
    setNewsletter(checked);
    _.set(dataFormDb, subtitleFields.accessor, checked);
  };
  return (
    <Col
      className="mt-3"
      lg={subtitleFields.cols}
      sm={subtitleFields.cols}
      md={subtitleFields.cols}
      xs={12}
    >
      {subtitleFields.Header && (
        <LabelBraile htmlFor="validationCustom01">
          {subtitleFields.Header}
        </LabelBraile>
      )}
      <FormGroup className="">
        <ToggleSwitch
          id={
            subtitleFields.idfield
              ? subtitleFields.idfield
              : subtitleFields.accessor
          }
          checked={newsletter}
          onChange={onNewsletterChange}
          disabled={subtitleFields.disabled}
        />
        {/* <label htmlFor="newsletter">Send me Marketing Emails!</label> */}
      </FormGroup>
      {subtitleFields.observation && (
        <Observation>{subtitleFields.observation}</Observation>
      )}
    </Col>
  );
}