import _ from "lodash"
import { Col } from "reactstrap"
import { typeCrudForm } from "src/components/Common/typeCrudForm"
import AvFieldBraile from "src/components/general/Input/AvFieldBraile";
import { Observation } from "../styled"

// Input # Carrega a lista pre carregada
export default function getSJ(
  valueField: any,
  subtitleFields: typeCrudForm,
  dataFormDb: any
): any {  
  if (valueField === null || valueField === undefined)
    _.set(
      dataFormDb,
      subtitleFields.accessor,
      subtitleFields.default ? subtitleFields.default : ""
    );

  if (subtitleFields.cols === "0") return "";

  let valueInit: any = "Selecione";
  
  if (!Array.isArray(valueField)) {
    valueField = [
      {
        value: valueField,
        key: 1,
        check: true,
      },
    ];
  }

  if (valueField && valueField.length && valueField.length > 0) {
    valueInit = valueField.filter(item => Boolean(item.check) === true);
    valueInit = valueInit.length > 0 && valueInit[0].value !== '' ? valueInit[0].value : "Selecione";
  } else {
    valueField = "0";
  }

  const options = [];

  // valueInit === "Selecione" &&
  //   options.push(
  //     <option key={0} value={""}>
  //       {valueInit}
  //     </option>
  //   );

  subtitleFields &&
  subtitleFields.listItens &&
  subtitleFields.listItens.length &&
  subtitleFields.listItens.length > 0 &&
  subtitleFields.listItens[0].qi_subgroup &&
  subtitleFields.listItens[0].qi_subgroup.length &&
  subtitleFields.listItens[0].qi_subgroup.length > 0
    ? subtitleFields.listItens.map((category, index) => {
        options.push(
          <option
            key={`subtitleFields_listItens_${index}`}
            value={category.cr_code}
            style={{ fontWeight: "bold" }}
            disabled={true}
          >
            {category.ws_value}
          </option>
        );
        category.qi_subgroup.map((subcategory) => {
          options.push(
            <option
              key={`category_qi_subgroup_${subcategory.cr_code}`}
              value={subcategory.cr_code}
              style={{ paddingLeft: "35px", left: "35px", marginLeft: "35px" }}
            >
              &#160;&#160;&#160;{subcategory.ws_name}
            </option>
          );
        });
      })
    : subtitleFields.listItens.map((item, index) => {        
        options.push(
          <option key={item.cr_code} value={item.cr_code}>            
            {item.ws_value}
          </option>
        );
      });

  return (
    <Col
      className="mt-3"
      lg={subtitleFields.cols}
      sm={subtitleFields.cols}
      md={subtitleFields.cols}
      xs={12}      
    >
      <AvFieldBraile
        disabled={subtitleFields.disabled}
        value={valueInit}
        name={subtitleFields.accessor}
        id={
          subtitleFields.idfield
            ? subtitleFields.idfield
            : subtitleFields.accessor
        }
        label={subtitleFields.Header}
        placeholder=""
        type="select"
        errorMessage={
          subtitleFields.errorMessage
            ? subtitleFields.errorMessage
            : "Campo obrigatório"
        }
        validate={{ required: { value: subtitleFields.required } }}
        onChange={e => {
            _.set(dataFormDb, subtitleFields.accessor, e.target.value)          
          }
        }
      >
        {valueInit === "Selecione" && (
          <option key={0} value={""}>
            {valueInit}
          </option>
        )}
        {options}
      </AvFieldBraile>
      {subtitleFields.observation && (
        <Observation>{subtitleFields.observation}</Observation>
      )}
    </Col>
  );
}