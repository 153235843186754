import moment from 'moment';
import React from "react";
import { contentUrlServer, typeUrlServer } from "src/common/urlServer";
import { formatDateTime } from "src/helpers/functions";
import CrudContainer from "./CrudContainer";
import {
  deletePopup,
  getNextCrCodePopup,
  getPopupByCode,
  getPopups,
  insertPopup,
  updatePopup,
} from "./graphqlQuery";
import { LabelCnpj, LabelSocial } from "./styled";

// Traz a configuração pré-definida de urls do servidor
const urlServer: typeUrlServer = contentUrlServer.filter(
  item => item.name == "POPUP"
)[0];

function BfLiberation({ value, column, row }) {
  return (
    <a>
      {value ? (
        <span style={{ color: "green" }}>Sim</span>
      ) : (
        <span style={{ color: "red" }}>Não</span>
      )}
    </a>
  );
}

function TitleLink({ value, column, row }) {
  return (
    <>
      <LabelCnpj>{value}</LabelCnpj>
      <LabelSocial>{row.original.hl_link}</LabelSocial>
    </>
  );
}

const Popup = () => {
  const columns = React.useMemo(
    () => [
      {
        Header: "Imagem",
        accessor: "wh_image",
        imgAccessor: "hl_link",
        Cell: ({ value, column, row }) => {
          const image =
            urlServer.urlServer + urlServer.pathServer + "/" + value;
          return (
            <div className="flex items-center">
              <div className="flex-shrink-0 h-10 w-10">
                <img
                  className="rounded img-t1"
                  src={image}
                  alt=""
                  style={{ maxWidth: "200px" }}
                />
              </div>
            </div>
          );
        },
        emailAccessor: "hl_link",
      },
      {
        Header: "Código",
        accessor: "cr_code",
        visible: false,
      },
      {
        Header: "Título e Link",
        accessor: "ws_title",
        Cell: TitleLink,
        emailAccessor: "hl_link",
      },
      {
        Header: "Tamanho",
        /* Cell: SizeImage, */
        accessor: "",
      },
      {
        Header: "Tempo",
        /* Cell: SizeImage, */
        accessor: "nb_time",
      },
      {
        Header: "Liberação",
        Cell: BfLiberation,
        accessor: "bf_liberation",
      },
      {
        Header: "Data Cadastro",
        accessor: "dx_register",
        Cell: ({ value, column, row }) => {
          return moment(value).format(formatDateTime);
        },
      },
      {
        Header: "Ações",
        accessor: "actions",
        Filter: () => <div style={{ height: "10px" }}></div>,
        Cell: cellContent => (
          <div
            style={{
              width: "100%",
              textAlign: "center",
              backgroundColor: "red",
            }}
          >
            {cellContent}
          </div>
        ),
      },
    ],
    []
  );
  return (
    <CrudContainer
      routePath=""
      queryGet={getPopups}
      queryGetByCode={getPopupByCode}
      querySave={insertPopup}
      queryUpdate={updatePopup}
      queryDelete={deletePopup}
      queryNextCrCode={getNextCrCodePopup}
      title="Popup | Braile Distribuidora"
      breadcrumbTitle="Popup"
      breadcrumbItem="Popups"
      columnsTable={columns}
    />
  );
};

export default Popup;
