import styled from "styled-components";

export const LabelCnpj = styled.div`
  font-size: 16px;
`;

export const LabelSocial = styled.div`
  font-size: 13px;
  color: #6d6c6c;
`;
