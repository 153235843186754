import _ from "lodash"
import React from "react"
import { Col } from "reactstrap"
import { typeCrudForm } from "src/components/Common/typeCrudForm"
import AvFieldBraile from "src/components/general/Input/AvFieldBraile";
import { Observation } from "../styled"

// Input # Campo de seleção unica com lista de valor unico, ex.: [String]
export default function getSL(
  valueField: any,
  subtitleFields: typeCrudForm,
  dataFormDb: any
): any {    
  if (valueField === null || valueField === undefined) valueField = [""];  
  const [itens, setItens] = React.useState([]);
  if (subtitleFields.cols === "0") return "";
  let maps = [];
  if (subtitleFields.cols === "0") return "";
  let valueInit: any = "Selecione";
  // Verifica se o valor é uma array, se não, cria uma array padrão para o options
  if (!Array.isArray(valueField)) {
    valueField = [
      {
        value: valueField,
        key: 1,
        check: true,
      },
    ];
  }
  // Sendo uma array, faz o filtro para trazer o deafult check
  if (valueField.length && valueField.length > 0) {
    valueInit = valueField.filter(item => Boolean(item.check) === true);
    valueInit = valueInit.length > 0 ? valueInit[0].value : "Selecione";
  }  

  return (
    <Col
      className="mt-3"
      lg={subtitleFields.cols}
      sm={subtitleFields.cols}
      md={subtitleFields.cols}
      xs={12}
    >
      {/* <select
        className="form-select"
        name={subtitleFields.accessor}
        id={
            subtitleFields.idfield
              ? subtitleFields.idfield
              : subtitleFields.accessor
          }
        onChange={e => {
          _.set(dataFormDb, subtitleFields.accessor, e.target.value);
        }}
        defaultValue={valueField}
      >
        <option key={1}>Selecione uma opção</option>
        <option key={2}>Acessórios e Periféricos</option>
      </select> */}
      {/* <AvFieldBraile */}
      <AvFieldBraile
        disabled={subtitleFields.disabled}
        value={valueInit}
        defaultValue={valueInit}
        name={subtitleFields.accessor}
        id={
          subtitleFields.idfield
            ? subtitleFields.idfield
            : subtitleFields.accessor
        }
        label={subtitleFields.Header}
        placeholder=""
        type="select"
        errorMessage={
          subtitleFields.errorMessage
            ? subtitleFields.errorMessage
            : "Campo obrigatório"
        }
        validate={{ required: { value: subtitleFields.required } }}
        onChange={e => {
            _.set(dataFormDb, subtitleFields.accessor, e.target.value)            
          }
        }
      >
        {valueInit === "Selecione" && (
          <option key={-1} value={null}>
            {valueInit}
          </option>
        )}
        {valueField.map((item, index) => (
          <option key={item.value} value={item.value ? item.value : item}>            
            {item.value ? item.value : item}
          </option>
        ))}
      </AvFieldBraile>
      {subtitleFields.observation && (
        <Observation>{subtitleFields.observation}</Observation>
      )}
    </Col>
  );
}