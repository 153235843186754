/* IMPORTANTE: Deve incluir as fun principais do CRUD
 * getData, updateData, deleteData, postData
 * estas chamadas são padrão no CRUD/CrudGroupForm */

export const getFlyers = (): string => {
  return `{
    getFlyer{ 
      cr_code
      dx_register
      ck_registeruser
      dx_modification
      ck_modificationuser
      cv_version
      bf_active
      bf_liberation
      cr_code_day
      dx_date_register
      ws_title
      sl_origin_price
      sl_type_price
      sl_order_product
      wb_orderp_cod_prod
      nb_width_image
      nb_font_size_image
      nb_margin_left_image
      nb_top_margin_bottom_image
      nb_footer_margin_higher_image
      nb_box_prod_width_image
      nb_box_prod_height_image
      nb_box_prod_margin_right_image
      nb_box_prod_margin_bottom_image
      cl_box_prod_border_color_image
      sl_top_footer_align_image
      sl_box_price_align
      nb_price_font_size
      nb_coin_font_size
      nb_coin_margin_right
      nb_coin_margin_higher
    }
  }`;
};

export const getFlyersByCode = (cr_code: string): string => {
  return `{
    getFlyerByCode(cr_code:"${cr_code}") {
      cr_code
      dx_register
      ck_registeruser
      dx_modification
      ck_modificationuser
      cv_version
      bf_active
      bf_liberation
      cr_code_day
      dx_date_register
      ws_title
      sl_origin_price
      sl_type_price
      sl_order_product
      wb_orderp_cod_prod
      nb_width_image
      nb_font_size_image
      nb_margin_left_image
      nb_top_margin_bottom_image
      nb_footer_margin_higher_image
      nb_box_prod_width_image
      nb_box_prod_height_image
      nb_box_prod_margin_right_image
      nb_box_prod_margin_bottom_image
      cl_box_prod_border_color_image
      sl_top_footer_align_image
      sl_box_price_align
      nb_price_font_size
      nb_coin_font_size
      nb_coin_margin_right
      nb_coin_margin_higher
    }
  }`;
};

export const deleteFlyer = (cr_code: string): string => {
  return `query {
    deleteCustomerByCode(cr_code:"${cr_code}") {
      message
    }
  }`;
};

export const updateFlyer = (data: {
  cr_code: String;
  cr_code_day: String;
  ws_title: String;
  sl_origin_price: String;
  sl_type_price: String;
  sl_order_product: String;
  // wb_orderp_cod_prod: String;
  nb_width_image: number;
  nb_font_size_image: number;
  nb_margin_left_image: number;
  nb_top_margin_bottom_image: number;
  nb_footer_margin_higher_image: number;
  nb_box_prod_width_image: number;
  nb_box_prod_height_image: number;
  nb_box_prod_margin_right_image: number;
  nb_box_prod_margin_bottom_image: number;
  cl_box_prod_border_color_image: String;
  sl_top_footer_align_image: String;
  sl_box_price_align: String;
  nb_price_font_size: number;
  nb_coin_font_size: number;
  nb_coin_margin_right: number;
  nb_coin_margin_higher: number;
}): string => {
  return `mutation {
    updateFlyer(
      cr_code: "${data.cr_code},
      ws_title: "${data.ws_title}",
      sl_origin_price: "${data.sl_origin_price}",
      sl_type_price: "${data.sl_type_price}",
      sl_order_product: "${data.sl_order_product}",
      nb_width_image: ${data.nb_width_image},
      nb_font_size_image: ${data.nb_font_size_image},
      nb_margin_left_image: ${data.nb_margin_left_image},
      nb_top_margin_bottom_image: ${data.nb_top_margin_bottom_image},
      nb_footer_margin_higher_image: ${data.nb_footer_margin_higher_image},
      nb_box_prod_width_image: ${data.nb_box_prod_width_image},
      nb_box_prod_height_image: ${data.nb_box_prod_height_image},
      nb_box_prod_margin_right_image: ${data.nb_box_prod_margin_right_image},
      nb_box_prod_margin_bottom_image: ${data.nb_box_prod_margin_bottom_image},
      cl_box_prod_border_color_image: "${data.cl_box_prod_border_color_image}",
      sl_top_footer_align_image: "${data.sl_top_footer_align_image}",
      sl_box_price_align: "${data.sl_box_price_align}",
      nb_price_font_size: ${data.nb_price_font_size},
      nb_coin_font_size: ${data.nb_coin_font_size},
      nb_coin_margin_right: ${data.nb_coin_margin_right},
      nb_coin_margin_higher: ${data.nb_coin_margin_higher},
   ){
  	message
   }
  }`;
};

export const insertFlyer = (data: {
  cr_code: String;
  cr_code_day: String;
  ws_title: String;
  sl_origin_price: String;
  sl_type_price: String;
  sl_order_product: String;
  nb_width_image: number;
  nb_font_size_image: number;
  nb_margin_left_image: number;
  nb_top_margin_bottom_image: number;
  nb_footer_margin_higher_image: number;
  nb_box_prod_width_image: number;
  nb_box_prod_height_image: number;
  nb_box_prod_margin_right_image: number;
  nb_box_prod_margin_bottom_image: number;
  cl_box_prod_border_color_image: String;
  sl_top_footer_align_image: String;
  sl_box_price_align: String;
  nb_price_font_size: number;
  nb_coin_font_size: number;
  nb_coin_margin_right: number;
  nb_coin_margin_higher: number;
}): string => {
  return `mutation {
    insertFlyer(
      cr_code: ${JSON.stringify(data.cr_code)},
      ws_title: ${JSON.stringify(data.ws_title)}",
      sl_origin_price: ${JSON.stringify(data.sl_origin_price)},
      sl_type_price: ${JSON.stringify(data.sl_type_price)},
      sl_order_product: ${JSON.stringify(data.sl_order_product)},
      nb_width_image: ${data.nb_width_image},
      nb_font_size_image: ${data.nb_font_size_image},
      nb_margin_left_image: ${data.nb_margin_left_image},
      nb_top_margin_bottom_image: ${data.nb_top_margin_bottom_image},
      nb_footer_margin_higher_image: ${data.nb_footer_margin_higher_image},
      nb_box_prod_width_image: ${data.nb_box_prod_width_image},
      nb_box_prod_height_image: ${data.nb_box_prod_height_image},
      nb_box_prod_margin_right_image: ${data.nb_box_prod_margin_right_image},
      nb_box_prod_margin_bottom_image: ${data.nb_box_prod_margin_bottom_image},
      cl_box_prod_border_color_image: ${JSON.stringify(data.cl_box_prod_border_color_image)},
      sl_top_footer_align_image: ${JSON.stringify(data.sl_top_footer_align_image)},
      sl_box_price_align: ${JSON.stringify(data.sl_box_price_align)},
      nb_price_font_size: ${data.nb_price_font_size},
      nb_coin_font_size: ${data.nb_coin_font_size},
      nb_coin_margin_right: ${data.nb_coin_margin_right},
      nb_coin_margin_higher: ${data.nb_coin_margin_higher},
   ){
  	message
   }
  }`;
};

export const getNextCrCodeFlayer = (): string => {
  return `query {
    getNextCrCodeCustomer {
      result
    }
  }`;
};

export const getCustomerPagination = (
  filter: String,
  limit: Number = 100,
  random: Boolean = false,
  skip: Number = 0,
  sort: String = "{}"
): string => {
  let filter2 = '{"ox_register_approval.bf_approval":false}';
  let filter3 = JSON.stringify(filter2);
  return `query {
    getCustomerPagination(filter: {
        filter: ${filter3},
        sort: "${sort}",
        skip: ${skip},  
        limit: ${limit},                      
        random: ${random},
      }) {
      result {
        cv_version
        bf_active
        bf_liberation
        cr_code
        ck_registeruser
        dx_register
        dx_modification
        wj_cnpj
        cr_code_seller
        cr_code_operation
        cr_code_registration
        wp_password
        sl_type_client
        sl_type_transport
        bf_restricted_registry
        bf_own_credit
        bf_generate_budget
        sl_curve_type
        nb_tax_condition
        dx_data_login
        sl_payment_term {
          value
          key
          check
        }
        sl_special_payment {
          value
          key
          check
        }
        ws_seller
        ox_registration_fields {
          cv_version
          bf_active
          bf_liberation
          cr_code
          ck_registeruser
          dx_register
          dx_modification
          ws_corporate_reason
          wj_cnpj
          wi_state_registration
          wm_email
          ws_indication
          ld_doc_social_contract
          ld_doc_social_identity
          ld_doc_address_voucher
          ld_doc_income_voucher
          ld_doc_state_registration
          ld_doc_cnpj
          ws_supplier_reference
          ws_bank_reference
          ws_agency_reference
          ct_contact_reference
          bf_approval
          dx_register_approval
          ws_registeruserapprovalaccount
          ox_register_approval {
            bf_approval
            dx_register
            ws_registeruserapprovalaccount
          }
          ct_qi_phone_contact {
            ws_description
            nc_number
            bf_whatsapp
          }
          qi_address {
            ws_street
            nb_street_number
            ws_district
            ws_city
            ws_state
            zp_zipcode
            ws_complement
            ox_geo {
              lt_lat
              lt_lng
            }
          }
        }
      } 
      rows
      rowsall
    }
  }`;
};

export const getPaymentTerms = (): string => {
  return `query {
    getPaymentTerms {
        cr_code
        ws_name
    }
  }`;
};
