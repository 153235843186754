/* IMPORTANTE: Deve incluir as fun principais do CRUD
 * getData, updateData, deleteData, postData
 * estas chamadas são padrão no CRUD/CrudGroupForm */

export const getAbcCurves = (): string => {
  return `query {
    getAbcCurves {
      cr_code
      sl_curve_type
      dc_value
    }
  }`
}

export const getAbcCurveByCode = (cr_code: string): string => {
  return `query {
    getAbcCurveByCode(cr_code: "${cr_code}") {
      cr_code
      sl_curve_type
      dc_value
    }
  }`
}

export const getNextCrCodeAbcCurve = (): string => {
  return `query {
    getNextCrCodeAbcCurve {
      result
    }
  }`
}

export const insertAbcCurve = (data: {
  cr_code: String;
  sl_curve_type: String;
  dc_value: number;
}): string => {
  return `mutation{
    insertAbcCurve(
      cr_code: ${JSON.stringify(data.cr_code)},
      sl_curve_type: ${JSON.stringify(data.sl_curve_type)},
      dc_value: ${data.dc_value},
    ){
      message
    }
  }`
}

export const updateAbcCurve = (data: {
  cr_code: String;
  sl_curve_type: String;
  dc_value: number;
}): string => {
  return `mutation{
    updateAbcCurve(
      cr_code: ${JSON.stringify(data.cr_code)},
      sl_curve_type: ${JSON.stringify(data.sl_curve_type)},
      dc_value: ${data.dc_value},
    ){
      message
    }
  }`
}

export const deleteAbcCurve = (cr_code: string): string => {
  return `mutation{
    deleteAbcCurveByCode(
      cr_code:"${cr_code}"
    ){
      message
    }
  }`
}