import _ from "lodash"
import moment from "moment"
import { useEffect } from "react"
import { Col } from "reactstrap"
import { typeCrudForm } from "src/components/Common/typeCrudForm"
import AvFieldBraile from "src/components/general/Input/AvFieldBraile";
import { Observation } from "../styled"

// Input # Data/Hora com DatePicker
export default function getDT(
  valueField: any,
  subtitleFields: typeCrudForm,
  dataFormDb: any
): any {
  const dateTemp = new Date();
  if (subtitleFields.cols === "0") return "";
  // var date = moment(valueField);
  // if (!valueField && subtitleFields.default) {
  //   valueField = subtitleFields.default
  //   _.set(dataFormDb, subtitleFields.accessor, valueField);
  // }
  var date = moment(valueField ? valueField : dateTemp);

  useEffect(() => {
    if (valueField && !valueField.includes(':') && subtitleFields.complementValueFieldEnd) {
      _.set(dataFormDb, subtitleFields.accessor, valueField + subtitleFields.complementValueFieldEnd);
    }
  }, [])

  return (
    <Col
      className="mt-3"
      lg={subtitleFields.cols}
      sm={subtitleFields.cols}
      md={subtitleFields.cols}
      xs={12}
    >
      {/* {date.isValid() === true ? ( */}
      <AvFieldBraile
        disabled={subtitleFields.disabled}
        value={valueField ? moment(moment(valueField, "YYYY-MM-DD")).format("YYYY-MM-DD") : null}
        // value={valueField}
        /* defaultValue={valueField} */
        name={subtitleFields.accessor}
        id={
          subtitleFields.idfield
            ? subtitleFields.idfield
            : subtitleFields.accessor
        }
        label={subtitleFields.Header}
        placeholder=""
        type="date"
        /* errorMessage={
          subtitleFields.errorMessage
            ? subtitleFields.errorMessage
            : "Campo obrigatório"
        } */
        /* validate={{ required: { value: subtitleFields.required } }} */
        onBlur={(e: any) => {
          if (e.target.value) {
            if (subtitleFields.complementValueFieldEnd) {
              _.set(dataFormDb, subtitleFields.accessor, e.target.value + subtitleFields.complementValueFieldEnd);
            } else {
              _.set(dataFormDb, subtitleFields.accessor, e.target.value);
            }
          }
        }}
      />
      {/* ) : (
        <AvFieldBraile
          disabled={true}
          value=""
          defaultValue=""
          name={subtitleFields.accessor}
          id={
            subtitleFields.idfield
              ? subtitleFields.idfield
              : subtitleFields.accessor
          }
          label={subtitleFields.Header}
          placeholder=""
          type="text"
          errorMessage={
            subtitleFields.errorMessage
              ? subtitleFields.errorMessage
              : "Campo obrigatório"
          }
          validate={{ required: { value: subtitleFields.required } }}
          onChange={e => {
            _.set(dataFormDb, subtitleFields.accessor, e.target.value);
          }}
        />
      )} */}

      {subtitleFields.observation && (
        <Observation>{subtitleFields.observation}</Observation>
      )}
    </Col>
  );
}