import {
  typeCrudCollapse,
  typeCrudForm
} from 'src/components/Common/typeCrudForm';
import GenerateForm from 'src/components/CRUD/generateComponents';
import { CustomPayment } from './customPayment';
import { getPaymentTermRangeByCode } from './graphqlQuery';

const FormManufacturer = (props: {
  dataFormDb: {
    cr_code: String;
    bf_liberation: Boolean;
    ws_name: String;
    ws_minimum_value: String;
    ws_description: String;
    qi_payment_term_range: Array<{
      ws_start: Number;
      ws_installment: String;
    }>;
  };
  nextCrCode: string;
}) => {
  let { dataFormDb, nextCrCode } = props;

  // Add o próximo código para novo cadastro
  if (nextCrCode) {
    dataFormDb.cr_code = nextCrCode;
  }

  //dataFormDb.qi_payment_term_range = ["TESTE"];

  // Constroi os titulos do formulário
  const subtitleContent: Array<typeCrudForm> = [
    {
      Header: 'Código',
      accessor: 'cr_code',
      cols: '6',
      required: true
    },
    {
      Header: 'Liberação',
      accessor: 'bf_liberation',
      cols: '4',
      required: false
    },
    {
      Header: 'Nome',
      accessor: 'ws_name',
      cols: '6',
      required: true,
      uniqueRow: true
    },
    {
      Header: 'Valor Mínimo',
      accessor: 'ws_minimum_value',
      cols: '6',
      required: true,
      uniqueRow: true
    },
    {
      Header: 'Descrição',
      accessor: 'ws_description',
      cols: '12',
      required: false
    }
  ];
  const subtitleContentRange: Array<typeCrudForm> = [
    {
      Header:
        'Condição de pagamento: Condição de pagamento usual (28D) \n DICA: a opção "à vista" sempre está disponível, não deve ser utilizada aqui.',
      accessor: 'qi_payment_term_range',
      cols: '12',
      required: true,
      custom: <CustomPayment dataFormDb={dataFormDb} label={'Condição de pagamento: Condição de pagamento usual (28D) DICA: a opção "à vista" sempre está disponível, não deve ser utilizada aqui.'} />
    }
  ];
  const contentForm: Array<typeCrudCollapse> = [
    {
      CollapseTitle: 'Condição de Pagamento',
      CollapseContent: subtitleContent
    },
    {
      CollapseTitle: 'Faixas e Parcelas',
      CollapseContent: subtitleContentRange,
      CollapseFunction: {
        queryGetByCode: getPaymentTermRangeByCode
      }
    }
  ];
  return GenerateForm(dataFormDb, contentForm);
};

export default FormManufacturer;
