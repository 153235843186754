import { CardBody, CardSubtitle, CardTitle } from "reactstrap"
import styled, { css } from "styled-components"
import BOCard from "../general/Cardcomponent/Card"
import BOButton from "../general/button"
import CardImg from "../general/image/index"

/* height: 292px; */
/* background: #ededed; */

interface Props {
  discountSale?: any;
  horizontal?: boolean; 
  offerRed?: boolean;
  width?: number;
  height?: number;
  borderColor?: string;
  fontSize?: number;
}

type CardViewProductsProps = { horizontal: boolean; offerRed?: boolean, fontSize?: number };

export const BoxCard = styled.div`
  width: 194px;
  /* height: 334px; */
  height: 100%;
  display: flex;
  align-items: end;
  text-align: -webkit-center;
`;
export const BoxCardLogged = styled.div<Props>`
  ${({ width = 194, height = 440, discountSale }) => css`
    width: ${width}px;
    height: ${height ? height : discountSale ? "371px" : "329px"};
	`}

  display: flex;
  align-items: end;
  text-align: -webkit-center;
`;
export const BoxCardRedLogged = styled.div<Props>`
  width: 194px;
  height: ${props => (props.discountSale ? "354px" : "306px")};
  background: radial-gradient(
    55.64% 1034.7% at 50% 50%,
    #d73831 0%,
    #952823 100%
  );
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    box-shadow: 0 0px 10px -2px;
  }
`;
export const BoxBestOfferLogged = styled.div`
  position: absolute;
  margin-bottom: 344px;
  margin-left: 35px;
  width: 124px;
  height: 36px;
  background-color: #f4e55f;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  text-align: center;

  color: #515151;
`;
export const CardImgLoggedStyled = styled(CardImg)`
  max-width: 100%;
  height: auto;
  max-height: 140px;
  height: ${props => (props.offerRed ? "172px" : "150px;")};
  margin: 0 auto;
  object-fit: cover;
  margin-top: ${props => (props.offerRed ? "12px" : "")};
  border-radius: 0;
  object-fit: contain;
  max-width: 152px;
`;
export const BoxtextLogged = styled("div")<CardViewProductsProps>`
  width: ${props => (props.horizontal ? "200px" : "160px")};
  display: flex;
  height: 20px;
  margin-top: 6px;
  margin-bottom: 8px;
  padding-bottom: 15px !important;
`;
export const BoxCardRed = styled.div`
  width: 194px;
  height: 312px;
  background: radial-gradient(
    55.64% 1034.7% at 50% 50%,
    #d73831 0%,
    #952823 100%
  );
  border-radius: 5px;
  &:hover {
    box-shadow: 0 0px 10px -2px;
  }
`;
export const BoxBestOffer = styled.div`
  position: absolute;
  margin-bottom: 298px;
  margin-left: 35px;
  width: 124px;
  height: 36px;
  background-color: #f4e55f;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  text-align: center;

  color: #515151;
`;

export const ContainerImg = styled("div")`
  overflow: hidden;
`;

export const CardStyled = styled(BOCard)<Props>`
  ${({ borderColor = '#e4e4e4', horizontal, width = 194, marginBottom =  0, marginRight = 0}) => css`
    display: ${horizontal ? "flex" : ""};
    flex-direction: ${horizontal ? "row" : ""};
    height: ${horizontal ? "108px" : "auto"};
    max-width: ${horizontal ? "353px" : `${width}px`};
    border: ${borderColor ? `1px solid ${borderColor}` : '1px solid #e4e4e4'};
    margin-bottom: ${marginBottom};
    margin-right: ${marginRight};
  `}

  border-radius: 5px;
  background: white; /* #fafafa; */
  cursor: pointer;
  &:hover {
    background: #ffffff;
    box-shadow: 0 0px 10px -2px;
  }
`;

export const Box = styled(BOCard)`
  width: 140px;
  height: 144px;
  padding: 4px;
  border: 1px solid #e4e4e4;
  border-radius: 5px;
  background: white; /* #fafafa; */
  cursor: pointer;
  &:hover {
    background: #ffffff;
    box-shadow: 0 0px 10px -2px;
  }
`;

export const BoxImgStyled = styled("div")<CardViewProductsProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${props => (props.horizontal ? "91px" : "152px")};
  /* height: ${props => (props.horizontal ? "91px" : "126px;")}; */
  margin: ${props => (props.horizontal ? "10px" : "auto")};
  margin-top: ${props => (props.horizontal ? "-18%" : "10px")};
  object-fit: cover;
  height: 150px;
`;

export const CardImgSimpleStyled = styled(CardImg)`
  width: auto;
  height: 132px;
  margin: auto;
  /* object-fit: cover; */
`;

export const CardImgStyled = styled(CardImg)`
  width: auto;
  /* max-height: ${props => (props.offerRed ? "168px" : "150px;")}; */
  margin: 0 auto;
  /* object-fit: cover; */
  margin-top: ${props => (props.offerRed ? "22px" : "")};
  border-radius: 0;
  object-fit: contain;
  max-width: 152px;
`;
export const CardBodyStyled = styled(CardBody)`
  padding: 10px 16px;
  width: ${props => (props.horizontal ? "179px" : "")};
`;
export const CardTilteStlyed = styled(CardTitle)<Props>`
  ${({ horizontal, fontSize = 14, offerRed }) => css`
    font-size: ${fontSize};
    max-width: ${horizontal ? "179px" : "22ch"};
    height: 70px;
    color: ${offerRed ? "#FFFFFF" : "#515151"};
  `}

  text-align: -webkit-left;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal !important;
  overflow: hidden;
  margin: 0 !important;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Boxtext = styled("div")<CardViewProductsProps>`
  width: ${props => (props.horizontal ? "200px" : "160px")};
  display: flex;
  height: 28px;
  margin-top: 8px;
  margin-bottom: 0px;
  padding-bottom: 15px !important;
`;
export const CardSubtitleStlyed = styled(CardSubtitle)`
  max-width: ${props => (props.offerRed ? "20ch" : "16ch")};
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: ${props => (props.horizontal ? "13px" : "14px")};
  color: ${props => (props.offerRed ? "#FFFFFF;" : "#515151;")};
  margin-right: auto;
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  max-height: 2.4em;
  line-height: 1em;
  padding-top: 5px;
`;

export const CodStyled = styled("p")<CardViewProductsProps>`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  color: ${props => (props.offerRed ? "#FFFFFF;" : "#898989;")};
  text-align: right !important;
`;

export const BoxValue = styled.div<{ priceAlign: string }>`
  ${({ priceAlign = 'left', typePrice = 'flex', marginTop = 0, marginRight = 0 }) => css`
    justify-content: ${priceAlign};
    display:  ${typePrice};
    margin-bottom: ${marginTop};
    margin-right: ${marginRight};
  `}

  width: 154px;
  height: auto;
  align-items: flex-start;
  flex-direction: row;
`;
export const TextTotalPrice = styled("p")<CardViewProductsProps>`
  ${({ fontSize = 13 }) => css`
    font-size: ${fontSize}px;
  `}
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  color: ${props => (props.offerRed ? "#FFFFFF;" : "#3a589b ")};
  margin: 0 !important;
`;


export const TextPartPrice = styled("p")<CardViewProductsProps>`
  ${({ fontSize = 14 }) => css`
    font-size: ${fontSize}px;
  `}

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  color: ${props => (props.offerRed ? "#FFFFFF;" : "#333;")};
  margin-top: 4px !important;
  margin: 0 !important;
`;

export const TagPriceStyled = styled("p")<CardViewProductsProps>`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  color: ${props => (props.offerRed ? "#FFFFFF;" : "#898989;")};
  text-align: left !important;
  margin: 0 !important;
  margin-top: 4px !important;
`;

export const TagPriceStyledPart = styled("p")<CardViewProductsProps>`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  color: ${props => (props.offerRed ? "#FFFFFF;" : "#898989;")};
  text-align: left !important;
  margin: 0 !important;
  margin-top: 2px !important;justify-content: center;
`;

export const InnerBoxValue = styled.div`
  width: 100%;
  height: auto;
`;

export const ButtonYellowReservation = styled(BOButton)`
  background-color: #df994f;
  color: white;
  font-size: 0.7em;
`;
