import React, { useState } from "react"
import { post } from "src/helpers/api_helper"
import CrudContainer from "./CrudContainer"
import {
  deleteOrder,
  getCustomers,
  getOrderByCode,
  getOrderFilter,
  getOrderPagination,
  getSellers,
  updateOrder
} from "./graphqlQuery"
import { LabelCnpj, LabelSocial } from "./styled"

function BfLiberation({ value, column, row }) {
  return (
    <a>
      {value ? (
        <span style={{ color: "green" }}>Sim</span>
      ) : (
        <span style={{ color: "red" }}>Não</span>
      )}
    </a>
  );
}

const Order = () => {
  const [listCustomers, setListCustomers] = useState([
    {
      name: "Todos",
      value: "",
    }
  ])
  const [listSellers, setListSellers] = useState([
    {
      name: "Todos",
      value: "",
    }
  ])
  const [listSituation, setListSituation] = useState([
    {
      name: "Incluído",
      value: "IN",
    },
    {
      name: "Em processamento",
      value: "PR",
    },
    {
      name: "Concluído",
      value: "CO",
    },
    {
      name: "Cancelado",
      value: "CA",
    }
  ])
  const columns = React.useMemo(
    () => [
      {
        Header: "Pedido",
        accessor: "cr_code",
        Cell: ({ value, column, row }) => {
          return (
            <>
              <LabelCnpj>{value}</LabelCnpj>
              <LabelSocial>
                <span style={{ color: "green" }}>
                  {row.original.cr_code_erp}
                </span>
              </LabelSocial>
            </>
          );
        },
      },
      {
        Header: "Pagar.me",
        accessor: "cr_pagarme",
        Cell: ({ value, column, row }) => {
          if (value) {
            return <span>{value}</span>;
          }
          return "";
        },
      },
      {
        Header: "Cliente",
        accessor: "ws_client",
        Cell: ({ value, column, row }) => {
          return (
            <>
              <LabelCnpj>{value}</LabelCnpj>
              <LabelSocial>{row.original.wj_cnpj}</LabelSocial>
            </>
          );
        },
      },
      {
        Header: "Vendedor",
        accessor: "ws_seller",
      },
      {
        Header: "Situação",
        accessor: "ws_site_situation",
        Cell: ({ value, column, row }) => {
          return <span style={{ color: "red" }}>{value}</span>;
        },
      },
      {
        Header: "Produtos R$",
        accessor: "dc_value_total",
        Cell: ({ value, column, row }) => {
          return (
            <div style={{ width: "100%", textAlign: "right" }}>
              <LabelCnpj>{value}</LabelCnpj>
              <LabelSocial>{row.original.ws_site_type_payment}</LabelSocial>
            </div>
          );
        },
      },
      {
        Header: "Email",
        accessor: "ws_email",
        Cell: ({ value, column, row }) => {
          if (value === "N") {
            return <span style={{ color: "red" }}>Não</span>;
          } else {
            return <span style={{ color: "green" }}>Sim</span>;
          }
        },
      },
      {
        Header: "Orç.",
        accessor: "ws_site_budget",
        Cell: ({ value, column, row }) => {
          if (value === "N") {
            return <span style={{ color: "red" }}>Não</span>;
          } else {
            return <span style={{ color: "green" }}>Sim</span>;
          }
        },
      },

      {
        Header: "Data Cadastro",
        accessor: "dx_register",
        Cell: ({ value, column, row }) => {
          return value;
        },
      },
      {
        Header: "Ações",
        accessor: "actions",
        Filter: () => <div style={{ height: "10px" }}></div>,
        Cell: cellContent => (
          <div
            style={{
              width: "100%",
              textAlign: "center",
              backgroundColor: "red",
            }}
          >
            {cellContent}
          </div>
        ),
      },
    ],
    []
  );  

  // Colunas do filtro customizavel
  const columnsFilterCustom = React.useMemo( 
    () => [
    {
      name: "Código Site",
      type: "text",
      field: "ox_identification.cr_code", 
      maxLength: 50
    },
    {
      name: "Código ERP",
      type: "text",
      field: "ox_register_data.cr_code_erp", 
      maxLength: 10
    },
    {
      name: "Cliente (Código ERP)",
      type: "text",
      field: "ox_register_data.cr_code_client", 
      cols: 2,
      // options: listCustomers,
    },    
    {
      name: "Período",
      type: "daterange",
      field: "ox_identification.dx_register", 
      fieldCustom: "ox_identification.dx_register", 
    },
    {
      name: "Situação",
      type: "select",
      field: "ox_register_data.ws_site_situation", 
      options: listSituation,
    },    
    {
      name: "Vendedor",
      type: "select",
      field: "ox_register_data.cr_code_seller", 
      options: listSellers,
    },
  ], [listSellers, listCustomers, listSituation]);
  
  React.useEffect(() => {
    // Retorna lista de clientes
    async function getCustomerList() {
      let response = await post(
        "/graphql",
        {
          query: getCustomers(),
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
    ).then(response => response);

    if (response.data[Object.keys(response.data)[0]]) {
      let temp = [];
      const list = response.data[Object.keys(response.data)[0]];
      list.map(item => {
        temp.push({
          name: item.ox_registration_fields.ws_corporate_reason + "/" + item.ox_registration_fields.wj_cnpj,
          value: item.cr_code_erp,
        });
      });
      return setListCustomers(temp);
    } else {
      alert("Falha ao trazer lista de clientes!");
      return setListCustomers([])
    }
  }

  // Retorna a lista de vendedores
  async function getSellerList() {
    let response = await post(
      "/graphql",
      {
        query: getSellers(),
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    ).then(response => response);
    if (response.data.getSellers) {
      let temp = [];
      const list = response.data.getSellers
      list.map(item => {
        temp.push({
          name: item.ws_name,
          value: item.cr_code,
        });
      });
      return setListSellers(temp);
    } else {
      alert("Falha ao trazer lista de clientes!");
      return setListSellers([])
    }
  }
  
  getSellerList();
  }, []);

  return (
    <CrudContainer
      routePath=""
      queryGet={getOrderPagination}
      queryGetFilter={getOrderFilter}
      queryGetByCode={getOrderByCode}
      querySave={null}
      queryUpdate={updateOrder}
      queryDelete={deleteOrder}
      queryNextCrCode={() => {}}
      title="Pedido | Braile Distribuidora"
      breadcrumbTitle=""
      breadcrumbItem="Pedido"
      columnsTable={columns}
      fieldsCustomFilters={columnsFilterCustom}
    />
  );
};

export default Order;
