import moment from 'moment'
import React from "react"
import { contentUrlServer, typeUrlServer } from 'src/common/urlServer'
import { formatDateTime } from "src/helpers/functions"
import CrudContainer from "./CrudContainer"
import {
  getManufacturerByCode,
  getManufacturers,
  getNextCrCodeManufacturer,
  insertManufacturer,
  updateManufacturer
} from "./graphqlQuery"

const urlServer: typeUrlServer = contentUrlServer.filter(
  item => item.name == "MANUFACTURER"
)[0];

function BfLiberation({ value, column, row }) {
  return (
    <a>
      {value ? (
        <span style={{ color: "green" }}>Sim</span>
      ) : (
        <span style={{ color: "red" }}>Não</span>
      )}
    </a>
  );
}

function AvatarCell({ value, column, row }) {
  const image = urlServer.urlServer + urlServer.pathServer + value;
  return (
    <div className="flex items-center">
      <div className="flex-shrink-0 h-10 w-10">
        <img className="rounded img-t1" src={image} alt="" style={{ maxWidth: '150px', maxHeight: '150px' }} />
      </div>
      <div className="ml-4">
        {/* <div className="text-sm font-medium text-gray-900">{value}</div> */}
        <div className="text-sm text-gray-500">
          {row.original[column.imgAccessor]}
        </div>
      </div>
    </div>
  );
}

const Manufacturer = () => {
  const columns = React.useMemo(
    () => [
      {
        Header: "Imagem",
        accessor: "wh_image",
        Cell: AvatarCell,
        imgAccessor: "hl_link",
      },
      {
        Header: "Código",
        accessor: "cr_code",
      },
      {
        Header: "Nome",
        accessor: "ws_name",
      },
      {
        Header: "Abreviatura",
        accessor: "ws_abbreviation",
      },
      {
        Header: "Liberação",
        Cell: BfLiberation,
        accessor: "bf_liberation",
      },
      {
        Header: "Data Cadastro",
        accessor: "dx_register",
        Cell: ({ value, column, row }) => {
          return moment(value).format(formatDateTime);
        },
      },
      {
        Header: "Ações",
        accessor: "actions",
        Filter: () => <div style={{ height: "10px" }}></div>,
        Cell: cellContent => (
          <div
            style={{
              width: "100%",
              textAlign: "center",
              backgroundColor: "red",
            }}
          >
            {cellContent}
          </div>
        ),
      },
    ],
    []
  );
  return (
    <CrudContainer
      routePath=""
      queryGet={getManufacturers}
      queryGetByCode={getManufacturerByCode}
      querySave={insertManufacturer}
      queryUpdate={updateManufacturer}
      queryDelete={null}
      queryNextCrCode={getNextCrCodeManufacturer}
      title="Fabricante | Braile Distribuidora"
      breadcrumbTitle="Outros"
      breadcrumbItem="Fabricantes"
      columnsTable={columns}
    />
  );
};

export default Manufacturer;
