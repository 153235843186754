import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const ConfirmModal = (props: {
  confirm: any;
  cancel: any;
  modal: any;
  setModal: any;
  title: any;
}) => {
  const { confirm, cancel, modal, setModal, title } = props;

  const toggleYes = () => {
    setModal(!modal);
    confirm();
  };
  const toggleNo = () => {
    setModal(!modal);
    cancel();
  };

  return (
    <div>
      <Modal isOpen={modal} toggle={toggleNo} centered={true}>
        <ModalHeader toggle={toggleNo}>{title}</ModalHeader>
        {/* <ModalBody>

        </ModalBody> */}
        <ModalFooter>
          <Button color="primary" onClick={toggleYes}>
            Sim
          </Button>{" "}
          <Button className="ml-2" color="secondary" onClick={toggleNo}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ConfirmModal;
