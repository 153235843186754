import React, { useState } from "react"
import { Button } from "reactstrap"
import { typeCrudForm } from "src/components/Common/typeCrudForm"
import FormCrudContainer from "src/components/CRUD/CrudForm"
import { post } from "src/helpers/api_helper"
import { getPaymentTerms, setUpdatePasswordCustomerAdmin } from "../graphqlQuery"
import FormManufacturer from "./forms/formCustomer"
import modalConfirm from "./forms/modalConfirm"
import modalPdfGenerator from "./forms/modalPdfGenerator"
import modalSuccess from "./forms/modalSuccess"

export default function FormCrud(props: {
  queryPreview: any;
  handleNewRegister: (mode: string) => void;
  queryGetByCode: any;
  queryGet: any;
  querySave: any;
  queryUpdate: any;
  queryDelete: any;
  selectedRow: any;
  setSelectedRow: any;
  queryNextCrCode: any;
  columnsTable: Array<typeCrudForm>;
}) {
  const [rowSelected, setRowSelected] = useState(null);
  const { handleNewRegister, selectedRow, setSelectedRow } = props;
  // Carrega as condições de pagamento
  const [listPayments, setListPayments] = React.useState([]);

  // Hooks do modal
  const [modal_backdrop, setmodal_backdrop] = useState(false);
  const [modal_fullscreen, setmodal_fullscreen] = useState(false);
  const [modal_success, setmodal_success] = useState(false);
  const [code, setCode] = useState(null);
  // Hooks do pdf
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  // Envia solicitação de nova senha
  async function updatePasswordAdmin(cr_code) {
    await post(
      "/graphql",
      {
        query: setUpdatePasswordCustomerAdmin(cr_code),
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then(response => {
      setCode(response?.data?.updatePasswordCustomerAdmin?.result)
      if(response?.data?.updatePasswordCustomerAdmin?.result) {
        setmodal_success(true)
      }
    })
    .catch(error => alert("Falha ao enviar nova senha!"))
  }

  function sendSuccess() {
    updatePasswordAdmin(rowSelected.cr_code)
  }

  // Functions modal
  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }
  function tog_backdrop() {    
    setmodal_backdrop(!modal_backdrop);
    removeBodyCss();    
  }
  function tog_fullscreen() {
    setmodal_fullscreen(!modal_fullscreen);
    removeBodyCss();
  }

  // Functions do PDF
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const buttonFunctions = (
    <>
      {modalSuccess(modal_success, setmodal_success, handleNewRegister, setSelectedRow, code)}
      {modalConfirm(modal_backdrop, setmodal_backdrop, tog_backdrop, sendSuccess, setmodal_success)}
      {modalPdfGenerator(
        modal_fullscreen,
        setmodal_fullscreen,
        tog_fullscreen,
        selectedRow
      )}
      {selectedRow?.cr_code_erp && (
        <Button type="reset" className="" onClick={() => tog_backdrop()}>
          Enviar nova senha...
        </Button>
      )}
      <Button type="reset" className="" onClick={() => tog_fullscreen()}>
        <i className="mdi mdi-file-pdf-box font-size-8" /> Gerar relatório em
        PDF...
      </Button>
    </>
  );

  React.useEffect(() => {
    async function getListPayment() {
      let response = await post(
        "/graphql",
        {
          query: getPaymentTerms(),
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      ).then(response => response);
      if (response.data[Object.keys(response.data)[0]]) {
        let temp = [];
        const list = response.data[Object.keys(response.data)[0]];
        list.map(item => {
          temp.push({
            cr_code: item.cr_code,
            ws_value: item.ws_name,
          });
        });
        temp = [
          {
            nameList: "ws_payments",
            content: temp,
          },
        ];
        setListPayments(temp);
      } else {
        alert("Falha ao trazer lista de categorias1!");
        setListPayments([]);
      }
    }
    getListPayment();
  }, []);

  return (
    <FormCrudContainer
      Form={FormManufacturer}
      handleNewRegister={handleNewRegister}
      buttons={buttonFunctions}
      listItens={listPayments}
      callBackRowSelected={setRowSelected}
      {...props}
    />
  );
}
