import { useEffect, useState } from "react"
import { Col, FormGroup, Input } from "reactstrap"
import { LabelStyled } from "./styled"

export const getRadioBox = (item, setFilterDb?: any) => {
  const [valueDefault, setValueDefault] = useState(false);
  const [value, setValue] = useState(valueDefault);  
  const [search, setSearch] = useState([]);
  const [defaultCols, setDefaultCols] = useState(3);
  const [cols, setCols] = useState(defaultCols);
  
  function handleChange(key: string, value: any) {
    console.log('val', value, key);
    setSearch(prevRegister => ({ ...prevRegister, [key]: value })); 
  }

  useEffect(() => {
    if (item?.cols && item?.cols > 0) {
      setCols(item.cols * defaultCols)
    }
  }, [item])

  // Limpa os campos
  useEffect(() => {
    setValue(valueDefault)    
  }, [item.clearFields])

  return <><Col 
  lg={cols}
  sm={cols}
  md={cols}
  xs={cols}
  style={{ textAlign: "left" }}
>
  <FormGroup check style={{ marginTop: "0px", top: "25px" }}>    
      <Input checked={value} type={item.type} name="radio1" onChange={e => handleChange(item.field, e.target.value)}/>{' '}
      <LabelStyled check>
        {item.name}
      </LabelStyled>
  </FormGroup>
</Col>
{item.uniqueRow && <Col 
  className="m-1"
  lg={cols - 12}
  sm={cols - 12}
  md={cols - 12}
  xs={cols - 12}
  style={{ textAlign: "left" }}
  ></Col>}
  </>
}