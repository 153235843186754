import moment from "moment"
import React, { useEffect, useState } from "react"
import { post } from "src/helpers/api_helper"
import { formatDateTime } from "src/helpers/functions"
import CrudContainer from "./CrudContainer"
import {
  getListManufacturers,
  getProductsFilter,
  getListSubCategorys,
  getNextCrCodeProduct,
  getProductByCode,
  getProducts,
  updateCache,
  updateProduct
} from "./graphqlQuery"

function BfLiberation({ value, column, row }) {
  return (
    <a>
      {value ? (
        <span style={{ color: "green" }}>Sim</span>
      ) : (
        <span style={{ color: "red" }}>Não</span>
      )}
    </a>
  );
}

function BfActive({ value, column, row }) {
  return (
    <a>
      {!value ? (
        <span style={{ color: "green" }}>Sim</span>
      ) : (
        <span style={{ color: "red" }}>Não</span>
      )}
    </a>
  );
}

function WScategory({ value, column, row }) {
  return (
    <>
      {row.original.ws_name_category} » {value}
    </>
  );
}

const Product = () => {
  const [listSubCategories, setListSubCategories] = useState([
    {
      name: "Todos",
      value: "",
    }
  ])
  const [listManufacturers, setListManufacturers] = useState([
    {
      name: "Todos",
      value: "",
    }
  ])
  const [listSituation, setListSituation] = useState([
    {
      name: "Todos",
      value: "",
    },
    {
      name: "Incluído",
      value: "IN",
    },
    {
      name: "Em processamento",
      value: "PR",
    },
    {
      name: "Concluído",
      value: "CO",
    },
    {
      name: "Cancelado",
      value: "CA",
    }
  ])
  const [listSpecialSales, setListSpecialSales] = useState([
    {
      name: "Todos",
      value: "",
    },
  ])
  const columns = React.useMemo(
    () => [
      {
        Header: "Código",
        accessor: "cr_code",
      },
      {
        Header: "Subcategoria",
        accessor: "ws_name_subcategory",
        Cell: WScategory,
        minWidth: 300,
      },
      {
        Header: "Descrição",
        accessor: "ws_commercial_description",
        minWidth: 500,
      },
      {
        Header: "Bloqueado",
        Cell: BfActive,
        accessor: "bf_active",
      },
      {
        Header: "Dest.",
        Cell: BfLiberation,
        accessor: "bf_highlighted",
      },
      {
        Header: "Oferta",
        Cell: BfLiberation,
        accessor: "bf_hot_deal",
      },
      {
        Header: "Novo",
        Cell: BfLiberation,
        accessor: "bf_new_product",
      },
      {
        Header: "Ativo",
        Cell: BfLiberation,
        accessor: "bf_liberation",
      },
      {
        Header: "Preço Esp. Dest.",
        Cell: BfLiberation,
        accessor: "bf_emphasis",
        accessorParent: "qf_price_special",
      },
      {
        Header: "Data Cadastro",
        accessor: "dx_register",
        Cell: ({ value, column, row }) => {
          return moment(value).format(formatDateTime);
        },
      },
      {
        Header: "Ações",
        accessor: "actions",
        Filter: () => <div style={{ height: "10px" }}></div>,
        Cell: cellContent => (
          <div
            style={{
              width: "100%",
              textAlign: "center",
              backgroundColor: "red",
            }}
          >
            {cellContent}
          </div>
        ),
      },
    ],
    []
  );

  // Colunas do filtro customizavel
  const columnsFilterCustom = [
    {
      name: "Código ERP",
      type: "text",
      field: "ox_identification.cr_code_erp", 
      maxLength: 50,
      cols: 1,
    },
    {
      name: "Descrição",
      type: "text",
      field: "ox_register_data.ws_commercial_description", 
      maxLength: 50,
      cols: 3
    },
    {
      name: "Subcategorias",
      type: "select",
      field: "ox_register_data.cr_code_subcategory", 
      cols: 2,
      options: listSubCategories,
    },
    {
      name: "Fabricantes",
      type: "select",
      field: "ox_register_data.cr_code_manufactures", 
      cols: 2,
      options: listManufacturers,
    },   
    {
      name: "Ativo",
      type: "switch",
      field: "ox_identification.bf_liberation", 
      cols: 1,
    }, 
    {
      name: "Disponivel",
      type: "switch",
      field: "ox_identification.bf_active", 
      cols: 1,
    },  
    {
      name: "Destaque",
      type: "switch",
      field: "ox_register_data.bf_highlighted", 
      cols: 1,
    },   
    {
      name: "Oferta",
      type: "switch",
      field: "ox_register_data.bf_hot_deal", 
      cols: 1,
    },   
    {
      name: "Novos",
      type: "switch",
      field: "ox_register_data.bf_new_product", 
      cols: 1,
    },    
    {
      name: "Sem imagem",
      type: "switch",
      field: "ox_register_data.wh_image", 
      cols: 1,
    },  
    {
      name: "Sem texto comp.",
      type: "switch",
      field: "ox_register_data.wt_commercial_text", 
      cols: 1,
    },  
    {
      name: "Sem Subcategoria",
      type: "switch",
      field: "ox_register_data.cr_code_subcategory", 
      cols: 1,
    },          
    // {
    //   name: "Preço Especial Dest.",
    //   type: "switch",
    //   field: "ox_register_data.bf_highlighted", 
    //   cols: 1,
    // },
  ];

  useEffect(() => {
    async function getListSubCategories() {
      let response = await post(
        "/graphql",
        {
          query: getListSubCategorys(),
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      ).then(response => response);
      if (response.data[Object.keys(response.data)[0]]) {
        let temp = [];
        const list = response.data[Object.keys(response.data)[0]];
        list.map(item => {
          temp.push({
            cr_code: item.cr_code,
            ws_value: item.ws_name,
            qi_subgroup: item.qi_subcategories,
          });
        });
        temp = [
          {
            nameList: "ws_subcategories",
            content: temp,
          },
        ];
        let temp2 = [];
        const list2 = temp.filter(item => item.nameList === "ws_subcategories");
        list2.length &&
          list2.length > 0 &&
          list2[0].content.map(item =>
            temp2.push({
              cr_code: item.cr_code,
              ws_value: item.ws_value,
              qi_subgroup: item.qi_subgroup,
            })
          );

        let temp3 = [];

        temp2.map((i) => {
          i.qi_subgroup.map(item => {
            temp3.push({
              value: item.cr_code,
              name: item.ws_name,
              // category: i.ws_value
            })
          })
        })

        setListSubCategories([...temp3]);
      } else {
        alert("Falha ao trazer lista de categorias!");
        setListSubCategories([]);
      }
    }

    async function getListManufacturershandle() {
      let response = await post(
        "/graphql",
        {
          query: getListManufacturers(),
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      ).then(response => {
        let temp = [];
        const list = response.data.getManufacturers;
        list.map(item => {
          temp.push({
            value: item.cr_code,
            name: item.ws_abbreviation,
          });
        });
        setListManufacturers(temp)
      });
    }

    getListManufacturershandle();
    getListSubCategories();
  }, []);

  return (
    <CrudContainer
      routePath=""
      queryGet={getProducts}
      queryGetFilter={getProductsFilter}
      queryGetByCode={getProductByCode}
      querySave={() => {}}
      queryUpdate={updateProduct}
      queryDelete={null}      
      queryNextCrCode={getNextCrCodeProduct}
      queryPutOneField={updateCache}
      title="Cadastro de Produtos | Braile Distribuidora"
      breadcrumbTitle="Produto"
      breadcrumbItem="Cadastro de Produtos"
      columnsTable={columns}
      fieldsCustomFilters={columnsFilterCustom}
    />
  );
};

export default Product;
