import { useEffect, useState } from "react"
import { Col, Input } from "reactstrap"
import { LabelStyled } from "./styled"

export const getDateBoxRange = (item, setFilterDb?: any) => {
  const [search, setSearch] = useState([]);
  const [defaultCols, setDefaultCols] = useState(3);
  const [cols, setCols] = useState(defaultCols);
  const [dateInit, setDateInit] = useState("");
  const [dateEnd, setDateEnd] = useState("");

  function handleChangeInit(key: string, value: any) {
    setSearch(prevRegister => ({ ...prevRegister, [key]: value }));
    setDateInit(value)
  }

  function handleChangeEnd(key: string, value: any) {
    setSearch(prevRegister => ({ ...prevRegister, [key]: value }));
    setDateEnd(value)
  }

  useEffect(() => {
    if (item?.cols && item?.cols > 0) {
      setCols(item.cols * defaultCols)
    }
  }, [item])

  useEffect(() => {
    if(dateInit && dateEnd) {
      setFilterDb(filterDb => { return { ...filterDb, [item.fieldCustom]: {
        $gte: dateInit,
        $lt: dateEnd
      } } })
    }
  }, [dateInit, dateEnd])

  // Limpa os campos
  useEffect(() => {
    setDateInit('')
    setDateEnd('')
  }, [item.clearFields])

  return <><Col
    lg={cols}
    sm={cols}
    md={cols}
    xs={cols}
    style={{ textAlign: "left" }}
  >
    <LabelStyled>{item.name}</LabelStyled>
    <Input
      bsSize="sm"
      type={"date"}
      onChange={e => {
        setDateInit(e.target.value)
        handleChangeInit(item.field, e.target.value)
      }}
      value={dateInit}
    />{" até "}
    <Input
      bsSize="sm"
      type={"date"}
      onChange={e => {
        setDateEnd(e.target.value)
        handleChangeEnd(item.fieldCustom, e.target.value)
      }}
      value={dateEnd}
    />
  </Col>
    {item.uniqueRow && <Col
      className="m-1"
      lg={cols - 12}
      sm={cols - 12}
      md={cols - 12}
      xs={cols - 12}
      style={{ textAlign: "left" }}
    ></Col>}
  </>
}