/* IMPORTANTE: Deve incluir as fun principais do CRUD
 * getData, updateData, deleteData, postData
 * estas chamadas são padrão no CRUD/CrudGroupForm */

export const getProductByCode = (cr_code: string): string => {
  return `{
    getProductByCodeAdmin(cr_code:"${cr_code}") {
      cr_code_erp
      cv_version
      bf_active
      bf_liberation
      cr_code
      ck_registeruser
      dx_register
      dx_modification      
      bf_highlighted
      bf_hot_deal
      bf_new_product
      qs_buy_too
      qs_bundle
      cr_fiscal_classification
      cr_code_manufactures
      cr_code_subcategory
      dt_validate_new
      ws_commercial_description
      wu_unit_measure
      ws_part_number
      dc_gross_weight
      dc_inventory_balance
      dc_percent_ipi
      nb_tax_code_source
      ws_name_category
      ws_name_subcategory
      dc_price_sale_cash
      dc_price_sale_rs
      dc_price_sale_sc
      dc_price_sale_cartucho
      dc_price_sale_pr
      wt_commercial_text
      bf_bundle
      ws_name_manufactures
      bf_discount_percentage
      nc_discount_percentage
      wh_image
      wl_image_list
      ws_gross_weight
      ws_percent_ipi
      bf_highlighted_card_top
      bf_highlighted_card_horizontal_showcase
      bf_highlighted_card_horizontal_showcase_best_offer
      qf_price_special{
        dc_price
        wr_price_currency
        sl_price_currency
        sl_price_description
        dc_price_format
        dt_start_period
        dt_end_period
        bf_emphasis
      }
    }
  }`;
};

export const getProducts = (
  filter: string = "{}",
  limit: Number = 10,
  random: Boolean = false,
  skip: Number = 0,
  sort: String = "{}"
): string => {
  let filterIsNew = JSON.stringify("{\"ox_register_data.bf_new_product\":true}");

  let filterJson = JSON.parse(filter);
  let filterText = '';
  if (filterJson.$text && filterJson.$text.$search) {
    filterText = JSON.stringify(filterJson.$text.$search);
  }

  let filterIsNewAndSearch = JSON.stringify(`{"$text":{"$search":${filterText}},"ox_register_data.bf_new_product":true}`);

  return `{
    getProductPaginationAdmin(filter: {
      filter: ${filter === "{}" ? filterIsNew : filterIsNewAndSearch},
      sort: "${sort}",
      skip: ${skip},  
      limit: ${limit},                      
      random: ${random},
    }) { 
      result {
        cr_code_erp
        cv_version
        bf_active
        bf_liberation
        cr_code
        ck_registeruser
        dx_register
        dx_modification
        bf_highlighted
        bf_hot_deal
        bf_new_product
        qs_buy_too
        qs_bundle
        cr_code_manufactures
        ws_name_manufactures
        cr_fiscal_classification
        cr_code_category
        cr_code_subcategory
        dt_validate_new
        ws_commercial_description
        wu_unit_measure
        ws_part_number
        dc_gross_weight
        dc_inventory_balance
        dc_percent_ipi
        nb_tax_code_source
        wt_commercial_text        
        wh_image
        ws_name_category
        ws_name_subcategory
        ws_gross_weight
        ws_percent_ipi
        qs_code_tags
        qf_price_special{
          dc_price
          wr_price_currency
          sl_price_currency
          sl_price_description
          dc_price_format
          dt_start_period
          dt_end_period
          bf_emphasis
        }
      }
      rows
      rowsall
    }
  }`;
};

export const deleteProduct = (cr_code: string): string => {
  return `query {
    deleteProductByCode(cr_code:"${cr_code}") {
      message
    }
  }`;
};

export const updateProduct = (data: {
  cr_code: String;
  dt_validate_new: String;
  // ws_name_subcategory: String;
  qs_code_tags: String[];
}): string => {
  return `mutation {
    updateProduct(
      cr_code: "${data.cr_code}", 
      dt_validate_new: "${data.dt_validate_new}", 
      qs_code_tags: [${data.qs_code_tags.map(subcategoryCode => { return `"${subcategoryCode}"` })}], 
    ){
  	message
   }
  }`;
};

export const getNextCrCodeProduct = (): string => {
  return `query {
    getNextCrCodeProduct {
      result
    }
  }`;
};

export const getListSubCategorys = (): string => {
  return `query {
    getLayoutFilterCategory {
      cr_code
      ws_name
      qi_subcategories {
        cr_code
        ws_name
        nb_products
      }
      nb_products
    }
  }`;
};

export const updateCache = (cr_code: string): string => {
  return `query {
    updateCacheProducts(cr_code_list: ["${cr_code}"]) {
      message
    }
  }`;
};
