import { typeCrudListColumns } from "src/components/Common/typeCridList"
import BraileCrudContainer from "src/components/CRUD/CrudContainer"
import CrudFormGroup from "../CrudFormGroup"
import CrudList from "../CrudList"

export default function index(props: {
  routePath: string;
  queryGet: any;
  queryGetFilter?: any;
  queryGetByCode: any;
  querySave: any;
  queryUpdate: any;
  queryDelete: any;
  queryNextCrCode: any;
  queryPutOneField: any;
  title: string;
  breadcrumbTitle: string;
  breadcrumbItem: string;
  columnsTable: Array<typeCrudListColumns>;
  fieldsCustomFilters?: any;
}) {
  return (
    <BraileCrudContainer
      CrudFormGroup={CrudFormGroup}
      CrudList={CrudList}
      fieldsCustomFilters={props.fieldsCustomFilters}
      {...props}
    />
  );
}
