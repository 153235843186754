/* IMPORTANTE: Deve incluir as fun principais do CRUD
 * getData, updateData, deleteData, postData
 * estas chamadas são padrão no CRUD/CrudGroupForm */

export const getPopupByCode = (cr_code: string): string => {
  return `{
    getPopupByCode(cr_code:"${cr_code}") {
      cv_version
      bf_active
      bf_liberation
      cr_code
      ck_registeruser
      dx_register
      dx_modification
      ws_title
      wh_image
      hl_link
      nb_time
    }
  }`;
};

export const getPopups = (): string => {
  return `query {
    getPopupsAdmin {
      cr_code
      ws_title
      wh_image
      hl_link
      nb_time
      bf_liberation
      dx_register
    }
  }`;
};

export const deletePopup = (cr_code: string): string => {
  return `query {
    deletePopupByCode(cr_code:"${cr_code}") {
      message
    }
  }`;
};

export const updatePopup = (data: {
  bf_liberation: Boolean;
  cr_code: String;
  wh_image: String;
  ws_title: String;
  hl_link: String;
  nb_time: Number;
}): string => {
  return `mutation {
    updatePopup(
      bf_liberation: ${data.bf_liberation},
      cr_code: "${data.cr_code ? data.cr_code : ""}",
      wh_image: "${data.wh_image ? data.wh_image : ""}",
      ws_title: "${data.ws_title ? data.ws_title : ""}",
      hl_link: "${data.hl_link ? data.hl_link : ""}",
      nb_time: ${data.nb_time ? data.nb_time : 0},
   ){
  	message
   }
  }`;
};

export const insertPopup = (data: {
  bf_liberation: Boolean;
  cr_code: String;
  wh_image: String;
  ws_title: String;
  hl_link: String;
  nb_time: Number;
}): string => {
  return `mutation {
    insertPopup(
      bf_liberation: ${data.bf_liberation},
      cr_code: "${data.cr_code ? data.cr_code : ""}",
      wh_image: "${data.wh_image ? data.wh_image : ""}",
      ws_title: "${data.ws_title ? data.ws_title : ""}",
      hl_link: "${data.hl_link ? data.hl_link : ""}",
      nb_time: ${data.nb_time ? data.nb_time : 0},
   ){
  	message
   }
  }`;
};

export const getNextCrCodePopup = (): string => {
  return `query {
    getNextCrCodePopup {
      result
    }
  }`;
};
