import React, { useEffect, useState } from "react"
import BootstrapTable from "react-bootstrap-table-next"
import cellEditFactory, { Type } from "react-bootstrap-table2-editor"
import paginationFactory from "react-bootstrap-table2-paginator"
import { Search } from "react-bootstrap-table2-toolkit"
import {
    Button,
    Card,
    CardBody,
    CardTitle,
    Col,
    Row,
    Spinner
} from "reactstrap"
import AvFieldBraile from "src/components/general/Input/AvFieldBraile";
import { post } from "src/helpers/api_helper"
import { getListSubCategorys } from "src/pages/ProductRegistration/graphqlQuery"
import {
    deleteDayItem,
    getDayItem,
    getDayItensPaginationController,
    getListManufacturers,
    getProducts,
    insertDayItens,
    updateDayItem
} from "../../graphqlQuery"
import { BoxDayItens, BoxSpinner, Message } from "./styled"
import "./table.css"
let APPLY_DISCOUNT_CASH = 1.5;

type dayItem = {
  cr_code_product: String;
  cr_code_day: String;
  dc_day_price_sale_site: String;
  dc_day_price_sale_site_forward: string;
  bf_price_site_automatic: boolean;
  bf_price_site_forward_automatic: boolean;
}

export const FormDayItens = (props: { dataFormDb?: any, setRefresh?: any }) => {
  const [dataSelectProduct, setDataSelectProduct] = useState([]);
  const [dataDaySelecteds, setDataDaySelecteds] = useState([]);
  const [loadingSelectProduct, setLoadingSelectProduct] = useState(false);
  const [loadingDaySelecteds, setLoadingDaySelecteds] = useState(false);
  const [loadingDayItensInsert, setLoadingDayItensInsert] = useState(false);
  const [loadingDayItensUpdate, setLoadingDayItensUpdate] = useState(false);
  const [search, setSearch] = useState("");
  const [limit, setLimit] = React.useState(10);
  const [skip, setSkip] = React.useState(0);
  const [limitIncludeds, setLimitIncludeds] = React.useState(10);
  const [skipIncludeds, setSkipIncludeds] = React.useState(0);
  const [listManufacturers, setListManufacturers] = React.useState([]);
  const [listSubcategories, setListSubcategories] = React.useState([]);
  const [manufacturerSelected, setManufacturerSelected] = React.useState("");
  const [subcategorySelected, setSubcategorySelected] = React.useState("");
  const [searchValue, setSearchValue] = React.useState("");
  const [pageSearch, setPageSearch] = useState(1);
  const [countSearch, setCountSearch] = useState(0);
  const [sizePerPageSearch, setSizePerPageSearch] = useState(10);
  const [pageIncludeds, setPageIncludeds] = useState(1);
  const [countIncludeds, setCountIncludeds] = useState(0);
  const [sizePerPageIncludeds, setSizePerPageIncludeds] = useState(10);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchIncluds, setSearchIncluds] = useState("");

  const { dataFormDb } = props;
  let listSelectedsProducts = [];
  let listDaySelects = [];


  function formatPrice(value) {
    var v = value.replace(/\D/g, '');
    v = (v / 100).toFixed(2) + '';
    v = v.replace(".", ",");
    v = v.replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,");
    v = v.replace(/(\d)(\d{3}),/g, "$1.$2,");
    return v
  }

  const removeProduct = (list, cr_code) =>
    list.filter(l => l.cr_code !== cr_code);

  const columnsSelectProduct = [
    {
      dataField: "cr_code",
      text: "Código",
      sort: true,
    },
    {
      dataField: "ws_commercial_description",
      text: "Produto",
      sort: true,
      // style: { maxWidth: '350px' },
      formatter: (cell, row) => <div style={{ whiteSpace: 'normal' }}>{cell ? cell : <b>'PRODUTO NÃO ENCONTRADO!'</b>}</div>
    },
    {
      dataField: "dc_inventory_balance" || "bf_active" || "bf_liberation",
      text: "Status",
      sort: true,
      headerAlign: 'center',
      formatter: (cell, row) => <div style={{ textAlign: 'center', color: row.dc_inventory_balance === "0" || row.bf_active === false || row.bf_liberation === false ? 'red' : 'green' }}>{row.bf_liberation === false ? 'Bloqueado' : row.bf_active === false ? 'Inativo' : row.dc_inventory_balance === "0" ? 'Sem estoque' : 'Liberado'}</div>
    },
    {
      dataField: "dc_day_price_sale_site_forward",
      text: "Venda Especial",
      sort: true,
      headerAlign: 'right',
      formatter: (cell, row) => <div style={{ textAlign: 'right' }}>{cell}</div>
      // headerStyle: { backgroundColor: 'green' }
      // headerAlign: 'right'
    },
    {
      dataField: "qi_price",
      text: "Valor à prazo por estado",
      sort: true,
      headerAlign: 'right',
      formatter: (cell, row) => <div style={{ textAlign: 'right' }}>{cell.length > 0 ? cell[0].dc_price_format : '0,00'}</div>
    },
    // {
    //   dataField: "qi_price",
    //   text: "Valor do sistema (à vista)",
    //   sort: true,
    //   headerAlign: 'right',
    //   formatter: (cell, row) => <div style={{ textAlign: 'right' }}>{cell.length > 0 ? cell[0].dc_price_format : '0,00'}</div>
    // },
  ];

  const columnsDaySelecteds = [
    {
      dataField: "ox_product.cr_code",
      text: "Código",
      sort: true,
      editable: false,
    },
    {
      dataField: "ox_product.ws_commercial_description",
      text: "Produto",
      sort: true,
      editable: false,
      // style: { maxWidth: '350px' },
      formatter: (cell, row) => <div style={{ whiteSpace: 'normal' }}>{cell ? cell : <b>'PRODUTO NÃO ENCONTRADO!'</b>}</div>
    },
    {
      dataField: "dc_day_price_sale_site",
      text: "Venda à vista",
      sort: true,
      editable: true,
      headerAlign: 'right',
      formatter: (cell, row) => <div style={{ textAlign: 'right' }}>{cell ? formatPrice(cell) : '0,00'}</div>,
      // editable: (cell, row, rowIndex, colIndex) => {
      //   // return true or false;
      //   return true
      // }
    },
    {
      dataField: "dc_day_price_sale_site_forward",
      text: "Venda à prazo",
      sort: true,
      editable: true,
      headerAlign: 'right',
      // headerStyle: { maxWidth: '80px' },
      formatter: (cell, row) => <div style={{ textAlign: 'right' }}>{cell ? formatPrice(cell) : '0,00'}</div>
    },
    {
      dataField: "bf_price_site_automatic",
      text: "Valor à vista automático",
      sort: true,
      editable: true,
      headerAlign: 'right',
      editor: {
        type: Type.CHECKBOX,
        value: 'true:false'
      },
      // headerStyle: { maxWidth: '80px' },
      formatter: (cell, row) => <div style={{ textAlign: 'center' }}>
        {cell ? 'Sim' : 'Não'}
      </div>
    },
    {
      dataField: "bf_price_site_forward_automatic",
      text: "Valor à prazo automático",
      sort: true,
      editable: true,
      headerAlign: 'right',
      editor: {
        type: Type.CHECKBOX,
        value: 'true:false'
      },
      // headerStyle: { maxWidth: '80px' },
      formatter: (cell, row) => <div style={{ textAlign: 'center' }}>
        {cell ? 'Sim' : 'Não'}
      </div>
    },
  ];

  const defaultSorted: any = [
    {
      dataField: "cr_code",
      order: "asc",
    },
  ];

  const handleOnSelectDelete = (row, isSelect) => {
    if (isSelect) {
      listDaySelects.push(row);
    } else {
      listDaySelects = removeProduct(listDaySelects, row.cr_code);
    }
  };

  const handleOnSelectAllDelete = (isSelect, rows) => {
    rows.map(row => {
      if (isSelect) {
        listDaySelects.push(row);
      } else {
        listDaySelects = removeProduct(listDaySelects, row.cr_code);
      }
    })
  };

  const handleOnSelect = (row, isSelect) => {
    if (isSelect) {
      listSelectedsProducts.push(row);
    } else {
      listSelectedsProducts = removeProduct(listSelectedsProducts, row.cr_code);
    }
  };

  const handleOnSelectAll = (isSelect, rows) => {
    rows.map(row => {
      if (isSelect) {
        listSelectedsProducts.push(row);
      } else {
        listSelectedsProducts = removeProduct(listSelectedsProducts, row.cr_code);
      }
    })
  };

  const handleDeleteItensDay = () => {
    if (listDaySelects.length > 0) {
      listDaySelects.map(product => {
        // Deleta local

        // Insere no servidor
        deleteDayItemGraphql({
          cr_code_product: product.cr_code_product,
          cr_code_day: dataFormDb.cr_code,
        });
      });
      setTimeout(() => {
        getDataCrudGraphql();
      }, 1000);
    } else {
      alert('Selecione um produto para excluir!');
    }
  };

  const handleInsertItensDay = () => {
    let dayPriceSaleSite = 0;
    let dayPriceSaleSiteForward = 0;
    if (listSelectedsProducts.length > 0) {
      listSelectedsProducts.map(product => {

        // Valida se veio os valores corretamente
        if (!product || product.qi_price.length <= 0) {
          alert("Falha no carregamento do produto!")
          return
        }

        //verifica se o produto já está na lista
        if (dataDaySelecteds.filter(item => item.cr_code_product === product.cr_code).length > 0) {
          alert("Produto já está na lista!")
          return
        }

        if (product.bf_active === false) {
          alert(`Produto ${product.cr_code} inativo! Remova-o da lista para adicionar os demais!`)
          return
        }

        if (product.bf_liberation === false) {
          alert(`Produto ${product.cr_code} bloqueado! Remova-o da lista para adicionar os demais!`)
          return
        }

        if (Number(product.dc_inventory_balance) <= 0) {
          alert(`Produto ${product.cr_code} sem estoque! Remova-o da lista para adicionar os demais!`)
          return
        }

        // Calcula o diferimento do produto se tiver, antes do desconto do day
        if (dataFormDb.sl_uf === "RS" && product.bf_discount_percentage === true && Number(product.nc_discount_percentage) > 0) {
          dayPriceSaleSite = product.qi_price[0].dc_price - Math.round(product.qi_price[0].dc_price * product.nc_discount_percentage) / 100
          dayPriceSaleSiteForward = product.qi_price[0].dc_price - Math.round(product.qi_price[0].dc_price * product.nc_discount_percentage) / 100
          dayPriceSaleSite = dayPriceSaleSite -
            Math.round(dayPriceSaleSite * APPLY_DISCOUNT_CASH) / 100;
        } else {
          dayPriceSaleSite = product.qi_price[0].dc_price
          dayPriceSaleSiteForward = product.qi_price[0].dc_price
          dayPriceSaleSite = dayPriceSaleSite -
            Math.round(dayPriceSaleSite * APPLY_DISCOUNT_CASH) / 100;
        }

        // // Insere local
        // setDataDaySelecteds(dataDaySelecteds => [
        //   {
        //     "ox_product.cr_code": product.cr_code,
        //     "ox_product.ws_commercial_description":
        //       product.ws_commercial_description,
        //     dc_day_price_sale_site: String(Number(dayPriceSaleSite - Math.round(dayPriceSaleSite * dataFormDb.nc_percentage_discount) / 100).toFixed(2)),
        //     dc_day_price_cartridge_10d: "0,00",
        //     dc_day_price_sale_site_forward: String(Number(dayPriceSaleSiteForward - Math.round(dayPriceSaleSiteForward * dataFormDb.nc_percentage_discount) / 100).toFixed(2)),
        //     bf_price_site_automatic: true,
        //     bf_price_site_forward_automatic: true,
        //   },
        //   ...dataDaySelecteds,
        // ]);

        // Insere no servidor
        insertDayItensGraphql({
          ck_registeruser: "Administrador",
          cr_code_product: product.cr_code,
          cr_code_day: dataFormDb.cr_code,
          dc_day_price_sale_site: String(Number(dayPriceSaleSite - Math.round(dayPriceSaleSite * dataFormDb.nc_percentage_discount) / 100).toFixed(2)),
          dc_day_price_cartridge_10d: "0",
          dc_day_price_sale_site_forward: String(Number(dayPriceSaleSiteForward - Math.round(dayPriceSaleSiteForward * dataFormDb.nc_percentage_discount) / 100).toFixed(2)),
          bf_price_site_automatic: true,
          bf_price_site_forward_automatic: true,
        });
      });
      // searchProductPaginationGraphql();
      setTimeout(() => {
        getDataCrudGraphql();
      }, 1000);
    } else {
      alert('Selecione um produto para inserir!');
    }
  };

  const handleUpdateItemDay = (value, row, column) => {
    let dc_day_price_sale_site = column.dataField === 'dc_day_price_sale_site' ? value.trim() : row.dc_day_price_sale_site ? row.dc_day_price_sale_site : row["dc_day_price_sale_site"];
    let dc_day_price_sale_site_forward = column.dataField === 'dc_day_price_sale_site_forward' ? value.trim() : row.dc_day_price_sale_site_forward ? row.dc_day_price_sale_site_forward : row["dc_day_price_sale_site_forward"];

    // Verifica se a coluna alterada é A PRAZO, se sim, reaclcula o valor A VISTA com -1.5% do novo valor A PRAZO
    if (column.dataField === 'dc_day_price_sale_site_forward') {
      const dayPriceSaleSite = Number(dc_day_price_sale_site_forward.replace(",", ".")) -
        Math.round(Number(dc_day_price_sale_site_forward.replace(",", ".")) * APPLY_DISCOUNT_CASH) / 100;
      dc_day_price_sale_site = String(dayPriceSaleSite)
    }

    // Prepara o item que vai ser alterado
    const dayItem = {
      cr_code_product: row.ox_product && row.ox_product.cr_code ? row.ox_product.cr_code : row["ox_product.cr_code"],
      cr_code_day: dataFormDb.cr_code,
      dc_day_price_sale_site: dc_day_price_sale_site,
      dc_day_price_sale_site_forward: dc_day_price_sale_site_forward,
      bf_price_site_automatic: column.dataField === 'dc_day_price_sale_site' ? false : row.bf_price_site_automatic ? row.bf_price_site_automatic : row["bf_price_site_automatic"],
      bf_price_site_forward_automatic: column.dataField === 'dc_day_price_sale_site_forward' ? false : row.bf_price_site_forward_automatic ? row.bf_price_site_forward_automatic : row["bf_price_site_forward_automatic"],
    };

    // Envia os dados salvando
    updateDayItemGraphql(dayItem);
  };

  // Select All Button operation
  const selectRow: any = {
    mode: "checkbox",
    clickToSelect: true,
    onSelect: handleOnSelect,
    onSelectAll: handleOnSelectAll,
  };

  const selectRowDay: any = {
    mode: "checkbox",
    clickToSelect: false,
    onSelect: handleOnSelectDelete,
    onSelectAll: handleOnSelectAllDelete,
  };

  const { SearchBar } = Search;

  // Traz a lista de itens do day
  const getDataCrudGraphql = React.useCallback(async (skip = 0, limit = 10) => {
    setLoadingDaySelecteds(true);
    if (!getDayItem) return;
    try {
      let response = await post(
        "/graphql",
        {
          query: getDayItensPaginationController(
              searchIncluds ?  `{"ox_register_data.cr_code_product":"${searchIncluds}", "ox_register_data.cr_code_day":"${dataFormDb.cr_code}"}` : `{"ox_register_data.cr_code_day":"${dataFormDb.cr_code}"}`,
              limit,
              false,
              skip,
              "{}"
          ),
        },
        {
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
        .then(response => response)
        .catch(error => {
          setLoadingDaySelecteds(false);
        });
      if (
        response &&
        response.data &&
        response.data[Object.keys(response.data)[0]]
      ) {
        const rowsall = response.data[Object.keys(response.data)[0]].result
          ? response.data[Object.keys(response.data)[0]].rowsall
          : 0;
          
        setCountIncludeds(rowsall)
        setDataDaySelecteds(
          response.data[Object.keys(response.data)[0]].result
            ? response.data[Object.keys(response.data)[0]].result
            : response.data[Object.keys(response.data)[0]]
        );
      }
    } catch (e) {
      setDataDaySelecteds([]);
      alert(`Falha ao buscar lista!`);
    } finally {
      setLoadingDaySelecteds(false);
    }
  }, [getDayItem, searchIncluds]);

  // Search de produto da busca para inserir
  const searchProductPaginationGraphql = React.useCallback(
    async (filter = search, pageSize = limit, sort = "{}") => {
      setLoadingSelectProduct(true);
      let tempPageCount = 0;
      if (!getProducts) return;
      try {
        let response = await post(
          "/graphql",
          {
            query: getProducts(
              filter,
              pageSize,
              false,
              skip,
              sort,
              dataFormDb && dataFormDb.sl_uf ? dataFormDb.sl_uf : null
            ),
          },
          {
            headers: {
              "Content-Type": "application/json; charset=utf-8",
              "Access-Control-Allow-Origin": "*",
            },
          }
        )
          .then(response => response)
          .catch(error => {
            setLoadingSelectProduct(false);
          });
        if (
          response &&
          response.data &&
          response.data[Object.keys(response.data)[0]]
        ) {
          const rowsall = response.data[Object.keys(response.data)[0]].result
            ? response.data[Object.keys(response.data)[0]].rowsall
            : 0;          
          setCountSearch(rowsall)
          // mergeCollumnsRows(response.data[Object.keys(response.data)[0]]);
          setDataSelectProduct(
            response.data[Object.keys(response.data)[0]].result
              ? response.data[Object.keys(response.data)[0]].result
              : response.data[Object.keys(response.data)[0]]
          );
        } else {
          setDataSelectProduct([]);
        }
      } catch (e) {
        setDataSelectProduct([]);
        alert(`Falha na busca de produto!`);
      } finally {
        setLoadingSelectProduct(false);
      }
    },
    [getProducts, skip, limit]
  );

  useEffect(() => {
    if (dataSelectProduct.length > 0) {
      setLoading(false);
      console.log('aqui data l false', dataSelectProduct)

    } else {
      setLoading(true);
      console.log('aqui data l true', dataSelectProduct)

    }
  }, [dataSelectProduct])

  function resetParamsPagination() {
    setSkip(0);
    setLimit(10);
    setSizePerPageSearch(10)
    setPageSearch(1);
    setDataSelectProduct([]);
  }

  useEffect(() => {
    resetParamsPagination();
  }, [manufacturerSelected, subcategorySelected]);

  // Search de produto para atualizar os produtos na lista de selecionados
  const searchProductSelectedPaginationGraphql = React.useCallback(
    async (filter = search, pageSize = limit, skip = 0, sort = "{}"): Promise<any | null> => {
      setLoadingSelectProduct(true);
      let tempPageCount = 0;
      if (!getProducts) return;
      try {
        let response = await post(
          "/graphql",
          {
            query: getProducts(
              filter,
              pageSize,
              false,
              skip,
              sort,
              dataFormDb && dataFormDb.sl_uf ? dataFormDb.sl_uf : null
            ),
          },
          {
            headers: {
              "Content-Type": "application/json; charset=utf-8",
              "Access-Control-Allow-Origin": "*",
            },
          }
        )
          .then(response => response)
          .catch(error => {
            setLoadingSelectProduct(false);
          });
        if (
          response &&
          response.data &&
          response.data[Object.keys(response.data)[0]]
        ) {
          // console.log(888, response.data[Object.keys(response.data)[0]].result[0]);
          // onEditElement(response.data[Object.keys(response.data)[0]].result[0].cr_code, response.data[Object.keys(response.data)[0]].result[0]);
          let itens: any | null = response.data[Object.keys(response.data)[0]].result[0]
          return itens;
        } else {
          // setDataSelectProduct([]);
        }
      } catch (e) {
        // setDataSelectProduct([]);
        alert(`Falha na busca de produto!`);
        return null
      } finally {
        setLoadingSelectProduct(false);
      }
    },
    []
  );

  // Edia um elemento da lista de itens do day selecionados
  const onEditElement = (elementId, newValue) => {
    let elements = [...dataDaySelecteds];
    let currentElementIndex = elements.findIndex((x) => x.cr_code === elementId);
    if (currentElementIndex === -1) return;
    elements[currentElementIndex] = newValue;
    // if you do elements[currentElementIndex].value = ... your data array will be mutated. then its better you do the way above
    setDataDaySelecteds(elements);
  };

  // Insert de produto no Day
  const insertDayItensGraphql = async (body: {
    ck_registeruser: string;
    cr_code_product: string;
    cr_code_day: string;
    dc_day_price_sale_site: string;
    dc_day_price_cartridge_10d: string;
    dc_day_price_sale_site_forward: string;
    bf_price_site_automatic: boolean;
    bf_price_site_forward_automatic: boolean;
  }) => {
    setLoadingDayItensInsert(true);
    try {
      let response = await post(
        "/graphql",
        {
          query: insertDayItens(body),
        },
        {
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
        .then(response => response)
        .catch(error => {
          setLoadingDayItensInsert(false);
        });
      if (
        response &&
        response.data &&
        response.data[Object.keys(response.data)[0]]
      ) {
      }
    } catch (e) {
      alert(`Falha na inserir produto no Day!`);
    } finally {
      setLoadingDayItensInsert(false);
    }
  };

  // Atualiza um produto no Day
  const updateDayItemGraphql = async (body: dayItem) => {
    setLoadingDayItensInsert(true);
    try {
      let response = await post(
        "/graphql",
        {
          query: updateDayItem(body),
        },
        {
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
        .then(response => response)
        .catch(error => {
          setLoadingDayItensInsert(false);
        });
      if (
        response &&
        response.data &&
        response.data[Object.keys(response.data)[0]]
      ) {
        // alert(response.data[Object.keys(response.data)[0]].message);
      }
    } catch (e) {
      alert(`Falha ao atualizar produto no Day!`);
    } finally {
      setLoadingDayItensInsert(false);
      // props.setRefresh(false)
    }
  };

  // Deleta um produto no Day
  const deleteDayItemGraphql = async (body: {
    cr_code_product: String;
    cr_code_day: String;
  }) => {
    setLoadingDayItensInsert(true);
    try {
      let response = await post(
        "/graphql",
        {
          query: deleteDayItem(body),
        },
        {
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
        .then(response => response)
        .catch(error => {
          setLoadingDayItensInsert(false);
        });
      if (
        response &&
        response.data &&
        response.data[Object.keys(response.data)[0]]
      ) {
        // alert(response.data[Object.keys(response.data)[0]].message);
      }
    } catch (e) {
      alert(`Falha ao atualizar produto no Day!`);
    } finally {
      setLoadingDayItensInsert(false);
    }
  };

  // Verifica qual coluna foi editada para realizar uma ação
  const getCollumnEditable = async (newValue, collumn, row, cr_code) => {
    let resultItemDay: any | null = null;
    let dayPriceSaleSite = 0;
    let dayPriceSaleSiteForward = 0;

    // Busca um produto
    resultItemDay = await searchProductSelectedPaginationGraphql('{"ox_identification.cr_code": ' + JSON.stringify(cr_code) + '}')

    // Calcula o diferimento do produto se tiver, antes do desconto do day
    if (dataFormDb.sl_uf === "RS" && resultItemDay.bf_discount_percentage === true && Number(resultItemDay.nc_discount_percentage) > 0) {
      dayPriceSaleSite = resultItemDay.qi_price[0].dc_price - Math.round(resultItemDay.qi_price[0].dc_price * resultItemDay.nc_discount_percentage) / 100
      dayPriceSaleSiteForward = resultItemDay.qi_price[0].dc_price - Math.round(resultItemDay.qi_price[0].dc_price * resultItemDay.nc_discount_percentage) / 100
    } else {
      dayPriceSaleSite = resultItemDay.qi_price[0].dc_price
      dayPriceSaleSiteForward = resultItemDay.qi_price[0].dc_price
    }

    // Verifica qual coluna foi alterada 
    switch (collumn.dataField) {
      case "bf_price_site_automatic":
        if (!newValue) return;
        if (resultItemDay) {
          await updateDayItemGraphql({
            cr_code_product: row.cr_code_product ? row.cr_code_product : row["cr_code_product"],
            cr_code_day: row.cr_code_day ? row.cr_code_day : row["cr_code_day"],
            dc_day_price_sale_site: String(Number(dayPriceSaleSite - Math.round(dayPriceSaleSite * dataFormDb.nc_percentage_discount) / 100).toFixed(2)),
            dc_day_price_sale_site_forward: row.dc_day_price_sale_site_forward ? row.dc_day_price_sale_site_forward : row["dc_day_price_sale_site_forward"],
            bf_price_site_automatic: true,
            bf_price_site_forward_automatic: row.bf_price_site_forward_automatic ? row.bf_price_site_forward_automatic : row["bf_price_site_forward_automatic"],
          })
          getDataCrudGraphql();
        }
        break;
      case "bf_price_site_forward_automatic":
        if (!newValue) return;
        if (resultItemDay) {
          await updateDayItemGraphql({
            cr_code_product: row.cr_code_product ? row.cr_code_product : row["cr_code_product"],
            cr_code_day: row.cr_code_day ? row.cr_code_day : row["cr_code_day"],
            dc_day_price_sale_site: row.dc_day_price_sale_site ? row.dc_day_price_sale_site : row["dc_day_price_sale_site"],
            dc_day_price_sale_site_forward: String(Number(dayPriceSaleSiteForward - Math.round(dayPriceSaleSiteForward * dataFormDb.nc_percentage_discount) / 100).toFixed(2)),
            bf_price_site_automatic: row.bf_price_site_automatic ? row.bf_price_site_automatic : row["bf_price_site_automatic"],
            bf_price_site_forward_automatic: true,
          })
          getDataCrudGraphql();
        }
        break;
      case "dc_day_price_sale_site":
        if (!newValue) return;
        if (resultItemDay) {
          await updateDayItemGraphql({
            cr_code_product: row.cr_code_product ? row.cr_code_product : row["cr_code_product"],
            cr_code_day: row.cr_code_day ? row.cr_code_day : row["cr_code_day"],
            dc_day_price_sale_site: newValue,
            dc_day_price_sale_site_forward: row.dc_day_price_sale_site_forward ? row.dc_day_price_sale_site_forward : row['dc_day_price_sale_site_forward'],
            bf_price_site_automatic: false,
            bf_price_site_forward_automatic: row.bf_price_site_forward_automatic ? row.bf_price_site_forward_automatic : row["bf_price_site_forward_automatic"],
          })
          getDataCrudGraphql();
        }
        break;
      case "dc_day_price_sale_site_forward":
        if (!newValue) return;
        if (resultItemDay) {
          // Sendo A PRAZO, reaclcula o valor A VISTA com -1.5% do novo valor A PRAZO          
          let valueTempStr = String(newValue).replace(",", ".");
          let valueTemp = Number(valueTempStr);
          valueTemp = Number(valueTemp) - Math.round(Number(valueTemp) * APPLY_DISCOUNT_CASH) / 100;
          // Envia os novos dados
          await updateDayItemGraphql({
            cr_code_product: row.cr_code_product ? row.cr_code_product : row["cr_code_product"],
            cr_code_day: row.cr_code_day ? row.cr_code_day : row["cr_code_day"],
            dc_day_price_sale_site: String(valueTemp).replace(",", "."),
            dc_day_price_sale_site_forward: newValue,
            bf_price_site_automatic: row.bf_price_site_automatic ? row.bf_price_site_automatic : row["bf_price_site_automatic"],
            bf_price_site_forward_automatic: false,
          })
          getDataCrudGraphql();
        }
        break;
      default:
        break;
    }
  }

  React.useEffect(() => {
    async function getListManufacturershandle() {
      let response = await post(
        "/graphql",
        {
          query: getListManufacturers(),
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      ).then(response => setListManufacturers(response.data.getManufacturers));
    }
    getListManufacturershandle();
  }, []);

  React.useEffect(() => {
    async function getListCategory() {
      let response = await post(
        "/graphql",
        {
          query: getListSubCategorys(),
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      ).then(response => response);
      if (response.data[Object.keys(response.data)[0]]) {
        let temp = [];
        const list = response.data[Object.keys(response.data)[0]];
        list.map(item => {
          temp.push({
            cr_code: item.cr_code,
            ws_value: item.ws_name,
            qi_subgroup: item.qi_subcategories,
          });
        });
        temp = [
          {
            nameList: "ws_subcategories",
            content: temp,
          },
        ];
        let temp2 = [];
        const list2 = temp.filter(item => item.nameList === "ws_subcategories");
        list2.length &&
          list2.length > 0 &&
          list2[0].content.map(item =>
            temp2.push({
              cr_code: item.cr_code,
              ws_value: item.ws_value,
              qi_subgroup: item.qi_subgroup,
            })
          );

        let temp3 = [];
        temp2.map(i => i.qi_subgroup.map(i => i.ws_name).map(i => temp3.push(i)));

        setListSubcategories(temp3);
      } else {
        alert("Falha ao trazer lista de categorias!");
        setListSubcategories([]);
      }
    }
    getListCategory();
  }, []);

  useEffect(() => {
    getDataCrudGraphql();
  }, [searchIncluds]);

  useEffect(() => {
    onkeydown = e => {
      if (e.keyCode === 13) {
        e.preventDefault();
      }
    };
  }, []);

  useEffect(() => {
    let searchTemp = search
    if (search === '""') searchTemp = ""
    if (searchTemp && !manufacturerSelected && !subcategorySelected) {
      searchProductPaginationGraphql(
        `{"$text":{"$search":${JSON.stringify(searchTemp)}}}`
      );
    } else if (searchTemp && manufacturerSelected && !subcategorySelected) {
      searchProductPaginationGraphql(
        `{"$text":{"$search":${JSON.stringify(searchTemp)}}, "ox_register_data.cr_code_manufactures": ${JSON.stringify(manufacturerSelected)}}`
      );
    } else if (searchTemp && !manufacturerSelected && subcategorySelected) {
      searchProductPaginationGraphql(
        `{"$text":{"$search":${JSON.stringify(searchTemp)}}, "ox_register_data.ws_name_subcategory": ${JSON.stringify(subcategorySelected)}}`
      );
    } else if (searchTemp && manufacturerSelected && subcategorySelected) {
      `{"$text":{"$search":${JSON.stringify(searchTemp)}}, "ox_register_data.cr_code_manufactures": ${JSON.stringify(manufacturerSelected)}, "ox_register_data.ws_name_subcategory": ${JSON.stringify(subcategorySelected)}}`
    } else if (!searchTemp && manufacturerSelected && !subcategorySelected) {
      searchProductPaginationGraphql(
        `{"ox_register_data.cr_code_manufactures": ${JSON.stringify(manufacturerSelected)}}`
      );
    } else if (!searchTemp && !manufacturerSelected && subcategorySelected) {
      searchProductPaginationGraphql(
        `{"ox_register_data.ws_name_subcategory": ${JSON.stringify(subcategorySelected)}}`
      );
    } else {
      setDataSelectProduct([]);
    }
  }, [search, setSearch, manufacturerSelected, subcategorySelected, limit, skip]);

  const handleTableChangeTableSearch = (type, { page, sizePerPage }) => {
    setPageSearch(page)
    setSizePerPageSearch(sizePerPage)
    setSkip((page - 1) * sizePerPage);
    setLimit(sizePerPage);
  }

  const handleTableChangeTableIncludeds = (type, { page, sizePerPage }) => {
    setPageIncludeds(page)
    setSizePerPageIncludeds(sizePerPage)
    setSkipIncludeds((page - 1) * sizePerPage);
    getDataCrudGraphql((page - 1) * sizePerPage, sizePerPage);
  }

  const RemotePaginationTableSearch = ({ data, columns, page, sizePerPage, onTableChange, totalSize }) => (
    <div>
      <p>
        <b>{data.length} de {countSearch} registros</b>
      </p>
      <BootstrapTable
        data={data}
        columns={columns}
        remote
        pagination={paginationFactory({ page, sizePerPage, totalSize })}
        onTableChange={onTableChange}
        // responsive
        keyField="cr_code"
        bordered={false}
        striped={true}
        defaultSorted={defaultSorted}
        selectRow={selectRow}
        // rowEvents={rowEvents}
        classes={"table align-middle table-nowrap"}
        headerWrapperClasses={"thead-light"}
        cellEdit={cellEditFactory({
          mode: "click",
          blurToSave: true,
          afterSaveCell: (
            oldValue,
            newValue,
            row,
            column
          ) => {
            // console.log(11, oldValue);
            // console.log(11, newValue);
            // console.log(11, row);
            // console.log(11, column);
          },
        })} />
    </div>
  );

  const RemotePaginationTableIncludeds = ({ data, columns, page, sizePerPage, onTableChange, totalSize }) => (
    <div>
      <p>
        <b>{data.length} de {countIncludeds} registros</b>
      </p>
        <BootstrapTable
            data={data}
            columns={columns}
            remote
            search
            pagination={paginationFactory({ page, sizePerPage, totalSize })}
            onTableChange={onTableChange}
            // responsive
            keyField="ox_product.cr_code"
            bordered={false}
            striped={true}
            defaultSorted={defaultSorted}
            selectRow={selectRowDay}
            classes={
              "table align-middle table-nowrap"
            }
            headerWrapperClasses={"thead-light"}
            cellEdit={cellEditFactory({
              mode: "click",
              blurToSave: true,
              beforeSaveCell(
                oldValue,
                newValue,
                row,
                column,
                done
              ) {
                setTimeout(() => {
                  // console.log(11, oldValue);
                  // console.log(11, newValue);
                  // console.log(11, row);
                  // console.log(11, column);
                  if (String(oldValue) !== String(newValue)) {
                    if (
                      confirm(
                        "Realmente deseja alterar este valor?"
                      )
                    ) {
                      getCollumnEditable(newValue, column, row, row.ox_product ? row.ox_product.cr_code : row['ox_product.cr_code'])
                      done(); // contine to save the changes
                    } else {
                      done(false); // reject the changes
                    }
                  }
                }, 0);
                return { async: true };
              },
              afterSaveCell: (
                oldValue,
                newValue,
                row,
                column
              ) => {
                handleUpdateItemDay(
                  newValue,
                  row,
                  column
                );
              },
            })}
          />
    </div>
  );

  return (
    <BoxDayItens>
      {!dataFormDb ? (
        <Row>
          <Col className="col-12">
            <Card style={{ backgroundColor: "#e9ecef" }}>
              <CardBody>
                <CardTitle className="h6 mb-4 mt-4">
                  POR FAVOR SALVE O DAY PARA INSERIR OS ITENS!
                </CardTitle>
              </CardBody>
            </Card>
          </Col>
        </Row>
      ) : (
        <>
          <Row>
            <Col className="col-12">
              <Card style={{ backgroundColor: "#e9ecef" }}>
                <CardBody>
                  <CardTitle className="h6 mb-4">FILTROS DA PESQUISA</CardTitle>
                  <Row>
                    <Col className="col-6">
                      <AvFieldBraile
                        value={""}
                        name={"inpSelSubCategory"}
                        id={"inpSelSubCategory"}
                        label="Subcategoria"
                        type="select"
                        onChange={e => {
                          // _.set(dataFormDb, subtitleFields.accessor, e.target.value)
                          setSubcategorySelected(e.target.value)
                          // searchProductPaginationGraphql(
                          //   `{"ox_register_data.ws_name_subcategory":${JSON.stringify(e.target.value)}}`
                          // );
                        }
                        }
                      >
                        <option value={""}>Selecione a subcategoria</option>
                        {listSubcategories.map((item, index) => {
                          return (
                            <option key={`listSubcategories_${index}`} value={item}>
                              {item}
                            </option>
                          );
                        })}
                      </AvFieldBraile>
                    </Col>
                    <Col className="col-6">
                      <AvFieldBraile
                        value={""}
                        name={"inpSelSubCategory"}
                        id={"inpSelSubCategory"}
                        label="Fabricante"
                        type="select"
                        onChange={e => {
                          // _.set(dataFormDb, subtitleFields.accessor, e.target.value)
                          setManufacturerSelected(e.target.value)
                          // searchProductPaginationGraphql(
                          //   `{"ox_register_data.cr_code_manufactures":${JSON.stringify(manufacturerSelected)}}`
                          // );
                        }
                        }
                      >
                        <option value={""}>Selecione o fabricante</option>
                        {listManufacturers.map((item, index) => {
                          return (
                            <>
                              <option key={`listManufacturers_${item.cr_code}`} value={item.cr_code}>
                                {item.ws_abbreviation}
                              </option>
                            </>
                          );
                        })}
                      </AvFieldBraile>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col className="col-12 pb-4" style={{ textAlign: 'right' }}>
              <Card style={{ minHeight: "650px", textAlign: 'left' }}>
                <CardBody>
                  <CardTitle className="h6">SELEÇÃO DE PRODUTOS</CardTitle>                  
                    <React.Fragment>
                      <Row className="mb-2" style={{ height: "60px" }}>
                        <Col md="4">
                          <AvFieldBraile
                            value={""}
                            name={"inpSelSubCategory"}
                            id={"inpSelSubCategory"}
                            placeHolder="Buscar produto a ser incluído"
                            type="text"
                            onChange={e => {
                              setSearchValue(`\"${e.target.value}\"`)
                            }}
                            onKeyDown={e => {
                              if (e.key === 'Enter') {
                                setSearch(searchValue)
                              }
                            }}
                          />
                        </Col>
                        <Col md="4">
                          <Button onClick={() => setSearch(searchValue)}>BUSCAR</Button>
                        </Col>
                      </Row>
                      <Row>
                        <Col xl="12">
                          {loadingSelectProduct ? (
                            <BoxSpinner>
                              <Spinner />
                              <h6>Carregando busca por produto</h6>
                            </BoxSpinner>
                          ) : (

                            !loading && <div className="table-responsive">
                              <RemotePaginationTableSearch
                                data={dataSelectProduct}
                                columns={columnsSelectProduct}                                
                                page={pageSearch}
                                sizePerPage={ sizePerPageSearch }
                                totalSize={ countSearch }
                                onTableChange={ handleTableChangeTableSearch }
                              />
                            </div>
                          )}
                        </Col>
                      </Row>
                      {dataSelectProduct.length > 0 ? (
                        <Row className="align-items-md-center mt-30">
                        </Row>
                      ) : (
                        !loadingSelectProduct && (
                          <div
                            style={{
                              width: "100%",
                              textAlign: "center",
                              paddingTop: "20%",
                            }}
                          >
                            <h6>
                              {search ? (
                                <>Nenhum produto encontrado.</>
                              ) : (
                                <>
                                  Faça a pesquisa nos campos acima para
                                  visualizar os produtos aqui.
                                </>
                              )}
                            </h6>
                          </div>
                        )
                      )}
                    </React.Fragment>                        
                </CardBody>
              </Card>
              <Button onClick={() => handleInsertItensDay()}>
                Adicionar selecionados
              </Button>
            </Col>
            <Col className="col-12">
              <Card style={{ minHeight: "650px" }}>
                <CardBody>
                  <CardTitle className="h6">
                    PRODUTOS INCLUÍDOS NO DAY
                  </CardTitle>                  
                  <React.Fragment>
                    {/* <Row className="mb-4">
                      <Col md="12">
                        <div className="search-box me-4 mb-4 d-inline-block">
                          <div className="position-relative">
                            <SearchBar
                              // {...toolkitProps.searchProps}
                              searchText={searchText}
                              
                              placeholder="Buscar produto incluído"
                              style={{ width: '500px' }}
                            />
                            <i className="bx bx-search-alt search-icon pt-3" />
                          </div>
                        </div>
                      </Col>
                    </Row> */}
                    <Row className="mb-2" style={{ height: "60px" }}>
                        <Col md="4">
                          <AvFieldBraile
                            value={""}
                            name={"inpSelSubCategory"}
                            id={"inpSelSubCategory"}
                            placeHolder="Buscar produto incluído"
                            type="text"
                            onChange={e => {
                              // setSearchText(`\"${e.target.value}\"`)
                              setSearchText(e.target.value)
                            }}
                            onKeyDown={e => {
                              if (e.key === 'Enter') {
                                setSearchIncluds(searchText)
                              }
                            }}
                          />
                        </Col>
                        <Col md="4">
                          <Button onClick={() => {
                            setSearchIncluds(searchText)
                          }}>BUSCAR</Button>
                        </Col>
                      </Row>
                    <Row>
                      <Col xl="12">
                        {loadingDaySelecteds ? (
                          <BoxSpinner>
                            <Spinner />
                            <h6>Carregando Itens do Day</h6>
                          </BoxSpinner>
                        ) : (
                          <div className="table-responsive">
                            <RemotePaginationTableIncludeds
                                data={dataDaySelecteds}
                                columns={columnsDaySelecteds}                                
                                page={pageIncludeds}
                                sizePerPage={ sizePerPageIncludeds }
                                totalSize={ countIncludeds }
                                onTableChange={ handleTableChangeTableIncludeds }
                              />
                          </div>
                        )}
                      </Col>
                    </Row>
                    {dataDaySelecteds.length > 0 ? (
                      <Row className="align-items-md-center mt-30">
                      </Row>
                    ) : (
                      !loadingDaySelecteds && (
                        <div
                          style={{
                            width: "100%",
                            textAlign: "center",
                            paddingTop: "20%",
                          }}
                        >
                          <h6>Nenhum produto incluído no day.</h6>
                        </div>
                      )
                    )}
                  </React.Fragment>
                </CardBody>
              </Card>
              <Button onClick={() => handleDeleteItensDay()}>
                Excluir selecionados
              </Button>
            </Col>
          </Row>
          <Message>
            ATENÇÃO! Para bundle, incluir todos os produtos no Day (o
            produto-pai e seus produtos-filhos).
          </Message>
        </>
      )
      }
    </BoxDayItens >
  );
};
