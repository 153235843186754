import React, { useEffect, useState } from "react"
import { Button, Col, Container, Input, Row } from "reactstrap"
import { contentUrlServer, typeUrlServer } from "src/common/urlServer"
import { post } from "src/helpers/api_helper"
import { getProductByCode, updateProduct } from "../../graphqlQuery"
import { dataProduct } from "./type"

const urlServer: typeUrlServer = contentUrlServer.filter(
  item => item.name == "PRODUCTIMAGE"
)[0];

export const Bundle = (props: {dataFormDb: dataProduct}) => {
  const [bundles, setBundles] = useState([]);
  const [valueField, setValueField] = useState('');  

  /* Lógica de requisições BACK END */
  const getDataBundle = React.useCallback(async (cr_code: string) => {
    try {
      let response = await post(
        "/graphql",
        {
          query: getProductByCode(cr_code),
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then(response => response)
        .catch(error => alert("Falha ao buscar dados do pedido!"));
      if (response.data[Object.keys(response.data)[0]]) {
        let listCodes = bundles;
        const product = response.data[Object.keys(response.data)[0]];        
        listCodes.push(product)
        setBundles(listCodes)
      } else {
        alert("Falha ao selecionar registro!");
      }
    } catch (e) {
      setBundles([]);
      alert(`Falha ao selecionar registro ${cr_code}!`);
    }
  }, []);

  const isnertBundle = React.useCallback(async () => {    
    let product = props.dataFormDb;
    product.qs_bundle.push(valueField);        
    try {
      let response = await post(
        "/graphql",
        {
          query: updateProduct({
            cr_code_erp: product.cr_code_erp,
            cv_version: product.cv_version,
            bf_active: product.bf_active,
            bf_liberation: product.bf_liberation,
            cr_code: product.cr_code,
            ck_registeruser: product.ck_registeruser,
            dx_register: product.dx_register,
            dx_modification: product.dx_modification, 
            // cr_code_tag: product.qs_code_tags, 
            bf_highlighted: product.bf_highlighted, 
            bf_hot_deal: product.bf_hot_deal, 
            bf_new_product: product.bf_new_product, 
            qs_buy_too: product.qs_buy_too, 
            qs_bundle: product.qs_bundle, 
            ws_name_manufactures: product.ws_name_manufactures, 
            cr_fiscal_classification: product.cr_fiscal_classification,
            cr_code_subcategory: product.cr_code_subcategory, 
            dt_validate_new: product.dt_validate_new, 
            ws_commercial_description: product.ws_commercial_description, 
            wu_unit_measure: product.wu_unit_measure,
            ws_part_number: product.ws_part_number,
            dc_gross_weight: product.dc_gross_weight,
            dc_inventory_balance: product.dc_inventory_balance,
            qf_price_special: product.qf_price_special,
            dc_percent_ipi: product.dc_percent_ipi,
            nb_tax_code_source: product.nb_tax_code_source,
            wh_image: product.wh_image,
            wl_image_list: product.wl_image_list,
            ws_name_category: product.ws_name_category,
            ws_name_subcategory: product.ws_name_subcategory,
            dc_price_sale_cash: product.dc_price_sale_cash,
            dc_price_sale_rs: product.dc_price_sale_rs,
            dc_price_sale_sc: product.dc_price_sale_sc,
            dc_price_sale_cartucho: product.dc_price_sale_cartucho,
            dc_price_sale_pr: product.dc_price_sale_pr,
            wt_commercial_text: product.wt_commercial_text,
            bf_bundle: product.bf_bundle,
            bf_discount_percentage: product.bf_discount_percentage,
            nc_discount_percentage: product.nc_discount_percentage,
            bf_highlighted_card_top: product.bf_highlighted_card_top,
            bf_highlighted_card_horizontal_showcase: product.bf_highlighted_card_horizontal_showcase,
            bf_highlighted_card_horizontal_showcase_best_offer: product.bf_highlighted_card_horizontal_showcase_best_offer,
            // bf_apply_icms: product.bf_apply_icms
          }),
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then(response => response)
        .catch(error => alert("Falha ao buscar dados do pedido!"));
      if (response.data[Object.keys(response.data)[0]]) {
        let listCodes = bundles;
        const product = response.data[Object.keys(response.data)[0]];        
        listCodes.push(product)
        setBundles(listCodes)
      } else {
        alert("Falha ao selecionar registro!");
      }
    } catch (e) {
      setBundles([]);
      alert(`Falha ao inserir registro ${valueField}!`);
    }
  }, []);

  useEffect(() => {
    if (props.dataFormDb.bf_bundle){
      setBundles([])
      props.dataFormDb.qs_bundle.map(bundle => {
        getDataBundle(String(bundle))
      })
    }
  }, [])

  function getBundles() {
    return (<Row>
      {bundles.map(bundle => {
        return (<Col md={3}>
        <img src={urlServer.urlServer + urlServer.pathServer + "/" + bundle.wh_image} />
        <p>{bundle.ws_commercial_description}</p>
        </Col>)
      })}
    </Row>)
  }

  const handleAddBundle = () => {
    isnertBundle()
    getDataBundle(String(valueField))
  }

  const handleChangeCode = (value) => {
    setValueField(value)
  }

  return (
    <>
    <Container>
      <h6>Código do produto:</h6>
      <Input
        style={{ maxWidth: "20%" }}
        id="button"
        label="Código produto"
        placeholder=""
        type="text"
        onChange={e => handleChangeCode(e.target.value)}
      />
      <br />
      <Button onClick={() => handleAddBundle()}>Adicionar</Button>
        <br />
        <br />
    {getBundles()}
    </Container>
    </>
  )
}
