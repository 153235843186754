/* IMPORTANTE: Deve incluir as fun principais do CRUD
 * getData, updateData, deleteData, postData
 * estas chamadas são padrão no CRUD/CrudGroupForm */

export const getSellerByCode = (cr_code: string): string => {
  return `{
    getSellerByCode(cr_code:"${cr_code}") {
      ws_email
      ws_name
      dx_modification
      dx_register
      ck_registeruser
      cr_code
      bf_liberation
      bf_active
      cv_version
    }
  }`;
};

export const getSellers = (): string => {
  return `query {
    getSellers {
      ws_email
      ws_name
      dx_modification
      dx_register
      ck_registeruser
      cr_code
      bf_liberation
      bf_active
      cv_version
    }
  }`;
};

export const deleteSeller = (cr_code: string): string => {
  return `query {
    deleteSellerByCode(cr_code:"${cr_code}") {
      message
    }
  }`;
};

export const updateSeller = (data: {
  ws_email: String;
  ws_name: String;
  cr_code: String;
  bf_liberation: Boolean;
  bf_active: Boolean;
}): string => {
  return `mutation {
    updateSeller(
      bf_active: ${data.bf_active},
      bf_liberation: ${data.bf_liberation},
      cr_code: "${data.cr_code}",
      ws_name: "${data.ws_name}",
      ws_email: "${data.ws_email}",
   ){
  	message
   }
  }`;
};

export const insertSeller = (data: {
  ws_email: String;
  ws_name: String;
  cr_code: String;
  bf_liberation: Boolean;
  bf_active: Boolean;
}): string => {
  return `mutation {
    insertSeller(
      bf_active: ${data.bf_active},
      bf_liberation: ${data.bf_liberation},
      cr_code: "${data.cr_code}",
      ws_name: "${data.ws_name}",
      ws_email: "${data.ws_email}",
   ){
  	message
   }
  }`;
};

export const getNextCrCodeSeller = (): string => {
  return `query {
    getNextCrCodeSeller {
      result
    }
  }`;
};
