import React, { useEffect, useState } from "react"
import {
  useParams
} from "react-router-dom";
import { typeCrudListColumns } from "src/components/Common/typeCridList"
import ConfirmModal from "src/components/Modal/confirmModal"
import ModalUserDescription from "src/components/Modal/modalUserDescription"
import DataTable from "src/components/Tables/Table"
import { CRUD_MODE } from "src/constants/layout"
import { post } from "src/helpers/api_helper"

export default function index(props: {
  queryGet?: any;
  queryGetFilter?: any;
  queryDelete?: any;
  queryUpdate?: any;
  queryPreview?: any;
  queryPutOneField?: any;
  columns: Array<typeCrudListColumns>;
  handleNewRegister?: any;
  setSelectedRow?: any;
  optionsNewRegister?: Array<any>;
  optionsActions?: any;
  visibleNewRegister?: Boolean;
  allData?: Array<any>;
  fieldsCustomFilters?: Array<typeCrudListColumns>;
  setCheckeds?: any;
}) {
  let id = useParams();
  const {
    queryGet,
    queryGetFilter,
    queryDelete,
    queryUpdate,
    queryPreview,
    queryPutOneField,
    columns,
    handleNewRegister,
    setSelectedRow,
    optionsNewRegister,
    optionsActions,
    visibleNewRegister = true,
    allData = null,
    fieldsCustomFilters = null,
    setCheckeds,
  } = props;

  const [modal, setModal] = React.useState(false);
  const [loadingEntities, setLoadingEntities] = useState(true);
  const [entitiesConfigurations, setEntitiesConfigurations] = useState([]);
  const [allDataFormDb, setAllDataFormDb] = useState([]);
  const [modal3, setModal3] = React.useState(false);
  const [deleteCrCode, setDeleteCrCode] = React.useState("");
  const [columnsCountTotal, setColumnsCountTotal] = React.useState(0);
  const [limit, setLimit] = React.useState(10);
  const [skip, setSkip] = React.useState(0);
  const [pageCount, setPageCount] = React.useState(0);
  const [pageIndexSet, setPageIndexSet] = React.useState(0);
  const fetchIdRef = React.useRef(0);
  const [loading, setLoading] = React.useState(false);
  const [globalFilter, setGlobalFilterSearch] = React.useState("");
  const [localFilter, setLocalFilterSearch] = React.useState(["", ""]);
  const [globalFilterCustom, setGlobalFilterCustom] = React.useState("");
  const [isReadyToExport, setIsReadyToExport] = React.useState(false);
  const [dataFilter, setDataFilter] = React.useState([]);
  const [loadingGraphToExport, setLoadingGraphToExport] = React.useState(false);

  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");

  /* Ações padrão do CRUD, qualquer outra ação personalizada deverá entrar no form customizado da Page */
  const buttonActionsDefault = (row: any): Array<any> => {
    if (id["id"] && row.wj_cnpj === id["id"]) {      
      handleNewRegister(CRUD_MODE.FORM)
      setSelectedRow(row)            
    }
    return [
      <button
        type="button"
        className={`btn btn-primary waves-effect waves-light p-0 px-2 m-auto ${!queryUpdate && "hide"
          }`}
        onClick={() => {
          handleNewRegister(CRUD_MODE.FORM), setSelectedRow(row);
        }}
      >
        <i className="mdi mdi-pencil d-block font-size-8"></i>
      </button>,
      " ",
      <button
        type="button"
        className={`btn btn-danger waves-effect waves-light p-0 px-2 m-auto ${!queryDelete && "hide"
          }`}
        onClick={() => deleteRegister(row.cr_code)}
      >
        <i className="mdi mdi-trash-can font-size-8"></i>
      </button>,
      " ",
      <button
        type="button"
        className={`btn btn-primary waves-effect waves-light p-0 px-2 m-auto ${!queryPreview && "hide"
          }`}
        onClick={() => {
          handleNewRegister(CRUD_MODE.FORM), setSelectedRow(row);
        }}
      >
        <i className="mdi mdi-eye font-size-8"></i>
      </button>,
      <button
        type="button"
        className={`btn btn-primary waves-effect waves-light p-0 px-2 m-auto ${!queryPutOneField && "hide"
          }`}
        onClick={() => {
          handlePutField(row);
        }}
      >
        <i className="mdi mdi-refresh d-block font-size-8"></i>
      </button>,
    ];
  };

  const mergeCollumnsRows = (row: any): Array<any> => {

    /* Retorna as ações Default do CRUD */
    let actionsDefault = buttonActionsDefault(row);

    /* Merge das ações default para add novas ações */
    let actionsFinally = [];
    actionsFinally.push(...actionsDefault);
    
    if (Array.isArray(optionsActions)) {
      optionsActions.map(action => actionsFinally.push({...action}));
    } else  {
      actionsFinally.push(optionsActions(row));
    }        

    /* const res = dataTable.map(item => {
      return {
        ...item,
        actions: buttonActionsDefault(row),
      };
    }); */
    return actionsFinally;
  };

  /* Função delete padrão do CRUD */
  const deleteRegister = React.useCallback(async (cr_code: string) => {
    setDeleteCrCode(cr_code);
    setModal3(true);
  }, []);

  const onSubmit = async () => {
    // ############################
    // Validação do formulario
    // Valida digitou usuario
    if (!user) {
      alert("Usuário obrigatório");
      return;
    }

    // ############################
    // Monta o body de envio do formulario
    const body = {
      user: user,
      password: password,
    };

    // ############################
    // Envia o formulario para o endpoint
    // http://192.1.1.1:3000/
    // Login usuário
    await post("/login", body)
      .then(response => {
        if (response.status === 200) {
          alert("Cadastro realizado com sucesso!");
        }
      })
      .catch(error => {
        alert(error);
      });
  };

  /* Função delete padrão do CRUD */
  const deleteRegisterByCrCode = React.useCallback(async (del: string) => {
    if (!queryDelete) return;
    setLoadingEntities(true);
    try {
      await post(
        "/graphql",
        {
          query: queryDelete(del),
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      ).then(() => getDataCrudGraphql());
    } catch (e) {
      console.error(e);
      alert(`Falha ao exluir registro!`);
    } finally {
      setLoadingEntities(false);
    }
  }, []);

  /* Função delete padrão do CRUD */
  const handlePutField = React.useCallback(async row => {
    setLoadingEntities(true);
    try {
      await post(
        "/graphql",
        {
          query: queryPutOneField(row.cr_code),
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      ).then(() => getDataCrudGraphql());
    } catch (e) {
      console.error(e);
      alert(`Falha ao atualizar registro!`);
    } finally {
      setLoadingEntities(false);
    }
  }, []);

  /* Função delete padrão do CRUD */
  const previewRegister = React.useCallback(async (cr_code: string) => {
    if (!queryPreview) return;
    setLoadingEntities(true);
    try {
      await post(
        "/graphql",
        {
          query: queryPreview(deleteCrCode),
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      ).then(() => getDataCrudGraphql());
    } catch (e) {
      console.error(e);
      alert(`Falha ao exluir registro!`);
    } finally {
      setLoadingEntities(false);
    }
  }, []);

  /* Função get todos os dados, padrão do CRUD */
  const getDataCrudGraphql = React.useCallback(
    async (filter = globalFilter, pageSize = limit, skip = 0) => {      
      setLoadingEntities(true);
      let tempPageCount = 0;
      if (!queryGet) return;      
      if (allData) {
        setAllDataFormDb(allData);
        setLoadingEntities(false);
        return;
      }
      try {
        let response = await post(
          "/graphql",
          {
            query: queryGet(filter, pageSize, false, skip),
          },
          {
            headers: {
              "Content-Type": "application/json; charset=utf-8",
              "Access-Control-Allow-Origin": "*",
            },
          }
        )
          .then(response => response)
          .catch(error => {
            setLoadingEntities(false);
          });
        if (
          response &&
          response.data &&
          response.data[Object.keys(response.data)[0]]
        ) {
          const count = response.data[Object.keys(response.data)[0]].result
            ? response.data[Object.keys(response.data)[0]].rowsall
            : 0;
          // setColumnsCountTotal(count);

          setLimit(count)

          tempPageCount = Math.ceil(count / pageSize);
          setPageCount(tempPageCount);

          // mergeCollumnsRows(response.data[Object.keys(response.data)[0]]);
          setAllDataFormDb(
            response.data[Object.keys(response.data)[0]].result
              ? response.data[Object.keys(response.data)[0]].result
              : response.data[Object.keys(response.data)[0]]
          );
        } else {
          handleNewRegister(CRUD_MODE.LIST);
        }
      } catch (e) {
        setAllDataFormDb([]);
        alert(`Falha ao buscar lista!`);
        handleNewRegister(CRUD_MODE.LIST);
      } finally {
        setLoadingEntities(false);
      }
    },
    [queryGet()]
  );

  const getDataCrudGraphqlToExport = React.useCallback(
    async (filter = globalFilterCustom, pageSize = limit, skip = 0) => {      
      setLoadingEntities(true);
      if (!queryGetFilter) return;
      try {
        let response = await post(
          "/graphql",
          {
            query: queryGetFilter(filter, pageSize, false, skip),
          },
          {
            headers: {
              "Content-Type": "application/json; charset=utf-8",
              "Access-Control-Allow-Origin": "*",
            },
          }
        )
          .then(response => response)
          .catch(error => {
            setLoadingEntities(false);
          });
        if (
          response &&
          response.data &&
          response.data[Object.keys(response.data)[0]]
        ) {
          const data = response.data[Object.keys(response.data)[0]].result
          ? response.data[Object.keys(response.data)[0]].result
          : response.data[Object.keys(response.data)[0]]
          setDataFilter(prev => [...prev, ...data]);
        }
      } catch (e) {
        alert(`Falha ao buscar lista FILTRO!`);
        setLoadingGraphToExport(false);
        setDataFilter([]);
        handleNewRegister(CRUD_MODE.LIST);
      } finally {
        // setLoadingEntities(false);
      }
    },
    [queryGetFilter(), limit, globalFilterCustom]
  );

  function getDataToExport() {
    let limitToExport = limit;
    let divider = limit > 10000 ? Math.round(limit / 1000) : limit / 10;
    let limitToExportPart = Math.round(limitToExport / divider);
    
    let limitToExportPartArray = [];
    for (let i = 0; i < divider; i++) {
      limitToExportPartArray.push(limitToExportPart);
    }
    let limitToExportPartArraySum = limitToExportPartArray.reduce(
      (a, b) => a + b,
      0
    );
    if (limitToExportPartArraySum < limitToExport) {
      limitToExportPartArray[0] =
        limitToExportPartArray[0] + (limitToExport - limitToExportPartArraySum);
    }
    let skip = 0;
    let promises = [];
    for (let i = 0; i < limitToExportPartArray.length; i++) {
      promises.push(
        getDataCrudGraphqlToExport(globalFilterCustom, limitToExportPartArray[i], skip)
      );
      skip = skip + limitToExportPartArray[i];
    }
    setLoadingGraphToExport(true);
    Promise.all(promises).then(() => {
      setLoadingGraphToExport(false);
    });
  }

  const fetchData = React.useCallback(({ pageSize, pageIndex }) => {
    // This will get called when the table needs new data
    // You could fetch your data from literally anywhere,
    // even a server. But for this example, we'll just fake it.

    // Give this fetch an ID
    const fetchId = ++fetchIdRef.current;

    // Set the loading state
    setLoading(true);
    // We'll even set a delay to simulate a server here
    // setTimeout(async () => {
    // Only update the data if this is the latest fetch
    if (fetchId === fetchIdRef.current) {
      const startRow = pageSize * pageIndex;
      const endRow = startRow + pageSize;
      setPageIndexSet(pageIndex);
      if (!globalFilterCustom) {
        getDataCrudGraphql("{}", pageSize, pageIndex * pageSize);
      } else {
        getDataCrudGraphql(globalFilterCustom, pageSize, pageIndex * pageSize);
        setDataFilter([]);
      }

      // Your server could send back total page count.
      // For now we'll just fake it, too
      setLoading(false);
    }
    // }, 2000);
  }, [globalFilterCustom]);

  useEffect(() => {
    if (globalFilter) {

      if (typeof globalFilter === "object") {
        getDataCrudGraphql(globalFilter)
      }

      if (globalFilter.length > 2) {
        getDataCrudGraphql(
          '{"$text":{"$search":' + JSON.stringify(globalFilter) + "}}"
        );
      } else {
        getDataCrudGraphql("{}");
      }
    } else {
      getDataCrudGraphql("{}");
    }
  }, [globalFilter, setGlobalFilterSearch]);

  useEffect(() => {
    if (globalFilterCustom && globalFilterCustom !== undefined) {
      getDataCrudGraphql(globalFilterCustom);
    } else {
      getDataCrudGraphql("{}");
    }
  }, [globalFilterCustom, setGlobalFilterCustom]);

  useEffect(() => { }, [localFilter, setLocalFilterSearch]);

  return (
    <React.Fragment>
      <ConfirmModal
        modal={modal3}
        confirm={() => deleteRegisterByCrCode(deleteCrCode)}
        cancel={() => setModal3(false)}
        setModal={setModal3}
        title="ATENÇÃO: confirma a exclusão do registro?"
      />
      <ModalUserDescription modal2={modal} setModal2={setModal} />
      <DataTable
        columns={columns}
        dataTable={allDataFormDb}
        headFilters={!fieldsCustomFilters}
        handleNewRegister={handleNewRegister}
        optionsNewRegister={optionsNewRegister}
        loadingEntitie={loadingEntities}
        mergeCollumnsRows={mergeCollumnsRows}
        visibleNewRegister={visibleNewRegister}
        // columnsCountTotal={columnsCountTotal}
        fetchData={fetchData}
        pageCount={pageCount}
        pageIndexSet={pageIndexSet}
        setGlobalFilterSearch={setGlobalFilterSearch}
        setLocalFilterSearch={setLocalFilterSearch}
        fieldsCustomFilters={fieldsCustomFilters}
        setCheckeds={setCheckeds}
        setGlobalFilterCustom={setGlobalFilterCustom}
        setLimit={setLimit}
        isReadyToExport={isReadyToExport}
        dataFilter={dataFilter}
        query={getDataToExport}
        loadingDataFilter={loadingGraphToExport}
        limit={limit}
      />
    </React.Fragment>
  );
}
