/* IMPORTANTE: Deve incluir as fun principais do CRUD
 * getData, updateData, deleteData, postData
 * estas chamadas são padrão no CRUD/CrudGroupForm */

export const getConfigurationRMA = (): string => {
  return `query {
    getAlertRmaAdmin {
      wt_commercial_text
      dt_publication_end
      dt_publication_begin
    }
  }`;
};

export const updateConfigurationRMA = (data: {
  wt_commercial_text: String,
  dt_publication_end: String,
  dt_publication_begin: String
}): string => {
  return `mutation {
    updateConfigurationRMA(
      wt_commercial_text: ${JSON.stringify(data.wt_commercial_text)},
      dt_publication_end: "${data.dt_publication_end}",
      dt_publication_begin: "${data.dt_publication_begin}"
    ){
      result      
    }
  }`;
};
