import { ContentState, convertToRaw, EditorState } from "draft-js"
import draftToHtml from "draftjs-to-html"
import htmlToDraft from "html-to-draftjs"
import _ from "lodash"
import { useEffect, useState } from "react"
import { Editor } from "react-draft-wysiwyg"
import { Col } from "reactstrap"
import { typeCrudForm } from "src/components/Common/typeCrudForm"
import { Observation } from "../styled"

// Input # Render HTML
export default function getWT(
  valueField: any,
  subtitleFields: typeCrudForm,
  dataFormDb: any
): any {
  if (subtitleFields.cols === "0") return "";
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const updateTextDescription = async state => {
    await setEditorState(state);

    const data = convertToRaw(editorState.getCurrentContent());
    _.set(
      dataFormDb,
      subtitleFields.accessor,
      draftToHtml(convertToRaw(editorState.getCurrentContent()))
    );
  };

  const html = '<p id="para"></p>';
  const contentBlock = valueField ? htmlToDraft(valueField) : "";

  useEffect(() => {
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const editorState = EditorState.createWithContent(contentState);
      setEditorState(editorState);
      /* this.state = {
        editorState,
        background: "",
        isOpen: false,
        emailBody: `<p id="para">test</p>`,
        fontcolor: "",
      }; */
    }
  }, []);

  return (
    <Col
      className="mt-3"
      lg={subtitleFields.cols}
      sm={subtitleFields.cols}
      md={subtitleFields.cols}
      xs={12}
    >
      <Editor
        editorState={editorState}
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName="editorClassName"
        // editorStyle={{ height: "200px" }}
        onEditorStateChange={updateTextDescription}
        editorStyle={{ height: "65vh", backgroundColor: "white" }}
      />
      {subtitleFields.observation && (
        <Observation>{subtitleFields.observation}</Observation>
      )}
    </Col>
  );
}