import React, { useState } from "react"
import { Button } from "reactstrap"
import { typeCrudForm } from "src/components/Common/typeCrudForm"
import FormCrudContainer from "src/components/CRUD/CrudForm"
import { CRUD_MODE } from "src/constants/layout"
import { post } from "src/helpers/api_helper"
import { setCustomerReprove } from "../graphqlQuery"
import FormManufacturer from "./forms/formManufacturer"

export default function FormCrud(props: {
  handleNewRegister: (mode: string) => void;
  queryGetByCode: any;
  queryGet: any;
  querySave: any;
  queryUpdate: any;
  queryDelete: any;
  queryPreview: any;
  queryPutOneField: any;
  selectedRow: string;
  setSelectedRow: any;
  queryNextCrCode: any;
  columnsTable: Array<typeCrudForm>;
}) {
  const { handleNewRegister } = props;
  const [rowSelected, setRowSelected] = useState(null);

  /* Função APROVAR CADASTRO CLIENTE */
  const approveRegister = React.useCallback(async (ws_corporate_reason: string, wj_cnpj: string, wm_email: string) => {
    if (!props.queryPreview) return;
    try {
      await post(
        "/graphql",
        {
          query: props.queryPreview(ws_corporate_reason, wj_cnpj, wm_email),
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      ).then(response => {
        if (!response.data.setCustomerApprove) {
          alert("Falha ao aprovar cadastro!");
          return;
        }
        alert(response.data.setCustomerApprove.message);
        handleNewRegister(CRUD_MODE.LIST);
        props.setSelectedRow(null);
      });
    } catch (e) {
      console.error(e);
      alert(`Falha ao aprovar cliente!`);
    }
  }, []);

  /* Função REPROVAR CADASTRO CLIENTE */
  const reproveRegister = React.useCallback(async (ws_corporate_reason: string, wj_cnpj: string, wm_email: string) => {
    try {
      await post(
        "/graphql",
        {
          query: setCustomerReprove(ws_corporate_reason, wj_cnpj, wm_email),
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      ).then(response => {
        if (!response.data.setCustomerReprove) {
          alert("Falha ao reprovar cadastro!");
          return;
        }
        alert(response.data.setCustomerReprove.message);
        handleNewRegister(CRUD_MODE.LIST);
        props.setSelectedRow(null);
      });
    } catch (e) {
      console.error(e);
      alert(`Falha ao reprovar cliente!`);
    }
  }, []);

  const buttonFunctions = (
    <>
      <Button
        color="success"
        type="reset"
        className=""
        onClick={() => {
          approveRegister(rowSelected["ox_registration_fields.ws_corporate_reason"], rowSelected.wj_cnpj, rowSelected["ox_registration_fields.wm_email"]);
        }}
      >
        Aprovar cadastro
      </Button>
      <Button
        type="reset"
        className=""
        onClick={() => {
          reproveRegister(rowSelected["ox_registration_fields.ws_corporate_reason"], rowSelected.wj_cnpj, rowSelected["ox_registration_fields.wm_email"]);
        }}
      >
        Reprovar cadastro
      </Button>
    </>
  );

  return (
    <FormCrudContainer
      Form={FormManufacturer}
      handleNewRegister={handleNewRegister}
      buttons={buttonFunctions}
      callBackRowSelected={setRowSelected}
      {...props}
    />
  );
}
