import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
interface AuthLayoutProps {
  component: any;
  layout: any;
  isAuthProtected: any;
  path?: string;
  exact?: boolean;
  key?: number;
}

const Authmiddleware = ({
  component,
  layout,
  isAuthProtected,
  path,
  exact,
  key,
  ...rest
}: AuthLayoutProps) => {
  const Layout = layout;
  const Component = component;

  const isHomologEnvironment =
    window.location.origin.includes('http://192.241.158.42')
    || window.location.origin.includes('admin.homologacao.braileonline.com.br');

  useEffect(() => {
    //fazer logout após 1h 
    setTimeout(() => {
      localStorage.removeItem("authUser");
      window.location.reload();
    }, 3600000);
  }, []);

  return (
    <Route
      {...rest}
      render={props => {
        const userInfo = JSON.parse(localStorage.getItem("authUser") || '{}');

        if (isAuthProtected && (!localStorage.getItem("authUser") || userInfo.password && userInfo.password !== 'BR41L3')) {
          return (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          );
        }

        return (
          <>
            {isHomologEnvironment && <div
              style={{
                position: 'fixed',
                bottom: 0,
                zIndex: 1002,
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center',
                background: 'rgba(215, 56, 49, 0.8)',
                color: 'white',
                fontWeight: 'bold',
              }}>
              AMBIENTE DE HOMOLOGAÇÃO
            </div>}
            <Layout>
              <Component {...props} />
            </Layout>
          </>
        );
      }}
    />
  );
};

export default Authmiddleware;
