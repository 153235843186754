import { useCallback } from "react"
import { Card, CardBody, CardHeader, CardSubtitle, CardTitle, Col, Table } from "reactstrap"
import { typeCrudForm } from "src/components/Common/typeCrudForm"
import { Observation } from "../styled"

// Input # Nome de arquivo
export default function getQF(
  valueField: any,
  subtitleFields: typeCrudForm,
  dataFormDb: any,
  getMapConvention: any
): any {
  if (subtitleFields.cols === "0") return "";
  const callComp = useCallback((column, dataFormDb, index, item) => {
    return getMapConvention(
      column.accessor.substring(0, 2),
      item[column.accessor],
      {
        Header: "",
        cols: "12",
        required: true,
        idfield: column.accessor + index,
        ...column,
      },
      dataFormDb[subtitleFields.accessor][index],
      null
    );
  }, []);
  return (
    <Col
      className="mt-3"
      lg={subtitleFields.cols}
      sm={subtitleFields.cols}
      md={subtitleFields.cols}
      xs={12}
    >
      <Card disabled={subtitleFields.disabled} name={subtitleFields.accessor}>
        <CardHeader>
          {subtitleFields.listView.caption && (
            <CardTitle>{subtitleFields.listView.caption}</CardTitle>
          )}
          {subtitleFields.listView.description && (
            <CardSubtitle className="mb-3">
              {subtitleFields.listView.description}
            </CardSubtitle>
          )}
        </CardHeader>
        <CardBody>
          <div className="table-responsive">
            <Table className="table mb-0">
              {subtitleFields.observation && (
                <caption>
                  <Observation>{subtitleFields.observation}</Observation>
                </caption>
              )}
              <thead>
                <tr>
                  {subtitleFields.listView.columns.map(column => (
                    <th>{column.header}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {Array.isArray(valueField) &&
                  valueField.map((item, index) => {
                    const countItens = Object.keys(item);
                    return (
                      <tr>
                        {subtitleFields.listView.columns.map(column => (
                          <td>
                            {callComp(column, dataFormDb, index, item)}
                            {/* {getMapConvention(
                              column.accessor.substring(0, 2),
                              item[column.accessor],
                              {
                                Header: "",
                                cols: "12",
                                required: true,
                                idfield: column.accessor + index,
                                ...column,
                              },
                              dataFormDb[subtitleFields.accessor][index],
                              null
                            )} */}
                          </td>
                        ))}
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
}