import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import {
  useAsyncDebounce,
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable
} from "react-table";
import {
  Button,
  Button as ButtonRS,
  ButtonDropdown,
  Card,
  CardBody,
  CardTitle,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  InputGroup,
  Row,
  UncontrolledDropdown
} from "reactstrap";
import { SortDownIcon, SortIcon, SortUpIcon } from "./shared/Icons";
import { classNames } from "./shared/Utils";
import "./styles.css";
// A great library for fuzzy filtering/sorting items
import matchSorter from "match-sorter";
import BTable from "react-bootstrap/Table";
import Filters from "src/components/Filters";
import LoadTable from "src/components/Loaders/loadTable";
import { CRUD_MODE } from "src/constants/layout";
import DownloadExcel from "../DownloadExcel";

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  handleNewRegister,
  optionsNewRegister,
  visibleNewRegister,
  setGlobalFilterSearch,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined);
  }, 200);
  const [infodrp_up11, setInfodrp_up11] = React.useState(false);

  return (
    <React.Fragment>
      <CardTitle className="h6">
        Filtros de Pesquisa ({count} registros)
      </CardTitle>
      <p />
      <Row className="p-0 m-0">
        <Col className="p-0 m-0" lg={6} sm={6} md={6} xs={12}>
          <InputGroup className="input-group-search" style={{ border: "0px" }}>
            <Input
              placeholder="Procurar qualquer conteúdo..."
              value={value || ""}
              onChange={e => {
                setValue(e.target.value);
                onChange(e.target.value);
              }}
              onKeyDown={e => {
                if (e.key === "Enter") {
                  setGlobalFilterSearch(`\"${value}\"`);
                }
              }}
            />
            <UncontrolledDropdown>
              <DropdownToggle
                type="button"
                className="btn btn-secondary-search"
                onClick={() => setGlobalFilterSearch(`\"${value}\"`)}
              >
                Pesquisar no servidor{" "}
                {/* <i className="mdi mdi-chevron-down"></i> */}
              </DropdownToggle>
            </UncontrolledDropdown>
          </InputGroup>
        </Col>
        <Col className="col-title p-0 m-0" lg={6} sm={6} md={6} xs={12}>
          {visibleNewRegister && (
            <>
              {handleNewRegister && optionsNewRegister.length > 0 ? (
                <div className="d-flex gap-2 flex-wrap justify-content-end">
                  <ButtonDropdown
                    direction="left"
                    isOpen={infodrp_up11}
                    className="btn-group dropend button-new"
                    toggle={() => setInfodrp_up11(!infodrp_up11)}
                  >
                    <DropdownToggle
                      caret
                      color="info"
                      className="dropdown-toggle-split button-new"
                    >
                      <i className="mdi mdi-chevron-left" />
                    </DropdownToggle>
                    <ButtonRS
                      className="button-new"
                      id="caret"
                      /* color="info" */
                      onClick={() => handleNewRegister(CRUD_MODE.FORM)}
                    >
                      Novo Registro
                    </ButtonRS>
                    <DropdownMenu data-popper-placement="right-start">
                      {optionsNewRegister.map(itemOption => (
                        <DropdownItem>{itemOption}</DropdownItem>
                      ))}
                    </DropdownMenu>
                  </ButtonDropdown>
                </div>
              ) : (
                handleNewRegister && (
                  <ButtonRS
                    className="button-new"
                    id="caret"
                    /* color="info" */
                    onClick={() => handleNewRegister(CRUD_MODE.FORM)}
                  >
                    Novo Registro
                  </ButtonRS>
                )
              )}
            </>
          )}
        </Col>
      </Row>
      {/* <Row className="p-0 m-2">
        <Col
          className="m-1 "
          lg={3}
          sm={3}
          md={3}
          xs={12}
          style={{ textAlign: "left" }}
        >
          <Label>Condição de Pagamento</Label>
          <Input type="select" id={"cond1"}>
            <option key={0} value="">
              Selecione
            </option>
            <option key={1} value="">
              Condição de pagamento usual (28D)
            </option>
            <option key={2} value="">
              Condição de pagamento usual (35D)
            </option>
          </Input>
        </Col>
        <Col
          className="m-1 text-left"
          lg={3}
          sm={3}
          md={3}
          xs={12}
          style={{ textAlign: "left" }}
        >
          <Label>Condição de Pagamento Especial</Label>
          <Input type="select" id={"cond2"}>
            <option key={0} value="">
              Selecione
            </option>
            <option key={1} value="">
              Condição de pagamento usual (28D)
            </option>
            <option key={2} value="">
              Condição de pagamento usual (35D)
            </option>
          </Input>
        </Col>
      </Row> */}
      <p />
    </React.Fragment>
  );
}

// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows;

  return (
    <>
      {/* <p>
        <i>{filterValue}</i>
      </p> */}
      <input
        className="form-control form-control-sm d-inline-block"
        value={filterValue || ""}
        onChange={e => {
          setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        }}
        placeholder={`Pesquisar em ${count.length} registros...`}
      />
    </>
  );
}

// This is a custom filter UI for selecting
// a unique option from a list
export function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id, render },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach(row => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <select
      className="form-control form-control-sm d-inline-block"
      name={id}
      id={id}
      value={filterValue}
      onChange={e => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
}

export function StatusPill({ value }) {
  const status = value ? value.toLowerCase() : "unknown";

  return (
    <span
      className={classNames(
        "px-3 py-1 uppercase leading-wide font-bold text-xs rounded-full shadow-sm",
        status.startsWith("active") ? "bg-green-100 text-green-800" : null,
        status.startsWith("inactive") ? "bg-yellow-100 text-yellow-800" : null,
        status.startsWith("offline") ? "bg-red-100 text-red-800" : null
      )}
    >
      {status}
    </span>
  );
}

export function AvatarCell({ value, column, row }) {
  const image = "https://braileonline.com.br/user/cate/" + value;
  return (
    <div className="flex items-center">
      <div className="flex-shrink-0 h-10 w-10">
        <img className="rounded img-t1" src={image} alt="" />
      </div>
      <div className="ml-4">
        {/* <div className="text-sm font-medium text-gray-900">{value}</div> */}
        <div className="text-sm text-gray-500">
          {row.original[column.imgAccessor]}
        </div>
      </div>
    </div>
  );
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [row => row.values[id]] });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = val => !val;

function Table({
  columns,
  dataTable,
  headFilters = false,
  handleNewRegister,
  optionsNewRegister = [],
  loadingEntitie,
  mergeCollumnsRows,
  visibleNewRegister,
  // columnsCountTotal,
  fetchData,
  pageCount: controlledPageCount,
  pageIndexSet = 0,
  setGlobalFilterSearch,
  setLocalFilterSearch,
  fieldsCustomFilters = [],
  setCheckeds = null,
  setGlobalFilterCustom,
  setLimit,
  isReadyToExport,
  dataFilter,
  query,
  loadingDataFilter,
  limit,
}) {
  // let pageCountCollumns = columnsCountTotal > 0 ? columnsCountTotal / 10 : 0;
  // const data = React.useMemo(() => dataTable, [dataTable]);
  const data = dataTable;
  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
              .toLowerCase()
              .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  /* React.useMemo(() => {
    setLocalFilterSearch(filterValue);
  }, []); */

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    rows,
    // Get the state from the instance
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      filterTypes,
      initialState: { pageIndex: Number(pageIndexSet) },
      manualPagination: true,
      pageCount: controlledPageCount,
    },
    useFilters, // useFilters!
    useGlobalFilter,
    useSortBy,
    usePagination // new
  );

  // We don't want to render all of the rows for this example, so cap
  // it for this use case
  const firstPageRows = rows.slice(0, 10);
  let countPage = 0;

  // Listen for changes in pagination and use the state to fetch our new data
  React.useEffect(() => {
    fetchData({ pageIndex, pageSize });
  }, [fetchData, pageIndex, pageSize]);

  // Render the UI for your table
  return (
    <>
      {/* {JSON.stringify(
        {
          pageIndex,
          pageSize,
          pageCount,
          canNextPage,
          canPreviousPage,
        },
        null,
        2
      )} */}
      <Card className="table-braile">
        <CardBody>
          <div className={headFilters ? "" : "hide"}>
            <GlobalFilter
              preGlobalFilteredRows={preGlobalFilteredRows}
              globalFilter={state.globalFilter}
              setGlobalFilter={setGlobalFilter}
              handleNewRegister={handleNewRegister}
              optionsNewRegister={optionsNewRegister}
              visibleNewRegister={visibleNewRegister}
              setGlobalFilterSearch={setGlobalFilterSearch}
            />
          </div>
          <div>
            <DownloadExcel
              filter={fieldsCustomFilters}
              dataFilter={dataFilter}
              columns={columns}
              query={query}
              loading={loadingDataFilter}
              limit={limit}
              dataTable={dataTable}
            />
            <Filters
              filter={fieldsCustomFilters}
              setGlobalFilterCustom={setGlobalFilterCustom}
            />
          </div>
          {setCheckeds && (
            <>
              <input
                type="checkbox"
                onClick={e => {
                  if (e.target.checked) {
                    const allDataFormDbIds = dataTable.map(
                      item => item.cr_code
                    );
                    setCheckeds(allDataFormDbIds);
                  } else {
                    setCheckeds([]);
                  }
                }}
                style={{ marginBottom: "10px", marginRight: "10px" }}
                id="checkAll"
              />
              <label htmlFor="checkAll">Selecionar todos</label>
            </>
          )}
          <div className="table-responsive">
            <BTable striped bordered hover size="sm" {...getTableProps()}>
              <thead className="table-light rounded" /* table-dark */>
                {headerGroups.map(headerGroup => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => {
                      if (column.visible !== false) {
                        return (
                          // Add the sorting props to control sorting. For this example
                          // we can add them into the header props
                          <th scope="col">
                            <div
                              {...column.getHeaderProps(
                                column.getSortByToggleProps()
                              )}
                            >
                              {column.render("Header")}
                              <span>
                                {column.isSorted ? (
                                  column.isSortedDesc ? (
                                    <SortDownIcon className="w-4 h-4 text-gray-400" />
                                  ) : (
                                    <SortUpIcon className="w-4 h-4 text-gray-400" />
                                  )
                                ) : (
                                  <SortIcon className="w-4 h-4 text-gray-400 opacity-0 group-hover:opacity-100" />
                                )}
                              </span>
                            </div>
                            {/* <div>
                              {column.canFilter
                                ? column.render("Filter")
                                : null}
                            </div> */}
                          </th>
                        );
                      }
                    })}
                  </tr>
                ))}
              </thead>
              {loadingEntitie && data.length === 0 ? (
                <LoadTable cols={columns.length} rows={10} />
              ) : data.length === 0 ? (
                <tbody
                  {...getTableBodyProps()}
                  className="bg-white divide-y divide-gray-200"
                >
                  <tr>
                    <td colSpan={columns.length}>
                      <div className="not-data-table align-me">
                        SEM REGISTROS
                      </div>
                    </td>
                  </tr>
                </tbody>
              ) : (
                <tbody
                  {...getTableBodyProps()}
                  className="bg-white divide-y divide-gray-200"
                >
                  {page.map((row, i) => {
                    // new
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()} key={row.id}>
                        {row.cells.map((cell, index) => {
                          // Coluna oculta marcada no criação da coluna
                          if (cell.column.visible === false) return;
                          if (Object.keys(row.values).length === index + 1) {
                            return (
                              <td
                                {...cell.getCellProps()}
                                /* className="px-6 py-4 whitespace-nowrap" minWidth */
                                className="p-2 align-baseline"
                                role="cell"
                                style={{
                                  minWidth: cell.column.minWidth
                                    ? cell.column.minWidth
                                    : "auto",
                                  maxWidth: cell.column.maxWidth
                                    ? cell.column.maxWidth
                                    : "auto",
                                }}
                              >
                                {cell.column.Cell.name === "defaultRenderer"
                                  ? (cell.render("Cell"),
                                    mergeCollumnsRows(row.values))
                                  : /* cell.render("Cell") */
                                  (cell.render("Cell"),
                                    mergeCollumnsRows(row.values))}
                              </td>
                            );
                          } else {
                            return (
                              <td
                                {...cell.getCellProps()}
                                /* className="px-6 py-4 whitespace-nowrap" */
                                className="p-2 align-baseline"
                                role="cell"
                                style={{
                                  minWidth: cell.column.minWidth
                                    ? cell.column.minWidth
                                    : "auto",
                                  maxWidth: cell.column.maxWidth
                                    ? cell.column.maxWidth
                                    : "auto",
                                }}
                              >
                                {cell.column.Cell.name === "defaultRenderer" ? (
                                  <div>{cell.render("Cell")}</div>
                                ) : (
                                  cell.render("Cell")
                                )}
                              </td>
                            );
                          }
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              )}
              <tfoot></tfoot>
            </BTable>
          </div>
          {/*
        Pagination can be built however you'd like.
        This is just a very basic UI implementation:
      */}
          <form className="form-inline">
            <div className="row p-0 m-0">
              <div className="form-group col d-flex justify-content-start">
                <select
                  className="form-control"
                  style={{ maxWidth: "120px", height: "100%" }}
                  value={pageSize}
                  onChange={e => {
                    setPageSize(Number(e.target.value));
                  }}
                >
                  {[10, 20, 30, 40, 50].map(pageSize => (
                    <option key={pageSize} value={pageSize}>
                      Mostrar {pageSize}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group col">
                <nav aria-label="Page navigation example">
                  <ul className="pagination justify-content-start">
                    <li
                      className={
                        !canPreviousPage ? "page-item disabled" : "page-item"
                      }
                    >
                      <a
                        className="page-link"
                        href="#"
                        tabIndex="-1"
                        onClick={() => {
                          previousPage();
                        }}
                      >
                        Anterior
                      </a>
                    </li>
                    {pageOptions.map((item, index) => {
                      if (index <= 9)
                        return (
                          <li
                            className={
                              pageIndex === index
                                ? "page-item active"
                                : "page-item"
                            }
                            onClick={() => {
                              gotoPage(index);
                            }}
                          >
                            <a className="page-link" href="#">
                              {index + 1}
                            </a>
                          </li>
                        );
                    })}
                    <li
                      className={
                        !canNextPage ? "page-item disabled" : "page-item"
                      }
                    >
                      <a
                        className="page-link"
                        href="#"
                        onClick={() => {
                          nextPage();
                        }}
                      >
                        Próximo
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
              <div className="form-group col d-flex justify-content-end">
                <span>
                  Página{" "}
                  <strong>
                    {pageIndex + 1} de {pageOptions.length}
                  </strong>{" "}
                  | Vá para página:{" "}
                  <input
                    type="number"
                    defaultValue={pageIndex + 1}
                    onChange={e => {
                      const page = e.target.value
                        ? Number(e.target.value) - 1
                        : 0;
                      gotoPage(page);
                    }}
                    style={{ width: "50px", marginLeft: "15px" }}
                  />
                </span>
              </div>
            </div>
          </form>
        </CardBody>
      </Card>
    </>
  );
}

export default Table;
