import React from "react"

//import Breadcrumbs
import moment from "moment"
import { formatDateTime } from "src/helpers/functions"
import CrudContainer from "./CrudContainer"
import { getFlyers, getFlyersByCode, getNextCrCodeFlayer, insertFlyer, updateFlyer } from "./graphqlQuery"

const Flyer = () => {
  const columns = React.useMemo(
    () => [
      // {
      //   Header: "Imagem",
      //   accessor: "wh_image",
      // },
      {
        Header: "Código",
        accessor: "cr_code",
        visible: false
      },
      {
        Header: "Título",
        accessor: "ws_title",
      },
      {
        Header: "Day",
        accessor: "cr_code_day",
      },
      {
        Header: "Preços",
        accessor: "sl_type_price",
      },
      {
        Header: "Ordem Produtos",
        accessor: "sl_order_product",
      },
      {
        Header: "Data Cadastro",
        accessor: "dx_date_register",
        Cell: ({ value, column, row }) => {
          return moment(value).format(formatDateTime);
        },
      },
      {
        Header: "Ações",
        accessor: "actions",
        Filter: () => <div style={{ height: "10px" }}></div>,
        Cell: cellContent => (
          <div
            style={{
              width: "100%",
              textAlign: "center",
              backgroundColor: "red",
            }}
          >
            {cellContent}
          </div>
        ),
      },
    ],
    []
  );
  return (
    <CrudContainer
      routePath=""
      queryGet={getFlyers}
      queryGetByCode={getFlyersByCode}
      querySave={insertFlyer}
      queryUpdate={updateFlyer}
      queryDelete={null}
      queryNextCrCode={getNextCrCodeFlayer}
      title="Flyers | Braile Distribuidora"
      breadcrumbTitle="Cadastro"
      breadcrumbItem="Flyers"
      columnsTable={columns}
    />
  );
};

export default Flyer;
