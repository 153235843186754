import _ from "lodash"
import { Col } from "reactstrap"
import { typeCrudForm } from "src/components/Common/typeCrudForm"
import AvFieldBraile from "src/components/general/Input/AvFieldBraile";
import { Observation } from "../styled"

// Input # Código do registro
export default function getCR(
  valueField: any,
  subtitleFields: typeCrudForm,
  dataFormDb: any
): any {
  if (Array.isArray(subtitleFields.listItens)) return "";

  // Se não, segue apenas mostrando o id desabilitado
  return (
    <Col
      className="mt-3"
      lg={subtitleFields.cols}
      sm={subtitleFields.cols}
      md={subtitleFields.cols}
      xs={12}
    >
      <AvFieldBraile
        value={valueField}
        name={subtitleFields.accessor}
        id={
          subtitleFields.idfield
            ? subtitleFields.idfield
            : subtitleFields.accessor
        }
        label={subtitleFields.Header}
        placeholder=""
        /* maxLength="18" */
        type="text"
        disabled={true}
        /* errorMessage="Código obrigatório" */
        /* validate={{ required: { value: subtitleFields.required } }} */
        onChange={e =>
          _.set(dataFormDb, subtitleFields.accessor, e.target.value)
        }
      />
      {subtitleFields.observation && (
        <Observation>{subtitleFields.observation}</Observation>
      )}
    </Col>
  );
}
