import { contentUrlServer, typeUrlServer } from "src/common/urlServer"
import {
  typeCrudCollapse,
  typeCrudForm
} from "src/components/Common/typeCrudForm"
import GenerateForm from "src/components/CRUD/generateComponents"

// Traz a configuração pré-definida de urls do servidor
const urlServer: typeUrlServer = contentUrlServer.filter(
  item => item.name == "POPUP"
)[0];

const FormBanner = (props: {
  dataFormDb: {
    bf_liberation: Boolean;
    cr_code: String;
    wh_image: String;
    hl_link: String;
    nb_time: String;
  };
  nextCrCode: string;
}) => {
  let { dataFormDb, nextCrCode } = props;
  if (nextCrCode) {
    dataFormDb.cr_code = nextCrCode;
  }

  const subtitleContent: Array<typeCrudForm> = [
    {
      Header: "Popup",
      accessor: "wh_image",
      cols: "6",
      required: true,
      uniqueRow: true,
      url: urlServer.urlServer + urlServer.pathServer + "/",
      urlPathServerFile: urlServer.pathServer
    },
    {
      Header: "Código",
      accessor: "cr_code",
      cols: "6",
      required: true,
    },
    {
      Header: "Liberação",
      accessor: "bf_liberation",
      cols: "4",
      required: true,
    },
    {
      Header: "Titulo",
      accessor: "ws_title",
      cols: "6",
      required: true,
      errorMessage: "Titulo obrigatório",
    },
    {
      Header: "Link",
      accessor: "hl_link",
      cols: "6",
      required: false,
    },

    {
      Header: "Tempo popup (segundos)",
      accessor: "nb_time",
      cols: "6",
      required: true,
    },
  ];
  const subtitleForm: Array<typeCrudCollapse> = [
    {
      CollapseContent: subtitleContent,
    },
  ];
  return GenerateForm(dataFormDb, subtitleForm);
};

export default FormBanner;
