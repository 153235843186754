import React from "react"

//import Breadcrumbs
import moment from "moment"
import { formatDateTime } from "src/helpers/functions"
import CrudContainer from "./CrudContainer"
import {
  getCustomerByCode,
  getCustomerPagination,
  getNextCrCodeCustomer,
  insertCustomer,
  putOneField
} from "./graphqlQuery"
import { LabelCnpj, LabelSocial } from "./styled"

function WSrazao_cnpj({ value, column, row }) {
  return (
    <>
      <LabelCnpj>{value}</LabelCnpj>
      <LabelSocial>{row.original.wj_cnpj}</LabelSocial>
    </>
  );
}

const RegistrationApproval = () => {
  const columns = React.useMemo(
    () => [
      {
        Header: "Código",
        accessor: "cr_code",
      },
      {
        Header: "Email",
        accessor: "ox_registration_fields.wm_email",
        visible: false
      },
      {
        Header: "Código",
        accessor: "wj_cnpj",
        visible: false,
      },
      {
        Header: "Razão Social e CNPJ",
        accessor: "ox_registration_fields.ws_corporate_reason",
        Cell: WSrazao_cnpj,
        minWidth: 800,
        /* headerStyle: (colum, colIndex) => {
          return { width: "300px", textAlign: "center" };
        }, */
      },
      {
        Header: "Data Cadastro",
        accessor: "dx_register",
        Cell: ({ value, column, row }) => {
          return moment(value).format(formatDateTime);
        },
      },
      {
        Header: "Ações",
        accessor: "actions",
        Filter: () => <div style={{ height: "10px" }}></div>,
        Cell: cellContent => (
          <div
            style={{
              width: "100%",
              textAlign: "center",
              backgroundColor: "red",
            }}
          >
            {cellContent}
          </div>
        ),
      },
    ],
    []
  );
  return (
    <CrudContainer
      routePath=""
      queryGet={getCustomerPagination}
      queryGetByCode={getCustomerByCode}
      querySave={insertCustomer}
      queryUpdate={null}
      queryDelete={null}
      queryNextCrCode={getNextCrCodeCustomer}
      queryPutOneField={null}
      queryPreview={putOneField}
      title="Aprovação de cadastro | Braile Distribuidora"
      breadcrumbTitle="Cadastro"
      breadcrumbItem="Aprovação de cadastro"
      columnsTable={columns}
    />
  );
};

export default RegistrationApproval;
