import React, { useEffect, useState } from "react";
import { typeCrudListColumns } from "src/components/Common/typeCridList";
import ListCrudContainer from "src/components/CRUD/CrudList";
import { post } from "src/helpers/api_helper";
import modalPdfGenerator from "./modalPdfGenerator"

export default function ListCrud(props: {
  columnsTable: Array<typeCrudListColumns>;
  handleNewRegister?: String;
  setSelectedRow?: any;
  queryPreview?: any;
}) {
  const { columnsTable, handleNewRegister, setSelectedRow, queryPreview } =
    props;
  const [rowSelected, setRowSelected] = useState(null);
  const [modal_fullscreen, setmodal_fullscreen] = useState(false);
  const [modal_backdrop, setmodal_backdrop] = useState(false);

  /* Função APROVAR CADASTRO CLIENTE */
  const preview = React.useCallback(async (cr_code: string) => {
    if (!props.queryPreview) return;
    try {
      await post(
        "/graphql",
        {
          query: props.queryPreview(cr_code),
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      ).then(response => {
        if (!response.data.setCustomerApprove) {
          alert("Falha ao aprovar cadastro!");
          return;
        }
        alert(response.data.setCustomerApprove.message);
      });
    } catch (e) {
      console.error(e);
      alert(`Falha ao aprovar cliente!`);
    }
  }, []);

  function tog_backdrop() {    
    setmodal_backdrop(!modal_backdrop);
    removeBodyCss();    
  }
  function tog_fullscreen() {
    setmodal_fullscreen(!modal_fullscreen);
    removeBodyCss();
  }
  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  function getButtonViewPDF(row) {
    setRowSelected(row)
    return <>      
      <button
        type="button"
        className={`btn btn-primary waves-effect waves-light p-0 px-2 m-auto`}
        onClick={() => tog_fullscreen()}
      >
        <i className="mdi mdi-eye font-size-8"></i>
      </button>
    </>
  }

  return (<>
    {modalPdfGenerator(
        modal_fullscreen,
        setmodal_fullscreen,
        tog_fullscreen,
        rowSelected
      )}
    <ListCrudContainer
      columns={columnsTable}
      handleNewRegister={handleNewRegister}
      setSelectedRow={setRowSelected}      
      optionsActions={(row) => getButtonViewPDF(row)}
      visibleNewRegister={false}
      {...props}
      queryPreview={null}
    />
  </>);
}
