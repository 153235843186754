import moment from "moment";
import { useEffect, useState } from "react";
import { Input, Modal } from "reactstrap"
import { post } from "src/helpers/api_helper";
import { getListSubCategorys } from "../../graphqlQuery";

export default function modalConfirm(
  isOpen,
  toggleModal,
  subcategorySelected,
  setSubcategorySelected, 
  date, 
  setDate, 
  handleUpdate,
) {

  const [listSubcategories, setListSubcategories] = useState([]);
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    async function getListCategory() {
      let response = await post(
        "/graphql",
        {
          query: getListSubCategorys(),
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      ).then(response => response);
      console.log('responde', response);
      if (response.data[Object.keys(response.data)[0]]) {
        let temp = [];
        const list = response.data[Object.keys(response.data)[0]];
        list.map(item => {
          temp.push({
            cr_code: item.cr_code,
            ws_value: item.ws_name,
            qi_subgroup: item.qi_subcategories,
          });
        });
        temp = [
          {
            nameList: "ws_subcategories",
            content: temp,
          },
        ];
        let temp2 = [];
        const list2 = temp.filter(item => item.nameList === "ws_subcategories");
        list2.length &&
          list2.length > 0 &&
          list2[0].content.map(item =>
            temp2.push({
              cr_code: item.cr_code,
              ws_value: item.ws_value,
              qi_subgroup: item.qi_subgroup,
            })
          );

        let temp3 = [];
        // temp2.map(i => i.qi_subgroup.map(i => {
        //   //colocar o nome e o código
        // temp3.push({
        //   code: i.cr_code,
        //   name: i.ws_name,
        // })
        // }));

        temp2.map((i) => {
          i.qi_subgroup.map(item => {
            temp3.push({
              code: item.cr_code,
              name: item.ws_name,
              category: i.ws_value
            })
          })
        })

        let sorted = temp3.sort(function(a, b){
          if(a.category < b.category) { return -1; }
          if(a.category > b.category) { return 1; }
          return 0;
        })
        setListSubcategories([...sorted]);
      } else {
        alert("Falha ao trazer lista de categorias!");
        setListSubcategories([]);
      }
    }
    getListCategory();
  }, []);


  const handleChange = (event) => {
    let opts = [], opt;

    for (let i = 0, len = event.target.options.length; i < len; i++) {
      opt = event.target.options[i];

      if (opt.selected) {
        opts.push(opt.value);
      }
    }
    setSelected(opts);
    setSubcategorySelected(opts);
  }

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => {
        toggleModal();
      }}
      backdrop={"static"}
      id="staticBackdrop"
    >
      <div className="modal-header">
        <h5 className="modal-title" id="staticBackdropLabel">
          Atualizar produtos selecionados
        </h5>
        <button
          type="button"
          className="btn-close"
          onClick={() => {
            toggleModal();
          }}
          aria-label="Close"
        ></button>
      </div>
      <div className="modal-body">

        <Input
          value={selected}
          name={"inpSelSubCategory"}
          id={"inpSelSubCategory"}
          type="select"
          label="Subcategoria"
          onChange={handleChange}
          style={{ marginBottom: '10px', height: '350px' }}
          multiple
        >
          <option value={""}>Selecione a subcategoria</option>
          {listSubcategories.map((item, index) => {
            return (
              <option key={item.code} value={item.code}>
                {item.category} - {item.name}
              </option>
            );
          })}
        </Input>

        <Input
          type="date"
          label="Data de validade"
          value={date ? moment(moment(date, "YYYY-MM-DD")).format("YYYY-MM-DD") : null}
          onChange={e => {
            const dateFormatted = moment(moment(e.target.value, "YYYY-MM-DD")).format("YYYY-MM-DD");
            setDate(dateFormatted);
          }}
        />

      </div>
      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-light"
          onClick={() => toggleModal()}
        >
          Cancelar
        </button>
        <button type="button" className="btn btn-primary" onClick={() => {
          handleUpdate()
        }}>
          Atualizar
        </button>
      </div>
    </Modal>
  );
}
