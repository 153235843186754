import moment from "moment"
import React from "react"
import { contentUrlServer, typeUrlServer } from "src/common/urlServer"
import { formatDateTime } from "src/helpers/functions"
import CrudContainer from "./CrudContainer"
import {
  deleteBanner,
  getBannerByCode,
  getBanners,
  getNextCrCodeBanner,
  insertBanner,
  updateBanner
} from "./graphqlQuery"

const urlServer: typeUrlServer = contentUrlServer.filter(
  item => item.name == "BANNER"
)[0];

function BfLiberation({ value, column, row }) {
  return (
    <a>
      {value ? (
        <span style={{ color: "green" }}>Sim</span>
      ) : (
        <span style={{ color: "red" }}>Não</span>
      )}
    </a>
  );
}

function SizeImage({ value, column, row }) {
  /* return (
    <a>
      {value ? (
        <span style={{ color: "green" }}>Sim</span>
      ) : (
        <span style={{ color: "red" }}>Não</span>
      )}
    </a>
  ); */
}

const Banner = () => {
  const columns = React.useMemo(
    () => [
      {
        Header: "Imagem",
        accessor: "wh_image",
        imgAccessor: "hl_link",
        identification: "banner",
        Cell: ({ value, column, row }) => {
          const image = urlServer.urlServer + urlServer.pathServer + value;
          return (
            <div className="flex items-center">
              <div className="flex-shrink-0 h-10 w-10">
                <img className="rounded img-t1" src={image} alt="" />
              </div>
              <div className="ml-4">
                <div className="text-sm text-gray-500">
                  {row.original[column.imgAccessor]}
                </div>
              </div>
            </div>
          );
        },
      },
      {
        Header: "Código",
        accessor: "cr_code",
      },
      {
        Header: "Ordem Apresentação",
        accessor: "nb_order_apresentation",
      },
      {
        Header: "Tamanho",
        accessor: "",
      },
      {
        Header: "Liberação",
        Cell: BfLiberation,
        accessor: "bf_liberation",
      },
      {
        Header: "Data Cadastro",
        accessor: "dx_register",
        Cell: ({ value, column, row }) => {
          return moment(value).format(formatDateTime);
        },
      },
      {
        Header: "Ações",
        accessor: "actions",
        Filter: () => <div style={{ height: "10px" }}></div>,
        Cell: cellContent => (
          <div
            style={{
              width: "100%",
              textAlign: "center",
              backgroundColor: "red",
            }}
          >
            {cellContent}
          </div>
        ),
      },
    ],
    []
  );

  return (
    <CrudContainer
      routePath=""
      queryGet={getBanners}
      queryGetByCode={getBannerByCode}
      querySave={insertBanner}
      queryUpdate={updateBanner}
      queryDelete={deleteBanner}
      queryNextCrCode={getNextCrCodeBanner}
      title="Banner | Braile Distribuidora"
      breadcrumbTitle="Banner"
      breadcrumbItem="Banners"
      columnsTable={columns}
    />
  );
};

export default Banner;
