/* IMPORTANTE: Deve incluir as fun principais do CRUD
 * getData, updateData, deleteData, postData
 * estas chamadas são padrão no CRUD/CrudGroupForm */

export const getCustomerByCode = (cr_code: string): string => {
  return `{
    getBundles {
      cr_code_erp
      cv_version
      bf_active
      bf_liberation
      cr_code
      ck_registeruser
      dx_register
      dx_modification
      bf_highlighted
      bf_hot_deal
      bf_new_product
      qs_buy_too
      qs_bundle
      cr_code_manufactures
      ws_name_manufactures
      cr_fiscal_classification
      cr_code_category
      cr_code_subcategory
      dt_validate_new
      ws_commercial_description
      wu_unit_measure
      ws_part_number
      dc_gross_weight
      dc_inventory_balance
      dc_percent_ipi
      nb_tax_code_source
      ws_commercial_text
      wh_image
      qi_price{
          dc_price
          wr_price_currency
          sl_price_description
      }
    }
  }`;
};

export const getBundles = (): string => {
  return `query {
    getBundles {
      bf_liberation
      ws_commercial_description      
    }
  }`;
};

export const deleteCustomer = (cr_code: string): string => {
  return `query {
    deleteCustomerByCode(cr_code:"${cr_code}") {
      message
    }
  }`;
};

export const updateCustomer = (data: {
  bf_liberation: Boolean;
  cr_code: String;
  ws_name: String;
  wh_image: String;
  ws_abbreviation: String;
  hl_abbreviation_url: String;
}): string => {
  return `mutation {
    updateCustomer(
      bf_liberation: ${data.bf_liberation},
      cr_code: "${data.cr_code ? data.cr_code : ""}",
      ws_name: "${data.ws_name ? data.ws_name : ""}",
      wh_image: "${data.wh_image ? data.wh_image : ""}",
      ws_abbreviation: "${data.ws_abbreviation ? data.ws_abbreviation : ""}",
      hl_abbreviation_url: "${
        data.hl_abbreviation_url ? data.hl_abbreviation_url : ""
      }",
   ){
  	message
   }
  }`;
};

export const insertCustomer = (data: {
  bf_liberation: Boolean;
  cr_code: String;
  ws_name: String;
  wh_image: String;
  ws_abbreviation: String;
  hl_abbreviation_url: String;
}): string => {
  return `mutation {
    insertCustomer(
      bf_liberation: ${data.bf_liberation},
      cr_code: "${data.cr_code ? data.cr_code : ""}",
      ws_name: "${data.ws_name ? data.ws_name : ""}",
      wh_image: "${data.wh_image ? data.wh_image : ""}",
      ws_abbreviation: "${data.ws_abbreviation ? data.ws_abbreviation : ""}",
      hl_abbreviation_url: "${
        data.hl_abbreviation_url ? data.hl_abbreviation_url : ""
      }",
   ){
  	message
   }
  }`;
};

export const getNextCrCodeCustomer = (): string => {
  return `query {
    getNextCrCodeCustomer {
      result
    }
  }`;
};

export const getCustomerPagination = (
  filter: String,
  limit: Number = 100,
  random: Boolean = false,
  skip: Number = 0,
  sort: String = "{}"
): string => {
  let filter2 = '{"ox_register_approval.bf_approval":false}';
  let filter3 = JSON.stringify(filter2);
  return `query {
    getCustomerPagination(filter: {
        filter: ${filter3},
        sort: "${sort}",
        skip: ${skip},  
        limit: ${limit},                      
        random: ${random},
      }) {
      result {
        cv_version
        bf_active
        bf_liberation
        cr_code
        ck_registeruser
        dx_register
        dx_modification
        wj_cnpj
        cr_code_seller
        cr_code_operation
        cr_code_registration
        wp_password
        sl_type_client
        sl_type_transport
        bf_restricted_registry
        bf_own_credit
        bf_generate_budget
        sl_curve_type
        nb_tax_condition
        dx_data_login
        sl_payment_term {
          value
          key
          check
        }
        sl_special_payment {
          value
          key
          check
        }
        ws_seller
        ox_registration_fields {
          cv_version
          bf_active
          bf_liberation
          cr_code
          ck_registeruser
          dx_register
          dx_modification
          ws_corporate_reason
          wj_cnpj
          wi_state_registration
          wm_email
          ws_indication
          ld_doc_social_contract
          ld_doc_social_identity
          ld_doc_address_voucher
          ld_doc_income_voucher
          ld_doc_state_registration
          ld_doc_cnpj
          ws_supplier_reference
          ws_bank_reference
          ws_agency_reference
          ct_contact_reference
          bf_approval
          dx_register_approval
          ws_registeruserapprovalaccount
          ox_register_approval {
            bf_approval
            dx_register
            ws_registeruserapprovalaccount
          }
          ct_qi_phone_contact {
            ws_description
            nc_number
            bf_whatsapp
          }
          qi_address {
            ws_street
            nb_street_number
            ws_district
            ws_city
            ws_state
            zp_zipcode
            ws_complement
            ox_geo {
              lt_lat
              lt_lng
            }
          }
        }
      } 
      rows
      rowsall
    }
  }`;
};

export const getPaymentTerms = (): string => {
  return `query {
    getPaymentTerms {
        cr_code
        ws_name
    }
  }`;
};
