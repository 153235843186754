import {
  typeCrudCollapse,
  typeCrudForm
} from 'src/components/Common/typeCrudForm';
import GenerateForm from 'src/components/CRUD/generateComponents';

const FormManufacturer = (props: {
  dataFormDb: {
    bf_liberation: Boolean;
    cr_code: String;
    ws_name: String;
    wh_image: String;
    ws_abbreviation: String;
    hl_abbreviation_url: String;
  };
  nextCrCode: string;
}) => {
  let { dataFormDb, nextCrCode } = props;
  if (nextCrCode) {
    dataFormDb.cr_code = nextCrCode;
  }

  const subtitleContent: Array<typeCrudForm> = [
    {
      Header: 'Logotipo',
      accessor: 'wh_image',
      cols: '6',
      required: true,
      uniqueRow: true
    },
    {
      Header: 'Código',
      accessor: 'cr_code',
      cols: '6',
      required: true
    },
    {
      Header: 'Liberação',
      accessor: 'bf_liberation',
      cols: '4',
      required: false
    },
    {
      Header: 'Nome',
      accessor: 'ws_name',
      cols: '6',
      required: true,
      uniqueRow: true
    },
    {
      Header: 'Abreviatura',
      accessor: 'ws_abbreviation',
      cols: '6',
      required: false,
      uniqueRow: true
    },
    {
      Header: 'Abreviatura URL',
      accessor: 'hl_abbreviation_url',
      cols: '6',
      required: false
    }
  ];
  const subtitleForm: Array<typeCrudCollapse> = [
    {
      CollapseContent: subtitleContent
    }
  ];

  return GenerateForm(dataFormDb, subtitleForm);
};

export default FormManufacturer;
