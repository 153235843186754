/* IMPORTANTE: Deve incluir as fun principais do CRUD
 * getData, updateData, deleteData, postData
 * estas chamadas são padrão no CRUD/CrudGroupForm */

export const getOrderByCode = (cr_code: string): string => {
  return `{
    getOrderByCode(cr_code:"${cr_code}") {
      cv_version
      bf_active
      bf_liberation
      cr_code
      ck_registeruser
      dx_modification
      cr_code_client
      dx_register_send_email
      dx_register
      cr_code_erp
      cr_code_seller
      ws_seller_name
      nb_salary_table_code
      ws_salary_table_description
      ws_operation_code
      ws_operation_description
      ws_operation_code_nature
      ws_sl_freight_type
      ws_site_type_payment
      ws_site_budget
      ws_site_type_delivery
      ws_site_situation
      ws_site_observation
      ws_order_via      
      wj_cnpj
      ws_client
      ws_seller
      ws_sum_order_itens
      dc_amount_total
      dc_value_total
      cr_pagarme
      dc_charge_pagarme
    }
  }`;
};

export const getOrderPagination = (
  filter: string,
  limit: Number = 10,
  random: Boolean = false,
  skip: Number = 0,
  sort: String = "{}"
): string => {
  let filter2;

  if (filter === undefined || filter === "{}") {
    filter2 = filter //no inicio sem filtro nenhum
  } else {
    let filterText = '';
    if (isJson(filter)) {
      let filterJson = JSON.parse(filter);
      if (filterJson.$text && filterJson.$text.$search) {
        filterText = JSON.stringify(filterJson.$text.$search);
      }
    } 

    if (filterText) {
      filter2 = `{"$text":{"$search":${filterText}}}`; //com f8ltro busca
    } else {
      filter2 = JSON.stringify(filter);  //filtro custom
    }
  }

  let filter3 = JSON.stringify(filter2);

  return `query {
    getOrderPagination(filter: {
        filter: ${filter3},
        sort: "${sort}",
        skip: ${skip},  
        limit: ${limit},                      
        random: ${random},
    }) 
    {
      result {
        cv_version
        bf_active
        bf_liberation
        cr_code
        ck_registeruser
        dx_modification
        cr_code_client
        dx_register_send_email
        dx_register
        cr_code_erp
        cr_code_seller
        ws_seller_name
        nb_salary_table_code
        ws_salary_table_description
        ws_operation_code
        ws_operation_description
        ws_operation_code_nature
        ws_sl_freight_type
        ws_site_type_payment
        ws_site_budget
        ws_site_type_delivery
        ws_site_situation
        ws_site_observation
        ws_order_via      
        wj_cnpj
        ws_client
        ws_seller
        ws_sum_order_itens
        dc_amount_total
        dc_value_total
        cr_pagarme
        dc_charge_pagarme
      }
      rows
      rowsall
    }
  }`;
};

export const deleteOrder = (cr_code: string): string => {
  return `query {
    deleteOrderByCode(cr_code:"${cr_code}") {
      message
    }
  }`;
};

export const updateOrder = (data: {
  bf_liberation: Boolean;
  cr_code: String;
  ws_name: String;
  wh_image: String;
  ws_abbreviation: String;
  hl_abbreviation_url: String;
}): string => {
  return `mutation {
    updateOrder(
      bf_liberation: ${data.bf_liberation},
      cr_code: "${data.cr_code ? data.cr_code : ""}",
      ws_name: "${data.ws_name ? data.ws_name : ""}",
      wh_image: "${data.wh_image ? data.wh_image : ""}",
      ws_abbreviation: "${data.ws_abbreviation ? data.ws_abbreviation : ""}",
      hl_abbreviation_url: "${
        data.hl_abbreviation_url ? data.hl_abbreviation_url : ""
      }",
   ){
  	message
   }
  }`;
};

export const insertOrder = (data: {
  bf_liberation: Boolean;
  cr_code: String;
  ws_name: String;
  wh_image: String;
  ws_abbreviation: String;
  hl_abbreviation_url: String;
}): string => {
  return `mutation {
    insertOrder(
      bf_liberation: ${data.bf_liberation},
      cr_code: "${data.cr_code ? data.cr_code : ""}",
      ws_name: "${data.ws_name ? data.ws_name : ""}",
      wh_image: "${data.wh_image ? data.wh_image : ""}",
      ws_abbreviation: "${data.ws_abbreviation ? data.ws_abbreviation : ""}",
      hl_abbreviation_url: "${
        data.hl_abbreviation_url ? data.hl_abbreviation_url : ""
      }",
   ){
  	message
   }
  }`;
};

export const getNextCrCodeOrder = (): string => {
  return `query {
    getNextCrCodeOrder {
      result
    }
  }`;
};

export const getOrderItensByCodeOrder = (cr_code: string): string => {
  return `{
    getOrderItensByCodeOrder(cr_code_order:"${cr_code}") {
      cv_version
      bf_active
      bf_liberation
      cr_code
      ck_registeruser
      dx_register
      dx_modification
      cr_code_order
      cr_code_product
      nb_sequence
      ws_category
      ws_subcategory
      ws_product_name
      ws_product_unit
      ws_operation_code
      ws_operation_description
      ws_operation_code_nature
      dc_amount
      dc_unitary_value
      dc_ipi_value
      dc_st_value
      dc_icms_percentage
      dc_ipi_percentage
      dc_perc_icms_subst
      dc_perc_base_subst_icms
      ws_day_title
      ws_unitary_value_format
      ws_sum_value_format
      ws_amount_format
      ws_unitary_value_ipi_st_format
      ws_sum_value_fiscal_format
    }
  }`;
};

export const getCustomers = (): string => {
  return `query {
    getCustomers {
      cr_code
      ox_registration_fields {
        ws_corporate_reason
        wj_cnpj        
      }
    }
  }`;
};

export const getSellers = (): string => {
  return `query {
    getSellers {
      cr_code
      ws_name      
    }
  }`;
};

function isJson(str) {
  try {
      JSON.parse(str);
  } catch (e) {
      return false;
  }
  return true;
}

export const getOrderFilter = (
  filter: string = "{}",
  limit: Number = 10,
  random: Boolean = false,
  skip: Number = 0,
  sort: String = "{}"
): string => {
  let filter2;

  if (filter === undefined || filter === "{}" || filter === "") {
    filter2 = filter //no inicio sem filtro nenhum
  } else {
    let filterText = '';
    if (isJson(filter)) {
      let filterJson = JSON.parse(filter);
      if (filterJson.$text && filterJson.$text.$search) {
        filterText = JSON.stringify(filterJson.$text.$search);
      }
    } 

    if (filterText) {
      filter2 = `{"$text":{"$search":${filterText}}}`; //com f8ltro busca
    } else {
      filter2 = JSON.stringify(filter);  //filtro custom
    }
  }

  let filter3 = filter2 === "" ? JSON.stringify("{}") : JSON.stringify(filter2);

  return `query {
    getOrderPagination(filter: {
        filter: ${filter3},
        sort: "${sort}",
        skip: ${skip},  
        limit: ${limit},                      
        random: ${random},
    }) 
    {
      result {
        cr_code
        ws_client
        ws_seller
        ws_site_situation
        dc_value_total
        ws_site_budget
        dx_register
      }
      rows
      rowsall
    }
  }`;
};