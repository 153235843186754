import { contentUrlServer, typeUrlServer } from "src/common/urlServer"
import {
  Box,
  BoxBestOffer,
  BoxBestOfferLogged,
  BoxCard,
  BoxCardLogged,
  BoxCardRed,
  BoxCardRedLogged,
  BoxImgStyled,
  Boxtext,
  BoxtextLogged,
  BoxValue,
  ButtonYellowReservation,
  CardBodyStyled,
  CardImgLoggedStyled,
  CardImgSimpleStyled,
  CardImgStyled,
  CardStyled,
  CardTilteStlyed,
  CodStyled,
  ContainerImg,
  InnerBoxValue,
  TagPriceStyled,
  TagPriceStyledPart,
  TextPartPrice,
  TextTotalPrice
} from "./styled"

export type CardItemType = {
  image: string;
  title: string;
  subTitle: string;
  codProduct: string;
  totalPrice: string;
  partPrice: string;
  partPriceFormat: string;
  discountPrice: string;
  discountPriceFormat: string;
  bfHotDeal: boolean;
  bfNewProduct: boolean;
};

const imageDefault =
  "https://camo.githubusercontent.com/b7b7dca15c743879821e7cfc14e8034ecee3588e221de0a6f436423e304d95f5/68747470733a2f2f7a7562652e696f2f66696c65732f706f722d756d612d626f612d63617573612f33363664616462316461323032353338616531333332396261333464393030362d696d6167652e706e67";

type CardViewProductProps = {
  cardItem: CardItemType;
  isLogged: boolean;
  horizontal: boolean;
  simple: boolean;
  offerRed: boolean;
  messages: any;
  options?: any;
};

// Traz a configuração pré-definida de urls do servidor
const urlServer: typeUrlServer = contentUrlServer.filter(
  item => item.name == "PRODUCTIMAGE"
)[0];

export default function CardViewProduct({
  cardItem,
  horizontal,
  simple,
  offerRed,
  messages,
  options,
}: CardViewProductProps) {
  const isLogged = true;
  const imageToRender =
    cardItem && cardItem.image
      ? urlServer.urlServer + urlServer.pathServer + cardItem.image
      : imageDefault;

  const {
    textFontSize,
    boxHeight,
    boxWidth,
    boxBorderColor,
    priceAlign,
    priceValueFontSize,
    typePrice,
    boxMarginRight,
    boxMarginBottom,
    priceMarginTop,
    priceMarginRight,
  } = options;

  console.log(options);
  if (offerRed)
    return (
      <div>
        {isLogged ? (
          <>
            <BoxBestOfferLogged>MELHOR OFERTA</BoxBestOfferLogged>
            <BoxCardLogged
              discountSale={cardItem.partPriceFormat}
              height={options && boxHeight}
              width={options && boxWidth}
            >
              <BoxCardRedLogged discountSale={cardItem.partPriceFormat}>
                <CardImgLoggedStyled offerRed={offerRed} src={imageToRender} />
                <CardBodyStyled>
                  <CardTilteStlyed offerRed={offerRed} fontSize={options && textFontSize}>
                    {cardItem.title}
                  </CardTilteStlyed>
                  <BoxtextLogged offerRed={offerRed} horizontal={null}>
                    {/* <CardSubtitleStlyed offerRed={offerRed}>
                    {cardItem.subTitle}
                  </CardSubtitleStlyed> */}
                    <CodStyled offerRed={offerRed} horizontal={horizontal}>
                      Cód. {cardItem.codProduct}
                    </CodStyled>
                  </BoxtextLogged>
                  {cardItem.partPriceFormat && (
                    <BoxValue priceAlign={options && priceAlign} typePrice={options && typePrice} >
                      <TextTotalPrice offerRed={offerRed} horizontal={null}>
                        {cardItem.partPriceFormat}
                      </TextTotalPrice>
                      <TagPriceStyled offerRed={offerRed} horizontal={null}>
                        {" "}
                        &nbsp;a prazo
                      </TagPriceStyled>
                    </BoxValue>
                  )}
                  {cardItem.discountPriceFormat && (
                    <BoxValue typePrice={options && typePrice}>
                      <TextPartPrice
                        offerRed={offerRed} 
                        horizontal={null}
                        fontSize={options && priceValueFontSize}
                      >
                        {cardItem.discountPriceFormat}
                      </TextPartPrice>
                      <TagPriceStyledPart offerRed={offerRed} horizontal={null}>
                        &nbsp;a vista
                      </TagPriceStyledPart>
                    </BoxValue>
                  )}
                </CardBodyStyled>
              </BoxCardRedLogged>
            </BoxCardLogged>
          </>
        ) : (
          <BoxCard>
            <BoxBestOffer>MELHOR OFERTA</BoxBestOffer>
            <BoxCardRed>
              <CardImgStyled offerRed={offerRed} src={imageToRender} />
              <CardBodyStyled>
                <CardTilteStlyed offerRed={offerRed}>
                  {cardItem.title}
                </CardTilteStlyed>
                <Boxtext offerRed={offerRed} horizontal={null}>
                  {/* <CardSubtitleStlyed offerRed={offerRed}>
                    {cardItem.subTitle}
                  </CardSubtitleStlyed> */}
                  <CodStyled
                    offerRed={offerRed}
                    horizontal={horizontal}
                    style={{ marginTop: "-15px" }}
                  >
                    Cód. {cardItem.codProduct}
                  </CodStyled>
                </Boxtext>
              </CardBodyStyled>
            </BoxCardRed>
          </BoxCard>
        )}
      </div>
    );
  if (simple)
    return (
      <div>
        <Box>
          <CardImgSimpleStyled horizontal={horizontal} src={imageToRender} />
        </Box>
      </div>
    );

  return (
    <div>
      <CardStyled horizontal={horizontal} width={options && boxWidth} borderColor={options && boxBorderColor} marginBottom={options && boxMarginBottom} marginRight={options && boxMarginRight}>
        {" "}
        <ContainerImg>
          <BoxImgStyled horizontal={horizontal}>
            <CardImgStyled src={imageToRender} />
          </BoxImgStyled>
        </ContainerImg>
        <CardBodyStyled horizontal={horizontal}>
          <CardTilteStlyed horizontal={horizontal} fontSize={options && textFontSize}>
            {cardItem.title}
          </CardTilteStlyed>
          <Boxtext horizontal={horizontal}>
            {/* <CardSubtitleStlyed horizontal={horizontal}>
              {cardItem.subTitle}
            </CardSubtitleStlyed> */}
            <CodStyled horizontal={horizontal}>
              Cód. {cardItem.codProduct}
            </CodStyled>
          </Boxtext>
          {isLogged && !horizontal && !simple && (
            <>
              {!cardItem.discountPriceFormat && !cardItem.partPriceFormat ? (
                <>
                  <InnerBoxValue
                    style={{
                      marginBottom:
                        cardItem.discountPrice && !cardItem.partPrice
                          ? "-4px"
                          : "5px",
                    }}
                  >
                    <ButtonYellowReservation
                      onClick={() => alert("Item solicitado com sucesso!")}
                      style={{
                        marginTop:
                          cardItem.discountPrice && !cardItem.partPrice
                            ? "13px"
                            : "24px",
                      }}
                    >
                      Avise-me quando chegar
                    </ButtonYellowReservation>
                  </InnerBoxValue>
                </>
              ) : (
                <>
                  {cardItem.partPriceFormat && (
                    <BoxValue priceAlign={options && priceAlign} marginTop={options && priceMarginTop} marginRight={options && priceMarginRight}>
                      <TextTotalPrice horizontal fontSize={options && priceValueFontSize}>
                        {cardItem.partPriceFormat}
                      </TextTotalPrice>
                      <TagPriceStyled horizontal style={{fontSize: priceValueFontSize}}> &nbsp;a prazo</TagPriceStyled>
                    </BoxValue>
                  )}
                  {cardItem.discountPriceFormat && (
                    <BoxValue priceAlign={options && priceAlign} marginTop={options && priceMarginTop} marginRight={options && priceMarginRight}>
                      <TextPartPrice horizontal fontSize={options && priceValueFontSize}>
                        {cardItem.discountPriceFormat}
                      </TextPartPrice>
                      <TagPriceStyledPart horizontal fontSize={options && priceValueFontSize}>
                        &nbsp;à vista
                      </TagPriceStyledPart>
                    </BoxValue>
                  )}
                </>
              )}
              {!options.imgFooter && messages}
            </>
          )}
        </CardBodyStyled>
        {!horizontal && !simple && <></>}
        {!cardItem.discountPriceFormat && !cardItem.partPriceFormat && (
          <>{/* <div style={{ height: '24px' }}></div> */}</>
        )}
      </CardStyled>
    </div>
  );
}

CardViewProduct.defaultProps = {
	options: {},
};