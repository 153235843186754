//Import Icons
import Icon from '@ailibs/feather-react-ts';
// MetisMenu
import MetisMenu from 'metismenujs';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef } from 'react';
//i18n
import { withTranslation } from 'react-i18next';
import { Link, withRouter } from 'react-router-dom';
// //Import Scrollbar
import SimpleBar from 'simplebar-react';
// Import rotas
import { RouterProps, routers } from 'src/routes/router';

const SidebarContent = (props: any) => {
  const ref = useRef<any>();

  const activateParentDropdown = useCallback(item => {
    item.classList.add('active');
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== 'side-menu') {
      parent2El.classList.add('mm-show');
    }

    if (parent) {
      parent.classList.add('mm-active');
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add('mm-show'); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add('mm-active'); // li
          parent3.childNodes[0].classList.add('mm-active'); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add('mm-show'); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add('mm-show'); // li
              parent5.childNodes[0].classList.add('mm-active'); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }, []);

  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname;

    const initMenu = () => {
      new MetisMenu('#side-menu');
      let matchingMenuItem = null;
      const ul: any = document.getElementById('side-menu');
      const items = ul.getElementsByTagName('a');
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i];
          break;
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    };
    initMenu();
  }, [props.location.pathname, activateParentDropdown]);

  useEffect(() => {
    ref.current.recalculate();
  });

  function scrollElement(item: any) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  function routerMap(routers: Array<RouterProps>) {
    return routers.map(item => routerMapChildren(item));
  }

  function routerMapChildren(router: RouterProps) {

    return (
      <li>
        <Link to={router.link} className={router.children ? 'has-arrow' : ''}>
          {router.icon && <Icon name={router.icon} />}
          <span>{props.t(router.name)}</span>
        </Link>
        {router.children && routerMapChildrenChildren(router.children)}
      </li>
    );
  }

  function routerMapChildrenChildren(router: Array<RouterProps>) {
    return (
      <ul className="sub-menu">
        {router &&
          router.map((child, index) => (
            <li key={`routerMapChildrenChildren_${index}`}>
              <Link to={"/" + child.link}>{props.t(child.name)}</Link>
              {child.children && routerMap(child.children)}
            </li>
          ))}
      </ul>
    );
  }

  return (
    <React.Fragment>
      <SimpleBar style={{ maxHeight: '100%' }} ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li>
              <Link to="/dashboard" className="">
                <Icon name="home" />
                <span>{props.t('Painel Geral')}</span>
              </Link>
            </li>
            <li className="menu-title">{props.t('Menu')} </li>
            {routerMap(routers)}
          </ul>
          {/* <div className="card sidebar-alert border-0 text-center mx-4 mb-0 mt-5">
            <div className="card-body">
              <img src={giftBox} alt="" />
              <div className="mt-4">
                <h5 className="alertcard-title font-size-16">
                  Unlimited Access
                </h5>
                <p className="font-size-13">
                  Upgrade your plan from a Free trial, to select ‘Business
                  Plan’.
                </p>
                <a href="#!" className="btn btn-primary mt-2">
                  Upgrade Now
                </a>
              </div>
            </div>
          </div> */}
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any
};

export default withTranslation()(withRouter(SidebarContent));
