import React, { useState } from "react"

//import Breadcrumbs
import moment from "moment";
import { formatDateTime } from "src/helpers/functions";
import CrudContainer from "./CrudContainer";
import {
  getCustomerByCode,
  getLgpdAccept,
  getLgpdPreview,
  getNextCrCodeCustomer,
  insertCustomer,
} from "./graphqlQuery";
import { LabelCnpj, LabelSocial } from "./styled";

function WSrazao_cnpj({ value, column, row }) {
  return (
    <>
      <LabelCnpj>{value}</LabelCnpj>
      <LabelSocial>{row.original.wj_cnpj}</LabelSocial>
    </>
  );
}

const RegistrationApproval = () => {
  const [modal_fullscreen, setmodal_fullscreen] = useState(false);

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  function tog_fullscreen() {
    setmodal_fullscreen(!modal_fullscreen);
    removeBodyCss();
  }

  
  const columns = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "cr_code",
      },

      {
        Header: "Razão Social e CNPJ",
        accessor: "ws_corporate_reason",
        Cell: WSrazao_cnpj,
        minWidth: 800,
        /* headerStyle: (colum, colIndex) => {
          return { width: "300px", textAlign: "center" };
        }, */
      },
      {
        Header: "	Política aceita em",
        accessor: "dx_register",
        Cell: ({ value, column, row }) => {
          return moment(value).format(formatDateTime);
        },
      },
      {
        Header: "Política Versão",
        accessor: "bf_active",
        Cell: ({ value, column, row }) => {
          return "6";
        },
      },
      {
        Header: "Políticas atualizada em",
        accessor: "dx_modification",
        Cell: ({ value, column, row }) => {
          return moment(value).format(formatDateTime);
        },
      },
      {
        Header: "CNPJ",
        accessor: "wj_cnpj",
        visible: false
      },
      {
        Header: "ip",
        accessor: "ip_ipaddress",
        visible: false
      },
      {
        Header: "ip",
        accessor: "cr_code_privacy_policy",
        visible: false
      },
      {
        Header: "ip",
        accessor: "cr_code_client",
        visible: false
      },       
      {
        Header: "Ações",
        accessor: "actions",
        Filter: () => <div style={{ height: "10px" }}></div>,
        Cell: cellContent => (
          <div
            style={{
              width: "100%",
              textAlign: "center",
              backgroundColor: "red",
            }}
          >
            {cellContent}
          </div>
        ),
        /* headerClassName: "table-react-header", */
      },
    ],
    []
  );
  return (
    <CrudContainer
      routePath=""
      queryGet={getLgpdAccept}
      queryGetByCode={getCustomerByCode}
      querySave={insertCustomer}
      queryUpdate={null}
      queryDelete={null}
      queryNextCrCode={getNextCrCodeCustomer}
      queryPreview={null}
      title="LGPD | Braile Distribuidora"
      breadcrumbTitle="Consulta"
      breadcrumbItem="LGPD"
      columnsTable={columns}
    />
  );
};

export default RegistrationApproval;
