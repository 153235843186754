import { contentUrlServer, typeUrlServer } from "src/common/urlServer"
import {
  typeCrudCollapse,
  typeCrudForm
} from "src/components/Common/typeCrudForm"
import GenerateForm from "src/components/CRUD/generateComponents"

// Traz a configuração pré-definida de urls do servidor
const urlServer: typeUrlServer = contentUrlServer.filter(
  item => item.name == "BANNER"
)[0];

const FormBanner = (props: {
  dataFormDb: {
    bf_liberation: Boolean;
    cr_code: String;
    wh_image: String;
    ws_image_name: String;
    hl_link: String;
    ws_text_botton: String;
    ws_text_title: String;
    nb_order_apresentation: Number;
  };
  nextCrCode: string;
}) => {
  let { dataFormDb, nextCrCode } = props;
  if (nextCrCode) {
    dataFormDb.cr_code = nextCrCode;
  }
  const subtitleContent: Array<typeCrudForm> = [
    {
      Header: "Banner",
      accessor: "wh_image",
      cols: "6",
      required: true,
      uniqueRow: true,
      urlPathServerFile: urlServer.pathServer
    },
    {
      Header: "Código",
      accessor: "cr_code",
      cols: "6",
      required: true,
    },
    {
      Header: "Liberação",
      accessor: "bf_liberation",
      cols: "4",
      required: true,
    },
    {
      Header: "Link",
      accessor: "hl_link",
      cols: "6",
      required: true,
    },

    {
      Header: "Ordem Apresentação",
      accessor: "nb_order_apresentation",
      cols: "6",
      required: true,
    },
    {
      Header: "Texto do botão",
      accessor: "ws_text_botton",
      cols: "6",
      required: false,
    },
  ];
  const subtitleForm: Array<typeCrudCollapse> = [
    {
      CollapseContent: subtitleContent,
    },
  ];
  return GenerateForm(dataFormDb, subtitleForm);
};

export default FormBanner;
