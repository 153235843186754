import _ from "lodash"
import { Col } from "reactstrap"
import { typeCrudForm } from "src/components/Common/typeCrudForm"
import AvFieldBraile from "src/components/general/Input/AvFieldBraile";
import { Observation } from "../styled"

// Input # Valor decimal
export default function getDC(
  valueField: any,
  subtitleFields: typeCrudForm,
  dataFormDb: any
): any {
  if (subtitleFields.cols === "0") return "";
  // if (valueField === undefined || valueField === null) return "teste dc";
  return (
    <Col
      className="mt-3"
      lg={subtitleFields.cols}
      sm={subtitleFields.cols}
      md={subtitleFields.cols}
      xs={12}
    >
      <AvFieldBraile
        disabled={subtitleFields.disabled}
        value={valueField}
        name={subtitleFields.accessor}
        id={
          subtitleFields.idfield
            ? subtitleFields.idfield
            : subtitleFields.accessor
        }
        label={subtitleFields.Header}
        placeholder=""
        type="text"
        errorMessage={
          subtitleFields.errorMessage
            ? subtitleFields.errorMessage
            : "Campo obrigatório"
        }
        validate={{ required: { value: subtitleFields.required } }}
        onChange={e =>
          _.set(
            dataFormDb,
            subtitleFields.accessor,
            e.target.value !== "" ? e.target.value : "0"
          )
        }
      />
      {subtitleFields.observation && (
        <Observation>{subtitleFields.observation}</Observation>
      )}
    </Col>
  );
}