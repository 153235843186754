import { contentUrlServer, typeUrlServer } from "src/common/urlServer"
import { typeCrudListColumns } from "src/components/Common/typeCridList"
import {
  typeCrudCollapse,
  typeCrudForm
} from "src/components/Common/typeCrudForm"
import GenerateForm from "src/components/CRUD/generateComponents"
import { FormDayItens } from "./formDayItens"
import { FormPreview } from "./formPreview"

// Traz a configuração pré-definida de urls do servidor
const urlServer: typeUrlServer = contentUrlServer.filter(
  item => item.name == "DAY"
)[0];

const FormDay = (props: {
  dataFormDb: {
    bf_liberation: Boolean;
    cr_code: String;
    ws_name: String;
    wh_image: String;
    ws_abbreviation: String;
    hl_abbreviation_url: String;
  };
  columnsTable: Array<typeCrudListColumns>;
  nextCrCode: string;
  listItens: any;
}) => {
  // const [refresh, setRefresh] = useState<boolean>(false);
  let { dataFormDb, columnsTable, nextCrCode, listItens } = props;
  const STATES = [
    {value: 'RS', label: 'Rio Grande do Sul'},
    {value: 'SC', label: 'Santa Catarina'},
  ];
  
  if (nextCrCode) {
    dataFormDb.cr_code = nextCrCode;
  }

  function adapterListManufacturers(): Array<{
    cr_code: string;
    ws_value: string;
  }> {
    let temp = [];
    const list = listItens.filter(item => item.nameList === "ws_manufacturers");
    list.length &&
      list.length > 0 &&
      list[0].content.map(item =>
        temp.push({
          cr_code: item.cr_code,
          ws_value: item.ws_value,
        })
      );
    return temp;
  }  

  function listStates(): Array<{
    cr_code: string;
    ws_value: string;
  }> {
    let temp = [];
    STATES.map(item => temp.push({
      cr_code: item.value,
      ws_value: item.label,
    }))

    return temp;
  }

  const subtitleContent: Array<typeCrudForm> = [    
    {
      Header: "Código",
      accessor: "cr_code",
      cols: "8",
      required: true,
      disabled: true
    },
    {
      Header: "Título",
      accessor: "ws_title",
      cols: "8",
      required: true,
    },
    {
      Header: "UF",
      accessor: "sl_uf",
      cols: "4",
      required: true,
    },
    {
      Header: "Fabricante",
      accessor: "cr_code_manufacturer",
      cols: "8",
      required: false,
      listItens: adapterListManufacturers(),
    },
    {
      Header: "Imagem Destaque",
      accessor: "wh_highlight_image",
      cols: "8",
      required: true,
      uniqueRow: true,
      urlPathServerFile: urlServer.pathServer + "destaque/",
      url: urlServer.urlServer + urlServer.pathServer + "destaque/",
    },
    {
      Header: "Imagem Dest. Transição",
      accessor: "wh_highlight_image_transition",
      cols: "8",
      required: false,
      uniqueRow: true,
      urlPathServerFile: urlServer.pathServer + "destaque_transicao/",
      url: urlServer.urlServer + urlServer.pathServer + "destaque_transicao/",
    },
    {
      Header: "Imagem Topo",
      accessor: "wh_top_image",
      cols: "8",
      required: false,
      uniqueRow: true,
      urlPathServerFile: urlServer.pathServer + "topo/",
      url: urlServer.urlServer + urlServer.pathServer + "topo/",
    },
    {
      Header: "Imagem Rodapé",
      accessor: "wh_footer_image",
      cols: "8",
      required: false,
      uniqueRow: true,
      urlPathServerFile: urlServer.pathServer + "rodape/",
      url: urlServer.urlServer + urlServer.pathServer + "rodape/",
    },
    {
      Header: "Cor Fundo Destaque",
      accessor: "cl_color_background",
      cols: "2",
      required: false,
      uniqueRow: true,
    },
    {
      Header: "Período Publicação de",
      accessor: "dt_publication_begin",
      cols: "4",
      required: false,
      uniqueRow: true,
      default: new Date().toISOString(),
      complementValueFieldEnd: "T00:00:00.000+0000"
    },
    {
      Header: "Período Publicação até",
      accessor: "dt_publication_end",
      cols: "4",
      required: false,
      uniqueRow: true,
      default: new Date().toISOString(),
      complementValueFieldEnd: "T23:59:59.000+0000"
    },
    {
      Header: "Desconto",
      accessor: "nc_percentage_discount",
      cols: "4",
      required: false,
      uniqueRow: true,
    },
    {
      Header: "Ativo",
      accessor: "bf_active",
      cols: "4",
      required: false,
      uniqueRow: true,
    },
    {
      Header: "Data cadastro",
      accessor: "dx_register",
      cols: "4",
      required: false,
      disabled: true,
      uniqueRow: true,
    },
  ];

  const productContent: Array<typeCrudForm> = [
    {
      Header: "Título",
      accessor: "ws_title",
      cols: "4",
      required: true,
      disabled: true,
    },
    {
      Header: "Fabricante",
      accessor: "cr_code_manufacturer",
      cols: "4",
      required: false,
      disabled: true,
    },
    {
      Header: "Desconto",
      accessor: "nc_percentage_discount",
      cols: "3",
      required: false,
      disabled: true,
    },
    {
      Header: "UF",
      accessor: "sl_uf",
      cols: "1",
      required: true,
      disabled: true,
    },
    {
      Header: "",
      accessor: "cr_code",
      cols: "12",
      required: false,
      custom: <>{<FormDayItens dataFormDb={dataFormDb} /* setRefresh={setRefresh}  *//>}</>,
    },
  ];  

  const previewContent: Array<typeCrudForm> = [
    {
      Header: "Título",
      accessor: "ws_title",
      cols: "4",
      required: true,
      disabled: true,
    },
    {
      Header: "Fabricante",
      accessor: "cr_code_manufacturer",
      cols: "4",
      required: false,
      disabled: true,
    },
    {
      Header: "Desconto",
      accessor: "nc_percentage_discount",
      cols: "3",
      required: false,
      disabled: true,
    },
    {
      Header: "UF",
      accessor: "sl_uf",
      cols: "1",
      required: true,
      default: "RS",
      // disabled: true,
      listItens: listStates(),
    },
    {
      Header: "",
      accessor: "ox_product",
      cols: "12",
      required: false,
      custom: <>{<FormPreview dataFormDb={dataFormDb} />}</>,
    },
  ];

  const subtitleForm: Array<typeCrudCollapse> = [
    {
      CollapseContent: subtitleContent,
      CollapseTitle: "Day",
    },
    {
      CollapseContent: productContent,
      CollapseTitle: "Produtos",
    },
    {
      CollapseContent: previewContent,
      CollapseTitle: "Visualização",
    },
  ];
  return GenerateForm(dataFormDb, subtitleForm);
};

export default FormDay;
