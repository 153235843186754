export type typeUrlServer = {
  name: string;
  urlServer: string;
  pathServer: string;
};

const url = "https://compras.braileonline.com.br";

export const contentUrlServer: Array<typeUrlServer> = [
  {
    name: "BANNER",
    urlServer: url,
    pathServer: "/user/banner/",
  },
  {
    name: "POPUP",
    urlServer: url,
    pathServer: "/user/popup/",
  },
  {
    name: "PRODUCTIMAGE",
    urlServer: url,
    pathServer: "/user/p/m/",
  },
  {
    name: "PRODUCTIMAGELARGE",
    urlServer: url,
    pathServer: "/user/p/g/",
  },
  {
    name: "DAY",
    urlServer: url,
    pathServer: "/user/day/",
  },
  {
    name: "CATEGORY",
    urlServer: url,
    pathServer: "/user/cate/",
  },
  {
    name: "CATEGORYMINIBANNER",
    urlServer: url,
    pathServer: "/user/cate/miniBanner/",
  },
  {
    name: "MANUFACTURER",
    urlServer: url,
    pathServer: "/user/fabr/",
  },
  {
    name: "STATIC",
    urlServer: url,
    pathServer: "/static/img/admin/",
  },
];
