import {
  Document,
  Page,
  PDFViewer,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import React, { useEffect, useState } from "react";
import { Modal } from "reactstrap";
import { post } from "src/helpers/api_helper";
import { getCustomerByCode } from "../../graphqlQuery";

// Create styles PDF
const styles = StyleSheet.create({
  page: {
    backgroundColor: "#ffffff",
    color: "black",
    fontSize: "11px",
    lineHeight: "1.8",
  },
  section: {
    margin: 20,
    padding: 10,
    marginTop: -10,
  },
  viewer: {
    width: window.innerWidth, //the pdf viewer will take up all of the width and height
    height: window.innerHeight,
  },
  hr: {
    color: "black",
    width: "100%",
    border: "0.5px solid black",
    marginTop: "3px",
  },
  blocktitleleft: {
    float: "left",
    marginTop: 30,
    textAlign: "left",
  },
  blocktitleright: {
    float: "right",
    marginTop: 30,
    textAlign: "right",
  },
  title: {
    fontWeight: "bold",
    marginTop: 30,
  },
  footer: {
    fontWeight: "bold",
    marginTop: 30,
    textAlign: "center",
  },
});

export default function modalPdfGenerator(
  modal_fullscreen,
  setmodal_fullscreen,
  tog_fullscreen,
  selectedRow
) {
  const [allDataFormDb, setAllDataFormDb] = useState(null);

  useEffect(() => {}, [allDataFormDb]);

  function previewPdf(allDataFormDb) {
    if (allDataFormDb) {
      const sl_payment_term =
        allDataFormDb.sl_payment_term.length > 0
          ? allDataFormDb.sl_payment_term.filter(item => item.check === true)
          : "";
      const sl_special_payment =
        allDataFormDb.sl_special_payment.length > 0
          ? allDataFormDb.sl_special_payment.filter(item => item.check === true)
          : "";
      return (
        <PDFViewer style={styles.viewer}>
          {/* Start of the document*/}
          <Document title={"Relatório de registro"}>
            {/*render a single page*/}
            <Page size="A4" style={styles.page}>
              <View style={styles.section}>
                {/* <Text>
                  Relatório de registro -{" "}
                  {allDataFormDb.ox_registration_fields.ws_corporate_reason}
                </Text> */}
                <Text style={styles.title}>
                  <View style={styles.blocktitleleft}>Registro de cliente</View>
                  <View style={styles.blocktitleright}>
                    {new Date().getTime()}
                  </View>
                </Text>

                <hr style={styles.hr} />
              </View>
              <View style={styles.section}>
                <Text>Codigo: {allDataFormDb.cr_code_erp}</Text>
                <Text>Vendedor: {allDataFormDb.ws_seller}</Text>
                <Text>Operação Fiscal: {allDataFormDb.cr_code_operation}</Text>
                <Text>
                  Condição de Pagamento:{" "}
                  {sl_payment_term && sl_payment_term.length > 0
                    ? sl_payment_term[0].value
                    : ""}
                </Text>
                <Text>
                  Condição de Pgto. Especial:{" "}
                  {sl_special_payment && sl_special_payment.length > 0
                    ? sl_special_payment[0].value
                    : ""}
                </Text>
                <Text>Já possui vendedor:</Text>
                <Text>
                  Razão Social:{" "}
                  {allDataFormDb.ox_registration_fields.ws_corporate_reason}
                </Text>
                <Text>CNPJ: {allDataFormDb.wj_cnpj}</Text>
                <Text>
                  Inscrição Estadual:{" "}
                  {allDataFormDb.ox_registration_fields.wi_state_registration}
                </Text>
                <Text>
                  Endereço:{" "}
                  {allDataFormDb.ox_registration_fields.qi_address.length > 0
                    ? allDataFormDb.ox_registration_fields.qi_address[0]
                        .ws_street +
                      ", " +
                      allDataFormDb.ox_registration_fields.qi_address[0]
                        .nb_street_number
                    : ""}
                </Text>
                <Text>
                  Bairro:{" "}
                  {allDataFormDb.ox_registration_fields.qi_address.length > 0
                    ? allDataFormDb.ox_registration_fields.qi_address[0]
                        .ws_district
                    : ""}
                </Text>
                <Text>
                  CEP:{" "}
                  {allDataFormDb.ox_registration_fields.qi_address.length > 0
                    ? allDataFormDb.ox_registration_fields.qi_address[0]
                        .zp_zipcode
                    : ""}
                </Text>
                <Text>
                  Cidade:{" "}
                  {allDataFormDb.ox_registration_fields.qi_address.length > 0
                    ? allDataFormDb.ox_registration_fields.qi_address[0]
                        .ws_city +
                      " / " +
                      allDataFormDb.ox_registration_fields.qi_address[0]
                        .ws_state
                    : ""}
                </Text>
                <Text>
                  Contato:{" "}
                  {allDataFormDb.ox_registration_fields.ct_qi_phone_contact
                    .length > 0
                    ? allDataFormDb.ox_registration_fields
                        .ct_qi_phone_contact[0].ws_description
                    : ""}
                </Text>
                <Text>
                  Email: {allDataFormDb.ox_registration_fields.wm_email}
                </Text>
                <Text>
                  Fornecedor 1:{" "}
                  {allDataFormDb.ox_registration_fields.ws_supplier_reference
                    .length > 0
                    ? allDataFormDb.ox_registration_fields
                        .ws_supplier_reference[0]
                    : ""}
                </Text>
                <Text>
                  Fornecedor 2:{" "}
                  {allDataFormDb.ox_registration_fields.ws_supplier_reference
                    .length > 1
                    ? allDataFormDb.ox_registration_fields
                        .ws_supplier_reference[1]
                    : ""}
                </Text>
                <Text>
                  Fornecedor 3:{" "}
                  {allDataFormDb.ox_registration_fields.ws_supplier_reference
                    .length > 2
                    ? allDataFormDb.ox_registration_fields
                        .ws_supplier_reference[2]
                    : ""}
                </Text>
                <Text>
                  Banco 1:{" "}
                  {allDataFormDb.ox_registration_fields.ws_bank_reference
                    .length > 0
                    ? allDataFormDb.ox_registration_fields.ws_bank_reference[0]
                    : ""}
                </Text>
                <Text>
                  Agência 1:{" "}
                  {allDataFormDb.ox_registration_fields.ws_agency_reference
                    .length > 0
                    ? allDataFormDb.ox_registration_fields
                        .ws_agency_reference[0]
                    : ""}
                </Text>
                <Text>
                  Telefone 1:{" "}
                  {allDataFormDb.ox_registration_fields.ct_contact_reference
                    .length > 0
                    ? allDataFormDb.ox_registration_fields
                        .ct_contact_reference[0]
                    : ""}
                </Text>
                <Text>
                  Banco 2:{" "}
                  {allDataFormDb.ox_registration_fields.ws_bank_reference
                    .length > 1
                    ? allDataFormDb.ox_registration_fields.ws_bank_reference[1]
                    : ""}
                </Text>
                <Text>
                  Agência 2:{" "}
                  {allDataFormDb.ox_registration_fields.ws_agency_reference
                    .length > 1
                    ? allDataFormDb.ox_registration_fields
                        .ws_agency_reference[1]
                    : ""}
                </Text>
                <Text>
                  Telefone 2:{" "}
                  {allDataFormDb.ox_registration_fields.ct_contact_reference
                    .length > 1
                    ? allDataFormDb.ox_registration_fields
                        .ct_contact_reference[1]
                    : ""}
                </Text>
                <Text>Tipo Cliente: {allDataFormDb.sl_type_client}</Text>
                <Text>Tipo Frete: {allDataFormDb.sl_type_transport}</Text>
                <Text>Tipo Curva: {allDataFormDb.sl_curve_type}</Text>
                <Text>Condição Tributária: </Text>
                <Text>Data Cadastro: {allDataFormDb.dx_register}</Text>
                <Text>Data Integr. ERP: {allDataFormDb.dx_modification}</Text>
                <Text>Data último Login: {allDataFormDb.dx_data_login}</Text>
                {/* <Text>Data último Login: {allDataFormDb.}</Text> */}
              </View>
              <View style={styles.section}>
                <hr style={styles.hr} />
                <Text style={styles.footer}>braileonline.com.br</Text>
              </View>
            </Page>
          </Document>
        </PDFViewer>
      );
    }
  }

  /* Lógica de requisições BACK END */
  const getDataCrudGraphql = React.useCallback(async (cr_code: string) => {
    try {
      let response = await post(
        "/graphql",
        {
          query: getCustomerByCode(cr_code),
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then(response => response)
        .catch(error => alert("Falha ao buscar dados do cliente!"));
      if (response.data[Object.keys(response.data)[0]]) {
        setAllDataFormDb(response.data[Object.keys(response.data)[0]]);
      } else {
        alert("Falha ao selecionar registro!");
      }
    } catch (e) {
      setAllDataFormDb(null);
      alert(`Falha ao selecionar registro ${cr_code}!`);
    }
  }, []);

  useEffect(() => {
    getDataCrudGraphql(selectedRow.cr_code);
  }, []);

  useEffect(() => {}, [allDataFormDb, selectedRow]);

  return (
    <Modal
      size="xl"
      isOpen={modal_fullscreen}
      toggle={() => {
        tog_fullscreen();
      }}
      className="modal-fullscreen"
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="exampleModalFullscreenLabel">
          Relatório de registro -{" "}
          {allDataFormDb &&
            allDataFormDb.ox_registration_fields.ws_corporate_reason}
        </h5>
        <button
          onClick={() => {
            setmodal_fullscreen(false);
          }}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">{previewPdf(allDataFormDb)}</div>
      <div className="modal-footer">
        <button
          type="button"
          onClick={() => {
            tog_fullscreen();
          }}
          className="btn btn-secondary "
          data-dismiss="modal"
        >
          Fechar
        </button>
        {/* <button type="button" className="btn btn-primary ">
          Save changes
        </button> */}
      </div>
    </Modal>
  );
}
