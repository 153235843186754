/* IMPORTANTE: Deve incluir as fun principais do CRUD
 * getData, updateData, deleteData, postData
 * estas chamadas são padrão no CRUD/CrudGroupForm */

export const getRecipientByCode = (cr_code: string): string => {
  return `{
    getRecipientByCode(cr_code:"${cr_code}") {
      ws_name
      bf_active
      cr_code
      ws_email
      dx_register
      dx_modification
      bf_pagina_contato
      bf_trabalhe_conosco
      bf_aviso_cad_cliente
      bf_aviso_pedido
      bf_aviso_rma
      bf_aviso_item_carrinho
      bf_integra_cad_site2erp
      bf_aviso_aval_credito
      bf_recuperacao_senha
    }
  }`;
};

export const getRecipients = (): string => {
  return `query {
    getRecipients {
      ws_name
      bf_active
      cr_code
      ws_email
      dx_register
      dx_modification
      bf_pagina_contato
      bf_trabalhe_conosco
      bf_aviso_cad_cliente
      bf_aviso_pedido
      bf_aviso_rma
      bf_aviso_item_carrinho
      bf_integra_cad_site2erp
      bf_aviso_aval_credito
      bf_recuperacao_senha
    }
  }`;
};

export const deleteRecipient = (cr_code: string): string => {
  return `query {
    deleteRecipient(cr_code:"${cr_code}") {
      message
    }
  }`;
};

export const updateRecipient = (data: {
  bf_active: Boolean;
  cr_code: String;
  ws_name: String;
  ws_email: String;
  bf_pagina_contato: Boolean;
  bf_trabalhe_conosco: Boolean;
  bf_aviso_cad_cliente: Boolean;
  bf_aviso_pedido: Boolean;
  bf_aviso_rma: Boolean;
  bf_aviso_item_carrinho: Boolean;
  bf_integra_cad_site2erp: Boolean;
  bf_aviso_aval_credito: Boolean;
  bf_recuperacao_senha: Boolean;
}): string => {
  return `mutation {
    updateRecipient(
      bf_active: ${data.bf_active},
      cr_code: "${data.cr_code ? data.cr_code : ""}",
      ws_name: "${data.ws_name ? data.ws_name : ""}",
      ws_email: "${data.ws_email ? data.ws_email : ""}",
      pagina_contato: ${data.bf_pagina_contato},
      trabalhe_conosco: ${data.bf_trabalhe_conosco},
      aviso_cad_cliente: ${data.bf_aviso_cad_cliente},
      aviso_pedido: ${data.bf_aviso_pedido},
      aviso_rma: ${data.bf_aviso_rma},
      aviso_item_carrinho: ${data.bf_aviso_item_carrinho},
      integra_cad_site2erp: ${data.bf_integra_cad_site2erp},       
      aviso_aval_credito: ${data.bf_aviso_aval_credito},       
      recuperacao_senha: ${data.bf_recuperacao_senha},       
   ){
  	result
    status
   }
  }`;
};

export const insertRecipient = (data: {
  bf_active: Boolean;
  cr_code: String;
  ws_name: String;
  ws_email: String;
  bf_pagina_contato: Boolean;
  bf_trabalhe_conosco: Boolean;
  bf_aviso_cad_cliente: Boolean;
  bf_aviso_pedido: Boolean;
  bf_aviso_rma: Boolean;
  bf_aviso_item_carrinho: Boolean;
  bf_integra_cad_site2erp: Boolean;
  bf_aviso_aval_credito: Boolean;
  bf_recuperacao_senha: Boolean;
}): string => {
  return `mutation {
    insertRecipient(
      bf_active: ${data.bf_active},
      cr_code: "${data.cr_code ? data.cr_code : ""}",
      ws_name: "${data.ws_name ? data.ws_name : ""}",
      ws_email: "${data.ws_email ? data.ws_email : ""}",
      pagina_contato: ${data.bf_pagina_contato},
      trabalhe_conosco: ${data.bf_trabalhe_conosco},
      aviso_cad_cliente: ${data.bf_aviso_cad_cliente},
      aviso_pedido: ${data.bf_aviso_pedido},
      aviso_rma: ${data.bf_aviso_rma},
      aviso_item_carrinho: ${data.bf_aviso_item_carrinho},
      integra_cad_site2erp: ${data.bf_integra_cad_site2erp},      
      aviso_aval_credito: ${data.bf_aviso_aval_credito},  
      recuperacao_senha: ${data.bf_recuperacao_senha},       
   ){
  	result
    status
   }
  }`;
};

export const getNextCrCodeRecipient = (): string => {
  return `query {
    getNextCrCodeRecipient {
      result
    }
  }`;
};
