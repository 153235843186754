export function hasJsonStructure(value) {
  value = JSON.stringify(value);
  try {
    const isObject =
      value.slice(0, 1) === "{" && value.slice(value.length - 1) === "}";
    if (typeof value === "string" && isObject) {
      JSON.parse(value);
    } else {
      return false;
    }
  } catch (err) {
    return false;
  }
  return true;
}

//if (obj && typeof obj === `object`) {
