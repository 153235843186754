import { CardTitle, Label } from "reactstrap"
import styled from "styled-components"

export const LabelStyled = styled(Label)`
  margin: 0px;
  padding: 0px;
  font-size: 0.8em;
`

export const CardTitleStyled = styled(CardTitle)`
  font-size: 1em;
`