import {
  Document,
  Page,
  PDFViewer,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import React, { useEffect, useState } from "react";
import { Modal } from "reactstrap";

// Create styles PDF
const styles = StyleSheet.create({
  page: {
    backgroundColor: "#ffffff",
    color: "black",
    fontSize: "11px",
    lineHeight: "1.8",
  },
  section: {
    margin: 20,
    padding: 10,
    marginTop: -10,
  },
  viewer: {
    width: window.innerWidth, //the pdf viewer will take up all of the width and height
    height: window.innerHeight,
  },
  hr: {
    color: "black",
    width: "100%",
    border: "0.5px solid black",
    marginTop: "3px",
  },
  blocktitleleft: {
    float: "left",
    marginTop: 30,
    textAlign: "left",
  },
  blocktitleright: {
    float: "right",
    marginTop: 30,
    textAlign: "right",
  },
  title: {
    fontWeight: "bold",
    marginTop: 30,
  },
  footer: {
    fontWeight: "bold",
    marginTop: 30,
    textAlign: "center",
  },
});

export default function modalPdfGenerator(
  modal_fullscreen,
  setmodal_fullscreen,
  tog_fullscreen,
  selectedRow
) {
  const [allDataFormDb, setAllDataFormDb] = useState(null);
  function previewPdf() {
    if (selectedRow) 
      return (
        <PDFViewer style={styles.viewer}>          
          <Document title={"Relatório LGPD"}>
            <Page size="A4" style={styles.page}>
              <View style={styles.section}>
                <Text style={styles.title}>
                  <View style={styles.blocktitleleft}>Registro de LOG</View>
                </Text>

                <hr style={styles.hr} />
              </View>
              <View style={styles.section}>
                <Text>ID: {selectedRow.cr_code}</Text>                                
                <Text>Erro do sistema: {selectedRow.ws_error_system}</Text>
                <Text>Status: {selectedRow.ws_status}</Text>                
                <Text>Data/Hora: {new Intl.DateTimeFormat('pt-BR', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(selectedRow.dx_register)}</Text>                
                <Text>Serviço: {selectedRow.sl_service_select}</Text>             
                <Text>Tipo: {selectedRow.sl_type}</Text>             
                <Text>Informações: {selectedRow.tx_infos_request}</Text>             
              </View>
              <View style={styles.section}>
                <hr style={styles.hr} />
                <Text style={styles.footer}>www.braileonline.com.br</Text>
              </View>
            </Page>
          </Document>
        </PDFViewer>
      );
  }

  useEffect(() => {}, [allDataFormDb, selectedRow]);

  return (
    <Modal
      size="xl"
      isOpen={modal_fullscreen}
      toggle={() => {
        tog_fullscreen();
      }}
      className="modal-fullscreen"
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="exampleModalFullscreenLabel">
          Relatório de log de E-mail
          {allDataFormDb &&
            allDataFormDb.ox_registration_fields.ws_corporate_reason}
        </h5>
        <button
          onClick={() => {
            setmodal_fullscreen(false);
          }}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">{previewPdf()}</div>
      <div className="modal-footer">
        <button
          type="button"
          onClick={() => {
            tog_fullscreen();
          }}
          className="btn btn-secondary "
          data-dismiss="modal"
        >
          Fechar
        </button>
      </div>
    </Modal>
  );
}
