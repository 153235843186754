import { useEffect, useState } from "react"
import { Col, FormGroup, Input } from "reactstrap"
import { LabelStyled } from "./styled"

export const getSelectBox = (item, setFilterDb?: any) => {
  const [valueDefault, setValueDefault] = useState('');
  const [value, setValue] = useState('');  
  const [search, setSearch] = useState([]);
  const [defaultCols, setDefaultCols] = useState(3);
  const [cols, setCols] = useState(defaultCols);
  
  function handleChange(key: string, value: any) {
    setSearch(prevRegister => ({ ...prevRegister, [key]: value })); 
    if (!value) {
      setFilterDb(filterDb => { 
        delete filterDb[key];
        return filterDb 
      })
    } else {
      setFilterDb(filterDb => { return { ...filterDb, [key]: value }})
      setValue(value)
    }
  }

  useEffect(() => {
    if (item?.cols && item?.cols > 0) {
      setCols(item.cols * defaultCols)
    }
  }, [item])

  // Limpa os campos
  useEffect(() => {
    setValue(valueDefault)
  }, [item.clearFields])

  return <><Col 
  lg={cols}
  sm={cols}
  md={cols}
  xs={cols}
  style={{ textAlign: "left" }}
>
  <FormGroup>
    <LabelStyled for="exampleSelect">{item.name}</LabelStyled>
      <Input 
        bsSize="sm" 
        type={item.type} 
        name="select" 
        id="exampleSelect" 
        onChange={(e) => handleChange(item.field, e.target.value)} value={value}
      >
        <option value="">Todos</option>
        {item.options.map((option, index) => {
          return <option key={option.value} value={option.value}>{option.name}
          </option>
        })}
      </Input>
  </FormGroup>
</Col>
{item.uniqueRow && <Col 
  className="m-1"
  lg={cols - 12}
  sm={cols - 12}
  md={cols - 12}
  xs={cols - 12}
  style={{ textAlign: "left" }}
  ></Col>}
  </>
}