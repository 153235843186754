// availity-reactstrap-validation
import { AvField, AvForm } from 'availity-reactstrap-validation'
import React from 'react'
import MetaTags from 'react-meta-tags'
//redux
import { useDispatch, useSelector } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { Alert, Col, Container, Row } from 'reactstrap'
// import images
import logo from '../../assets/images/braile/logobrailegrande.png'
// actions
import { loginUser, socialLogin } from '../../store/actions'
import CarouselPage from './CarouselPage'

interface LoginProps {
  history: object;
}

const Login = ({ history }: LoginProps) => {
  const dispatch = useDispatch();

  const { error } = useSelector((state: any) => ({
    error: state.login.error
  }));

  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');

  // handleValidSubmit
  const handleValidSubmit = (event: any, values: any) => {
    if (
      values.email.toString() != "admin@braileonline.com.br" ||
      values.password.toString() != "BR41L3"
    ) {
      alert("Usuário ou senha inválida!");
      return;
    }

    if(localStorage.getItem("authUser")){ 
      localStorage.removeItem("authUser");
    }
    
    localStorage.setItem(
      "authUser",
      JSON.stringify({
        uid: 1,
        username: "admin",
        role: "admin",
        email: "admin@braileonline.com.br",
      })
    );
    dispatch(loginUser(values, history));
  };

  const signIn = (res: any, type: any) => {
    if (type === 'google' && res) {
      const postData = {
        name: res.profileObj.name,
        email: res.profileObj.email,
        token: res.tokenObj.access_token,
        idToken: res.tokenId
      };
      dispatch(socialLogin(postData, history, type));
    } else if (type === 'facebook' && res) {
      const postData = {
        name: res.name,
        email: res.email,
        token: res.accessToken,
        idToken: res.tokenId
      };
      dispatch(socialLogin(postData, history, type));
    }
  };

  //handleTwitterLoginResponse
  // const twitterResponse = e => {}

  return (
    <React.Fragment>
      <MetaTags>
        <title>Login | Braile Distribuidora</title>
      </MetaTags>
      <div className="auth-page">
        <Container fluid className="p-0">
          <Row className="g-0">
            <Col lg={4} md={5} className="col-xxl-3">
              <div className="auth-full-page-content d-flex p-sm-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-4 mb-md-5 text-center">
                      <Link to="/dashboard" className="d-block auth-logo">
                        <img src={logo} alt="" height="68" />{' '}
                        {/* <span className="logo-txt">Braile</span> */}
                      </Link>
                    </div>
                    <div className="auth-content my-auto">
                      <div className="text-center">
                        <h5 className="mb-0">Bem Vindo!</h5>
                        <p className="text-muted mt-2">
                          Área administrativa da Braile.
                        </p>
                      </div>
                      <AvForm
                        className="custom-form mt-4 pt-2"
                        onValidSubmit={(e: any, v: any) => {
                          handleValidSubmit(e, v);
                        }}
                      >
                        {error ? <Alert color="danger">{error}</Alert> : null}
                        <div className="mb-3">
                          <AvField
                            name="email"
                            label="Email"
                            value={email}
                            className="form-control"
                            placeholder="Enter email"
                            type="email"
                            required
                            onChange={(e: any) => setEmail(e.target.value)}
                            errorMessage="Usuário inválido"
                          />
                        </div>
                        <div className="mb-3">
                          <div className="d-flex align-items-start">
                            <div className="flex-grow-1">
                              <label className="form-label">Senha</label>
                            </div>
                            <div className="flex-shrink-0">
                              <div className="">
                                {/* <Link
                                  to="/auth-recoverpw"
                                  className="text-muted"
                                >
                                  Forgot password?
                                </Link> */}
                              </div>
                            </div>
                          </div>

                          <div className="mb-3">
                            <AvField
                              name="password"
                              value={password}
                              type="password"
                              className="form-control"
                              required
                              placeholder="Enter Password"
                              onChange={(e: any) => setPassword(e.target.value)}
                              errorMessage="Informe sua senha"
                            />
                          </div>
                        </div>
                        <div className="row mb-4 hide">
                          <div className="col">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="remember-check"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="remember-check"
                              >
                                Remember me
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="mb-3">
                          <button
                            className="btn btn-brailebutton w-100 waves-effect waves-light"
                            type="submit"
                          >
                            Entrar
                          </button>
                        </div>
                      </AvForm>

                      <div className="mt-4 text-center hide">
                        <h5 className="font-size-14 mb-3">Sign in with</h5>

                        <ul className="list-inline">
                          <li className="list-inline-item"></li>
                          {/*<li className="list-inline-item">*/}
                          {/*  <TwitterLogin*/}
                          {/*    loginUrl={*/}
                          {/*      "http://localhost:4000/api/v1/auth/twitter"*/}
                          {/*    }*/}
                          {/*    onSuccess={this.twitterResponse}*/}
                          {/*    onFailure={this.onFailure}*/}
                          {/*    requestTokenUrl={*/}
                          {/*      "http://localhost:4000/api/v1/auth/twitter/revers"*/}
                          {/*    }*/}
                          {/*    showIcon={false}*/}
                          {/*    tag={"div"}*/}
                          {/*  >*/}
                          {/*    <a*/}
                          {/*      href=""*/}
                          {/*      className="social-list-item bg-info text-white border-info"*/}
                          {/*    >*/}
                          {/*      <i className="mdi mdi-twitter"/>*/}
                          {/*    </a>*/}
                          {/*  </TwitterLogin>*/}
                          {/*</li>*/}
                          <li className="list-inline-item"></li>
                        </ul>
                      </div>

                      <div className="mt-5 text-center hide">
                        <p className="text-muted mb-0">
                          Don't have an account ?{' '}
                          <Link
                            to="/register"
                            className="text-primary fw-semibold"
                          >
                            {' '}
                            Signup now{' '}
                          </Link>{' '}
                        </p>
                      </div>
                    </div>
                    <div className="mt-4 mt-md-5 text-center">
                      <p className="mb-0">
                        © {new Date().getFullYear()} Braile Distribuidora .
                        Versão{' '}
                        {/* <i className="mdi mdi-heart text-danger"></i> */}{' '}
                        2.1.0
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <CarouselPage />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Login);
