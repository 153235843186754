import React from 'react'
import MetaTags from 'react-meta-tags'
import { Container } from 'reactstrap'
import Breadcrumb from 'src/components/Common/Breadcrumb'
import { typeCrudListColumns } from "src/components/Common/typeCridList"
import { CRUD_MODE } from 'src/constants/layout'

export default function index(props: {
  routePath: string;
  queryGet: any;
  queryGetFilter?: any;
  queryGetByCode: any;
  querySave: any;
  queryUpdate: any;
  queryDelete: any;
  queryNextCrCode: any;
  queryPutOneField?: any;
  queryPreview?: any;
  title: string;
  breadcrumbTitle: string;
  breadcrumbItem: string;
  CrudFormGroup: any;
  CrudList: any;
  columnsTable: Array<typeCrudListColumns>;
  fieldsCustomFilters?: any;
  setCheckeds?: any;
}) {
  const {
    routePath,
    queryGet,
    queryGetFilter,
    queryGetByCode,
    querySave,
    queryUpdate,
    queryDelete,
    queryNextCrCode,
    queryPutOneField,
    queryPreview,
    title,
    CrudFormGroup,
    CrudList,
    breadcrumbTitle,
    breadcrumbItem,
    columnsTable,
    fieldsCustomFilters,
    setCheckeds
  } = props;
  const [modeView, setModeView] = React.useState(CRUD_MODE.LIST);
  const [selectedRow, setSelectedRow] = React.useState(null);
  const loadView = () => {
    switch (modeView) {
      case CRUD_MODE.LIST:
        return (
          <CrudList
            queryGet={queryGet}
            queryGetFilter={queryGetFilter ? queryGetFilter : queryGet}
            queryDelete={queryDelete}
            queryUpdate={queryUpdate}
            queryPutOneField={queryPutOneField}
            queryPreview={queryPreview}
            handleNewRegister={setModeView}
            setSelectedRow={setSelectedRow}
            columnsTable={columnsTable}
            fieldsCustomFilters={fieldsCustomFilters}
            setCheckeds={setCheckeds}
          />
        );
      case CRUD_MODE.FORM:
        return (
          <CrudFormGroup
            routePath
            queryGetByCode={queryGetByCode}
            querySave={querySave}
            queryUpdate={queryUpdate}
            queryDelete={queryDelete}
            queryPreview={queryPreview}
            handleNewRegister={setModeView}
            selectedRow={selectedRow}
            setSelectedRow={setSelectedRow}
            columnsTable={columnsTable}
            queryNextCrCode={queryNextCrCode}
          />
        );
      default:
        return <>ERROR</>;
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{title}</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumb title={breadcrumbTitle} breadcrumbItem={breadcrumbItem} />
          {loadView()}
        </Container>
      </div>
    </React.Fragment>
  );
}
