/* IMPORTANTE: Deve incluir as fun principais do CRUD
 * getData, updateData, deleteData, postData
 * estas chamadas são padrão no CRUD/CrudGroupForm */

export const getCategoryByCode = (cr_code: string): string => {
  return `{
    getCategoryByCode(cr_code:"${cr_code}") {
      cv_version
      cr_code
      bf_highlight
      bf_active
      dx_register
      ws_name
      nb_order_menu
      nb_column_menu
      hl_banner_link
      wh_icone_imagem
      wh_banner_imagem
      sl_banner_position
      ws_name_url     
      sl_react_icones 
    }
  }`;
};

export const getCategorys = (): string => {
  return `query {
    getCategorysAdmin {
      cr_code
      ws_name
      wh_icone_imagem
      ws_name_url
      sl_react_icones
      qi_subcategories {
        cr_code
        ws_name
        hl_name_url
        bf_sale_cartucho
      }
      bf_highlight
      bf_active
    }
  }`;
};

export const deleteCategory = (cr_code: string): string => {
  return `query {
    deleteCategoryByCode(cr_code:"${cr_code}") {
      message
    }
  }`;
};

export const updateCategory = (data: {
  cr_code: String;
  bf_highlight: Boolean;
  bf_active: Boolean;
  ws_name: String;
  nb_order_menu: Number;
  nb_column_menu: Number;
  hl_banner_link: String;
  wh_icone_imagem: String;
  wh_banner_imagem: String;
  sl_banner_position: String;
  sl_react_icones: String;
}): string => {
  return `mutation {
    updateCategory(
      cr_code: "${data.cr_code}",
      bf_highlight: ${
        data.bf_highlight === undefined ? false : data.bf_highlight
      },
      bf_active: ${data.bf_active === undefined ? false : data.bf_active},
      ws_name: "${data.ws_name}",
      nb_order_menu: ${data.nb_order_menu},
      nb_column_menu: ${data.nb_column_menu},
      hl_banner_link: "${data.hl_banner_link}",
      wh_icone_imagem: "${data.wh_icone_imagem}",
      wh_banner_imagem: "${data.wh_banner_imagem}",
      sl_banner_position: "${data.sl_banner_position}",
      sl_react_icones: "${data.sl_react_icones}"
   ){
  	message
   }
  }`;
};

export const insertCategory = (data: {
  cr_code: String;
  bf_highlight: Boolean;
  bf_active: Boolean;
  ws_name: String;
  nb_order_menu: Number;
  nb_column_menu: Number;
  hl_banner_link: String;
  wh_icone_imagem: String;
  wh_banner_imagem: String;
  sl_banner_position: String;
  sl_react_icones: String;
}): string => {
  return `mutation {
    insertCategory(
      cr_code: "${data.cr_code}",
      bf_highlight: ${
        data.bf_highlight === undefined ? false : data.bf_highlight
      },
      bf_active: ${data.bf_active === undefined && false},
      ws_name: "${data.ws_name}",
      nb_order_menu: ${data.nb_order_menu},
      nb_column_menu: ${data.nb_column_menu},
      hl_banner_link: "${data.hl_banner_link}",
      wh_icone_imagem: "${data.wh_icone_imagem}",
      wh_banner_imagem: "${data.wh_banner_imagem}",
      sl_banner_position: "${data.sl_banner_position}",
      sl_react_icones: "${data.sl_react_icones}"
   ){
  	message
   }
  }`;
};

export const getNextCrCodeCategory = (): string => {
  return `query {
    getNextCrCodeCategory {
      result
    }
  }`;
};
