import React from "react";

//import Breadcrumbs
import CrudContainer from "./CrudContainer";
import {
  getCustomerByCode,
  getCustomerPagination,
  getNextCrCodeCustomer,
  insertCustomer,
} from "./graphqlQuery";
import { LabelCnpj, LabelSocial } from "./styled";

function WSrazao_cnpj({ value, column, row }) {
  return (
    <>
      <LabelCnpj>{value}</LabelCnpj>
      <LabelSocial>{row.original.wj_cnpj}</LabelSocial>
    </>
  );
}

const RegistrationApproval = () => {
  const columns = React.useMemo(
    () => [
      {
        Header: "Título",
        accessor: "cr_code",
      },
      {
        Header: "UF",
        accessor: "ox_registration_fields.ws_corporate_reason",
        Cell: WSrazao_cnpj,
        minWidth: 800,
        /* headerStyle: (colum, colIndex) => {
          return { width: "300px", textAlign: "center" };
        }, */
      },
      {
        Header: "Itens",
        accessor: "sl_curve_type",
      },
      {
        Header: "Desconto",
        accessor: "sl_generate_budget",
      },
      {
        Header: "Ativo",
        accessor: "bf_active",
      },
      {
        Header: "Ações",
        accessor: "actions",
        Filter: () => <div style={{ height: "10px" }}></div>,
        Cell: cellContent => (
          <div
            style={{
              width: "100%",
              textAlign: "center",
              backgroundColor: "red",
            }}
          >
            {cellContent}
          </div>
        ),
      },
    ],
    []
  );
  return (
    <CrudContainer
      routePath=""
      queryGet={getCustomerPagination}
      queryGetByCode={getCustomerByCode}
      querySave={insertCustomer}
      queryUpdate={null}
      queryDelete={() => {}}
      queryNextCrCode={getNextCrCodeCustomer}
      title="Itens Diferidos | Braile Distribuidora"
      breadcrumbTitle="Cadastro"
      breadcrumbItem="Itens Diferidos"
      columnsTable={columns}
    />
  );
};

export default RegistrationApproval;
