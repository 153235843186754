import { AvForm } from "availity-reactstrap-validation"
import React, { useEffect, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Col,
  Row,
  Spinner
} from "reactstrap"
import {
  typeCrudListColumns,
  typeCrudListItens
} from "src/components/Common/typeCridList"
import ModalUserDescription from "src/components/Modal/modalUserDescription"
import { CRUD_MODE } from "src/constants/layout"
import { post } from "src/helpers/api_helper"

export default function index(props: {
  Form: any;
  queryGetByCode: any;
  querySave: any;
  queryUpdate: any;
  queryDelete: any;
  queryNextCrCode: any;
  handleNewRegister: (mode: string) => void;
  selectedRow: string;
  setSelectedRow: (selectedRow: string) => void;
  columnsTable: Array<typeCrudListColumns>;
  buttons?: any;
  listItens?: Array<typeCrudListItens>;
  callBackRowSelected?: any;
}) {
  const {
    Form,
    handleNewRegister,
    queryGetByCode,
    querySave,
    queryUpdate,
    queryNextCrCode,
    selectedRow,
    setSelectedRow,
    columnsTable,
    buttons,
    listItens,
    callBackRowSelected,
  } = props;
  const [modal, setModal] = useState(false);
  const [loadingEntities, setLoadingEntities] = useState(false);
  const [entitiesConfigurations, setEntitiesConfigurations] = useState([]);
  const [allDataFormDb, setAllDataFormDb] = useState({});
  const [nextCrCode, setNextCrCode] = useState(null);

  /* Lógica de requisições BACK END retorna um novo CR_CODE */
  const getNextCrCodeGraphql = React.useCallback(async () => {
    try {
      let response = await post(
        "/graphql",
        {
          query: queryNextCrCode(),
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then(response => response)
        .catch(error => setLoadingEntities(false));
      if (response.data[Object.keys(response.data)[0]].result) {
        setNextCrCode(response.data[Object.keys(response.data)[0]].result);
      } else {
        alert("Falha ao trazer novo código de registro!");
        handleNewRegister(CRUD_MODE.LIST);
        setSelectedRow(null);
        setNextCrCode(null);
      }
    } catch (e) {
      setAllDataFormDb(null);
      alert(`Falha ao selecionar registro!`);
      handleNewRegister(CRUD_MODE.LIST);
      setSelectedRow(null);
      setNextCrCode(null);
    } finally {
      setLoadingEntities(false);
    }
  }, [queryNextCrCode()]);

  /* Lógica de requisições BACK END */
  const getDataCrudGraphql = React.useCallback(
    async (cr_code: string) => {
      try {
        let response = await post(
          "/graphql",
          {
            query: queryGetByCode(cr_code),
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
          .then(response => response)
          .catch(error => setLoadingEntities(false));
        if (response.data[Object.keys(response.data)[0]]) {
          setAllDataFormDb(response.data[Object.keys(response.data)[0]]);
        } else {          
          alert("Falha ao selecionar registro!");
          handleNewRegister(CRUD_MODE.LIST);
          setSelectedRow(null);
        }
      } catch (e) {
        console.error(999, e);
        setAllDataFormDb(null);
        alert(`Falha ao selecionar registro ${cr_code}!`);
        handleNewRegister(CRUD_MODE.LIST);
        setSelectedRow(null);
      } finally {
        setLoadingEntities(false);
      }
    },
    [queryGetByCode()]
  );

  /* Função update padrão do CRUD  */
  const updateRegister = React.useCallback(async dataForm => {
    setLoadingEntities(true);
    try {
      await post(
        "/graphql",
        {
          query: queryUpdate(dataForm),
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then(() => {
          setSelectedRow(null);
          handleNewRegister(CRUD_MODE.LIST);
        })
        .catch(error => console.log(error));
    } catch (e) {
      console.error(e);
      alert(`Falha ao atualizar registro!`);
    } finally {
      setLoadingEntities(false);
      setNextCrCode(null);
    }
  }, []);

  /* Função insert padrão do CRUD  */
  const insertRegister = React.useCallback(async dataForm => {
    setLoadingEntities(true);
    try {
      await post(
        "/graphql",
        {
          query: querySave(dataForm),
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      ).then(() => handleNewRegister(CRUD_MODE.LIST));
    } catch (e) {
      console.error(e);
      alert(`Falha ao atualizar registro!`);
    } finally {
      setSelectedRow(null);
      setLoadingEntities(false);
      setNextCrCode(null);
    }
  }, []);

  useEffect(() => {
    // Retorna todos os dados PRINCIAIS do formulário
    // (Os dados complementares de configuração são carregados dinamicamente em outra função)
    if (selectedRow) {
      setNextCrCode(null);
      getDataCrudGraphql(selectedRow["cr_code"]);
    } else {
      //setAllDataFormDb(null);
      getNextCrCodeGraphql();
      setLoadingEntities(false);
    }
  }, [getDataCrudGraphql]);

  useEffect(() => {
    callBackRowSelected && callBackRowSelected(selectedRow);
  }, [callBackRowSelected, selectedRow]);

  return (
    <React.Fragment>
      <ModalUserDescription modal2={modal} setModal2={setModal} />
      <Card style={{ backgroundColor: "#f1f1f1" }}>
        <AvForm
          onValidSubmit={() => {
            if (selectedRow) {
              updateRegister(allDataFormDb);
            } else {
              insertRegister(allDataFormDb);
            }
          }}
        >
          <CardBody>
            {loadingEntities ? (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{
                  width: "100%",
                  height: "50vh",
                }}
              >
                <Spinner
                  className="p-2"
                  animation="border"
                  role="status"
                  variant="danger"
                />
                <span className="p-4">Carregando formulário...</span>
              </div>
            ) : (
              <>
                <Row>
                  <Col lg={6} sm={6} md={6} xs={12}>
                    <CardTitle className="h5">Novo Registro</CardTitle>
                  </Col>
                  <Col className="col-title" lg={6} sm={6} md={6} xs={12}>
                    <Button
                      id="caret"
                      /* color="info" */
                      onClick={() => {
                        handleNewRegister(CRUD_MODE.LIST), setSelectedRow(null);
                      }}
                    >
                      Cancelar
                    </Button>
                  </Col>
                </Row>
                <br />
                <Form
                  dataFormDb={allDataFormDb}
                  setAllDataFormDb={setAllDataFormDb}
                  columnsTable={columnsTable}
                  nextCrCode={nextCrCode}
                  listItens={listItens}
                />
              </>
            )}
          </CardBody>
          <CardFooter style={{ backgroundColor: "#f1f1f1" }}>
            <div className="d-flex flex-wrap gap-2">
              {queryUpdate && (
                <Button
                  type="submit"
                  color="success"
                  className=""
                  /* onClick={() => {
                  if (selectedRow) {
                    updateRegister(allDataFormDb);
                  } else {
                    insertRegister(allDataFormDb);
                  }
                }} */
                >
                  Salvar
                </Button>
              )}{" "}
              <Button
                type="reset"
                className=""
                onClick={() => {
                  handleNewRegister(CRUD_MODE.LIST), setSelectedRow(null);
                }}
              >
                Cancelar
              </Button>
              {buttons}
            </div>
          </CardFooter>
        </AvForm>
      </Card>
    </React.Fragment>
  );
}
function useCallback(arg0: () => Promise<any>, arg1: any[]): Promise<any> {
  throw new Error("Function not implemented.");
}
