import React from "react";
// import { useNavigate } from "react-router-dom";
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap';
import ConfirmModal from 'src/components/Modal/confirmModal';

const ModalUserDescription = (props: {
  modal2: any;
  buttonLabel?: any;
  className?: any;
  setModal2?: any;
  approvalAccount?: any;
  deleteAccount?: any;
  userSelected?: any;
}) => {
  const {
    buttonLabel,
    className,
    modal2,
    setModal2,
    approvalAccount,
    deleteAccount,
    userSelected,
  } = props;

  //const history = useNavigate();
  const [modal, setModal] = React.useState(modal2);
  const [modal3, setModal3] = React.useState(false);
  const [modal4, setModal4] = React.useState(false);
  const toggle = () => {
    setModal(!modal);
    setModal2(!modal);
    /* history('/'); */
  };

  React.useEffect(() => {
    setModal(props.modal2);
  }, [props, userSelected]);

  return (
    <div className="admin-user modal">
      <ConfirmModal
        modal={modal3}
        confirm={deleteAccount}
        cancel={() => {}}
        setModal={setModal3}
        title="Confirmar exclusão de conta?"
      />
      <ConfirmModal
        modal={modal4}
        confirm={approvalAccount}
        cancel={() => {}}
        setModal={setModal4}
        title="Confirmar aprovação de conta?"
      />
      {/* <Button color="danger" onClick={toggle}>{buttonLabel}</Button> */}
      <Modal
        isOpen={modal}
        modalTransition={{ timeout: 700 }}
        backdropTransition={{ timeout: 1300 }}
        toggle={toggle}
        className={className}
      >
        <ModalHeader toggle={toggle}>Ficha de inscrição</ModalHeader>
        <ModalBody>
          {userSelected && userSelected.qi_custom_fields && (
            <div className="body-modal">
              <Row className="align-items-center">
                <Col md="12">
                  <span>Nome social: </span>
                  <strong>{userSelected.qi_custom_fields[0].socialname}</strong>
                </Col>
                <Col md="12">
                  <span>Nome artístico: </span>
                  <strong>
                    {userSelected.qi_custom_fields[0].artisticname}
                  </strong>
                </Col>
                <Col md="12">
                  <span>Orientação sexual: </span>
                  <strong>
                    {userSelected.qi_custom_fields[0].sexualorientation}
                  </strong>
                </Col>
                <Col md="12">
                  <span>Identidade de gênero: </span>
                  <strong>
                    {userSelected.qi_custom_fields[0].genderidentity}
                  </strong>
                </Col>
                <Col md="12">
                  <span>Auto declaração: </span>
                  <strong>
                    {userSelected.qi_custom_fields[0].selfdeclaration}
                  </strong>
                </Col>
                <Col md="12">
                  <span>Área de atuação: </span>
                  <strong>
                    {userSelected.qi_custom_fields[0].occupationarea}
                  </strong>
                </Col>
                <Col md="12">
                  <span>Instituição de vínculo: </span>
                  <strong>
                    {userSelected.qi_custom_fields[0].bondinstitution}
                  </strong>
                </Col>
                <Col md="12">
                  <span>Apresentação de Trabalho: </span>
                  <strong>
                    {userSelected.qi_custom_fields[0].workpresentation === ""
                      ? "NÃO"
                      : userSelected.qi_custom_fields[0].workpresentation}
                  </strong>
                </Col>
                <Col md="12">
                  <span>Eixo temático: </span>
                  <strong>
                    {userSelected.qi_custom_fields[0].thematic_axis == ""
                      ? "NÃO"
                      : userSelected.qi_custom_fields[0].thematic_axis}
                  </strong>
                </Col>
                {userSelected.qi_custom_fields[0].thematic_axis !== "" && (
                  <>
                    <Col md="12">
                      <span>Modalidade da apresentação de Trabalho: </span>
                      <strong>
                        {userSelected.qi_custom_fields[0].typeworkpresentation}
                      </strong>
                    </Col>
                    <Col md="12">
                      <span>Nome do Trabalho: </span>
                      <strong>
                        {
                          userSelected.qi_custom_fields[0]
                            .typeworkpresentationname
                        }
                      </strong>
                    </Col>
                  </>
                )}
                <Col md="12">
                  <span>Oficina 1: </span>
                  <strong>
                    {userSelected.qi_rolesGroup.length > 0 &&
                      userSelected.qi_rolesGroup[0].ws_name}
                  </strong>
                </Col>
                <Col md="12">
                  <span>Oficina 2: </span>
                  <strong>
                    {userSelected.qi_rolesGroup.length > 1 &&
                      userSelected.qi_rolesGroup[1].ws_name}
                  </strong>
                </Col>
                <Col md="12">
                  <span>Oficina 3: </span>
                  <strong>
                    {userSelected.qi_rolesGroup.length > 2 &&
                      userSelected.qi_rolesGroup[2].ws_name}
                  </strong>
                </Col>
              </Row>
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={() => {
              toggle();
              setModal4(true);
            }}
            style={{ backgroundColor: "#51acff" }}
          >
            Aprovar Inscrição
          </Button>
          <Button
            className="ml-2"
            style={{ float: "left", backgroundColor: "red" }}
            onClick={() => {
              toggle();
              setModal3(true);
            }}
          >
            Excluir Inscrição
          </Button>
          <Button
            className="ml-auto p-2"
            onClick={toggle}
            style={{ backgroundColor: "rgb(15, 199, 98)" }}
          >
            Sair
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ModalUserDescription;
