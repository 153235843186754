import React from "react"
import { typeCrudForm } from "src/components/Common/typeCrudForm"
import FormCrudContainer from "src/components/CRUD/CrudForm"
import { post } from "src/helpers/api_helper"
import { getListManufacturers } from "../graphqlQuery"
import FormDay from "./forms/formDay"

export default function FormCrud(props: {
  handleNewRegister: (mode: string) => void;
  queryGetByCode: any;
  queryGet: any;
  querySave: any;
  queryUpdate: any;
  queryDelete: any;
  selectedRow: string;
  setSelectedRow: any;
  queryNextCrCode: any;
  columnsTable: Array<typeCrudForm>;
}) {
  const [listItensSelected, setListItensSelected] = React.useState([]);
  const { handleNewRegister } = props;

  React.useEffect(() => {
    async function getListCategory() {
      let response = await post(
        "/graphql",
        {
          query: getListManufacturers(),
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      ).then(response => response);
      if (response.data[Object.keys(response.data)[0]]) {
        let temp = [];
        const list = response.data[Object.keys(response.data)[0]];
        list.map(item => {
          temp.push({
            cr_code: item.cr_code,
            ws_value: item.ws_abbreviation,
          });
        });
        temp = [
          {
            nameList: "ws_manufacturers",
            content: temp,
          },
        ];
        setListItensSelected(temp);
      } else {
        alert("Falha ao trazer lista de fornecedores!");
        setListItensSelected([]);
      }
    }
    getListCategory();
  }, []);

  return (
    <FormCrudContainer
      Form={FormDay}
      handleNewRegister={handleNewRegister}
      listItens={listItensSelected}
      {...props}
    />
  );
}
