import moment from "moment";

/* Mascara para CPF/CNPJ, muda conforme quantidade de caracteres */
export const handleCpfCnpjChange = event => {
  // Get only the numbers from the data input
  let data = "";
  /* if (event && event.target && event.target.value) { */
  data = event.target.value.replace(/\D/g, "");
  /* } else {
    data = event.replace(/\D/g, "");
  } */
  // Checking data length to define if it is cpf or cnpj
  if (data.length > 11) {
    // It's cnpj
    let cnpj = `${data.substr(0, 2)}.${data.substr(2, 3)}.${data.substr(
      5,
      3
    )}/`;
    if (data.length > 12) {
      cnpj += `${data.substr(8, 4)}-${data.substr(12, 2)}`;
    } else {
      cnpj += data.substr(8);
    }
    data = cnpj;
  } else {
    // It's cpf
    let cpf = "";
    let parts = Math.ceil(data.length / 3);
    for (let i = 0; i < parts; i++) {
      if (i === 3) {
        cpf += `-${data.substr(i * 3)}`;
        break;
      }
      cpf += `${i !== 0 ? "." : ""}${data.substr(i * 3, 3)}`;
    }
    data = cpf;
  }
  return data;
};

export const handleCpfCnpjChange2 = event => {
  // Get only the numbers from the data input
  let data = "";
  /* if (event && event.target && event.target.value) { */

  data = event.replace(/\D/g, "");
  // Checking data length to define if it is cpf or cnpj
  if (data.length > 11) {
    // It's cnpj
    let cnpj = `${data.substr(0, 2)}.${data.substr(2, 3)}.${data.substr(
      5,
      3
    )}/`;
    if (data.length > 12) {
      cnpj += `${data.substr(8, 4)}-${data.substr(12, 2)}`;
    } else {
      cnpj += data.substr(8);
    }
    data = cnpj;
  } else {
    // It's cpf
    let cpf = "";
    let parts = Math.ceil(data.length / 3);
    for (let i = 0; i < parts; i++) {
      if (i === 3) {
        cpf += `-${data.substr(i * 3)}`;
        break;
      }
      cpf += `${i !== 0 ? "." : ""}${data.substr(i * 3, 3)}`;
    }
    data = cpf;
  }
  return data;
};

/* Valida cpf é legitimo */
export function validateCPF(cpf) {
  cpf = cpf.replace(/\D/g, "");
  if (cpf.toString().length != 11 || /^(\d)\1{10}$/.test(cpf)) return false;
  var result = true;
  [9, 10].forEach(function (j) {
    var soma = 0,
      r;
    cpf
      .split(/(?=)/)
      .splice(0, j)
      .forEach(function (e, i) {
        soma += parseInt(e) * (j + 2 - (i + 1));
      });
    r = soma % 11;
    r = r < 2 ? 0 : 11 - r;
    if (r != cpf.substring(j, j + 1)) result = false;
  });
  return result;
}

/* Validação de email */
function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

function casasDecimais(num, precisao) {
  var casas = Math.pow(10, precisao);
  return Math.floor(num * casas) / casas;
}

export const formatDateTime = "DD/MM/YYYY [às] HH:mm:ss";
export const formatDate = "DD/MM/YYYY";
export const formatTime = "HH:mm:ss";
export const timeZone = "America/Sao_Paulo";

export const formatDateTimeMongoDb = "YYYY-MM-DD HH:mm:ss";
export const formatDateTimeView = "DD/MM/YYYY HH:mm:ss";
export const formatDateView = "DD/MM/YYYY";
export const formatTimeView = "HH:mm:ss";
// moment().tz(timeZone).format();

/* Formata uma data selecionada conforme o formato selecionado */
export function getDateTimeZone(date = moment().tz(timeZone), format = "") {
  // const dateBegin1 = getDateTimeZone();
  // const dateBegin2 = getDateTimeZone(dateInit);
  // const dateBegin3 = getDateTimeZone(getDateTimeZone(), formatDateTimeView);
  // console.log(111, dateBegin1);
  // console.log(112, dateBegin2);
  // console.log(113, dateBegin3);
  return moment(date).format(format);
}
