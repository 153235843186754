import {typeCrudListColumns} from "src/components/Common/typeCridList";
import {typeCrudCollapse, typeCrudForm,} from "src/components/Common/typeCrudForm";
import GenerateForm from "src/components/CRUD/generateComponents";
import {FormPreview} from "./formPreview";

const FormManufacturer = (props: {
  dataFormDb: {
    bf_liberation: Boolean;
    cr_code: String;
    ws_name: String;
    wh_image: String;
    ws_abbreviation: String;
    hl_abbreviation_url: String;
  };
  columnsTable: Array<typeCrudListColumns>;
  nextCrCode: string;
  listItens: any;
}) => {
  let { dataFormDb, columnsTable, nextCrCode, listItens } = props;
  if (nextCrCode) {
    dataFormDb.cr_code = nextCrCode;
  }

  function listDays(): Array<{
    cr_code: string;
    ws_value: string;
  }> {
    let temp = [];
    const list = listItens.filter(item => item.nameList === "ws_days");
    list.length &&
    list.length > 0 &&
    list[0].content.map(item =>
        temp.push({
          cr_code: item.cr_code,
          ws_value: item.ws_value,
        })
    );
    return temp;
  }

 const ORIGIN_PRICE = [
    {value: 'P', label: 'Produto'},
    {value: 'D', label: 'Day'},
 ];

 const TYPE_PRICE = [
    {value: 'V', label: 'Á vista'},
    {value: 'P', label: 'A prazo'},
    {value: 'S', label: 'Sem preço'},
 ];

 const ORDER_PRODUCT = [
    {value: 'D', label: 'Descrição'},
    {value: 'P', label: 'Preço'},
    {value: 'Z', label: 'Personalizada'},
 ];

 const TYPE_ALIGN = [
    {value: 'E', label: 'Esquerda'},
    {value: 'C', label: 'Centralizado'},
    {value: 'D', label: 'Direita'},
 ];

  function listOriginPrice(): Array<{
    cr_code: string;
    ws_value: string;
  }> {
    let temp = [];
    ORIGIN_PRICE.map(item => temp.push({
      cr_code: item.value,
      ws_value: item.label,
    }))

    return temp;
  }

  function listTypePrice(): Array<{
    cr_code: string;
    ws_value: string;
  }> {
    let temp = [];
    TYPE_PRICE.map(item => temp.push({
      cr_code: item.value,
      ws_value: item.label,
    }))

    return temp;
  }

  function listOrderProduct(): Array<{
    cr_code: string;
    ws_value: string;
  }> {
    let temp = [];
    ORDER_PRODUCT.map(item => temp.push({
      cr_code: item.value,
      ws_value: item.label,
    }))

    return temp;
  }

  function listTypeAlign(): Array<{
    cr_code: string;
    ws_value: string;
  }> {
    let temp = [];
    TYPE_ALIGN.map(item => temp.push({
      cr_code: item.value,
      ws_value: item.label,
    }))

    return temp;
  }


  const flyerContent: Array<typeCrudForm> = [
    {
      Header: "Código",
      accessor: "cr_code",
      cols: "6",
      required: true,
    },
    {
      Header: "Título",
      accessor: "ws_title",
      cols: "6",
      required: false,
    },
    {
      Header: "Day",
      accessor: "cr_code_day",
      cols: "12",
      required: false,
      uniqueRow: false,
      listItens: listDays(),
    },
    {
      Header: "Origem preço",
      accessor: "sl_origin_price",
      cols: "4",
      required: false,
      listItens: listOriginPrice(),
    },
    {
      Header: "Tipo preço",
      accessor: "sl_type_price",
      cols: "4",
      required: false,
      listItens: listTypePrice(),
    },
    {
      Header: "Ordem dos produtos",
      accessor: "sl_order_product",
      cols: "4",
      required: false,
      listItens: listOrderProduct(),
    },
    {
      Header: "Flyer largura",
      accessor: "nb_width_image",
      cols: "4",
      required: false,
    },
    {
      Header: "Flyer margem esq.",
      accessor: "nb_margin_left_image",
      cols: "4",
      required: false,
    },
    {
      Header: "TOPO - Margem inferior",
      accessor: "nb_top_margin_bottom_image",
      cols: "4",
      required: false,
    },
    {
      Header: "RODAPÉ - Margem superior",
      accessor: "nb_footer_margin_higher_image",
      cols: "4",
      required: false,
    },
    {
      Header: "Topo/Rodapé alinh.",
      accessor: "sl_top_footer_align_image",
      cols: "4",
      required: false,
      listItens: listTypeAlign(),
    },
    {
      Header: "Tamanho da fonte",
      accessor: "nb_font_size_image",
      cols: "4",
      required: false,
    },
  ];

  const dimensionsBoxContent: Array<typeCrudForm> = [
    {
      Header: "Largura",
      accessor: "nb_box_prod_width_image",
      cols: "6",
      required: false,
    },
    {
      Header: "Altura",
      accessor: "nb_box_prod_height_image",
      cols: "6",
      required: false,
    },
  ];

  const marginsBoxContent: Array<typeCrudForm> = [
    {
      Header: "Direita",
      accessor: "nb_box_prod_margin_right_image",
      cols: "4",
      required: false,
    },
    {
      Header: "Inferior",
      accessor: "nb_box_prod_margin_bottom_image",
      cols: "4",
      required: false,
    },
    {
      Header: "Cor da borda",
      accessor: "cl_box_prod_border_color_image",
      cols: "4",
      required: false,
    },
  ];

  const priceContent: Array<typeCrudForm> = [
    {
      Header: "Alinhamento",
      accessor: "sl_box_price_align",
      cols: "4",
      required: false,
      listItens: listTypeAlign(),
    },
    {
      Header: "Margem superior",
      accessor: "nb_coin_margin_higher",
      cols: "4",
      required: false,
    },
    {
      Header: "Margem direita",
      accessor: "nb_coin_margin_right",
      cols: "4",
      required: false,
    },
    {
      Header: "Tamanho da fonte para preço",
      accessor: "nb_price_font_size",
      cols: "6",
      required: false,
    },
    {
      Header: "Tamanho da fonte para moeda",
      accessor: "nb_coin_font_size",
      cols: "6",
      required: false,
    },
  ];

  const previewContent: Array<typeCrudForm> = [
    {
      Header: "",
      accessor: "ox_product",
      cols: "12",
      required: false,
      custom: <>{<FormPreview dataFormDb={dataFormDb} />}</>,
    },
  ];

  const subtitleForm: Array<typeCrudCollapse> = [
    {
      CollapseContent: flyerContent,
      CollapseTitle: "Flyer",
    },
    {
      CollapseContent: dimensionsBoxContent,
      CollapseTitle: "Dimensões caixa produto",
    },
    {
      CollapseContent: marginsBoxContent,
      CollapseTitle: "Margens da caixa",
    },
    {
      CollapseContent: priceContent,
      CollapseTitle: "Preço",
    },
    {
      CollapseContent: previewContent,
      CollapseTitle: "Pré-visualização",
    },
  ];
  return GenerateForm(dataFormDb, subtitleForm);
};

export default FormManufacturer;
