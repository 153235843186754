export const getIntegrationConfiguration =  (): string => {
  return `query 
    {
      getIntegrationConfigurationStatus(
        filter: {
          sl_serviceSelectName: ""
          ws_serviceStatus: ""
          ws_serviceStatusDescription: ""
        }
      ) {
          services {
            cr_code
            dx_register
            sl_service_select_name
            ws_status
            bf_active
            ws_error_system
          }
          error                         
      }
    }`
}

export const setIntegrationConfigurationStatus = (
  sl_service_select_name: String,
  bf_active: Boolean
): string => {
  return `mutation 
    {
      updateIntegrationStatus(
        sl_service_select_name: "${sl_service_select_name}"
        bf_active: ${bf_active}
      ) {
          message
          error
      }
    }`
}