import moment from 'moment';
import React from 'react';
import { formatDateTime } from 'src/helpers/functions';
import CrudContainer from './CrudContainer';
import {
  deleteSubCategory,
  getNextCrCodeSubCategory,
  getSubCategoryByCode,
  getSubCategorys,
  insertSubCategory,
  updateSubCategory
} from './graphqlQuery';

function BfLiberation({ value, column, row }) {
  return (
    <a>
      {value ? (
        <span style={{ color: 'green' }}>Sim</span>
      ) : (
        <span style={{ color: 'red' }}>Não</span>
      )}
    </a>
  );
}

function WScategories({ value, column, row }) {
  return <>{value ? value : row.original.ws_categorie}</>;
}

const SubCategory = () => {
  const columns = React.useMemo(
    () => [
      {
        Header: "Código",
        accessor: "cr_code",
        visible: false,
      },
      {
        Header: "Categoria Pai",
        accessor: "ws_categories",
        Cell: WScategories,
      },
      {
        Header: "Subcategoria",
        accessor: "ws_name",
      },
      {
        Header: "Preço Cartucho",
        Cell: BfLiberation,
        accessor: "bf_sale_cartucho",
      },
      {
        Header: "Data Cadastro",
        accessor: "dx_register",
        Cell: ({ value, column, row }) => {
          return moment(value).format(formatDateTime);
        },
      },
      {
        Header: "Ações",
        accessor: "actions",
        Filter: () => <div style={{ height: "10px" }}></div>,
        Cell: cellContent => (
          <div
            style={{
              width: "100%",
              textAlign: "center",
              backgroundColor: "red",
            }}
          >
            {cellContent}
          </div>
        ),
      },
    ],
    []
  );
  return (
    <CrudContainer
      routePath=""
      queryGet={getSubCategorys}
      queryGetByCode={getSubCategoryByCode}
      querySave={insertSubCategory}
      queryUpdate={updateSubCategory}
      queryDelete={deleteSubCategory}
      queryNextCrCode={getNextCrCodeSubCategory}
      title="SubCategorias | Braile Distribuidora"
      breadcrumbTitle="Categoria"
      breadcrumbItem="SubCategorias"
      columnsTable={columns}
    />
  );
};

export default SubCategory;
