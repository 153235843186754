import { typeCrudListColumns } from "src/components/Common/typeCridList"
import ListCrudContainer from "src/components/CRUD/CrudList"

export default function ListCrud(props: {
  columnsTable: Array<typeCrudListColumns>;
  handleNewRegister?: string;
  setSelectedRow?: string;
  fieldsCustomFilters?: any;
}) {
  const { columnsTable, handleNewRegister, setSelectedRow, fieldsCustomFilters } = props;

  return (
    <ListCrudContainer
      columns={columnsTable}
      handleNewRegister={handleNewRegister}
      setSelectedRow={setSelectedRow}
      optionsActions={[]}
      visibleNewRegister={false}
      fieldsCustomFilters={fieldsCustomFilters}
      {...props}
    />
  );
}
