/* IMPORTANTE: Deve incluir as fun principais do CRUD
 * getData, updateData, deleteData, postData
 * estas chamadas são padrão no CRUD/CrudGroupForm */

export const getCustomerByCode = (cr_code: string): string => {
  return `{
    getCustomerByCode(cr_code:"${cr_code}") {
      cv_version
      bf_active
      bf_liberation
      cr_code
      ck_registeruser
      dx_register
      dx_integration_sitetoerp
      dx_integration_erptosite
      dx_modification
      wj_cnpj
      cr_code_seller
      cr_code_operation
      cr_code_registration
      wp_password
      sl_type_client
      sl_type_transport
      bf_restricted_registry
      bf_own_credit
      bf_generate_budget
      sl_curve_type
      nb_tax_condition
      dx_data_login
      cr_code_erp
      sl_payment_term {
        value
        key
        check
      }
      sl_special_payment {
        value
        key
        check
      }
      ws_seller
      ws_seller_indication
      cr_code_payment_terms
      cr_code_special_payment
      ox_registration_fields {
        cv_version
        bf_active
        bf_liberation
        cr_code
        ck_registeruser
        dx_register
        dx_modification
        ws_corporate_reason
        wj_cnpj
        wi_state_registration
        wm_email
        ws_indication
        bf_doc_social_contract
        bf_doc_social_identity
        bf_doc_address_voucher
        bf_doc_income_voucher
        bf_doc_state_registration
        bf_doc_cnpj
        ws_supplier_reference
        ws_bank_reference
        ws_agency_reference
        ct_contact_reference
        bf_approval
        dx_register_approval
        ws_registeruserapprovalaccount
        ox_register_approval {
          bf_approval
          dx_register
          ws_registeruserapprovalaccount
        }
        ct_qi_phone_contact {
          ws_description
          nc_number
          bf_whatsapp
        }
        qi_address {
          ws_street
          nb_street_number
          ws_district
          ws_city
          ws_state
          zp_zipcode
          ws_complement
          ox_geo {
            lt_lat
            lt_lng
          }
        }
      }
    }
  }`;
};

export const getCustomers = (): string => {
  return `query {
    getCustomers {
      cv_version
      bf_active
      bf_liberation
      cr_code
      ck_registeruser
      dx_register
      dx_modification
      wj_cnpj
      cr_code_seller
      cr_code_operation
      cr_code_registration
      wp_password
      sl_type_client
      sl_type_transport
      bf_restricted_registry
      bf_own_credit
      bf_generate_budget
      sl_curve_type
      nb_tax_condition
      dx_data_login
      ox_registration_fields {
        cv_version
        bf_active
        bf_liberation
        cr_code
        ck_registeruser
        dx_register
        dx_modification
        ws_corporate_reason
        wj_cnpj
        wi_state_registration
        wm_email
        ws_indication
        bf_doc_social_contract
        bf_doc_social_identity
        bf_doc_address_voucher
        bf_doc_income_voucher
        bf_doc_state_registration
        bf_doc_cnpj
        ws_supplier_reference
        ws_bank_reference
        ws_agency_reference
        ct_contact_reference
        bf_approval
        dx_register_approval
        ws_registeruserapprovalaccount
        ox_register_approval {
          bf_approval
          dx_register
          ws_registeruserapprovalaccount
        }
        ct_qi_phone_contact {
          ws_description
          nc_number
          bf_whatsapp
        }
        ws_seller
        qi_address {
          ws_street
          nb_street_number
          ws_district
          ws_city
          ws_state
          zp_zipcode
          ws_complement
          ox_geo {
            lt_lat
            lt_lng
          }
        }
      }
    }
  }`;
};

export const deleteCustomer = (cr_code: string): string => {
  return `query {
    deleteCustomerByCode(cr_code:"${cr_code}") {
      message
    }
  }`;
};

export const updateCustomer = (data: {
  cr_code: String;
  cr_code_payment_terms: String;
  cr_code_special_payment: String;
  bf_restricted_registry: Boolean;
  bf_own_credit: Boolean;
  bf_generate_budget: Boolean;
}): string => {
  return `mutation {
    updateCustomerAdmin(
      cr_code: "${data.cr_code}",
      cr_code_payment_terms: "${data.cr_code_payment_terms ? data.cr_code_payment_terms : ""
    }",
      cr_code_special_payment: "${data.cr_code_special_payment ? data.cr_code_special_payment : ""
    }",
      bf_restricted_registry: ${data.bf_restricted_registry},
      bf_own_credit: ${data.bf_own_credit},
      bf_generate_budget: ${data.bf_generate_budget},
   ){
  	message
   }
  }`;
};

export const insertCustomer = (data: {
  bf_liberation: Boolean;
  cr_code: String;
  ws_name: String;
  wh_image: String;
  ws_abbreviation: String;
  hl_abbreviation_url: String;
}): string => {
  return `mutation {
    insertCustomer(
      bf_liberation: ${data.bf_liberation},
      cr_code: "${data.cr_code ? data.cr_code : ""}",
      ws_name: "${data.ws_name ? data.ws_name : ""}",
      wh_image: "${data.wh_image ? data.wh_image : ""}",
      ws_abbreviation: "${data.ws_abbreviation ? data.ws_abbreviation : ""}",
      hl_abbreviation_url: "${data.hl_abbreviation_url ? data.hl_abbreviation_url : ""
    }",
   ){
  	message
   }
  }`;
};

export const getNextCrCodeCustomer = (): string => {
  return `query {
    getNextCrCodeCustomer {
      result
    }
  }`;
};

function isJson(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export const getCustomerPagination = (
  filter: string,
  limit: Number = 10,
  random: Boolean = false,
  skip: Number = 0,
  sort: String = "{}"
): string => {
  let filter3;

  if (filter === undefined || filter === "{}") {
    filter3 = '{"ox_register_approval.bf_approval":true,"ox_identification.cr_code_erp":{"$ne":null}}' //no inicio sem filtro nenhum
  } else {
    let filterText = '';
    if (isJson(filter)) {
      let filterJson = JSON.parse(filter);
      if (filterJson.$text && filterJson.$text.$search) {
        filterText = JSON.stringify(filterJson.$text.$search);
      }
    } 

    if (filterText) {
      filter3 = `{"$text":{"$search":${filterText}},"ox_register_approval.bf_approval":true,"ox_identification.cr_code_erp":{"$ne":null}}`; //com f8ltro busca
    } else {
      filter3 = JSON.stringify(filter); 
    }
  }

  return `query {
    getCustomerPagination(filter: {
        filter: ${JSON.stringify(filter3)},
        sort: "${sort}",
        skip: ${skip},  
        limit: ${limit},                      
        random: ${random},
      }) {
      result {
        cr_code_erp
        cv_version
        bf_active
        bf_liberation
        cr_code
        ck_registeruser
        dx_register
        dx_integration_sitetoerp
        dx_integration_erptosite
        dx_modification
        wj_cnpj
        cr_code_seller
        cr_code_operation
        cr_code_registration
        wp_password
        sl_type_client
        sl_type_transport
        bf_restricted_registry
        bf_own_credit
        bf_generate_budget
        sl_curve_type
        nb_tax_condition
        dx_data_login
        cr_code_erp
        sl_payment_term {
          value
          key
          check
        }
        sl_special_payment {
          value
          key
          check
        }
        ws_seller
        ox_registration_fields {
          cv_version
          bf_active
          bf_liberation
          cr_code
          ck_registeruser
          dx_register
          dx_modification
          ws_corporate_reason
          wj_cnpj
          wi_state_registration
          wm_email
          ws_indication
          bf_doc_social_contract
          bf_doc_social_identity
          bf_doc_address_voucher
          bf_doc_income_voucher
          bf_doc_state_registration
          bf_doc_cnpj
          ws_supplier_reference
          ws_bank_reference
          ws_agency_reference
          ct_contact_reference
          bf_approval
          dx_register_approval
          ws_registeruserapprovalaccount
          ox_register_approval {
            bf_approval
            dx_register
            ws_registeruserapprovalaccount
          }
          ct_qi_phone_contact {
            ws_description
            nc_number
            bf_whatsapp
          }
          qi_address {
            ws_street
            nb_street_number
            ws_district
            ws_city
            ws_state
            zp_zipcode
            ws_complement
            ox_geo {
              lt_lat
              lt_lng
            }
          }
        }
      } 
      rows
      rowsall
    }
  }`;
};

export const getPaymentTerms = (): string => {
  return `query {
    getPaymentTerms {
        cr_code
        ws_name
    }
  }`;
};

export const setUpdatePasswordCustomerAdmin = (
  cr_code: String
): string => {
  return `mutation {
    updatePasswordCustomerAdmin(
      cr_code_user: "${cr_code}"
    ) {
      result
      status
    }
  }`;
};

export const getCustomerFilter = (
  filter: string = "{}",
  limit: Number = 10,
  random: Boolean = false,
  skip: Number = 0,
  sort: String = "{}"
): string => {
  let filter2;

  if (filter === undefined || filter === "{}" || filter === "") {
    filter2 = '{"ox_register_approval.bf_approval":true,"ox_identification.cr_code_erp":{"$ne":null}}' //no inicio sem filtro nenhum
  } else {
    let filterText = '';
    if (isJson(filter)) {
      let filterJson = JSON.parse(filter);
      if (filterJson.$text && filterJson.$text.$search) {
        filterText = JSON.stringify(filterJson.$text.$search);
      }
    } 

    if (filterText) {
      filter2 = `{"$text":{"$search":${filterText}},"ox_register_approval.bf_approval":true,"ox_identification.cr_code_erp":{"$ne":null}}`; //com f8ltro busca
    } else {
      filter2 = JSON.stringify(filter); 
    }
  }

  let filter3 = filter2 === "" ? JSON.stringify("{}") : JSON.stringify(filter2);

  return `query {
    getCustomerPagination(filter: {
        filter: ${filter3},
        sort: "${sort}",
        skip: ${skip},  
        limit: ${limit},                      
        random: ${random},
      }) {
      result {
        cr_code_erp
        cr_code
        sl_type_client
        sl_curve_type
        nb_tax_condition
        bf_restricted_registry
        bf_own_credit
        dx_data_login
        bf_liberation
        dx_register
        ox_registration_fields {
          ws_corporate_reason
          qi_address {
            ws_state
          }
        }
      } 
      rows
      rowsall
    }
  }`;
};


