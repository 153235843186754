import React, { useEffect } from 'react';

import { Button, Spinner } from 'reactstrap';

import { DownloadExcelProps } from './types';

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

const DownloadExcel: React.FC<DownloadExcelProps> = (props) => {
  const { filter, dataFilter, columns, query, loading, limit, dataTable } = props;

  const [dataExport, setDataExport] = React.useState([]);

  useEffect(() => {
    if (dataFilter && columns) {
      const dataFilterFormatted = [...dataFilter];
      const dataExport = dataFilterFormatted.map((item) => {
        //para cada item do array columns, retorna um objeto com o nome do atributo Header como obj[key] e o valor do item com o atributo accessor
        const object = columns.filter(i => i.Header !== 'Ações').reduce((obj, key) => {
          if (item[key.accessor] === undefined && key.accessorParent) {
            //usa o accessorParent para pegar o valor do objeto dentro do objeto
            let value;

            if (item[key.accessorParent][key.accessorParent2]) {
              value = item[key.accessorParent][key.accessorParent2][0][key.accessor];
            } else {
              if (item[key.accessorParent].length > 0) {
                value = item[key.accessorParent][0][key.accessor];
              } else {
                value = item[key.accessorParent][key.accessor];
              }
            }

            obj[key.Header] = value === false ? 'Não' : value === true ? 'Sim' : value;
          } else {
            if (item[key.accessor] === null) {
              obj[key.Header] = 'Nunca';
            } else {
              if (item[key.accessor] === true || item[key.accessor] === false || item[key.accessor] === 'N') {
                obj[key.Header] = item[key.accessor] === false || item[key.accessor] === 'N' ? 'Não' : 'Sim';
              } else {
                obj[key.Header] = item[key.accessor];
              }
            }
          }
          return obj;
        }
          , {});
        return object;
      });
      setDataExport(dataExport);
    }
  }, [dataFilter])


  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = (apiData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(apiData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (<>
    {filter && filter.length > 0 && (
      <>
        {dataExport.length === 0 && !loading && dataTable && dataTable.length > 0 && 
          <Button style={{ marginBottom: '10px' }} onClick={() => query()}>Preparar dados para exportar</Button>
        }

        {dataExport && dataExport.length > 0 && !loading && //pronto para exportar
          <Button style={{ marginBottom: '10px' }} onClick={(e) => exportToCSV(dataExport, 'Relatório')}>Gerar relatório - {dataExport.length} registros</Button>
        }

        {loading &&
          (
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
              <Spinner size="sm" animation="border" variant="secondary" />
              <span style={{ marginLeft: '5px' }}>Carregando dados para exportar: {dataExport.length} de {limit}</span>
            </div>
          )
        }
      </>
    )}
  </>)
}

export default DownloadExcel;
