import _ from "lodash"
import React, { useEffect } from "react"
import { Col, FormGroup } from "reactstrap"
import { contentUrlServer, typeUrlServer } from "src/common/urlServer"
import { typeCrudForm } from "src/components/Common/typeCrudForm"
import AvFieldBraile from "src/components/general/Input/AvFieldBraile";
import LabelBraile from "src/components/general/Label/Label";
import { API_URL_UPLOAD, post } from "src/helpers/api_helper"
import { Observation } from "../styled"

// Traz a configuração pré-definida de urls do servidor
const urlServer: typeUrlServer = contentUrlServer.filter(
  item => item.name == "BANNER"
)[0];


// Input # Lista de Imagens do arquivo
export default function getWL (
  valueField: any,
  subtitleFields: typeCrudForm,
  dataFormDb: any
): any {   
  const [img, setImg] = React.useState(null);
  const [imgFile, setImgFile] = React.useState(null);
  let [invalidFile, setInvalidFile] = React.useState(false);

  // ==========================================
  // Upload formdata
  const submitForm = (files) => {    
    const formDataNew = new FormData();
    formDataNew.append("location", subtitleFields.urlPathServerFile);
    formDataNew.append("file", files[0]);
    const apiUrl = API_URL_UPLOAD;
    const options = {
      method: 'POST',
      body: formDataNew,
      maxContentLength: "infinity",
      maxBodyLength: "infinity",
      // headers: new Headers({
      //   'Access-Control-Allow-Origin': '*',
      //   'Access-Control-Allow-Headers': 'Content-Type, Authorization', 
      //   'Access-Control-Allow-Methods': 'OPTIONS, GET, POST, PUT, PATCH, DELETE', 
      //   'Content-Type': 'application/json; charset=utf-8'
      // }), 
    };

    fetch(`${apiUrl}/user/upload`, options)
    // postForm('/banner', formDataNew, {
    //   headers: {
    //     // 'Accept': 'application/json',
    //     'Access-Control-Allow-Origin': '*',
    //     // "Content-Type": "multipart/form-data",
    //     // "Access-Control-Allow-Headers": 'Content-Type, Authorization',
    //     "Access-Control-Allow-Methods": 'OPTIONS, GET, POST, PUT, PATCH, DELETE'
    //   }
    // })
    // .then(response => console.log(response))
    // .catch(error => alert("Erro upload: " + error))
  }
  // ==========================================

  function dataURLToBlob(dataurl) {
    let arr = dataurl.split(",");
    let mime = arr[0].match(/:(.*?);/)[1];
    let bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  }

  const handleChangeImage = ({ target: { files, name } }) => {
    const cancel = !files.length;
    if (cancel) return;
    _.set(dataFormDb, subtitleFields.accessor, files[0].name.toLowerCase());
    _.set(dataFormDb, "ws_image_name", files[0].name);
    // _.set(dataFormDb, "ws_image", files[0].name);
    // if (files[0].size > 1024)
    // onFileSelectError({ error: "File size cannot exceed more than 1MB" });

    setImg(URL.createObjectURL(files[0]));
    setImgFile(files[0]);
    submitForm(files)
  };

  const onFileUpload = async () => {
    const formData = new FormData();
    formData.append("file", img);
    formData.append("name", img.name);
    formData.append("size", img.size);
    formData.append("identification", subtitleFields.identification);

    let response = await post("/sendfile", formData, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(response => alert(response))
      .catch(error => console.log(error));
  };

  const validateFile = React.useCallback((value, ctx, input, cb) => {
    if (!value) {
      setInvalidFile(true);
      cb("Campo obrigatório");
      return;
    }

    setInvalidFile(false);
    cb(true);
  }, []);

  const handleCancelImage = e => {
    _.set(
      dataFormDb,
      subtitleFields.accessor,
      e.target.files[0].name.toLowerCase()
    );
    _.set(dataFormDb, "ws_image_name", "");
    setImg("");
  };

  // Teste
  function toDataUrl(url, callback) {
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
      callback(xhr.response);
    };
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.send();
  }

  useEffect(() => {
    /* toDataUrl(
      urlServer.urlServer + urlServer.pathServer + valueField,
      function (x) {
        console.log(999, x);
        setImgFile(x);
      }
    ); */
  }, [valueField]);

  return (
    <Col
      className="mt-3"
      lg={subtitleFields.cols}
      sm={subtitleFields.cols}
      md={subtitleFields.cols}
      xs={12}
    >
      <FormGroup className="">
        <LabelBraile htmlFor="validationCustom01">
          {subtitleFields.Header}
        </LabelBraile>
        <Col className="mt-12" lg="12" sm="12" md="12" xs={12}>
          {/* <input
              type="file"
              className="form-control"
              id="inputGroupFile04"
              aria-describedby="inputGroupFileAddon04"
              aria-label="Carregar arquivo"
              onChange={handleChangeImage}
            /> */}
          <AvFieldBraile
            className="form-control"
            aria-describedby={subtitleFields.accessor}
            aria-label="Carregar arquivo"
            /* value={imgFile ? imgFile : null} */
            name={subtitleFields.accessor}
            id={
              subtitleFields.idfield
                ? subtitleFields.idfield
                : subtitleFields.accessor
            }
            type="file"
            accept="image/png, image/jpeg"
            errorMessage="Campo obrigatório"
            validate={{
              required: {
                value: !img && valueField.length === 0 ? subtitleFields.required : false,
              },
              /* custom: validateFile, */
            }}
            onChange={handleChangeImage}
            /* invalid={invalidFile} */
          />
          {/* <button
              className="btn btn-outline-secondary"
              type="button"
              id="inputGroupFileAddon04"
              onClick={() => handleCancelImage}
            >
              Cancelar
            </button> */}
        </Col>
        <Col className="mt-3" lg="9" sm="9" md="9" xs={12}>
          <div className="flex items-center">
            <div className="flex-shrink-0 h-10 w-10">
              {img ? (
                <img
                  className="rounded img-t1"
                  src={img}
                  alt="img"
                  style={{ maxWidth: "80%" }}
                />
              ) : valueField && valueField.length > 0 ? (<>
                {valueField.map(image => <img
                  className="rounded img-t1"
                  src={
                    subtitleFields.url
                      ? subtitleFields.url + image
                      : urlServer.urlServer + urlServer.pathServer + image
                  }
                  alt="Imagem"
                  style={{ maxWidth: "80%" }}
                />)}                
              </>) : (
                ""
              )}
            </div>
          </div>
        </Col>
      </FormGroup>
      {subtitleFields.observation && (
        <Observation>{subtitleFields.observation}</Observation>
      )}
    </Col>
  );
};