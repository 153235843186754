import { contentUrlServer, typeUrlServer } from "src/common/urlServer"
import { typeCrudListColumns } from "src/components/Common/typeCridList"
import {
  typeCrudCollapse,
  typeCrudForm
} from "src/components/Common/typeCrudForm"
import GenerateForm from "src/components/CRUD/generateComponents"

// Traz a configuração pré-definida de urls do servidor
const urlServer: typeUrlServer = contentUrlServer.filter(
  item => item.name == "MANUFACTURER"
)[0];

const FormManufacturer = (props: {
  dataFormDb: {
    bf_liberation: Boolean;
    cr_code: String;
    ws_name: String;
    wh_image: String;
    ws_abbreviation: String;
    hl_abbreviation_url: String;
  };
  columnsTable: Array<typeCrudListColumns>;
  nextCrCode: string;
}) => {
  let { dataFormDb, columnsTable, nextCrCode } = props;
  if (nextCrCode) {
    dataFormDb.cr_code = nextCrCode;
  }

  // Traz a configuração pré-definida de urls do servidor
  const urlServer: typeUrlServer = contentUrlServer.filter(
    item => item.name == "MANUFACTURER"
  )[0];


  const subtitleContent: Array<typeCrudForm> = [
    {
      Header: "Logotipo",
      accessor: "wh_image",
      url: urlServer.urlServer + urlServer.pathServer + "/",
      cols: "6",
      required: true,
      uniqueRow: true,
      urlPathServerFile: urlServer.pathServer,
      observation:
        "As dimensões (largura X altura) da imagem são livres, observar para não ficar grande demais na página de detalhes de produto.",
    },
    {
      Header: "Código",
      accessor: "cr_code",
      cols: "6",
      required: true,
    },
    {
      Header: "Liberação",
      accessor: "bf_liberation",
      cols: "4",
      required: false,
      observation: "Apenas os fabricantes liberados aparecem no site.",
    },
    {
      Header: "Nome",
      accessor: "ws_name",
      cols: "6",
      required: true,
      uniqueRow: true,
      disabled: true,
    },
    {
      Header: "Abreviatura",
      accessor: "ws_abbreviation",
      cols: "6",
      required: false,
      uniqueRow: true,
    },
    {
      Header: "Abreviatura URL",
      accessor: "hl_abbreviation_url",
      cols: "6",
      required: false,
      disabled: true,
      uniqueRow: true,
    },
    {
      Header: "Data cadastro",
      accessor: "dx_register",
      cols: "6",
      required: false,
      disabled: true,
    },
    {
      Header: "Data int. ERP",
      accessor: "dx_modification",
      cols: "6",
      required: false,
      disabled: true,
    },
  ];
  const subtitleForm: Array<typeCrudCollapse> = [
    {
      CollapseContent: subtitleContent,
    },
  ];
  return GenerateForm(dataFormDb, subtitleForm);
};

export default FormManufacturer;
