import { useEffect, useState } from "react"
import { Col, Input } from "reactstrap"
import { LabelStyled } from "./styled"

export const getDateBox = (item, setFilterDb?: any) => {
  const [search, setSearch] = useState([]);
  const [defaultCols, setDefaultCols] = useState(3);
  const [cols, setCols] = useState(defaultCols);
  
  function handleChange(key: string, value: any) {
    console.log('val', value, key);
    setSearch(prevRegister => ({ ...prevRegister, [key]: value })); 
  }

  useEffect(() => {
    if (item?.cols && item?.cols > 0) {
      setCols(item.cols * defaultCols)
    }
  }, [item])

  return <><Col 
  lg={cols}
  sm={cols}
  md={cols}
  xs={cols}
  style={{ textAlign: "left" }}
>
  <LabelStyled>{item.name}</LabelStyled>  
  <Input 
    bsSize="sm"
    type={item.type}
    onChange={e => handleChange(item.field, e.target.value)}
  />
</Col>
{item.uniqueRow && <Col 
  className="m-1"
  lg={cols - 12}
  sm={cols - 12}
  md={cols - 12}
  xs={cols - 12}
  style={{ textAlign: "left" }}
  ></Col>}
  </>
}