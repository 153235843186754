import axios from "axios"
import accessToken from "./jwt-token-access/accessToken"

const token = accessToken;
export const API_URL_DNS = "https://braileonline.com.br";        //PRD

// export const API_URL = "https://braileonline.com.br:5000/graphql";        //PRD
export const API_URL = "https://compras.braileonline.com.br:5000/graphql";        //PRD
// export const API_URL = "http://127.0.0.1:5000";
//  const API_URL = "http://192.241.158.42:5000";
// const API_URL = "http://177.153.59.214:5000";   //PRD
export const API_URL_UPLOAD = "https://compras.braileonline.com.br:5000";        //PRD

const axiosApi = axios.create({
  baseURL: API_URL,
  headers: {    
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': 'Content-Type, Authorization', 
    'Access-Control-Allow-Methods': 'OPTIONS, GET, POST, PUT, PATCH, DELETE', 
    'Content-Type': 'application/json; charset=utf-8',
  }
});

//axiosApi.defaults.headers.common["Authorization"] = token

axiosApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
);

export async function get(url: string, config = {}) {
  return await axiosApi.get(url, { ...config }).then(response => response.data);
}

export async function post(url: string, data: any, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => response.data);
}

export async function put(url: string, data: any, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data);
}

export async function del(url: string, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data);
}
