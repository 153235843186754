/* IMPORTANTE: Deve incluir as fun principais do CRUD
 * getData, updateData, deleteData, postData
 * estas chamadas são padrão no CRUD/CrudGroupForm */

export const getPaymentTermRangeByCode = (cr_code: string): string => {
  return `{
    getPaymentTermRangeByCode(cr_code:"${cr_code}") {
      ws_installment
      ws_start
    }
  }`;
};
