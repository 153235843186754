import { typeCrudListColumns } from "src/components/Common/typeCridList"
import {
  typeCrudCollapse,
  typeCrudForm
} from "src/components/Common/typeCrudForm"
import GenerateForm from "src/components/CRUD/generateComponents"

const FormManufacturer = (props: {
  dataFormDb: {
    bf_liberation: Boolean;
    cr_code: String;
    ws_name: String;
    ws_email: String;
    bf_pagina_contato: Boolean;
    bf_trabalhe_conosco: Boolean;
    bf_aviso_cad_cliente: Boolean;
    bf_aviso_pedido: Boolean;
    bf_aviso_rma: Boolean;
    bf_aviso_item_carrinho: Boolean;
    bf_integra_cad_site2erp: Boolean;
  };
  columnsTable: Array<typeCrudListColumns>;
  nextCrCode: string;
}) => {
  let { dataFormDb, columnsTable, nextCrCode } = props;
  if (nextCrCode) {
    dataFormDb.cr_code = nextCrCode;
  }

  const complementsContent: Array<typeCrudForm> = [
    {
      Header: "Nome Completo",
      accessor: "ws_name",
      cols: "8",
      required: true,
    },
    {
      Header: "Ativo",
      accessor: "bf_active",
      cols: "4",
      required: false,
      observation:
        "Destinatários inativos não recebem os e-mails enviados pelo site.",
    },
    {
      Header: "E-mail",
      accessor: "ws_email",
      cols: "8",
      required: true,
      uniqueRow: true,
    },
    {
      Header: "Página Contato",
      accessor: "bf_pagina_contato",
      cols: "12",
      required: false,
      uniqueRow: true,
      observation:
        "Se o destinatário deve receber e-mails enviados pela página pública de contato do site.",
    },
    {
      Header: "Trabalhe Conosco",
      accessor: "bf_trabalhe_conosco",
      cols: "12",
      required: false,
      uniqueRow: true,
      observation:
        "Se o destinatário deve receber e-mails da página de contato contendo upload de currículo.",
    },
    {
      Header: "Cadastro Cliente",
      accessor: "bf_aviso_cad_cliente",
      cols: "12",
      required: false,
      uniqueRow: true,
      observation:
        "Se o destinatário deve receber avisos sobre novos clientes cadastrados a partir do site.",
    },
    {
      Header: "Aviso Pedido",
      accessor: "bf_aviso_pedido",
      cols: "12",
      required: false,
      uniqueRow: true,
      observation:
        "Se o destinatário deve receber avisos sobre novos pedidos realizados no site.",
    },
    {
      Header: "Integração Cadastro",
      accessor: "bf_aviso_rma",
      cols: "12",
      required: false,
      uniqueRow: true,
      observation:
        "Se o destinatário deve receber aviso da lista-resumo dos cadastros integrados do site para o ERP.",
    },
    {
      Header: "Aviso RMA",
      accessor: "bf_aviso_item_carrinho",
      cols: "12",
      required: false,
      uniqueRow: true,
      observation: "Se o destinatário deve receber avisos sobre RMA.",
    },
    {
      Header: "Item Carrinho",
      accessor: "bf_integra_cad_site2erp",
      cols: "12",
      required: false,
      uniqueRow: true,
      observation:
        "Se o destinatário deve receber avisos sobre carrinho pendente.",
    },
    {
      Header: "Avaliação de Crédito",
      accessor: "bf_aviso_aval_credito",
      cols: "12",
      required: false,
      uniqueRow: true,
      observation:
        "Se o destinatário deve receber avisos sobre avaliação de crédito no menu \"Meus dados\" no site do cliente.",
    },
    {
      Header: "Recuperação de Senha",
      accessor: "bf_recuperacao_senha",
      cols: "12",
      required: false,
      uniqueRow: true,
      observation:
        "Se o destinatário deve receber avisos sobre solicitação de recuperação de senha do cliente.",
    },    
  ];

  const subtitleForm: Array<typeCrudCollapse> = [
    {
      CollapseContent: complementsContent,
      CollapseTitle: "Informações",
    },
  ];
  return GenerateForm(dataFormDb, subtitleForm);
};

export default FormManufacturer;
