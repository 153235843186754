import { typeCrudListColumns } from "src/components/Common/typeCridList";
import {
  typeCrudCollapse,
  typeCrudForm,
} from "src/components/Common/typeCrudForm";
import GenerateForm from "src/components/CRUD/generateComponents";

const FormManufacturer = (props: {
  dataFormDb: {
    bf_liberation: Boolean;
    cr_code: String;
    ws_name: String;
    wh_image: String;
    ws_abbreviation: String;
    hl_abbreviation_url: String;
  };
  columnsTable: Array<typeCrudListColumns>;
  nextCrCode: string;
}) => {
  let { dataFormDb, columnsTable, nextCrCode } = props;
  if (nextCrCode) {
    dataFormDb.cr_code = nextCrCode;
  }

  const complementsContent: Array<typeCrudForm> = [
    {
      Header: "Código",
      accessor: "cr_code",
      cols: "6",
      required: true,
      uniqueRow: true,
    },
    {
      Header: "Vendedor",
      accessor: "cr_code_seller",
      cols: "6",
      required: true,
      uniqueRow: true,
      disabled: true,
    },
    /* {
      Header: "Operação Fiscal",
      accessor: "cr_code_seller",
      cols: "6",
      required: true,
      uniqueRow: true,
    }, */
    {
      Header: "Condição de Pagamento",
      accessor: "bf_liberation",
      cols: "4",
      required: false,
      uniqueRow: true,
    },
    {
      Header: "Condição de Pgto. Especial",
      accessor: "ws_name",
      cols: "6",
      required: true,
      uniqueRow: true,
    },
    {
      Header: "Já possui vendedor",
      accessor: "ws_abbreviation",
      cols: "6",
      required: false,
      uniqueRow: true,
      disabled: true,
    },
    {
      Header: "Razão Social",
      accessor: "ws_corporate_reason",
      subaccessor: "ox_registration_fields",
      cols: "6",
      required: false,
      disabled: true,
    },
    {
      Header: "CNPJ",
      accessor: "wj_cnpj",
      cols: "6",
      required: false,
      disabled: true,
    },
    {
      Header: "Inscrição Estadual",
      accessor: "wi_state_registration",
      subaccessor: "ox_registration_fields",
      cols: "6",
      required: false,
      disabled: true,
    },
    {
      Header: "Contato",
      accessor: "ct_qi_phone_contact",
      subaccessor: "ox_registration_fields",
      cols: "6",
      required: false,
      disabled: true,
    },
    {
      Header: "Email",
      accessor: "wm_email",
      subaccessor: "ox_registration_fields",
      cols: "6",
      required: false,
      disabled: true,
    },
  ];

  const addressContent: Array<typeCrudForm> = [
    {
      Header: "Endereço",
      accessor: "ws_street",
      subaccessor: "ox_registration_fields",
      subaccessorin: "qi_address",
      cols: "8",
      required: true,
      disabled: true,
    },
    {
      Header: "Número",
      accessor: "nb_street_number",
      subaccessor: "ox_registration_fields",
      subaccessorin: "qi_address",
      cols: "4",
      required: true,
      uniqueRow: true,
      disabled: true,
    },
    {
      Header: "Bairro",
      accessor: "ws_district",
      subaccessor: "ox_registration_fields",
      subaccessorin: "qi_address",
      cols: "8",
      required: false,
      disabled: true,
    },
    {
      Header: "CEP",
      accessor: "zp_zipcode",
      subaccessor: "ox_registration_fields",
      subaccessorin: "qi_address",
      cols: "4",
      required: true,
      uniqueRow: true,
      disabled: true,
    },
    {
      Header: "Cidade",
      accessor: "ws_city",
      subaccessor: "ox_registration_fields",
      subaccessorin: "qi_address",
      cols: "8",
      required: false,
      uniqueRow: true,
      disabled: true,
    },
  ];

  const bankContent: Array<typeCrudForm> = [
    {
      Header: "Fornecedor 1",
      accessor: "st_0",
      subaccessor: "ox_registration_fields",
      subaccessorin: "ws_supplier_reference",
      cols: "4",
      required: false,
      disabled: true,
    },
    {
      Header: "Fornecedor 2",
      accessor: "st_1",
      subaccessor: "ox_registration_fields",
      subaccessorin: "ws_supplier_reference",
      cols: "4",
      required: false,
      disabled: true,
    },
    {
      Header: "Fornecedor 3",
      accessor: "st_2",
      subaccessor: "ox_registration_fields",
      subaccessorin: "ws_supplier_reference",
      cols: "4",
      required: false,
      disabled: true,
    },
    {
      Header: "Banco 1",
      accessor: "st_0",
      subaccessor: "ox_registration_fields",
      subaccessorin: "ws_bank_reference",
      cols: "4",
      required: false,
      disabled: true,
    },
    {
      Header: "Agência 1",
      accessor: "st_0",
      subaccessor: "ox_registration_fields",
      subaccessorin: "ws_agency_reference",
      cols: "4",
      required: false,
      disabled: true,
    },
    {
      Header: "Telefone 1",
      accessor: "st_0",
      subaccessor: "ox_registration_fields",
      subaccessorin: "ct_contact_reference",
      cols: "4",
      required: false,
      disabled: true,
    },
    {
      Header: "Banco 2",
      accessor: "st_1",
      subaccessor: "ox_registration_fields",
      subaccessorin: "ws_bank_reference",
      cols: "4",
      required: false,
      disabled: true,
    },
    {
      Header: "Agência 2",
      accessor: "st_1",
      subaccessor: "ox_registration_fields",
      subaccessorin: "ws_agency_reference",
      cols: "4",
      required: false,
      disabled: true,
    },
    {
      Header: "Telefone 2",
      accessor: "st_1",
      subaccessor: "ox_registration_fields",
      subaccessorin: "ct_contact_reference",
      cols: "4",
      required: false,
      disabled: true,
    },
    {
      Header: "Banco 3",
      accessor: "st_2",
      subaccessor: "ox_registration_fields",
      subaccessorin: "ws_bank_reference",
      cols: "4",
      required: false,
      disabled: true,
    },
    {
      Header: "Agência 3",
      accessor: "st_2",
      subaccessor: "ox_registration_fields",
      subaccessorin: "ws_agency_reference",
      cols: "4",
      required: false,
      disabled: true,
    },
    {
      Header: "Telefone 3",
      accessor: "st_2",
      subaccessor: "ox_registration_fields",
      subaccessorin: "ct_contact_reference",
      cols: "4",
      required: false,
      disabled: true,
    },
  ];

  const resumeContent: Array<typeCrudForm> = [
    {
      Header: "Tipo Cliente",
      accessor: "sl_type_client",
      cols: "4",
      required: true,
      disabled: true,
    },
    {
      Header: "Tipo Frete",
      accessor: "sl_type_transport",
      cols: "4",
      required: true,
      disabled: true,
    },
    {
      Header: "Tipo Curva",
      accessor: "sl_curve_type",
      cols: "4",
      required: false,
      disabled: true,
    },
    {
      Header: "Condição Tributária",
      accessor: "nb_tax_condition",
      cols: "4",
      required: true,
      disabled: true,
    },
    {
      Header: "Cadastro Restrito",
      accessor: "bf_restricted_registry",
      cols: "4",
      required: false,
    },
    {
      Header: "Possui Crédito",
      accessor: "bf_own_credit",
      cols: "4",
      required: false,
    },
    {
      Header: "Gera Orçamento",
      accessor: "sl_generate_budget",
      cols: "4",
      required: false,
      uniqueRow: true,
    },
    {
      Header: "Data Cadastro",
      accessor: "dx_register",
      cols: "6",
      required: false,
      disabled: true,
    },
    {
      Header: "Data Integr. ERP",
      accessor: "dx_modification",
      cols: "6",
      required: false,
      disabled: true,
    },
    {
      Header: "Data último Login",
      accessor: "dx_modification",
      cols: "6",
      required: false,
      disabled: true,
    },
    {
      Header: "Data de aprovação",
      accessor: "dx_modification",
      cols: "6",
      required: false,
      disabled: true,
    },
  ];

  const subtitleForm: Array<typeCrudCollapse> = [
    {
      CollapseContent: complementsContent,
      CollapseTitle: "Informações de identificação",
    },
    {
      CollapseContent: addressContent,
      CollapseTitle: "Informações de Endereço",
    },
    {
      CollapseContent: bankContent,
      CollapseTitle: "Informações bancarias",
    },
    {
      CollapseContent: resumeContent,
      CollapseTitle: "Informações complementares",
    },
  ];
  return GenerateForm(dataFormDb, subtitleForm);
};

export default FormManufacturer;
