import { ContentState, convertToRaw, EditorState } from "draft-js"
import draftToHtml from "draftjs-to-html"
import htmlToDraft from "html-to-draftjs"
import React, { useEffect, useState } from "react"
import { Editor } from "react-draft-wysiwyg"
import MetaTags from "react-meta-tags"

//import Breadcrumbs
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap"
import { post } from "src/helpers/api_helper"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { getPrivacyPolicy, updatePrivacyPolicy } from "./graphqlQuery"

const PrivacyPolicy = () => {
  const [dataFormDb, setDataFormDb] = useState("");
  const [valueField, setValueField] = useState("");
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const updateTextDescription = async state => {
    await setEditorState(state);

    const data = convertToRaw(editorState.getCurrentContent());
    setValueField(draftToHtml(data))
  };

  const html = '<p id="para"></p>';
  const contentBlock = dataFormDb ? htmlToDraft(dataFormDb) : "";
  const cr_code = "1";

  /* Lógica de requisições BACK END */
  const getDataPolicy = React.useCallback(async () => {
    try {
      let response = await post(
        "/graphql",
        {
          query: getPrivacyPolicy(cr_code),
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then(response => response)
        .catch(error => alert("Falha ao buscar Política de Privacidade!"));
      if (response.data) {
        setDataFormDb(response.data.getLgpdPreview.wt_content)
      }
    } catch (e) {
      setValueField("");
    }
  }, []);

  const updateDataPolicy = React.useCallback(async () => {
    if (!valueField) {
      alert("Nenhuma alteração realizada!")
      return
    }
    const data = {
      cr_code: cr_code,
      ht_content: valueField,
    }
    try {
      await post(
        "/graphql",
        {
          query: updatePrivacyPolicy(data),
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then(response => {
          if(response.data.updateLgpd.status=== 200){
            alert(response.data.updateLgpd.result)
            getDataPolicy();
          }
        })
        .catch(error => {
          console.log(error)
          alert("Falha ao editar Política de Privacidade!")
        });
    } catch (e) {
      console.log(e);
    }
  }, [valueField]);

  useEffect(() => {
    getDataPolicy();
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const editorState = EditorState.createWithContent(contentState);
      setEditorState(editorState);
    }
  }, [dataFormDb]);

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Política de Privacidade | Braile Distribuidora</title>
        </MetaTags>
        <Container fluid style={{ display: 'flex', flexDirection: 'column'}}>
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title="Outros"
            breadcrumbItem="Política de Privacidade"
          />
          <Card style={{ backgroundColor: "#f1f1f1" }}>
            <CardBody>
              <Row>
                <Col className="mt-3" lg={12} sm={12} md={12} xs={12}>
                  <Editor
                    editorState={editorState}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    editorStyle={{ height: "45vh", backgroundColor: "white" }}
                    onEditorStateChange={updateTextDescription}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Button
            color="success"
            onClick={updateDataPolicy}
            style={{ marginLeft: 'auto', marginBottom: '10px' }}
          >
            Salvar
          </Button>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default PrivacyPolicy;
