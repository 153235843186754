import _ from "lodash"
import { Col } from "reactstrap"
import { typeCrudForm } from "src/components/Common/typeCrudForm"
import AvFieldBraile from "src/components/general/Input/AvFieldBraile";
import { Observation } from "../styled"

// Input # URL
export default function getHL(
  valueField: any,
  subtitleFields: typeCrudForm,
  dataFormDb: any
): any {
  return (
    <Col
      className="mt-3"
      lg={subtitleFields.cols}
      sm={subtitleFields.cols}
      md={subtitleFields.cols}
      xs={12}
    >
      <AvFieldBraile
        disabled={subtitleFields.disabled}
        value={valueField}
        name={subtitleFields.accessor}
        id={
          subtitleFields.idfield
            ? subtitleFields.idfield
            : subtitleFields.accessor
        }
        label={subtitleFields.Header}
        placeholder=""
        maxLength="300"
        type="text"
        errorMessage="URL obrigatória"
        validate={{ required: { value: subtitleFields.required } }}
        onChange={e =>
          _.set(dataFormDb, subtitleFields.accessor, e.target.value)
        }
      />
      {subtitleFields.observation && (
        <Observation>{subtitleFields.observation}</Observation>
      )}
    </Col>
  );
}