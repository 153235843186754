import _ from "lodash"
import React, { useEffect, useState } from "react"
import { Col } from "reactstrap"
import { typeCrudForm } from "src/components/Common/typeCrudForm"
import AvFieldBraile from "src/components/general/Input/AvFieldBraile";
import { Observation } from "../styled"

// Input # Carrega a lista pre carregada com icones
export default function getSI(
  valueField: any,
  subtitleFields: typeCrudForm,
  dataFormDb: any
): any {  
  const [icon, setIcon] = useState('AiOutlineGlobal')
  const [itens, setItens] = React.useState([]);
  if (valueField === null || valueField === undefined)
    _.set(
      dataFormDb,
      subtitleFields.accessor,
      subtitleFields.default ? subtitleFields.default : ""
    );
  if (subtitleFields.cols === "0") return "";
  // let maps = [];
  let valueInit: any = "Selecione";
  // Verifica se o valor é uma array, se não, cria uma array padrão para o options
  if (!Array.isArray(valueField)) {
    valueField = [
      {
        value: valueField,
        key: 1,
        check: true,
      },
    ];
  }
  // Sendo uma array, faz o filtro para trazer o deafult check
  if (valueField && valueField.length && valueField.length > 0) {
    valueInit = valueField.filter(item => Boolean(item.check) === true);
    valueInit = valueInit.length > 0 ? valueInit[0].value : "Selecione";
  } else {
    valueField = "0";
  }

  const options = [];

  {
    valueInit === "Selecione" &&
      options.push(
        <option key={0} value={""}>
          {valueInit}
        </option>
      );
  }

  subtitleFields &&
  subtitleFields.listItensIcons &&
  subtitleFields.listItensIcons.length &&
  subtitleFields.listItensIcons.length > 0 &&
  subtitleFields.listItensIcons[0].qi_subgroup &&
  subtitleFields.listItensIcons[0].qi_subgroup.length &&
  subtitleFields.listItensIcons[0].qi_subgroup.length > 0
    ? subtitleFields.listItensIcons.map((category, index) => {
        options.push(
          <option
            key={`subtitle_fields_options_${index}_${options.length}`}
            value={category.cr_code}
            style={{ fontWeight: "bold" }}
            disabled={true}
          >
            {category.ws_value}
          </option>
        );
        category.qi_subgroup.map(subcategory => {
          options.push(
            <option
              key={`subtitle_fields_options_${index}_${options.length}`}
              value={subcategory.cr_code}
              style={{ paddingLeft: "35px", left: "35px", marginLeft: "35px" }}
            >
              &#160;&#160;&#160;{subcategory.ws_name}
            </option>
          );
        });
      })
    : subtitleFields.listItensIcons.map((item, index) => {        
        options.push(
          <option key={`listItensIcons_${index}`} value={item.cr_code}>            
            {item.ws_value}
          </option>
        );
      });

  const getIcon = () => {
    let tmpIcon = ''
    if (icon && subtitleFields && subtitleFields.listItensIcons &&
      subtitleFields.listItensIcons.length &&
      subtitleFields.listItensIcons.length > 0) {
      let temp = subtitleFields.listItensIcons.filter(item => String(item.ws_value) === String(icon))      
      if (temp && temp.length && temp.length > 0 && temp[0].icon){
        tmpIcon = temp[0].icon
      }
    }
    return tmpIcon
  }

  useEffect(() => {
    if (valueInit) setIcon(valueInit)
  }, [valueInit])

  return (
    <Col
      className="mt-3"
      lg={subtitleFields.cols}
      sm={subtitleFields.cols}
      md={subtitleFields.cols}
      xs={12}      
    >
      <div style={{ width: '100%', display: 'flex' }}>
      <AvFieldBraile
        disabled={subtitleFields.disabled}
        value={valueInit}
        // defaultValue={valueInit}
        name={subtitleFields.accessor}
        id={
          subtitleFields.idfield
            ? subtitleFields.idfield
            : subtitleFields.accessor
        }
        label={subtitleFields.Header}
        placeholder=""
        type="select"
        errorMessage={
          subtitleFields.errorMessage
            ? subtitleFields.errorMessage
            : "Campo obrigatório"
        }
        validate={{ required: { value: subtitleFields.required } }}
        onChange={e => {
            setIcon(e.target.value)
            _.set(dataFormDb, subtitleFields.accessor, e.target.value)
          }
        }
      >
        {valueInit === "Selecione" && (
          <option key={0} value={""}>
            {valueInit}
          </option>
        )}
        {options}
      </AvFieldBraile>
      <div style={{ width: '100%', paddingTop: '25px', marginLeft: '15px' }}>
        {getIcon()}      
      </div>
      </div>
      {subtitleFields.observation && (
        <Observation>{subtitleFields.observation}</Observation>
      )}
    </Col>
  );
}