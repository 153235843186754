import React, { useCallback, useEffect, useRef, useState } from "react"
import styled from "styled-components"
import html2canvas from 'html2canvas';
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap"
import CardViewProduct from "src/components/CardViewProduct/Index"
import { post } from "src/helpers/api_helper"
import { getDayItem } from "../../graphqlQuery"
import { contentUrlServer, typeUrlServer } from "src/common/urlServer";
import {
  BoxDayItens,
  RowStyled,
  Flyer,
  ButtonDownload,
  Visualization,
  VisualizationFlyer,
  WrapperImage
} from "./styled"

const urlServer: typeUrlServer = contentUrlServer.filter(
  item => item.name == "STATIC"
)[0];

export const FormPreview = (props: { dataFormDb?: any, refresh?: boolean }) => {
  // Loading do day
  const [loadingDaySelecteds, setLoadingDaySelecteds] = useState(false);
  // Lista com todos os itens do day
  const [dataDaySelecteds, setDataDaySelecteds] = useState([]);
  // Dados do day (estrutura principal do crud)
  const { dataFormDb } = props;
  let listProducts = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  // Traz a lista de itens do day
  const getDataCrudGraphql = React.useCallback(async () => {
    setLoadingDaySelecteds(true);
    let tempPageCount = 0;
    if (!getDayItem) return;
    try {
      let response = await post(
        "/graphql",
        {
          query: getDayItem(dataFormDb.cr_code),
        },
        {
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
        .then(response => response)
        .catch(error => {
          setLoadingDaySelecteds(false);
        });
      if (
        response &&
        response.data &&
        response.data[Object.keys(response.data)[0]]
      ) {
        const count = response.data[Object.keys(response.data)[0]].result
          ? response.data[Object.keys(response.data)[0]].rowsall
          : 0;
        // mergeCollumnsRows(response.data[Object.keys(response.data)[0]]);
        setDataDaySelecteds(
          response.data[Object.keys(response.data)[0]].result
            ? response.data[Object.keys(response.data)[0]].result
            : response.data[Object.keys(response.data)[0]]
        );
      }
    } catch (e) {
      setDataDaySelecteds([]);
      alert(`Falha ao buscar lista!`);
    } finally {
      setLoadingDaySelecteds(false);
    }
  }, [getDayItem]);

  useEffect(() => {
    getDataCrudGraphql();
  }, []);

  //canvas
  const ref = useRef(null)

  const [flyer, setFlyer] = useState('');

  const options = {
    width: 950,
    marginLeft: 20,
    marginRight: 20,
    marginTop: 20,
    marginBottom: 20,
    footerAlign: 'left',
    gap: 5,
    textFontSize: 14,
    boxHeight: 440,
    boxWidth: 178,
    boxBorderColor: '#e4e4e4',
    priceAlign: 'left',
    priceCurrencyFontSize: 14,
    priceValueFontSize: 14,
    imgHeader: 'https://compras.braileonline.com.br/user/banner/banner-xalingo.jpg',
    imgFooter: 'https://cdn.discordapp.com/attachments/1012746827231146035/1022224607119417384/rodape.jpg',
  }

  const handleFlyer = useCallback(() => {
    if (ref.current === null) {
      return
    }

    html2canvas(ref.current, {
      allowTaint: true,
      useCORS: true
    }).then(canvas => {
      let imageData = canvas.toDataURL("image/png");
      imageData = imageData.replace(/^data:image\/png/, "data:application/octet-stream");
      setFlyer(imageData);
    })
  }, [ref]);

  const handleDownloadFlyer = useCallback(() => {
    if (ref.current === null) {
      return
    }

    html2canvas(ref.current, {
      allowTaint: true,
      useCORS: true
    }).then(canvas => {
      let imageData = canvas.toDataURL("image/png");
      imageData = imageData.replace(/^data:image\/png/, "data:application/octet-stream");

      const link = document.createElement('a')
      link.download = 'flyer.png'
      link.href = imageData
      link.click()
    })
  }, [ref]);

  useEffect(() => {
    setTimeout(() => {
      handleFlyer()
    }, 5000)
  }, [handleFlyer])

  // CardViewProduct
  return (
    <BoxDayItens>
      <Row>
        <Col className="col-12">
          <Card style={{ backgroundColor: "#e9ecef" }}>
            <CardBody>
              <CardTitle
                className="h6 mb-4 mt-4"
                style={{ textAlign: "center" }}
              >
                VISUALIZAÇÃO DOS PRODUTOS CONFORME O ESTADO
                <br />
                <br />
                <br />
                <RowStyled gap={20}>
                  {dataDaySelecteds.map(product => {
                    let totalPrice = '0,00';
                    let partPrice = '0,00';

                    // Remove as formatações se existir
                    let dc_day_price_sale_site = product.dc_day_price_sale_site.trim().split("$").pop().trim()
                    let dc_day_price_sale_site_forward = product.dc_day_price_sale_site_forward.trim().split("$").pop().trim()
                    dc_day_price_sale_site = dc_day_price_sale_site.replace(',', '.')
                    dc_day_price_sale_site_forward = dc_day_price_sale_site_forward.replace(',', '.')

                    totalPrice = Number(dc_day_price_sale_site).toLocaleString("pt-br", {
                      style: "currency",
                      currency: "BRL",
                    });
                    partPrice = Number(dc_day_price_sale_site_forward).toLocaleString("pt-br", {
                      style: "currency",
                      currency: "BRL",
                    });

                    return (
                      <Col>
                        <CardViewProduct
                          cardItem={{
                            image: product.ox_product.wh_image,
                            title: product.ox_product.ws_commercial_description,
                            subTitle: "",
                            codProduct: product.ox_product.cr_code,
                            totalPrice: totalPrice,
                            partPrice: partPrice,    // A prazo
                            partPriceFormat: partPrice,
                            discountPrice: totalPrice,    // A vista
                            discountPriceFormat: totalPrice,
                            bfHotDeal: false,
                            bfNewProduct: false,
                          }}
                          isLogged={false}
                          horizontal={false}
                          simple={false}
                          offerRed={false}
                          messages={
                            <div style={{ marginTop: '10px' }}>
                              {product.bf_price_site_automatic ? (<DivContent>Preço à vista automático</DivContent>) : <DivContent>Preço à vista manual</DivContent>}
                              {product.bf_price_site_forward_automatic ? (<DivContent>Preço à prazo automático</DivContent>) : <DivContent>Preço à prazo manual</DivContent>}
                              {product.ox_product.bf_discount_percentage && dataFormDb.sl_uf === "RS" ? (<DivContent>Diferimento: {product.ox_product.nc_discount_percentage} %</DivContent>) : <DivContentNot>Sem Diferimento</DivContentNot>}
                              {Number(dataFormDb.nc_percentage_discount) > 0 ? (<DivContent>Desconto Day: {dataFormDb.nc_percentage_discount} %</DivContent>) : <DivContentNot>Sem Desconto Day</DivContentNot>}
                            </div>
                          }
                        />
                      </Col>
                    )
                  })}
                </RowStyled>
              </CardTitle>
            </CardBody>
          </Card>
        </Col>
      </Row>
      {/* <Row className="d-flex justify-content-center" style={{ width: '950px' }}>
        <Flyer
          ref={ref}
          className="flyer"
          width={options.width}
          marginLeft={options.marginLeft}
          marginRight={options.marginRight}
          marginTop={options.marginTop}
          marginBottom={options.marginBottom}
        >
          <img src={options.imgHeader} width={'100%'} style={{ marginBottom: '10px' }} />
          <RowStyled gap={options.gap}>
            {dataDaySelecteds.map(product => {
              let totalPrice = '0,00';
              let partPrice = '0,00';

              // Remove as formatações se existir
              let dc_day_price_sale_site = product.dc_day_price_sale_site.trim().split("$").pop().trim()
              let dc_day_price_sale_site_forward = product.dc_day_price_sale_site_forward.trim().split("$").pop().trim()
              dc_day_price_sale_site = dc_day_price_sale_site.replace(',', '.')
              dc_day_price_sale_site_forward = dc_day_price_sale_site_forward.replace(',', '.')

              totalPrice = Number(dc_day_price_sale_site).toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
              });
              partPrice = Number(dc_day_price_sale_site_forward).toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
              });

              return (
                <CardViewProduct
                  cardItem={{
                    image: product.ox_product.wh_image,
                    title: product.ox_product.ws_commercial_description,
                    subTitle: "",
                    codProduct: product.ox_product.cr_code,
                    totalPrice: totalPrice,
                    partPrice: partPrice,    // A prazo
                    partPriceFormat: partPrice,
                    discountPrice: totalPrice,    // A vista
                    discountPriceFormat: totalPrice,
                    bfHotDeal: false,
                    bfNewProduct: false,
                  }}
                  isLogged={false}
                  horizontal={false}
                  simple={false}
                  offerRed={false}
                  messages={
                    <div style={{ marginTop: '10px' }}>
                      {product.bf_price_site_automatic ? (<DivContent>Preço à vista automático</DivContent>) : <DivContent>Preço à vista manual</DivContent>}
                      {product.bf_price_site_forward_automatic ? (<DivContent>Preço à prazo automático</DivContent>) : <DivContent>Preço à prazo manual</DivContent>}
                      {product.ox_product.bf_discount_percentage ? (<DivContent>Diferimento: {product.ox_product.nc_discount_percentage} %</DivContent>) : <DivContentNot>sem Diferimento</DivContentNot>}
                      {Number(dataFormDb.nc_percentage_discount) > 0 ? (<DivContent>Desconto Day: {dataFormDb.nc_percentage_discount} %</DivContent>) : <DivContentNot>sem Desconto Day</DivContentNot>}
                    </div>
                  }
                  options={options}
                />
              )
            })}
          </RowStyled>
          <img src={options.imgFooter} width={'100%'} style={{ marginTop: '10px' }} />
        </Flyer>
      </Row>
      <Row style={{ width: '100%', justifyContent: 'center' }}>
        <Visualization>
          <p>
            Esta é a pré-visualização dinâmica do Flyer a partir das configurações atuais de layout (mesmo sem gravar o registro). Tipo preço: <strong>Produto | A prazo</strong>
            <br />
            <strong>ATENÇÃO:</strong> enquanto o Flyer não for confirmado, esta pré-visualização é temporária.
          </p>
          <VisualizationFlyer background={urlServer.urlServer + urlServer.pathServer + 'fundo_quad.png'}>
            <WrapperImage>
              {flyer && flyer !== 'data:,' && <img src={flyer} width={'100%'} />}
            </WrapperImage>
          </VisualizationFlyer>
        </Visualization>

        <ButtonDownload onClick={handleDownloadFlyer}>Baixar flyer</ButtonDownload>
      </Row> */}
    </BoxDayItens>
  );
};

const DivContent = styled.div`
  width: 100%;
  background-color: #c2dfc4;
  margin-bottom: 5px;
  text-align: left;
  padding: 2px;
  font-size: 11px;
  border-radius: 5px;
  padding-left: 5px;
`

const DivContentNot = styled.div`
  width: 100%;
  background-color: #e9cccd;
  margin-bottom: 5px;
  text-align: left;
  padding: 2px;
  font-size: 11px;
  border-radius: 5px;
  padding-left: 5px;
`