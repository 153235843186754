import { Modal } from "reactstrap"
import { CRUD_MODE } from "src/constants/layout";

export default function modalSuccess(
  modal_success,
  setmodal_success,
  handleNewRegister,
  setSelectedRow,
  code
) {
  return (
    <Modal
      isOpen={modal_success}
      backdrop={"static"}
      id="staticBackdrop"
    >
      <div className="modal-header">
        <h5 className="modal-title" id="staticBackdropLabel">
          Clientes
        </h5>
        <button
          type="button"
          className="btn-close"
          onClick={() => {
            setmodal_success(false)
          }}
          aria-label="Close"
        ></button>
      </div>
      <div className="modal-body">
        <p style={{ color: 'green' }}>Email enviado com sucesso!</p>
        <p>Conforme solicitado, foi gerada uma nova senha de acesso ao site Braile:</p>
        <p style={{ fontSize: '20px', fontWeight: 'bold' }}>{code}</p>
        <p>A partir de agora, utilize-a para acessar o site, junto com o seu CNPJ.</p>

        <div className="modal-footer">
          <button type="button" className="btn btn-primary"
            onClick={() => {
              setmodal_success(false)
              handleNewRegister(CRUD_MODE.LIST)
              setSelectedRow(null)
            }}
          >
            Clientes
          </button>
          <button type="button" className="btn btn-primary" onClick={() => { setmodal_success(false) }}>
            Alterar cliente
          </button>
        </div>
      </div>
    </Modal>
  );
}
